import {APP_MENU_VISIBLE,APP_MODE} from "../constants";

const initialState = {
    openedMenu: null,
    appMode: false
};

export default function reducer(state = initialState, actions) {
    switch (actions.type) {
        case APP_MENU_VISIBLE:
            return {
                ...state,
                openedMenu: actions.payload
            };
        case APP_MODE:
            return {
                ...state,
                appMode: actions.payload
            };
        default:
            return {
                ...state,
                ...actions.payload
            }
    }
}
