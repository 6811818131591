import {spacing} from "./spacing"

export const layer = (buttonObject) => {
    return {
        backgroundColor: buttonObject?.bgColor?(buttonObject.bgColor):'',
        color: buttonObject?.fontColor?(buttonObject.fontColor):'',
        fontSize: buttonObject?.fontSize?(buttonObject.fontSize):'',
        border:(buttonObject?.borderWidth)?`solid ${buttonObject?.borderWidth}px ${buttonObject?.borderColor}`:'0',
        borderRadius: spacing(buttonObject?.borderRadius??{}),
        padding: spacing(buttonObject?.padding??{}),
        margin: spacing(buttonObject?.margin??{})
    }
}
