import React,{useState,useEffect} from "react";
import API from "../../utils/API";
import Select from "react-select";

const TemplateCategory = ({name,value,onChange}) => {
    const [source,setSource] = useState(null)
    const [values, setValues] = useState([])

    const changeSelect = (value) => {
        let i,values = []
        for (i=0;i<value?.length;i++) {
            values.push(value[i].value)
        }
        if (name) {
            onChange(name,values)
        }
    }

    useEffect(()=>{
        if (source && value) {
            let i,valueTemp = [];
            for (i=0;i<source.length;i++) {
                if (value.includes(source[i].value)) {
                    valueTemp.push({value:source[i].value,label:source[i].label})
                }
            }
            setValues(valueTemp)
        }
    },[source,value])

    useEffect(() => {
        API.get('/admin/system/templatecategories').then((response) => {
            let options,i,optionsList = []
            if (response.data.data) {
                options = response.data.data
                for (i=0;i<options.length;i++) {
                    optionsList.push({value:options[i].id,label:options[i].title})
                }
            }
            setSource(optionsList)
        });
    }, [])

    return (
        <Select
            className="react-select-container"
            classNamePrefix="react-select"
            options={source}
            value={values}
            onChange={(value)=>changeSelect(value)}
            isMulti
            placeholder="Template Category"
        />
    )

}

export default TemplateCategory;
