import React, {useEffect, useState} from "react";
import "../../assets/style/profile.scss";
import ImageUploader from "react-images-upload";
import API from "../../utils/API";
import TextEditor from "../../components/builder/TextEditor";
import {useNavigate, useParams} from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import {Store} from "react-notifications-component";

const Post = () => {
    const [postData, setPostData] = useState({title: null, content: null, image: null})

    let {id} = useParams();
    let {appid} = useParams()
    let navigate = useNavigate()

    const [category,setCategory] = useState([])
    const [loaded,setLoaded] = useState(false)

    useEffect(() => {
        if (appid) {
            API.post(`/blogpost/categories/${appid}`).then((result) => {
                setCategory(result.data.data)
            })
        }
        if (id) {
            API.get(`/blogpost/post/${appid}/${id}`).then((result) => {
                setPostData(result.data.data)
                setLoaded(true)
            })
        }else {
            setLoaded(true)
        }
    },[appid,id])

    const setValue = (name, value) => {
        setPostData({...postData, [name]: value})
    }

    const onDrop = (picture,pictureURI) => {
        if (pictureURI.length > 0) {
            API.post('/imagemulti/' + appid, {pictureURI}).then((response) => {
                setValue('image', response.data.data)
            });
        }else {
            setValue("image", null)
        }
    };

    const onSelect = (name, selectedList, selectedItem) => {
        setValue(name, selectedList)
    }

    const onRemove = (name, selectedList, removedItem) => {
        setValue(name, selectedList)
    }

    const savePost = () => {
        API.post(`/blogpost/post/${id?'save':'add'}/${appid}${id?('/'+id):''}`,postData).then((result) => {
            Store.addNotification({
                title: "Success!",
                message: "Post saved successfully",
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
            navigate('/blogpost/'+appid+'/posts');
        });
    }

    return (
        <div className="mainPage ecommerce">
            <div className="hero">
                <div className="row">
                    <div className="col">
                        <span className="title">Post</span>
                    </div>
                    <div className="col-auto">
                        <div className="row">
                            <div className="col-auto">
                                <button className="btn newProductButton cursorPointer ps-3 pe-3"
                                        disabled={!postData?.title} onClick={() => savePost()}>Save
                                </button>
                            </div>
                            <div className="col-auto">
                                <div className="secondaryButton ps-3 pe-3"
                                     onClick={() => navigate('/blogpost/' + appid + '/posts')}>X
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="product">
                <div className="tabNavigation mt-3">
                    <div className="header m-3 p-3 typeTitle">
                        Post description
                    </div>
                    <div className="ms-4 mt-1 mb-3">
                        <div className="row profilePage m-0 p-0 me-4">
                            <div className="col-3 editArea text-center pt-4">
                                <label>Images</label>
                                <ImageUploader
                                    withIcon={true}
                                    onChange={onDrop}
                                    imgExtension={[".jpg",".jpeg", ".gif", ".png", ".gif", ".webp"]}
                                    maxFileSize={5242880}
                                    withPreview={true}
                                    defaultImages={postData?.image?postData.image:[]}
                                />
                            </div>
                            <div className="col-8 offset-1 editArea p-4">
                                <div className="row mt-1">
                                    <div className="col-12">
                                        <label>Category</label>
                                        {category &&
                                            <Multiselect
                                                options={category}
                                                selectedValues={postData.category}
                                                onSelect={(selectedList, SelectedItem) => onSelect('category',selectedList, SelectedItem)}
                                                onRemove={(selectedList, SelectedItem) => onRemove('category',selectedList, SelectedItem)}
                                                displayValue="title"
                                                placeholder=""
                                            />
                                        }
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-12">
                                        <label>Post name</label>
                                        <input type="text" className="form-control"
                                               onChange={(e) => setValue('title', e.target.value)}
                                               placeholder="Write the title message" value={postData.title}/>
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-12">
                                        <label>Post content</label>
                                        {loaded &&
                                            <TextEditor setValue={setValue} name={'content'} value={postData.content}/>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Post
