import React,{useState} from "react"
import { SketchPicker } from 'react-color'

const ColorPicker = ({setValue, name, value, featureName}) => {

    const [showPicker,setShowPicker] = useState(false)

    const styles = {
        color: {
            width: '36px',
            height: '20px',
            borderRadius: '2px',
            background: value,
        },
        swatch: {
            padding: '5px',
            background: '#fff',
            borderRadius: '1px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer',
        },
        popover: {
            position: 'relative'
        },
        cover: {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        },
    };

    return <>
        <div className="row">
            <div className="col pt-2">
                <span className="blockTitle">{featureName}</span>
            </div>
            <div className="col-auto pt-2">
                <div>
                    <div style={styles.swatch} onClick={()=>setShowPicker(!showPicker)}>
                        <div style={styles.color}/>
                    </div>
                </div>
            </div>
        </div>
        {showPicker ? <div className="row justify-content-center"><div className="col-auto"><div style={styles.popover}>
            <div style={styles.cover} onClick={()=>setShowPicker(false)}/>
            <SketchPicker color={value} onChange={(color)=>{setValue(name,color.hex)}}/>
        </div></div></div> : null}
    </>
}

export default ColorPicker
