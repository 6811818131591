import React, {useEffect, useState} from "react";
import {useSelector,useDispatch} from "react-redux";
import uniqid from "uniqid";
import {set} from "../../../redux/actions/pageeditorActions";
import API from "../../../utils/API";

const SectionAdd = () => {

    const dispatch = useDispatch()

    const [page,setPage] = useState()

    const pageeditor = useSelector(state => {
        return state.pageEditor
    });

    useEffect(()=>{
        API.get(`/page/${pageeditor.page}`).then((response) => {
            setPage(response.data.data.page)
        })
    },[pageeditor.page])

    const addSection = () => {
        let currentItems = pageeditor.sections

        currentItems.push({
            elements: [
                {
                    id:uniqid()
                }
            ],
            layout: [12]
        })

        dispatch(set({sections:currentItems,editSectionId:0,editType:"section"}))
    }

    return <>
        {(page && (page.issplashscreen !== '1' || (page.issplashscreen === '1' && !pageeditor?.sections?.length))) &&
        <div className="row addSectionButton mb-2 mt-2 ms-0 me-0">
            <div className="col pt-1">
                <hr className="line"/>
            </div>
            <div className="col-auto">
                <div className="addIcon" onClick={()=>addSection()}>+</div>
            </div>
            <div className="col pt-1">
                <hr className="line"/>
            </div>
        </div>
        }
    </>
}

export default SectionAdd
