import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import "../../../assets/style/sections.scss";
import "../../../assets/style/blockEdit.scss";
import {build} from "../../../components/builder/blocks/BlockFactory";
import {setContent} from "../../../redux/actions/pageeditorActions";
import API from "../../../utils/API";
import _ from "lodash"
import {useIsTablet} from "../../../hooks/useIsTablet";

const BlockEditor = (props) => {

    const [block,setBlock] = useState(null)

    const [blockData,setBlockData] = useState(null)

    const pageeditor = useSelector(state => {
        return state.pageEditor
    });

    const dispatch = useDispatch()

    const isTablet = useIsTablet()

    useEffect(()=>{
        if (block && block.component) {
            API.get('/user/block/byslug/'+block.component).then((response) => {
                setBlockData(response.data.data)
            })
        }
    },[block])

    const setValue = (name, value) => {

        if (pageeditor.editBlockId) {
            let i, j, n = pageeditor.sections.length, k, newData = [], editBlock, newSection, dataBlock

            let isPersistentBlock = false;
            let persistentBlockId = null;
            let persistentBlockData = null;
            let persistentBlocks = [];

            for (i = 0; i < n; i++) {
                newSection = {elements : []}
                if (pageeditor.sections[i].layout) {
                    newSection.layout = pageeditor.sections[i].layout
                }

                k = pageeditor.sections[i].elements.length

                for (j = 0; j < k; j++) {
                    if (pageeditor.sections[i].elements[j]?.data?.persistDataAcrossAllInstances === true) {
                        persistentBlocks.push(pageeditor.sections[i].elements[j]?.data?.persistentTemplateId)
                    }

                    if (pageeditor.sections[i].elements[j].id === pageeditor.editBlockId) {
                        editBlock = JSON.parse(JSON.stringify(pageeditor.sections[i].elements[j]))
                        dataBlock = editBlock.data??{}
                        _.set(dataBlock, name, value);

                        if (dataBlock.persistDataAcrossAllInstances === true) {
                            isPersistentBlock = true;
                            persistentBlockId = dataBlock.persistentTemplateId;
                            persistentBlockData = JSON.parse(JSON.stringify(dataBlock));
                        }

                        editBlock.data = dataBlock
                        newSection.elements.push(editBlock)
                    }else {
                        newSection.elements.push(pageeditor.sections[i].elements[j])
                    }
                }

                newData.push(newSection)
            }

            if (isPersistentBlock && persistentBlocks.filter(x => x===persistentBlockId).length > 1) {
                newData = [];

                for (i = 0; i < n; i++) {
                    newSection = {elements : []}
                    if (pageeditor.sections[i].layout) {
                        newSection.layout = pageeditor.sections[i].layout
                    }

                    k = pageeditor.sections[i].elements.length

                    for (j = 0; j < k; j++) {
                        if (pageeditor.sections[i].elements[j]?.data?.persistentTemplateId === persistentBlockId) {
                            editBlock = JSON.parse(JSON.stringify(pageeditor.sections[i].elements[j]))
                            editBlock.data = JSON.parse(JSON.stringify(persistentBlockData));
                            newSection.elements.push(editBlock)
                        }else {
                            newSection.elements.push(pageeditor.sections[i].elements[j])
                        }
                    }

                    newData.push(newSection)
                }
            }

            dispatch(setContent({sections:newData,isPersistentBlock,persistentBlockId,persistentBlockData:persistentBlockData}))
        }
    }

    useEffect(()=>{
        if (pageeditor.editBlockId) {
            let i, j, n = pageeditor.sections.length, k

            for (i = 0; i < n; i++) {
                k = pageeditor.sections[i].elements.length

                for (j = 0; j < k; j++) {
                    if (pageeditor.sections[i].elements[j].id === pageeditor.editBlockId) {
                        setBlock(pageeditor.sections[i].elements[j])
                    }
                }
            }
        }
    },[pageeditor])

    return <div className={`blocksContainer manageBlock sectionEditor ${!isTablet && 'ms-auto'} me-0`}>
        <div className="titleBlock">
            {blockData &&
                <div className="title sectionEditBg">
                    <div className="row">
                        <div className="col-auto iconBlock">
                            <img src={blockData.image} style={{width: "38px", height: "37px"}}/>
                        </div>
                        <div className="col name sectionEditText">
                            edit {blockData.title}
                        </div>
                    </div>
                </div>
            }
        </div>
        <div className="list blockEdit">
            {block &&
                React.cloneElement(build(block.component),{...block, ...{edit:true,setValue:setValue}})
            }
        </div>
    </div>
}

export default BlockEditor
