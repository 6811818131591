import React from "react";
import SliderInput from "../../editor/subelements/SliderInput";
import Animation from "../../editor/Animation";
import {MdAnimation} from "react-icons/md";
import {useCustomAnimation} from "../../helpers/animation";
import ColorPicker from "../../editor/subelements/ColorPicker";
import Spacing from "../../editor/subelements/Spacing";
import {bgImage} from "../../helpers/bgImage";
import GooglePlaceSelect from "../../editor/subelements/GooglePlaceSelect";
import { GoogleMap as GoogleMapModule,Marker } from '@react-google-maps/api';
import {useClickDisable} from "../../helpers/clickDisable";

const GoogleMap = (props) => {
    const animation = useCustomAnimation(props.data.animation)
    const clickDisable = useClickDisable()

    if (props.edit) {
        return <div>
            <div className="editItem p-2">
                <div className="subElement subElementNoBorderTop">
                    <GooglePlaceSelect name={"center"} value={props.data?.center} setValue={props.setValue} featureName={"Place"}/>
                </div>
                <div className="subElement">
                    <SliderInput name={"zoom"} value={props.data?.zoom??10} setValue={props.setValue} featureName={"Zoom"} min={1} max={20} type={'x'}/>
                </div>
                <div className="subElement">
                    <SliderInput name={"height"} value={props.data?.height??400} setValue={props.setValue} featureName={"Height"} min={100} max={1000}/>
                </div>
                <div className="subElement">
                    <ColorPicker name={"bgColor"} value={props.data?.bgColor} setValue={props.setValue} featureName={"Background color"}/>
                </div>
                <div className="subElement">
                    <Spacing name={"margin"} value={props.data?.margin} setValue={props.setValue} featureName={"Margin"}/>
                </div>
                <div className="subElement">
                    <Spacing name={"padding"} value={props.data?.padding} setValue={props.setValue} featureName={"Padding"}/>
                </div>
                <div className="subElement">
                    <Spacing name={"borderRadius"} value={props.data?.borderRadius} setValue={props.setValue} featureName={"Border radius"}/>
                </div>
                <div className="subElement">
                    <ColorPicker name={"borderColor"} value={props.data?.borderColor} setValue={props.setValue} featureName={"Border color"}/>
                </div>
                <div className="subElement">
                    <SliderInput name={"borderWidth"} value={props.data?.borderWidth} setValue={props.setValue} featureName={"Border width"} min={0} max={20}/>
                </div>
            </div>
            <div className="editItem">
                <Animation name={'animation'} setValue={props.setValue} value={props.data.animation} featureName="Animation" imageName={<MdAnimation style={{fontSize:"18px"}}/>}/>
            </div>
        </div>
    }else {
        return <div className={clickDisable}><div style={bgImage(props?.data)??{}} className={animation}>{props?.data?.center &&
            <GoogleMapModule
                mapContainerStyle={{
                    width: '100%',
                    height: `${props.data?.height??400}px`
                }}
                center={props?.data?.center}
                zoom={props.data?.zoom??10}
            >
                <Marker
                    position={props?.data?.center}
                />
            </GoogleMapModule>
        }</div></div>
    }
}

export default GoogleMap
