import React from "react";
import {buttons,MenuIcon} from "../../helpers/MenuIcon";

const MenuButtonPicker = ({setValue, name, value, featureName}) => {
    return <div className="p-2">
        <div className="row">
            <div className="col">
                <span className="blockTitle">{featureName}</span>
            </div>
        </div>
        <div className="row ps-1 justify-content-center">
                {buttons.map((button, key) => {
                    return (
                        <div className="col-auto">
                        <div
                            className={`p-2 text-center cursor-pointer ${value === button ? "border-dark border" : ""}`}
                            onClick={() => setValue(name, button)} key={key}>
                            <MenuIcon name={button}/>
                        </div>
                        </div>
                    )
                })}
        </div>
    </div>
}

export default MenuButtonPicker
