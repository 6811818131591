import React,{useState,useEffect} from "react";
import "../assets/style/mainpage.scss"
import "../assets/style/shop.scss"
import {useNavigate} from "react-router-dom";
import {FaPlus} from "react-icons/fa";
import Modal from "react-modal";
import API from "../utils/API";

const Templates = () => {
    let navigate = useNavigate()
    const [newTemplate, setNewTemplate] = useState(false)
    const [newTemplateName, setNewTemplateName] = useState(null)
    const [newTemplateApp, setNewTemplateApp] = useState(null)
    const [availableApps, setAvailableApps] = useState([])
    const [templates, setTemplates] = useState([])
    const [performances, setPerformances] = useState(null)

    useEffect(() => {
        API.post("/templates/availableapps").then(res=>{
            setAvailableApps(res.data.data)
        });
        API.post("/templates/getall").then(res=>{
            setTemplates(res.data.data);
        })
        API.get("/templates/marketplace/performance").then(res=>{
            setPerformances(res.data.data)
        })
    },[])

    const createTemplate = () => {
        if (newTemplateName && newTemplateApp) {
            API.post("/templates", {
                title: newTemplateName,
                app: newTemplateApp
            }).then(res=>{
                setNewTemplate(false)
                navigate(`/shop/template/${res.data.data.id}`)
            })
        }
    }

    return <div className="mainPage shop">
        <div className="hero">
            <div className="row mb-2">
                <div className="col">
                    <span className="title">My Templates</span>
                    {performances &&
                    <div className="row">
                        <div className="col-auto">
                            <div className="m-1 fs-6">Purchases: <strong>{performances.purchases}</strong></div>
                            <div className="m-1 fs-6">Revenue: <strong>${performances.revenue}</strong></div>
                        </div>
                        <div className="col-auto">
                            <button className="btn btn-outline-primary primaryButton mt-2 mb-2" type="button" onClick={()=>{
                                navigate(`/shop/statistics`)
                            }}>Store performance</button>
                        </div>
                    </div>
                    }
                </div>
                <div className="col-auto">
                    <button className="btn btn-outline-primary primaryButton mt-2 mb-2" type="button" onClick={()=>{
                        setNewTemplate(true)
                    }}>Add template</button>
                </div>
            </div>
        </div>
        <div className="m-2">
            <div className="row">
                {templates.length > 0 &&
                    templates.map(template => {
                        return <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="template">
                                {template.template.image &&
                                    <img src={template.template.image} className="img-fluid image"/>
                                }
                                <div className="title mt-2 mb-2">
                                    {template.template.title} {template.template.status ? <span className="badge bg-success">Published</span> : <span className="badge bg-secondary">Unpublished</span>}
                                </div>
                                <div className="row">
                                    <div className="col-auto">
                                        <button className="btn btn-outline-primary primaryButton mt-2 mb-2" type="button" onClick={()=>navigate(`/app/${template.app.id}/pages`)}>Edit template</button>
                                    </div>
                                    <div className="col-auto">
                                        <button className="btn btn-outline-success primaryButton mt-2 mb-2" type="button" onClick={()=>navigate(`/shop/template/${template.template.id}`)}>Template settings</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
        <Modal
            isOpen={newTemplate}
            className="modal-window newPageModal"
            overlayClassName="modal-overlay"
            onRequestClose={()=>setNewTemplate(false)}
        >
            <div>
                <div className="modalTitle row justify-content-center">
                    <div className="col-auto icon">
                        <FaPlus/>
                    </div>
                    <div className="col-auto text pt-2">
                        Add template
                    </div>
                </div>
                {availableApps.length > 0 ?
                    <>
                    <div className="row justify-content-center mt-3 mb-3">
                        <div className="col-10">
                            <lable>App as a template</lable>
                            <select className="form-control" placeholder="Select app" onChange={(e) => {setNewTemplateApp(e.target.value)}}>
                                <option>Select app</option>
                                {availableApps.map((app, index) => {
                                    return <option key={index} value={app.id}>{app.title}</option>
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-3 mb-3">
                        <div className="col-10">
                            <lable>Template name</lable>
                            <input className="form-control" placeholder="Template name" onChange={(e) => {setNewTemplateName(e.target.value)}}/>
                        </div>
                    </div>
                    <div className="row justify-content-center mb-2">
                        <div className="col-auto">
                            <div className="button pt-2" onClick={()=>createTemplate()}>Create template</div>
                        </div>
                    </div>
                    </>
                    :
                    <div className="row justify-content-center mb-2 mt-2">
                        <div className="col-auto">
                            Create an app before creating a template
                        </div>
                    </div>
                }
            </div>
        </Modal>
    </div>
}

export default Templates;
