import React,{useState} from "react";
import bg from "../assets/image/signup.png"
import google from "../assets/image/google.png"
import {useSelector,useDispatch} from "react-redux";
import API from "../utils/API";
import {login} from "../redux/actions/userActions";
import {
    useNavigate
} from "react-router-dom";
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import TermsOfService from "./TermsOfService";
import PrivacyPolicy from "./PrivacyPolicy";

const Signup = () => {
    const [email,setEmail] = useState(null)
    const [password,setPassword] = useState(null)
    const [passwordConfirm,setPasswordConfirm] = useState(null)
    const [fullName,setFullName] = useState(null)
    const [confirmTerms,setConfirmTerms] = useState(false)
    const [error,setError] = useState(null)
    const [loading,setLoading] = useState(false)

    let navigate = useNavigate()

    const sign = useSelector(state => {
        return state.user.sign
    });

    const signup = useGoogleLogin({
        onSuccess: (codeResponse) => {
            setLoading(true)
            API.post('/auth/google/signup', {token: codeResponse.access_token})
                .then(data => {
                    dispatch(login(data.data.data));
                    setLoading(false)
                    if (data.data.data.newUser) {
                        navigate("/billing");
                    }else {
                        navigate("/");
                    }
                })
                .catch(error => {
                    setError('User not found')
                    setLoading(false)
                })
        },
        onError: (error) => console.log('Login Failed:', error),
        ux_mode: 'popup'
    });

    const dispatch = useDispatch();

    const submit = () => {
        let errors = false;
        if (!email) {
            errors = true;
            setError('Email is required')
        }
        if (!password) {
            errors = true;
            setError('Password is required')
        }
        if (password!== passwordConfirm) {
            errors = true;
            setError('Passwords do not match')
        }
        if (!fullName) {
            errors = true;
            setError('Full name is required')
        }
        if (!confirmTerms) {
            errors = true;
            setError('You must agree to the terms and conditions')
        }
        if (password && password.length < 8) {
            errors = true;
            setError('Password must be at least 8 characters')
        }
        if (errors) {
            return
        }

        if (!loading) {
            setLoading(true)
            API.post('/signup', {email: email, password: password, name:fullName})
                .then(data => {
                    console.log('before navigate to billing');
                    dispatch(login(data.data.data));
                    setLoading(false)
                    console.log('navigate to billing');
                    navigate("/billing");
                })
                .catch(error => {
                    setError('Email already exists. Please try another email')
                    setLoading(false)
                })
        }
    }

    return (
        <div className="auth main d-flex w-100 justify-content-center">
            <div className="row w-100 content justify-content-center">
                <div className="col-xl-8 col-lg-10 col-md-12 col-sm-12">
                    <div className="row m-0 p-0 centerBlock justify-content-center">
                        <div className="col-md col-sm-12 m-0 p-0" style={{minHeight:"200px",backgroundImage:`url(${bg})`,backgroundRepeat: "no-repeat",backgroundPosition : "center",backgroundSize: "cover"}}/>
                        <div className="col-md col-sm-12">
                            <div className="text-end p-2">
                                <span className="logoTitle">APP WORLD</span>
                            </div>
                            <div className="form">
                                <div>
                                    <span className="title">Welcome!</span>
                                    {error &&
                                        <div className="text-center text-danger">{error}</div>
                                    }
                                    <div className="row">
                                        <div className="col-12 mb-2">
                                            <label className="label">
                                                <span className="h6 small bg-white text-muted px-1">Full name</span>
                                            </label>
                                            <input type="text" className="form-control mt-2" onChange={(e) => {setFullName(`${e.target.value}`);setError(null)}}/>
                                        </div>
                                        <div className="col-12 mb-2">
                                            <label className="label">
                                                <span className="h6 small bg-white text-muted px-1">Email</span>
                                            </label>
                                            <input type="text" className="form-control mt-2" onChange={(e) => {setEmail(`${e.target.value}`);setError(null)}}/>
                                        </div>
                                        <div className="col-12 mb-2">
                                            <label className="label">
                                                <span className="h6 small bg-white text-muted px-1">Password</span>
                                            </label>
                                            <input type="password" className="form-control mt-2" onChange={(e) => {setPassword(`${e.target.value}`);setError(null)}}/>
                                        </div>
                                        <div className="col-12 mb-2">
                                            <label className="label">
                                                <span className="h6 small bg-white text-muted px-1">Password confirmation</span>
                                            </label>
                                            <input type="password" className="form-control mt-2" onChange={(e) => {setPasswordConfirm(`${e.target.value}`);setError(null)}}/>
                                        </div>
                                        <div className="col-12 mb-3 mt-3">
                                            <label><input type="checkbox" checked={confirmTerms} onChange={()=>setConfirmTerms(!confirmTerms)}/> I agree to the <TermsOfService/> and <PrivacyPolicy/></label>
                                        </div>
                                        <div className="col-12 mb-2 mt-2">
                                            <button className="btn btn-primary w-100 button" onClick={submit}>
                                                {loading ?
                                                    <>Loading...</>
                                                    :
                                                    <>Create account</>
                                                }
                                            </button>
                                        </div>
                                        <div className="col-12 mb-3 mt-3">
                                            <div className="strike">
                                                <span className="orSignIn">Or sign up with</span>
                                            </div>
                                        </div>
                                        <div className="col-12 mb-2 mt-2 text-center">
                                            <div onClick={() => signup()} className="cursorPointer"><img src={google} style={{width:"20px"}}/> <span className="googleSignIn">Sign up with Google</span></div>
                                        </div>
                                        <div className="col-12 mb-3 mt-3 text-center">
                                            <span className="orSignUp cursorPointer" onClick={()=>navigate("/signin")}>Already have an account? <span className="text-primary">Sign In</span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Signup
