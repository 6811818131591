export const spacing = (data) => {
    if (data?.type !== 'percent') {
        if (data?.spaceType !== 'separate') {
            return `${data?.spaceAll??0}px`;
        }else {
            return `${data?.spaceTop??0}px ${data?.spaceRight??0}px ${data?.spaceBottom??0}px ${data?.spaceLeft??0}px`;
        }
    }else {
        if (data?.spaceType !== 'separate') {
            return `${data?.spaceAll??0}%`;
        }else {
            return `${data?.spaceTop??0}% ${data?.spaceRight??0}% ${data?.spaceBottom??0}% ${data?.spaceLeft??0}%`;
        }
    }
}
