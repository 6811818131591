import React,{useState} from "react";
import bg from "../assets/image/signin.png"
import google from "../assets/image/google.png"
import {useSelector,useDispatch} from "react-redux";
import API from "../utils/API";
import {login} from "../redux/actions/userActions";
import {
    useNavigate, useParams
} from "react-router-dom";

const SetPassword = () => {
    const [password,setPassword] = useState(null)
    const [passwordConfirm,setPasswordConfirm] = useState(null)
    const [error,setError] = useState(null)
    const [loading,setLoading] = useState(false)

    let navigate = useNavigate()

    let { id } = useParams();

    const sign = useSelector(state => {
        return state.user.sign
    });

    const dispatch = useDispatch();

    const submit = () => {
        if (!password || password.length < 8) {
            setError('Password length should be minimum 8 characters')
            return;
        }

        if (password !== passwordConfirm) {
            setError('Password and confirm password did not match')
            return;
        }

        if (!loading) {
            setLoading(true)
            API.post('/auth/set', {key:id,password:password})
                .then(data => {
                    dispatch(login(data.data.data));
                    setLoading(false)
                    navigate("/");
                })
                .catch(error => {
                    setError('Invalid restore key')
                    setLoading(false)
                })
        }
    }

    return (
        <div className="auth main d-flex w-100 justify-content-center">
            <div className="row w-100 content justify-content-center">
                <div className="col-xl-8 col-lg-8 col-md-10 col-sm-11">
                    <div className="row m-0 p-0 centerBlock justify-content-center">
                        <div className="col-md col-sm-12 m-0 p-0" style={{minHeight:"480px",backgroundImage:`url(${bg})`,backgroundRepeat: "no-repeat",backgroundPosition : "center",backgroundSize: "cover"}}/>
                        <div className="col-md col-sm-12">
                            <div className="text-end p-2">
                                <span className="logoTitle">APP WORLD</span>
                            </div>
                            <div className="form">
                                <div>
                                    <div className="title mb-5">Reset password</div>
                                    {error &&
                                        <div className="text-center text-danger">{error}</div>
                                    }
                                    <div className="row">
                                        <div className="col-12 mb-2">
                                            <label className="label">
                                                <span className="h6 small bg-white text-muted px-1">Password</span>
                                            </label>
                                            <input type="password" className="form-control mt-2" onChange={(e) => {setPassword(`${e.target.value}`);setError(null)}}/>
                                        </div>
                                        <div className="col-12 mb-2">
                                            <label className="label">
                                                <span className="h6 small bg-white text-muted px-1">Password confirmation</span>
                                            </label>
                                            <input type="password" className="form-control mt-2" onChange={(e) => {setPasswordConfirm(`${e.target.value}`);setError(null)}}/>
                                        </div>
                                        <div className="col-12 mb-2 mt-4">
                                            <button className="btn btn-primary w-100 button" onClick={submit}>
                                                {loading ?
                                                    <>Loading...</>
                                                    :
                                                    <>Set new password</>
                                                }
                                            </button>
                                        </div>
                                        <div className="col-12 mb-3 mt-3 text-center">
                                            <span className="orSignUp cursorPointer" onClick={()=>navigate("/signin")}><span className="text-primary">Sign In</span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SetPassword
