import React,{useState,useEffect} from "react";
import "../../assets/style/mainpage.scss"
import "../../assets/style/ecommerce.scss"
import {useNavigate, useParams} from "react-router-dom";
import ImageUploader from "react-images-upload";
import API from "../../utils/API";
import TextEditor from "../../components/builder/TextEditor";
import { MultiSelect } from 'primereact/multiselect';
import Multiselect from "multiselect-react-dropdown";

const Variant = () => {
    let { appid } = useParams()
    let { product } = useParams()
    let { variant } = useParams()

    let navigate = useNavigate()

    const [productData,setProductData] = useState(null)
    const [tags,setTags] = useState(null)

    const [variantData,setVariantData] = useState({image:null})

    const [loaded,setLoaded] = useState(false)

    useEffect(()=>{
        if (appid) {
            API.post(`/ecommerce/tags/${appid}`).then((result) => {
                setTags(result.data.data)
            })
        }
        if (appid && product) {
            API.get(`/ecommerce/product/${appid}/${product}`).then((result) => {
                setProductData(result.data.data)
            })
        }
        if (appid && product && variant) {
            API.get(`/ecommerce/product/variant/${product}/${variant}`).then((result) => {
                setVariantData(result.data.data)
                setLoaded(true)
            })
        }else{
            setLoaded(true)
        }
    },[appid,product,variant])

    const setValue = (name,value) => {
        setVariantData({ ...variantData, [name]: value })
    }

    const onDrop = (picture,pictureURI) => {
        if (pictureURI.length > 0) {
            API.post('/imagemulti/' + appid, {pictureURI}).then((response) => {
                setValue('image', response.data.data)
            });
        }else {
            setValue("image", null)
        }
    };

    const onSelect = (name, selectedList, selectedItem) => {
        setValue(name, selectedList)
    }

    const onRemove = (name, selectedList, removedItem) => {
        setValue(name, selectedList)
    }

    const save = () => {
        API.post(`/ecommerce/product/variant/${variant?'save':'add'}/${appid}/${product}/${variant?('/'+variant):''}`,variantData).then((result) => {
            navigate(`/ecommerce/${appid}/product/${product}`)
        });
    }

    return (
        <div className="mainPage ecommerce">
            <div className="hero">
                <div className="row">
                    <div className="col">
                        <span className="title">{productData?.title} | Product variant</span>
                    </div>
                    <div className="col-auto">
                        <div className="row">
                            <div className="col-auto">
                                <button className="btn newProductButton cursorPointer ps-3 pe-3" disabled={!variantData.title} onClick={()=>save()}>Save</button>
                            </div>
                            <div className="col-auto">
                                <div className="secondaryButton ps-3 pe-3" onClick={()=>navigate(`/ecommerce/${appid}/product/${product}`)}>X</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="product">
                <div className="tabNavigation mt-3">
                    <div className="header m-3 p-3 typeTitle">
                        Variant data
                    </div>
                    <div className="ms-4 mt-1 mb-3">
                        <div className="row me-3">
                            <div className="col-4">
                                <ImageUploader
                                    withIcon={true}
                                    onChange={onDrop}
                                    imgExtension={[".jpg",".jpeg", ".gif", ".png", ".gif", ".webp"]}
                                    maxFileSize={5242880}
                                    withPreview={true}
                                    defaultImages={variantData.image?variantData.image:[]}
                                />
                            </div>
                            <div className="col descriptionBlock pt-2 pb-2">
                                <div className="row mt-1">
                                    <div className="col-12">
                                        <label>Title</label>
                                        <input type="text" className="form-control" value={variantData.title} onChange={(e)=>setValue('title',e.target.value)} placeholder="Write the variant title"/>
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-12">
                                        <label>SKU</label>
                                        <input type="text" className="form-control" value={variantData.sku} onChange={(e)=>setValue('sku',e.target.value)} placeholder="SKU"/>
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-12">
                                        <label>Price, $</label>
                                        <input type="number" className="form-control" value={variantData.price} onChange={(e)=>setValue('price',e.target.value)} placeholder="Price"/>
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-12">
                                        <label>Stock</label>
                                        <input type="number" className="form-control" value={variantData.stock} onChange={(e)=>setValue('stock',e.target.value)} placeholder="Stock"/>
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-12">
                                        <label>Tags</label>
                                        {tags &&
                                            <Multiselect
                                                options={tags}
                                                selectedValues={variantData.tag}
                                                onSelect={(selectedList, SelectedItem) => onSelect('tag',selectedList, SelectedItem)}
                                                onRemove={(selectedList, SelectedItem) => onRemove('tag',selectedList, SelectedItem)}
                                                displayValue="title"
                                                placeholder=""
                                            />
                                        }
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-12">
                                        <label>Description</label>
                                        {loaded &&
                                        <TextEditor setValue={setValue} name={'description'} value={variantData.description}/>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Variant
