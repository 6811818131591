import React from "react";
import {useParams} from "react-router-dom";
import PageSelectionRequirement from "./PageSelectionRequirement";
import Navigator from "./Navigator";
import {HTML5Backend} from "react-dnd-html5-backend";
import { DndProvider } from 'react-dnd'
import "../../assets/style/blocks.scss"
import PageView from "./blocks/PageView";
import ElementManagement from "./blocks/ElementManagement";
import {useIsTablet} from "../../hooks/useIsTablet";
import {useIsEditor} from "../../hooks/useIsEditor";
import {useDispatch} from "react-redux";
import {set} from "../../redux/actions/pageeditorActions";
import {FiLayers} from "react-icons/fi";

const Navigation = () => {
    let { appid } = useParams()
    const isTablet = useIsTablet()
    const isEditor = useIsEditor();

    const dispatch = useDispatch()

    const setNavigatorView = () => {
        dispatch(set({editBlockId:null,editType:null}))
    }

    return (
        <>
            <PageSelectionRequirement/>
            <div className="row blocks position-relative">
                <div className={`col ${isTablet && 'm-0 p-0'}`}>
                    {isTablet ?
                        <div className="row justify-content-center">
                            <div className="col-auto">
                            {isEditor ?
                                <>
                                    <div className="blocksContainer manageBlock mb-2" style={{height:"unset"}} onClick={()=>setNavigatorView()}>
                                        <div className="titleBlock">
                                            <div className="title">
                                                <div className="row">
                                                    <div className="col-3 iconBlock">
                                                        <FiLayers className="icon"/>
                                                    </div>
                                                    <div className="col-9 name">
                                                        navigator
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ElementManagement/>
                                </>
                                :
                                <Navigator/>
                            }
                            </div>
                        </div>
                        :
                        <Navigator/>
                    }
                </div>
                <div className={`col ${isTablet && 'm-0 p-0'}`}>
                    <div className="row justify-content-center">
                        <div className="col-auto blocks">
                            <div className="appEditContainer" id="app-root">
                            <DndProvider backend={HTML5Backend}>
                                <PageView appid={appid}/>
                            </DndProvider>
                            </div>
                        </div>
                    </div>
                </div>
                {!isTablet &&
                <div className="col">
                    <ElementManagement/>
                </div>
                }
                {!isTablet &&
                <div className="col-auto" style={{width:"88px"}}></div>
                }
            </div>
        </>
    )
}

export default Navigation
