import React from "react";
import "../../assets/style/newAppDashboard.scss"
import {AiOutlinePlus} from "react-icons/ai";
import {useNavigate} from "react-router-dom";

const NewApp = () => {
    let navigate = useNavigate()

    return (
        <div className='newAppDashboard' onClick={()=>navigate('/newapp')}>
            <div className="addLink text-center pt-4">
                <AiOutlinePlus color={"#56B3F8"} size={"80px"}/>
            </div>
            <div className="innerBox">
                <div className="title text-center">Build your<br/>new app</div>
                <div className="button text-center"><span className="text">New</span></div>
            </div>
        </div>
    )
}

export default NewApp
