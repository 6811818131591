import React from "react";
import {FaRegSquare,FaCompress} from "react-icons/fa"

const Spacing = ({setValue, name, value, featureName}) => {
    return <div className="spacing">
        <div className="row">
            <div className="col">
                <span className="blockTitle">{featureName}</span>
            </div>
            <div className="col-auto pt-1">
                <div className="row">
                    <div onClick={()=>setValue(name+".type","px")} className={`col-6 typeSelector ${value?.type !== 'percent' ?`typeSelected`:``}`}>
                        px
                    </div>
                    <div onClick={()=>setValue(name+".type","percent")} className={`col-6 typeSelector ${value?.type === 'percent' ?`typeSelected`:``}`}>
                        %
                    </div>
                </div>
            </div>
        </div>
        <div className="row justify-content-between mt-2">
            <div className="col-3 ps-4">
                <div className="row">
                    <div onClick={()=>setValue(name+".spaceType","all")} className={`col-auto spacingTypeButton ${value?.spaceType !== 'separate' ?`spacingTypeSelected`:``}`}>
                        <FaRegSquare/>
                    </div>
                    <div onClick={()=>setValue(name+".spaceType","separate")} className={`col-auto spacingTypeButton ${value?.spaceType === 'separate' ?`spacingTypeSelected`:``}`}>
                        <FaCompress/>
                    </div>
                </div>
            </div>
            <div className="col-9">
                {value?.spaceType !== 'separate'?
                    <div className="row mt-1 justify-content-end">
                        <div className="col-auto">
                            <input maxLength={2} name={name+".spaceAll"} value={value?.spaceAll??''} onChange={(e)=>setValue(name+".spaceAll",e.target.value)} className="sizeInput"/>
                        </div>
                    </div>
                    :
                    <div className="row mt-1 justify-content-end">
                        <div className="col-auto">
                            <input maxLength={2} name={name+".spaceLeft"} value={value?.spaceLeft??''} onChange={(e)=>setValue(name+".spaceLeft",e.target.value)} className="sizeInput"/>
                        </div>
                        <div className="col-auto">
                            <input maxLength={2} name={name+".spaceTop"} value={value?.spaceTop??''} onChange={(e)=>setValue(name+".spaceTop",e.target.value)} className="sizeInput"/>
                        </div>
                        <div className="col-auto">
                            <input maxLength={2} name={name+".spaceRight"} value={value?.spaceRight??''} onChange={(e)=>setValue(name+".spaceRight",e.target.value)} className="sizeInput"/>
                        </div>
                        <div className="col-auto">
                            <input maxLength={2} name={name+".spaceBottom"} value={value?.spaceBottom??''} onChange={(e)=>setValue(name+".spaceBottom",e.target.value)} className="sizeInput"/>
                        </div>
                    </div>
                }
            </div>
        </div>
    </div>
}

export default Spacing
