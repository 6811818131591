import React from "react";

const ListStat = ({data}) => {
    return (
        <>
            {data &&
                <div className="m-1 ml-2">
                    Showing {data.start}-{data.end}, from <strong>{data.total}</strong> records
                </div>
            }
        </>
    )
}

export default ListStat
