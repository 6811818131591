import React from "react";
import "../../assets/style/mainpage.scss"
import "../../assets/style/ecommerce.scss"
import {
    useNavigate, useLocation, useParams
} from "react-router-dom";

const EcommerceLayout = ({ children }) => {
    let navigate = useNavigate()
    const location = useLocation();

    let { appid } = useParams()

    return (
        <div className="mainPage ecommerce">
            <div className="hero">
                <div className="row">
                    <div className="col">
                        <span className="title">Manage your E-Commerce platform</span>
                    </div>
                    <div className="col-auto">
                        <button className="btn newProductButton" onClick={(e)=>navigate('/ecommerce/'+appid+'/product')}>+ New Product</button>
                    </div>
                </div>
            </div>
            <div className="tabNavigation mt-3">
                <div className="header m-3">
                    <div className="row">
                        <div className="col-auto ms-2 me-2">
                            <span onClick={(e)=>navigate('/ecommerce/'+appid+'/products')} className={`${location.pathname.indexOf('/ecommerce/'+appid+'/product') !== -1?'buttonActive':'buttonNotActive'} m-1`}>Product list</span>
                        </div>
                        <div className="col-auto ms-2 me-2">
                            <span onClick={(e)=>navigate('/ecommerce/'+appid+'/orders')} className={`${location.pathname.indexOf('/ecommerce/'+appid+'/order') !== -1?'buttonActive':'buttonNotActive'} m-1`}>Orders</span>
                        </div>
                        <div className="col-auto">
                            <span onClick={(e)=>navigate('/ecommerce/'+appid+'/sales')} className={`${location.pathname.indexOf('/ecommerce/'+appid+'/sale') !== -1?'buttonActive':'buttonNotActive'} m-1`}>Sales & Coupons</span>
                        </div>
                        <div className="col-auto">
                            <span onClick={(e)=>navigate('/ecommerce/'+appid+'/categories')} className={`${location.pathname.indexOf('/ecommerce/'+appid+'/categories') !== -1?'buttonActive':'buttonNotActive'} m-1`}>Categories</span>
                        </div>
                        <div className="col-auto">
                            <span onClick={(e)=>navigate('/ecommerce/'+appid+'/shippingtaxes')} className={`${location.pathname.indexOf('/ecommerce/'+appid+'/ship') !== -1?'buttonActive':'buttonNotActive'} m-1`}>Shipping & Taxes</span>
                        </div>
                    </div>
                </div>
                <div className="ms-4 mt-1 mb-3">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default EcommerceLayout
