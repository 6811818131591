import {spacing} from "./spacing"

export const imageStyle = (imageObject) => {
    return {
        backgroundRepeat: "no-repeat",
        backgroundPosition : "center",
        backgroundSize: "cover",
        position:"relative",
        border:(imageObject?.borderWidth)?`solid ${imageObject?.borderWidth}px ${imageObject?.borderColor}`:'0',
        borderRadius: spacing(imageObject?.borderRadius??{}),
        padding: spacing(imageObject?.padding??{}),
        margin: spacing(imageObject?.margin??{}),
    }

}
