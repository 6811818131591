import React from "react";
import OnOff from "./subelements/OnOff";
import {MdBlurOn,MdOutlineAvTimer} from "react-icons/md";
import {GiTransform,GiBouncingSword} from "react-icons/gi";
import {TbFlipVertical} from "react-icons/tb"
import {FiRotateCw} from "react-icons/fi"
import {IoMdResize} from "react-icons/io"
import {FaCompress, FaRegSquare} from "react-icons/fa";
import {VscBell} from "react-icons/vsc"

const Animation = ({setValue, name, value, featureName, imageName}) => {
    return <div className="mb-1 animation">
        <div className="p-2">
            <OnOff name={name+".enabled"} value={value?.enabled} featureName={featureName} setValue={setValue} imageName={imageName}/>
        </div>
        {value?.enabled==="on" &&
            <>
                <div className="typeSelectorBlock">
                    <div className="types">
                        <div className={`type typeBorder ${value?.type==='animate__pulse'?'typeSelected':''}`} onClick={()=>setValue(name+".type","animate__pulse")}>
                            <div className="row">
                                <div className="col">
                                    <span className="title">Pulse</span>
                                </div>
                                <div className="col-auto">
                                    <span className="icon"><MdBlurOn/></span>
                                </div>
                            </div>
                        </div>
                        <div className={`type typeBorder ${value?.type==='animate__jackInTheBox'?'typeSelected':''}`} onClick={()=>setValue(name+".type","animate__jackInTheBox")}>
                            <div className="row">
                                <div className="col">
                                    <span className="title">Transform</span>
                                </div>
                                <div className="col-auto">
                                    <span className="icon"><GiTransform/></span>
                                </div>
                            </div>
                        </div>
                            <div className={`type typeBorder ${value?.type==='animate__flip'?'typeSelected':''}`} onClick={()=>setValue(name+".type","animate__flip")}>
                                <div className="row">
                                    <div className="col">
                                        <span className="title">Flip</span>
                                    </div>
                                    <div className="col-auto">
                                        <span className="icon"><TbFlipVertical/></span>
                                    </div>
                                </div>
                            </div>
                        <div className={`type typeBorder ${value?.type==='animate__rotateIn'?'typeSelected':''}`} onClick={()=>setValue(name+".type","animate__rotateIn")}>
                            <div className="row">
                                <div className="col">
                                    <span className="title">Rotate</span>
                                </div>
                                <div className="col-auto">
                                    <span className="icon"><FiRotateCw/></span>
                                </div>
                            </div>
                        </div>
                        <div className={`type typeBorder ${value?.type==='animate__slideInUp'?'typeSelected':''}`} onClick={()=>setValue(name+".type","animate__slideInUp")}>
                            <div className="row">
                                <div className="col">
                                    <span className="title">Slide</span>
                                </div>
                                <div className="col-auto">
                                    <span className="icon"><IoMdResize/></span>
                                </div>
                            </div>
                        </div>
                        <div className={`type typeBorder ${value?.type==='animate__bounceIn'?'typeSelected':''}`} onClick={()=>setValue(name+".type","animate__bounceIn")}>
                            <div className="row">
                                <div className="col">
                                    <span className="title">Bounce</span>
                                </div>
                                <div className="col-auto">
                                    <span className="icon"><GiBouncingSword/></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="adjustmentBlock">
                    <div className="row">
                        <div className="col-6">
                            <span className="title">Adjustment</span>
                        </div>
                        <div className="col-6 text-end">
                            <span className="titleSecond">seconds</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4 text-center">
                            <span className="adjustmentType">Direction</span>
                            <div className="row justify-content-center">
                                <div onClick={()=>setValue(name+".direction","all")} className={`col-auto spacingTypeButton ${value?.direction !== 'separate' ?`spacingTypeSelected`:``}`}>
                                    <FaRegSquare/>
                                </div>
                                <div onClick={()=>setValue(name+".direction","separate")} className={`col-auto spacingTypeButton ${value?.direction === 'separate' ?`spacingTypeSelected`:``}`}>
                                    <FaCompress/>
                                </div>
                            </div>
                        </div>
                        <div className="col-4 text-center">
                            <span className="adjustmentType">Duration</span>
                            <div className="row justify-content-center mt-1">
                                <div className={`col-auto p-0 m-0`}>
                                    <MdOutlineAvTimer style={{fontSize:"20px"}}/>
                                </div>
                                <div className={`col-auto p-0 m-0`}>
                                    <input maxLength={2} className="sizeInput"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-4 text-center">
                            <span className="adjustmentType">Delay</span>
                            <div className="row justify-content-center mt-1">
                                <div className={`col-auto p-0 m-0`}>
                                    <VscBell style={{fontSize:"20px"}}/>
                                </div>
                                <div className={`col-auto p-0 m-0`}>
                                    <input maxLength={2} className="sizeInput"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        }
    </div>
}

export default Animation
