import React,{useEffect,useState} from 'react';
import API from "../../utils/API";
import {useNavigate, useParams} from "react-router-dom";
import CardItem from "../billing/CardItem";
import {AiOutlinePlus} from "react-icons/ai";
import Modal from 'react-modal'
import NewPaymentMethod from "../billing/NewPaymentMethod";

const SelectPaymentMethod = () => {
    const [paymentMethods,setPaymentMethod] = useState([])
    const [loaded,setLoaded] = useState(false)
    const [selectedPaymentMethod,setSelectedPaymentMethod] = useState(null)

    const [modalIsOpen,setModalIsOpen] = useState(false)
    const [checked, setChecked] = useState(false)

    let navigate = useNavigate()

    const [monthlyPlan,setMonthlyPlan] = useState(false)
    const [yearlyPlan,setYearlyPlan] = useState(false)

    let { appid } = useParams()
    let { planType } = useParams()

    useEffect(()=> {
        if (!checked && appid) {
            API.get(`/billing/subscription/${appid}`).then(res => {
                if (res?.data?.data?.id) {
                    navigate(`/app/${appid}/submissionform`)
                }
                setChecked(true)
            });
        }
    },[checked,appid])

    const getPaymentMethods = () => {
        API.get('/billing/paymentmethods',)
            .then((response) => {
                setPaymentMethod(response.data.data)
                setLoaded(true)
                if (response.data.data.length > 0) {
                    setSelectedPaymentMethod(response.data.data[0].id)
                }
            })

        API.get("/billing/plans").then(res => {
            setMonthlyPlan(res.data.data.monthly)
            setYearlyPlan(res.data.data.yearly)
        })
    }

    const submitPayment = () => {
        API.post("/billing/submissionpayment", {
            app: appid,
            product: planType,
            paymentMethod: selectedPaymentMethod
        })
          .then(res => {
              navigate(`/app/${appid}/submissionform`)
            })
    }

    useEffect(() => {
        getPaymentMethods()
    }, [])

    const finishPaymentMethodAdd = () => {
        setModalIsOpen(false)
        getPaymentMethods()
    }

    return (
        <>{checked &&
            <>
            <h3>App Submission</h3>
        <div className="row mt-3">
            <div className="mx-auto col-xl-8 col-md-10">
            <div className="row mt-3 boxShadow">
                <div className="col-auto">
                    <div className="row justify-content-center mb-2">
                        <div className="col-auto p-1">
                            <div className="row newPaymentMethodButton" onClick={()=>setModalIsOpen(true)}>
                                <div className="col-auto p-1">
                                    <div className="addLink text-center">
                                        <AiOutlinePlus color={"#56B3F8"} size={"40px"}/>
                                    </div>
                                </div>
                                <div className="col-auto addLinkTitle pt-3">
                                    Add payment method
                                </div>
                            </div>
                        </div>
                    </div>
                    {paymentMethods &&
                        paymentMethods.map((paymentMethod, key) => {
                            return <div className="cursorPointer mt-3" onClick={()=>setSelectedPaymentMethod(paymentMethod.id)} style={paymentMethod.id===selectedPaymentMethod?{border:"solid 10px #5351FB",borderRadius:"30px"}:{}}>
                                <CardItem paymentMethod={paymentMethod}/>
                                {paymentMethod.isdefault === "1" &&
                                    <div className="text-primary text-center">Default payment method</div>
                                }
                            </div>
                        })
                    }
                </div>
                <div className="col text-center">
                    <h2>Payment details</h2>
                    <div className="mt-3">
                        <table className="table table-hover w-auto me-auto ms-auto">
                            <tr style={{borderBottom:"dashed 1px gray"}}>
                                <td style={{textAlign:"right"}}><span className="fs-4">Subscription</span></td>
                                <td style={{textAlign:"left",paddingLeft:"10px"}}>
                                    {planType === 'monthly' ?
                                        <>
                                            <span className="fs-5">${monthlyPlan.price}/mo</span>
                                        </>
                                        :
                                        <>
                                            <span className="fs-5">${yearlyPlan.price}/year</span>
                                        </>
                                    } <a className="text-primary cursorPointer" onClick={(e)=>navigate(`/app/${appid}/selectplan`)}>Change</a>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="mt-2">
                        <button type="button" className="btn mainButton btn-lg" disabled={!selectedPaymentMethod} onClick={()=>submitPayment()}>Submit payment</button>
                    </div>
                </div>
            </div>
            </div>
        </div>
            <Modal
                isOpen={modalIsOpen}
                className="modal-window"
                overlayClassName="modal-overlay"
                onRequestClose={()=>setModalIsOpen(false)}
            ><NewPaymentMethod onFinish={finishPaymentMethodAdd}/></Modal>
            </>
        }
            </>
    )
}


export default SelectPaymentMethod;
