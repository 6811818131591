import React,{useEffect} from "react";

const CategoryTypeSelect = ({setValue, name, value, featureName}) => {

    useEffect(()=>{
        if (!value) {
            setValue(name,'categories')
        }
    },[]);

    return <div className="slider">
        <div className="row">
            <div className="col">
                <span className="blockTitle">{featureName}</span>
            </div>
        </div>
        <div className="row ps-1">
            <div className="col">
                <select className="form-control" onChange={(e)=>{setValue(name,e.target.value)}}>
                    <option value="categories" selected={value==='categories'}>Category</option>
                    <option value="brands" selected={value==='brands'}>Brand</option>
                    <option value="tags" selected={value==='tags'}>Tag</option>
                </select>
            </div>
        </div>
    </div>
}

export default CategoryTypeSelect
