import React from "react";
import "../../assets/style/mainpage.scss"
import "../../assets/style/blogpost.scss"
import {
    useNavigate, useLocation, useParams
} from "react-router-dom";

const BlogpostLayout = ({ children }) => {
    let navigate = useNavigate()
    const location = useLocation();

    let { appid } = useParams()

    return (
        <div className="mainPage blogpost">
            <div className="hero">
                <div className="row">
                    <div className="col">
                        <span className="title">Manage your blog platform</span>
                    </div>
                    <div className="col-auto">
                        <button className="btn newPostButton" onClick={(e)=>navigate('/blogpost/'+appid+'/post')}>+ New Post</button>
                    </div>
                </div>
            </div>
            <div className="tabNavigation mt-3">
                <div className="header m-3">
                    <div className="row">
                        <div className="col-auto ms-2 me-2">
                            <span onClick={(e)=>navigate('/blogpost/'+appid+'/posts')} className={`${location.pathname.indexOf('/blogpost/'+appid+'/post') !== -1?'buttonActive':'buttonNotActive'} m-1`}>Blog posts</span>
                        </div>
                        <div className="col-auto">
                            <span onClick={(e)=>navigate('/blogpost/'+appid+'/categories')} className={`${location.pathname.indexOf('/blogpost/'+appid+'/cat') !== -1?'buttonActive':'buttonNotActive'} m-1`}>Categories</span>
                        </div>
                    </div>
                </div>
                <div className="ms-4 mt-1 mb-3">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default BlogpostLayout
