import React from "react";
import Switch from "react-switch";

const OnOff = ({setValue, name, value, featureName, imageName}) => {
    return <div className="row onOff">
        <div className="col-auto">
            <div className="image text-center">
                <span className="title">{imageName}</span>
            </div>
        </div>
        <div className="col pt-2">
            <span className="blockTitle">{featureName}</span>
        </div>
        <div className="col-auto pt-2">
            <Switch onChange={(checked)=>{
                if (checked) {
                    setValue(name,"on")
                }else {
                    setValue(name,"off")
                }
            }} checked={value==="on"} uncheckedIcon={false} checkedIcon={false} onColor={"#69B2FF"}/>
        </div>
    </div>
}

export default OnOff
