import React, {useEffect, useState} from "react";
import "../../assets/style/mainpage.scss"
import "../../assets/style/ecommerce.scss"
import {useNavigate, useParams} from "react-router-dom";
import ImageUploader from "react-images-upload";
import API from "../../utils/API";
import TextEditor from "../../components/builder/TextEditor";
import DateTimePicker from 'react-datetime-picker';
import Multiselect from 'multiselect-react-dropdown';
import {AiFillDelete, AiFillEdit} from "react-icons/ai";
import {confirmAlert} from "react-confirm-alert";
import {Store} from "react-notifications-component";

const Product = () => {
    let { appid } = useParams()
    let { productid } = useParams()

    let navigate = useNavigate()

    const [productData,setProductData] = useState({image:null})
    const [tags,setTags] = useState([])
    const [brands,setBrands] = useState([])
    const [category,setCategory] = useState([])
    const [loaded,setLoaded] = useState(false)

    useEffect(()=>{
        if (appid) {
            API.post(`/ecommerce/brands/${appid}`).then((result) => {
                setBrands(result.data.data)
            })
            API.post(`/ecommerce/tags/${appid}`).then((result) => {
                setTags(result.data.data)
            })
            API.post(`/ecommerce/categories/${appid}`).then((result) => {
                setCategory(result.data.data)
            })
        }
        if (productid) {
            loadProduct()
        }else {
            setLoaded(true)
        }
    },[appid,productid])

    const setValue = (name,value) => {
        setProductData({ ...productData, [name]: value })
    }

    const onDrop = (picture,pictureURI) => {
        if (pictureURI.length > 0) {
            API.post('/imagemulti/' + appid, {pictureURI}).then((response) => {
                setValue('image', response.data.data)
            });
        }else {
            setValue("image", null)
        }
    };

    const onSelect = (name, selectedList, selectedItem) => {
        setValue(name, selectedList)
    }

    const onRemove = (name, selectedList, removedItem) => {
        setValue(name, selectedList)
    }

    const save = () => {
        API.post(`/ecommerce/product/${productid?'save':'add'}/${appid}${productid?('/'+productid):''}`,productData).then((result) => {
            Store.addNotification({
                title: "Success!",
                message: "Product saved successfully",
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
            navigate('/ecommerce/'+appid+'/products');
        });
    }

    const loadProduct = () => {
        API.get(`/ecommerce/product/${appid}/${productid}`).then((result) => {
            setProductData(result.data.data)
            setLoaded(true)
        })
    }

    const deleteVariant = (id) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure you want to delete this variant?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        API.delete(`/ecommerce/product/variant/${productid}/${id}`).then((result)=>{
                            loadProduct()
                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    }

    return (
        <div className="mainPage ecommerce">
            <div className="hero">
                <div className="row">
                    <div className="col">
                        <span className="title">Product</span>
                    </div>
                    <div className="col-auto">
                        <div className="row">
                            <div className="col-auto">
                                <button className="btn newProductButton cursorPointer ps-3 pe-3" disabled={!productData.title} onClick={()=>save()}>Save</button>
                            </div>
                            <div className="col-auto">
                                <div className="secondaryButton ps-3 pe-3" onClick={()=>navigate('/ecommerce/'+appid+'/products')}>X</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="product">
                <div className="tabNavigation mt-3">
                    <div className="header m-3 p-3 typeTitle">
                        Product description
                    </div>
                    <div className="ms-4 mt-1 mb-3">
                        <div className="row me-3">
                            <div className="col-4">
                                <ImageUploader
                                    withIcon={true}
                                    onChange={onDrop}
                                    imgExtension={[".jpg",".jpeg", ".gif", ".png", ".gif", ".webp"]}
                                    maxFileSize={5242880}
                                    withPreview={true}
                                    defaultImages={productData.image?productData.image:[]}
                                />
                            </div>
                            <div className="col descriptionBlock pt-2 pb-2">
                                <h5>Description</h5>
                                <div className="row mt-1">
                                    <div className="col-12">
                                        <label>Product name</label>
                                        <input type="text" className="form-control" value={productData.title} onChange={(e)=>setValue('title',e.target.value)} placeholder="Write the product Name"/>
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-12">
                                        <label>Product SKU</label>
                                        <input type="text" className="form-control" value={productData.sku} onChange={(e)=>setValue('sku',e.target.value)} placeholder="SKU"/>
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-12">
                                        <label>Description</label>
                                        {loaded &&
                                            <TextEditor setValue={setValue} name={'description'} value={productData.description}/>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="tabNavigation mt-3">
                    <div className="header m-3 p-3 typeTitle">
                        Product features
                    </div>
                    <div className="ms-4 mt-1 mb-3 category">
                        <div className="row me-3">
                            <div className="col area me-1">
                                <div className="m-2">
                                    <div className="header">
                                        Weight & Size
                                    </div>
                                </div>
                                <div className="m-1">
                                    <label>Weight, lb</label>
                                    <input type="number" className="form-control" value={productData.weight} onChange={(e)=>setValue('weight',e.target.value)}/>
                                </div>
                                <div className="m-1">
                                    <label>Dimension, inch</label>
                                    <div className="row">
                                        <div className="col">
                                            <label>Height</label>
                                            <input type="number" className="form-control" value={productData.height} onChange={(e)=>setValue('height',e.target.value)}/>
                                        </div>
                                        <div className="col-auto p-0 pt-4">
                                            X
                                        </div>
                                        <div className="col">
                                            <label>Length</label>
                                            <input type="number" className="form-control" value={productData.length} onChange={(e)=>setValue('length',e.target.value)}/>
                                        </div>
                                        <div className="col-auto p-0 pt-4">
                                            X
                                        </div>
                                        <div className="col">
                                            <label>Depth</label>
                                            <input type="number" className="form-control" value={productData.depth} onChange={(e)=>setValue('depth',e.target.value)}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col area me-1">
                                <div className="m-2">
                                    <div className="header">
                                        Category & Brand
                                    </div>
                                </div>
                                <div className="row p-0 m-1">
                                    <div className="col-6 p-0 pe-1">
                                        <label>Category</label>
                                        {category &&
                                            <Multiselect
                                                options={category}
                                                selectedValues={productData.category}
                                                onSelect={(selectedList, SelectedItem) => onSelect('category',selectedList, SelectedItem)}
                                                onRemove={(selectedList, SelectedItem) => onRemove('category',selectedList, SelectedItem)}
                                                displayValue="title"
                                                placeholder=""
                                            />
                                        }
                                    </div>
                                    <div className="col-6 p-0 ps-1">
                                        <label>Brand</label>
                                        {brands &&
                                            <Multiselect
                                                options={brands}
                                                selectedValues={productData.brand}
                                                onSelect={(selectedList, SelectedItem) => onSelect('brand',selectedList, SelectedItem)}
                                                onRemove={(selectedList, SelectedItem) => onRemove('brand',selectedList, SelectedItem)}
                                                displayValue="title"
                                                placeholder=""
                                            />
                                        }
                                    </div>
                                </div>
                                <div className="m-1">
                                    <label>Tags</label>
                                    {tags &&
                                        <Multiselect
                                            options={tags}
                                            selectedValues={productData.tag}
                                            onSelect={(selectedList, SelectedItem) => onSelect('tag',selectedList, SelectedItem)}
                                            onRemove={(selectedList, SelectedItem) => onRemove('tag',selectedList, SelectedItem)}
                                            displayValue="title"
                                            placeholder=""
                                        />
                                    }
                                </div>
                            </div>
                            <div className="col area">
                                <div className="m-2">
                                    <div className="header">
                                        Schedule
                                    </div>
                                </div>
                                <div className="m-1">
                                    <label>Publish from (Optional)</label>
                                    <div>
                                        <DateTimePicker className="form-control" onChange={(value)=>setValue('publishedfrom',value)} value={productData.publishedfrom}/>
                                    </div>
                                </div>
                                <div className="m-1">
                                    <label>Publish to (Optional)</label>
                                    <div>
                                        <DateTimePicker className="form-control" onChange={(value)=>setValue('publishedto',value)} value={productData.publishedto}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {productData.id &&
                    <div className="tabNavigation mt-3">
                        <div className="header m-3 p-3 typeTitle">
                            <div className="row justify-content-between">
                                <div className="col-auto pt-2">
                                    Variables
                                </div>
                                <div className="col-auto">
                                    <div className="newVariantButton" onClick={()=>navigate(`/ecommerce/${appid}/product/${productData.id}/variant`)}>+ New Variant</div>
                                </div>
                            </div>
                        </div>
                        <div className="ms-4 mt-1 mb-3 category" style={{minHeight:"50px"}}>
                            {productData.varians?.length > 0 ?
                                <table className="table table-borderless products">
                                    <tr>
                                        <th style={{width:"100px"}}></th>
                                        <th>Title</th>
                                        <th>Price</th>
                                        <th>Stock</th>
                                        <th>Tag</th>
                                        <th></th>
                                    </tr>
                                    {productData.varians.map((variant,variantKey)=>{
                                        return <tr key={variantKey}>
                                            <td>
                                                {variant.image &&
                                                    <img src={variant.image[0]} className="img-fluid" style={{borderRadius:"10px"}}/>
                                                }
                                            </td>
                                            <td>{variant.title}</td>
                                            <td>
                                                {variant.price &&
                                                    <>${variant.price}</>
                                                }
                                            </td>
                                            <td>{variant.stock}</td>
                                            <td>{
                                                variant?.tag?.map((brandItem,brandKey)=>{
                                                    return <span className="badge bg-primary m-1">{brandItem.title}</span>
                                                })
                                            }</td>
                                            <td className="fs-4 cursorPointer text-end pe-3"><span className="text-primary" onClick={()=>navigate(`/ecommerce/${appid}/product/${productData.id}/variant/${variant.id}`)}><AiFillEdit/></span> <span className="text-secondary" onClick={()=>deleteVariant(variant.id)}><AiFillDelete/></span></td>
                                        </tr>
                                    })
                                    }
                                </table>
                                :
                                <div className="text-center"><h4>No variants</h4></div>
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default Product
