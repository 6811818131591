import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import React from "react";
import { CgMenuGridR } from "react-icons/cg";
import { RiMenuUnfoldFill } from "react-icons/ri";
import { GrAppsRounded } from "react-icons/gr";
import { IoAppsOutline } from "react-icons/io5";
import { IoApps } from "react-icons/io5";

export const MenuIcon = ({name,size,color}) => {
    return (<span style={{fontSize:`${size}px`}}>
        {name === 'bars' &&
            <FontAwesomeIcon icon={icon({name: 'bars', style: 'solid'})} color={color}/>
        }
        {name === 'ellipsis-v' &&
            <FontAwesomeIcon icon={icon({name: 'ellipsis-v', style: 'solid'})} color={color}/>
        }
        {name === 'ellipsis-h' &&
            <FontAwesomeIcon icon={icon({name: 'ellipsis-h', style: 'solid'})} color={color}/>
        }
        {name === 'align-left' &&
            <FontAwesomeIcon icon={icon({name: 'align-left', style: 'solid'})} color={color}/>
        }
        {name === 'align-right' &&
            <FontAwesomeIcon icon={icon({name: 'align-right', style: 'solid'})} color={color}/>
        }
        {name === 'th-large' &&
            <FontAwesomeIcon icon={icon({name: 'th-large', style: 'solid'})} color={color}/>
        }
        {name === 'th' &&
            <FontAwesomeIcon icon={icon({name: 'th', style: 'solid'})} color={color}/>
        }
        {name === 'menugrid' &&
            <CgMenuGridR color={color}/>
        }
        {name === 'rimenuunfoldfill' &&
            <RiMenuUnfoldFill color={color}/>
        }
        {name === 'grappsrounded' &&
            <GrAppsRounded color={color}/>
        }
        {name === 'ioappsoutline' &&
            <IoAppsOutline color={color}/>
        }
        {name === 'ioapps' &&
            <IoApps color={color}/>
        }
    </span>)
}

export const buttons = ['bars','ellipsis-v','ellipsis-h','th-large','th','menugrid','rimenuunfoldfill','grappsrounded','ioappsoutline','ioapps']
