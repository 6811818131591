import React,{useEffect,useState} from "react";
import {AiFillDelete} from "react-icons/ai";
import {confirmAlert} from "react-confirm-alert";
import API from "../../utils/API";
import Category from "./Category";
import {useParams} from "react-router-dom";

const Categories = () => {
    let { appid } = useParams()

    const [list,setList] = useState([])
    const [editCategory, setEditCategory] = useState(false)
    const [editCategoryId, setEditCategoryId] = useState(null)

    const newCategory = () => {
        setEditCategoryId(null)
        setEditCategory(true)
    }

    const closeEditCategory = () => {
        setEditCategoryId(null)
        setEditCategory(false)
        loadList()
    }

    const deleteItem = (id) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure you want to delete this category?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        API.delete(`/blogpost/category/${appid}/${id}`).then((result)=>{
                            loadList()
                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    }

    const startEditCategory = (id) => {
        setEditCategoryId(id)
        setEditCategory(true)
    }

    const loadList = () => {
        API.post(`/blogpost/categories/${appid}`).then((result)=>{
            setList(result.data.data)
        })
    }

    useEffect(()=>{
        loadList()
    },[])

    return (<div className="me-3">
            <div className="m-2">
                <div className="row justify-content-between">
                    <div className="col-auto">
                        {editCategory ?
                            <>
                                {editCategoryId ?
                                    <h5>Edit category</h5>
                                    :
                                    <h5>New category</h5>
                                }
                            </>
                            :
                            <div className="primaryButton" onClick={()=>newCategory()}>+ New Category</div>
                        }
                    </div>
                </div>
            </div>
            {editCategory ?
                <Category id={editCategoryId} app={appid} onClose={closeEditCategory}/>
                :
                <>
                    {
                        list?.map((item,key)=>{
                            return <div key={key} className="categoryType">
                                <div className="row">
                                    <div className="col" onClick={()=>startEditCategory(item.id)}>
                                        <span>{item.title}</span>
                                    </div>
                                    <div className="col-auto" onClick={()=>deleteItem(item.id)}>
                                        <AiFillDelete/>
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </>
            }
        </div>
    )
}

export default Categories
