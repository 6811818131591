import {APP_MENU_VISIBLE,APP_MODE} from "../constants";

export function setMenuStatus(menuId) {
    return {
        type: APP_MENU_VISIBLE,
        payload: menuId
    };
}

export function setAppMode() {
    return {
        type: APP_MODE,
        payload: true
    };
}
