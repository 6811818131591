import React,{useState,useEffect} from "react";
import "../assets/style/mainpage.scss"
import "../assets/style/shop.scss"
import API from "../utils/API";
import {BsFillCheckCircleFill} from "react-icons/bs";
import Modal from "react-modal";
import {FaShoppingCart} from "react-icons/fa";
import {AiOutlinePlus} from "react-icons/ai";
import CardItem from "./billing/CardItem";
import NewPaymentMethod from "./billing/NewPaymentMethod";
import {useNavigate} from "react-router-dom";

const ShopItem = ({templateId}) => {
    let navigate = useNavigate()
    const [template,setTemplate] = useState(null)
    const [purchaseModal,setPurchaseModal] = useState(false);
    const [templatePrice, setTemplatePrice] = useState(0);
    const [useModal,setUseModal] = useState(false);
    const [previewModal,setPreviewModal] = useState(false);
    const [newAppName,setNewAppName] = useState('');

    const [paymentMethods,setPaymentMethod] = useState([])
    const [loaded,setLoaded] = useState(false)
    const [selectedPaymentMethod,setSelectedPaymentMethod] = useState(null)
    const [modalIsOpen,setModalIsOpen] = useState(false)

    useEffect(() => {
        loadTemplate();
    },[templateId])

    const purchaseApp = () => {
        if (newAppName && newAppName.length > 0) {
            API.post('/marketplace/purchase',{template:templateId,title:newAppName}).then((response) => {
                navigate(`/app/${response.data.data.app}/pages`);
            })
        }
    }

    const getPaymentMethods = () => {
        API.get('/billing/paymentmethods',)
            .then((response) => {
                setPaymentMethod(response.data.data)
                setLoaded(true)
                if (response.data.data.length > 0) {
                    setSelectedPaymentMethod(response.data.data[0].id)
                }
            })
    }

    useEffect(() => {
        getPaymentMethods()
    }, [])

    const finishPaymentMethodAdd = () => {
        setModalIsOpen(false)
        getPaymentMethods()
    }

    const loadTemplate = () => {
        if (templateId) {
            API.get(`/marketplace/item/${templateId}`).then((response) => {
                setTemplate(response.data.data)
            })
        }
    }

    const payForTemplate = () => {
        API.post('/marketplace/payment',{template:templateId,paymentMethod:selectedPaymentMethod}).then((response) => {
            setPurchaseModal(false);
            loadTemplate();
        })
    }

    return <div className="marketplaceItem mt-2">
        {template &&
            <>
            <div className="row">
                <div className="col-6">
                    <img src={template.template.image} className="img-fluid templateImage"/>
                </div>
                <div className="col-6 descriptionBlock p-3 position-relative">
                    <div className="title">{template.template.title}</div>
                    <div className="description mt-2">{template.template.description}</div>
                    <div className="position-absolute bottom-0">
                        <span className="typeTitle">Full Version:</span>
                        <div className="row mt-2 mb-2">
                            <div className="col-auto">
                                <div className="feature p-2">
                                    <div className="row">
                                        <div className="col-auto">
                                            <div className="featureOn"></div>
                                        </div>
                                        <div className="col">IOS</div>
                                        <div className="col-auto"><BsFillCheckCircleFill className="featureChecked"/></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="feature p-2">
                                    <div className="row">
                                        <div className="col-auto">
                                            <div className="featureOn"></div>
                                        </div>
                                        <div className="col">Android</div>
                                        <div className="col-auto"><BsFillCheckCircleFill className="featureChecked"/></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-end mt-2">
                <div className="col-auto">
                    <button className="btn primaryButton" style={{minWidth:"170px"}} onClick={()=>{
                        setPreviewModal(true);
                    }}>Preview</button>
                </div>
                <div className="col-auto">
                    <button className="btn button" style={{minWidth:"170px"}} onClick={()=>{
                        if (template.template.price && !template.purchased) {
                            setPurchaseModal(true);
                            setTemplatePrice(template.template.price);
                        }else {
                            setUseModal(true);
                        }
                    }}>
                        {(template.template.price && !template.purchased) ?
                            <span>Purchase</span>
                            :
                            <span>Use</span>
                        }
                    </button>
                </div>
            </div>
                <Modal
                    isOpen={useModal}
                    className="modal-window newPageModal"
                    overlayClassName="modal-overlay"
                    onRequestClose={()=>setUseModal(false)}
                >
                    <div>
                        <div className="modalTitle row justify-content-center">
                            <div className="col-auto icon">
                                <FaShoppingCart/>
                            </div>
                            <div className="col-auto text pt-2">
                                Use template
                            </div>
                        </div>
                        <div className="row justify-content-center mt-3 mb-3">
                            <div className="col-10">
                                <input className="form-control" placeholder="Application name" onChange={(e) => {setNewAppName(e.target.value)}}/>
                            </div>
                        </div>
                        <div className="row justify-content-center mb-2">
                            <div className="col-auto">
                                <div className="button pt-2" onClick={()=>purchaseApp()}>Create an app</div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={purchaseModal}
                    className="modal-window"
                    overlayClassName="modal-overlay"
                    onRequestClose={()=>setPurchaseModal(false)}
                >
                    <div style={{width:"700px"}}>
                        <div className="modalTitle row justify-content-center">
                            <div className="col-auto icon">
                                <FaShoppingCart/>
                            </div>
                            <div className="col-auto text pt-2">
                                Purchase template
                            </div>
                        </div>
                        <div className="row justify-content-center mt-3 mb-3">
                            <div className="col-auto">
                                <div className="row justify-content-center mb-2">
                                    <div className="col-auto p-1">
                                        <div className="row newPaymentMethodButton" onClick={()=>setModalIsOpen(true)}>
                                            <div className="col-auto p-1">
                                                <div className="addLink text-center">
                                                    <AiOutlinePlus color={"#56B3F8"} size={"40px"}/>
                                                </div>
                                            </div>
                                            <div className="col-auto addLinkTitle pt-3">
                                                Add payment method
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {paymentMethods &&
                                    paymentMethods.map((paymentMethod, key) => {
                                        return <div className="cursorPointer mt-3" onClick={()=>setSelectedPaymentMethod(paymentMethod.id)} style={paymentMethod.id===selectedPaymentMethod?{border:"solid 10px #5351FB",borderRadius:"30px"}:{}}>
                                            <CardItem paymentMethod={paymentMethod}/>
                                            {paymentMethod.isdefault === "1" &&
                                                <div className="text-primary text-center">Default payment method</div>
                                            }
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                        <div className="row justify-content-center mb-2">
                            <div className="col-auto">
                                <div className="button pt-2" onClick={()=>payForTemplate()}>Pay ${templatePrice}</div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={previewModal}
                    className="modal-window newPageModal p-0"
                    overlayClassName="modal-overlay"
                    onRequestClose={()=>setPreviewModal(false)}
                >
                    <div className="p-0 m-0" style={{overflowX:"hidden"}}>
                        <iframe height="671px" width="403px" style={{overflowX:"hidden",padding:"0px"}} src={`/nativeapp/${templateId}`}/>
                    </div>
                </Modal>
                <Modal
                    isOpen={modalIsOpen}
                    className="modal-window"
                    overlayClassName="modal-overlay"
                    onRequestClose={()=>setModalIsOpen(false)}
                ><NewPaymentMethod onFinish={finishPaymentMethodAdd}/></Modal>
            </>
        }
    </div>
}

export default ShopItem
