import React from "react";
import "../../assets/style/blocks.scss"
import {useParams} from "react-router-dom";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import SectionAdd from "./blocks/SectionAdd";
import PageSelectionRequirement from "./PageSelectionRequirement";
import PageView from "./blocks/PageView";
import ElementManagement from "./blocks/ElementManagement";
import BlocksSelect from "./blocks/BlocksSelect";
import {useIsTablet} from "../../hooks/useIsTablet";
import {useIsEditor} from "../../hooks/useIsEditor";
import {FiLayers} from "react-icons/fi";
import {useDispatch} from "react-redux";
import {set} from "../../redux/actions/pageeditorActions";

const Blocks = () => {
    let { appid } = useParams();
    const isTablet = useIsTablet()
    const isEditor = useIsEditor();

    const dispatch = useDispatch()

    const setComponentsView = () => {
        dispatch(set({editBlockId:null,editType:null}))
    }

    return (
        <DndProvider backend={HTML5Backend}>
            <PageSelectionRequirement/>
        <div className="row blocks position-relative">
            <div className={`col ${isTablet && 'm-0 p-0'}`}>
                {isTablet ?
                    <div className="row justify-content-center">
                        <div className="col-auto">
                        {isEditor ?
                            <>
                                <div className="blocksContainer manageBlock mb-2" style={{height:"unset"}} onClick={()=>setComponentsView()}>
                                    <div className="titleBlock">
                                        <div className="title">
                                            <div className="row">
                                                <div className="col-3 iconBlock">
                                                    <FiLayers className="icon"/>
                                                </div>
                                                <div className="col-9 name">
                                                    components
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ElementManagement/>
                            </>
                            :
                        <BlocksSelect/>
                        }
                        </div>
                    </div>
                    :
                    <BlocksSelect/>
                }
            </div>
            <div className={`col ${isTablet && 'm-0 p-0'}`}>
                <div className="row justify-content-center">
                    <div className="col-auto">
                        <div className="appEditContainer" id="app-root">
                            <SectionAdd/>
                            <PageView appid={appid}/>
                        </div>
                    </div>
                </div>
            </div>
            {!isTablet &&
            <div className="col">
                <ElementManagement/>
            </div>
            }
            {!isTablet &&
            <div className="col-auto" style={{width:"88px"}}></div>
            }
        </div>
        </DndProvider>
    )
}

export default Blocks
