import React, {useState} from 'react';
import Modal from "react-modal";

const TermsOfService = () => {
    const [modalOpen, setModalOpen] = useState(false);

    return <><a href="#" onClick={()=>setModalOpen(true)}>terms and conditions</a><Modal
        isOpen={modalOpen}
        className="modal-window overflow-auto h-75"
        overlayClassName="modal-overlay"
        onRequestClose={()=>setModalOpen(false)}
    >
        <div>
            <div className="modalTitle row justify-content-center">
                <div className="col-auto text pt-2">
                    Terms and conditions
                </div>
            </div>
            <div className="row justify-content-center mt-2">
                <div className="col-10 text-start">
                    <div className="small-10 medium-8 medium-push-2 small-push-1 text-large headline">
                        <p className="text-large"> THIS AGREEMENT GOVERNS YOUR USE OF THE CAPZOOL WEBSITE AND SERVICES</p>
                        <h3 className="text-dark offset">ACCEPTANCE OF TERMS</h3>
                        <p className="text-large">You agree to and are bound by the terms and conditions set forth below and in any modified or additional terms that Capzool, Inc. ("Capzool") may publish from time to time (collectively, the "Terms of Use"). If you do not agree to all of the terms and conditions contained in the Terms of Use, do not access or use this Site.</p>
                        <p className="text-large">Capzool may change these Terms of Use from time to time. Your continued access or use of the Site constitutes your acceptance of such changes. Your access and use of the Site will be subject to the current version of the Terms of Use, rules and guidelines posted on the Site at the time of such use. Please regularly check the "Terms of Use" link on the home page of shutterstock.com to view the then-current terms. If you breach any of the Terms of Use, your license to access or use this Site shall automatically terminate.</p>

                        <h3 className="text-dark offset">INTELLECTUAL PROPERTY</h3>
                        <p className="text-large">All materials (including the organization and presentation of such material) on this Web site (the “Materials”) are the property of Capzool and its licensors and may be protected by intellectual property laws including laws relating to copyrights, trade-marks, trade- names, internet domain names, and other similar rights.</p>
                        <p className="text-large">Unless you have entered into a separate agreement with Capzool, any other use of these Materials without Capzool's written permission is prohibited.</p>
                        <p className="text-large">The Materials may only be used and copied for your own, non-commercial, personal or educational purposes, provided that the Materials are not modified and that copyright and other intellectual property notices are not deleted. You may not create derivative works from or otherwise exploit the Materials in any way.</p>
                        <p className="text-large">You agree not to copy, republish, frame, link to, download, transmit, modify, adapt, create derivative works based on, rent, lease, loan, sell, assign, distribute, display, perform, license, sublicense or reverse engineer the Site or any Capzool Content. In addition, you agree not to use any data mining, robots or similar data and/or image gathering and extraction methods in connection with the Site or Capzool Content.</p>
                        <p className="text-large">You may not remove any watermarks or copyright notices contained in the Capzool Content.</p>

                        <h3 className="text-dark offset">TRADEMARKS</h3>
                        <p className="text-large">Capzool and any other product or service name or slogan contained in the Site are trademarks of Capzool and its suppliers or licensors, and may not be copied, imitated or used, in whole or in part, without the prior written permission of Capzool or the applicable trademark holder. You may not use metatags or any other "hidden text" utilizing "Capzool" or any other name, trademark or product or service name of Capzool without our prior written permission. In addition, the look and feel of the Site, including all page headers, custom graphics, button icons and scripts, is the service mark, trademark and/or trade dress of Capzool and may not be copied, imitated or used, in whole or in part, without our prior written permission. All other trademarks, registered trademarks, product names and company names or logos mentioned in the Site are the property of their respective owners. Reference to any products, services, processes or other information, by trade name, trademark, manufacturer, supplier or otherwise does not constitute or imply endorsement, sponsorship or recommendation thereof by us.</p>

                        <h3 className="text-dark offset">YOUR CONTENT</h3>
                        <p className="text-large">For any image, text, or any other content that you upload or post to the Site (“Your Content”), including but not limited to the Site Services, you represent and warrant that: (i) you have all necessary rights to submit Your Content to the Site Services and grant the licenses set forth herein; (ii) Capzool will not need to obtain licenses from any third party or pay royalties to any third party with respect to Your Content; (iii) Your Content does not infringe any third party's rights, including intellectual property rights and privacy rights; and (iv) Your Content complies with these Terms of Use and all applicable laws.</p>
                        <p className="text-large">By uploading Your Content, you grant Capzool a limited, worldwide, non-exclusive, royalty-free license and right to copy, transmit, distribute, publicly perform and display (through all media now known or hereafter created), and make derivative works from Your Content for the purpose of allowing you to edit and display Your Content using the Site Services and archiving or preserving Your Content for disputes, legal proceedings, or investigations. The above licenses will continue unless and until you remove Your Content from the Site, in which case the licenses will terminate within a commercially reasonable period of time. Notwithstanding the foregoing, the license for legal archival/ preservation purposes will continue indefinitely.</p>
                        <p className="text-large">You may not upload or post any image, text, or other content that:</p>
                        <ul>
                            <li>A. Infringes any third party's copyrights or other intellectual property rights or any right of publicity or privacy;</li>
                            <li>B. Contains any pornographic, defamatory, or otherwise unlawful or immoral content.</li>
                            <li>C. Exploits minors;</li>
                            <li>D. Depicts unlawful or violent acts;</li>
                            <li>E. Depicts animal cruelty or violence towards animals;</li>
                            <li>F. Promotes fraudulent schemes or gives rise to a claim of deceptive advertising or unfair competition; or</li>
                            <li>G. Violates any law, statute, or regulation.</li>
                        </ul>

                        <h3 className="text-dark offset">THIRD PARTY SERVICES</h3>
                        <ul>
                            <li>A. External Sites. The Service may contain links to, or otherwise may allow You to connect to and use certain third party products, services or software under separate terms and conditions (collectively, “Third-party Services”) in conjunction with Our Service. If You decide to access and use such Third- party Services, be advised that Your use is governed solely by the terms and conditions of such Third-party Services, and We do not endorse, are not responsible for, and make no representations as to such Third-party Services, their content or the manner in which they handle Your data. Capzool is not liable for any damage or loss caused or alleged to be caused by or in connection with Your access or use of any such Third- party Services, or Your reliance on the privacy practices or other policies of such Third-party Services.</li>
                            <li>B. Integration. The Service may contain features that enable various Third- party Services (such as social media services like Facebook and Twitter) to be directly integrated into your Capzool account. To take advantage of these features, You will be required to register for or log into such Third- party Services on their respective websites. By enabling third party services within the Service, You are allowing Capzool to pass Your log-in information to these Third-party Services for this purpose.</li>
                        </ul>

                        <h3 className="text-dark offset">BILLING, PLAN MODIFICATIONS AND PAYMENTS</h3>
                        <ul>
                            <li>A. Billing and Payments. Unless otherwise agreed to in writing by Capzool, the Service is made available on a pay-as-you-go basis and is charged at the start of Your elected subscription term (generally monthly or annually). If You do not provide Your credit card or other payment information to Capzool before the expiration of any applicable free trial period, Your account will be suspended until payment information and charge authorization are provided. You will be promptly billed and, if applicable, any trial period will terminate. Thereafter, Your subscription will renew automatically based on Your plan’s renewal cycle. The Service provides an interface for the account owner to change credit card information (e.g., upon card renewal).</li>
                            <li>B. Modifying Your Subscription. If You choose to upgrade Your plan or number of users during Your elected subscription period, any incremental cost will be prorated over the remaining term of the subscription period and charged to Your account. Subsequently, You will be charged the adjusted rate on Your next billing cycle. If your payments for the Service are made by credit card, your credit card will be billed the prorated charge at the time your account is modified. Except as provided in the ‘Disclaimer of Warranties and Liability’ section, regardless of Your billing cycle, there are no refunds or credits for partial months of Service, plan downgrades, or refunds for unused time if You close Your account before the end of Your subscription period. Downgrading Your plan level may cause the loss of content, features, or capacity of Your account and Capzool does not accept any liability for such loss.</li>
                            <li>C. Authorization to Charge Your Credit Card. By becoming a subscriber of the Service and submitting your credit card information to Capzool, you authorize Capzool to store your payment card information and to charge the billing source you have provided for your account according to the subscription plan you selected until your account is terminated.</li>
                            <li>D. Overdue charges. If You fail to pay your subscription fee on time, or if Your credit card payment information is entered in error or does not go through for processing and You do not update payment information upon Our request, your entire subscription may be suspended or cancelled. Even after your subscription is suspended or cancelled, You will still have access to the Site, and you will be able to access your account information to restore your access to the Service by providing a proper billing source. Capzool reserves the right to remove such settings from our servers with NO liability or notice to you.</li>
                            <li>E. Billing Privacy. Capzool uses a third-party intermediary to manage credit card processing and this intermediary is not permitted to store, retain, or use Your billing information except to process Your credit card information for Capzool. For more information about Our billing practices and Your personal information please visit Our Privacy Policy.</li>
                            <li>F. Taxes. Unless otherwise stated, Our charges do not include any taxes, levies, duties or similar governmental assessments, including value- added, sales, use or withholding taxes assessable by any local, state, provincial or foreign jurisdiction (collectively “Taxes”). You are responsible for paying Taxes except those assessable against Capzool based on its income. We will invoice You for such Taxes if we believe we have a legal obligation to do so.</li>
                        </ul>

                        <h3 className="text-dark offset">CURRENCY OF WEB SITE</h3>
                        <p className="text-large">Capzool updates the information on this Web site regularly. However, Capzool cannot guarantee or accept any responsibility or liability for the accuracy, currency or completeness of the information on this Web site. Capzool may revise, supplement or delete information, services and/or the resources contained in the Web site and reserves the right to make such changes without prior notification to past, current or prospective visitors.</p>

                        <h3 className="text-dark offset">LINKED WEB SITES</h3>
                        <p className="text-large">This Web site may provide links to third party Web sites for your convenience only. The inclusion of these links does not imply that Capzool monitors or endorses these Web sites. Capzool does not accept any responsibility for such Web sites. Capzool shall not be responsible or liable, directly or indirectly, for any damage or loss, caused or alleged to be caused by or in connection with the use of or the reliance upon any information, content, goods or services available on or through any third party Web sites or linked resources.</p>

                        <h3 className="text-dark offset">INFRINGEMENT CLAIMS / DMCA NOTICES</h3>
                        <p className="text-large">If you believe that any Image or other material made available by Capzool infringes upon any copyright that you own or control, you may notify Capzool in the manner set forth in our DMCA Copyright Infringement Notice Policy.</p>

                        <h3 className="text-dark offset">RESTRICTION AND TERMINATION OF USE</h3>
                        <p className="text-large">Capzool may block, restrict, disable, suspend or terminate your access to all or part of the Site, the Site Services, and/or Capzool Content at any time in Capzool’s discretion, without prior notice or liability to you. Any conduct by you that, in Capzool’s sole discretion, restricts or inhibits any other person or entity from using or enjoying the Site or the Site Services is strictly prohibited and may result in the termination of your access to the Site or the Site Services without further notice.</p>

                        <h3 className="text-dark offset">INTERNET SOFTWARE OR COMPUTER VIRUSES</h3>
                        <p className="text-large">Due to technical difficulties with the Internet, Internet software or transmission problems could produce inaccurate or incomplete copies of information contained on this Web site. Computer viruses or other destructive programs may also be inadvertently downloaded from this Web site.</p>
                        <p className="text-large">Capzool shall not be responsible or liable for any software, computer viruses or other destructive, harmful or disruptive files or programs that may infect or otherwise impact your use of your computer equipment or other property on account of your access to, use of, or browsing on this Web site or your downloading of any of the Materials from this Web site. Capzool recommends that you install appropriate anti-virus or other protective software.</p>

                        <h3 className="text-dark offset">DISCLAIMER OF WARRANTIES AND LIABILITY</h3>
                        <ul>
                            <li>A. THE SERVICE, INCLUDING THE SITE AND CONTENT, AND ALL SERVER AND NETWORK COMPONENTS ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND TO THE FULLEST EXTENT PERMITTED BY LAW, AND CAPZOOL EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.</li>
                            <li>B. CAPZOOL DOES NOT REPRESENT OR WARRANT THAT THE SERVICE, CONTENT OR MATERIALS FROM OR RELATED TO THE SERVICE ARE ACCURATE, COMPLETE, RELIABLE, CURRENT OR ERROR-FREE OR THAT THE SITE OR THE SERVICE, SERVERS, OR ANY PLATFORM APPLICATIONS ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. THEREFORE, YOU SHOULD EXERCISE CAUTION IN THE USE AND DOWNLOADING OF ANY SUCH SOFTWARE, CONTENT OR MATERIALS AND USE INDUSTRY-RECOGNIZED SOFTWARE TO DETECT AND DISINFECT VIRUSES. ALL RESPONSIBILITY OR LIABILITY FOR ANY DAMAGES CAUSED BY VIRUSES CONTAINED WITHIN THE ELECTRONIC FILE CONTAINING A FORM OR DOCUMENT IS DISCLAIMED. WITHOUT LIMITING THE FOREGOING, YOU UNDERSTAND AND AGREE THAT YOU DOWNLOAD OR OTHERWISE OBTAIN CONTENT, MATERIAL, DATA OR SOFTWARE (INCLUDING ANY MOBILE CLIENT) FROM OR THROUGH THE SITE, THE SOFTWARE, OR ANY PLATFORM APPLICATIONS AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR YOUR USE THEREOF AND ANY DAMAGES TO YOUR MOBILE DEVICE OR COMPUTER SYSTEM, LOSS OF DATA OR OTHER HARM OF ANY KIND THAT MAY RESULT.</li>
                            <li>C. YOU ACKNOWLEDGE THAT CAPZOOL DOES NOT WARRANT THAT INFORMATION OR ADVICE OBTAINED BY YOU FROM CAPZOOL OR THROUGH THE SERVICE SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THESE TERMS. CAPZOOL DISCLAIMS ALL LIABILITY FOR DAMAGES CAUSED BY ANY SUCH INTERRUPTION OR ERRORS IN THE FUNCTIONING OF THE WEBSITE, EXCEPT AS OTHERWISE EXPRESSLY SETFORTH HEREIN. FURTHERMORE, CAPZOOL DISCLAIMS ALL LIABILITY FOR ANY MALFUNCTIONING, IMPOSSIBILITY OF ACCESS, OR POOR USE CONDITIONS OF THE SERVICE DUE TO INAPPROPRIATE EQUIPMENT, DISTURBANCES RELATED TO INTERNET SERVICE PROVIDERS, TO THE SATURATION OF THE INTERNET NETWORK, ERROR, OMISSION, INTERRUPTION, DELETION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMMUNICATIONS LINE FAILURE, THEFT OR DESTRUCTION OR UNAUTHORIZED ACCESS TO, OR ALTERATION OF, USER COMMUNICATIONS, PROBLEMS RELATED TO THE SERVICE OR ITS USE, LOSS OF PERSONAL CONTENT ON THE SITE, LOST OR UNDELIVERABLE EMAIL, AND FOR ANY OTHER REASON. Under no circumstances will Capzool be responsible for any loss or damage, including, but not limited to personal injury or death, resulting from use of the Site or the Service, or any interactions between users of the Site or the Service, whether online or offline.</li>
                            <li>D. Capzool provides the platform for the Service. Capzool does not control or vet user generated content for accuracy. Although We provide rules for user conduct and postings, We do not control and We are not responsible for what users post, transmit or share on or through the Site or any other website (even if such content is published on or through the Site). Furthermore, we are not responsible for any offensive, inappropriate, obscene, unlawful or otherwise objectionable content You may encounter on the Site. Capzool is not responsible for the conduct, whether online or offline, of any user of the Site or the Service. Further, Capzool is not responsible or liable in any manner for any 3rd party applications, software, viruses, etc. that are uploaded or posted on the Site, caused by users of the Site, or that are related to the use of the Service by third parties, or caused by any third parties’ use of the equipment or programming associated with or utilized in the Site or the Service.</li>
                            <li>E. CAPZOOL CANNOT GUARANTEE AND DOES NOT PROMISE ANY SPECIFIC RESULTS FROM USE OF THE SITE AND/OR THE SERVICE.</li>
                            <li>F. We do not monitor content published through the Service and We are not responsible for content published through the Service. Notwithstanding the foregoing, Capzool reserves the right to delete, move, or edit messages or materials, including, but not limited to, advertisements, public postings, and messages, that we, in our sole discretion, deem necessary to be removed.</li>
                            <li>G. Without limiting the foregoing, Capzool makes no warranty that: (i) the Site will meet your requirements; (ii) access to the Site will be uninterrupted; (iii) the quality of the Site will meet your expectations; and (iv) any errors or defects in the site, services or materials will be corrected. Capzool makes no representations or warranties that the Site Services will be permitted in your jurisdiction, that the Site Services or your use of the Site Services will be uninterrupted or error-free, that any of Your Content submitted by you will be available through the Site Services or will be stored by the Site Services, that the Site Services will meet your needs, or that Capzool will continue to support any particular feature of the Site Services. To the extent any disclaimer or limitation of liability does not apply, all applicable express, implied, and statutory warranties will be limited in duration to a period of thirty (30) days after the date on which you first used the Site Services, and no warranties shall apply after such period.</li>
                        </ul>

                        <h3 className="text-dark offset">INDEMNIFICATION</h3>
                        <p className="text-large">You agree to defend, indemnify and hold harmless Capzool, its subsidiaries, affiliates, licensors, employees, agents, third party information providers, Submitters and independent contractors against any claims, damages, costs, liabilities and expenses (including, but not limited to, reasonable attorneys' fees) arising out of or related to your conduct, your use or inability to use Site, the Site Services, your breach or alleged breach of the Website Terms of Use or of any representation or warranty contained herein, your unauthorized use of the Capzool Content, or your violation of any rights of another.</p>

                        <h3 className="text-dark offset">EXPORT CONTROL</h3>
                        <p className="text-large">Your use of Service, including our software, is subject to export and re-export control laws and regulations, including the Export Administration Regulations (“EAR”) maintained by the United States Department of Commerce and sanctions programs maintained by the Treasury Department's Office of Foreign Assets Control. You shall not — directly or indirectly — sell, export, re-export, transfer, divert, or otherwise dispose of any Service or Software to any end-user without obtaining the required authorizations from the appropriate government authorities. You also warrant that you are not prohibited from receiving US origin products, including services or software.</p>

                        <h3 className="text-dark offset">RELATIONSHIP; INDEPENDENT CONTRACTOR</h3>
                        <p className="text-large">Nothing herein contained shall be so construed as to constitute the parties as principal and agent, employer and employee, partners or joint venturers, nor shall any similar relationship be deemed to exist between the parties. Neither party shall have any power to obligate or bind the other party, except as specifically provided herein.</p>

                        <h3 className="text-dark offset">FEDERAL GOVERNMENT END USE PROVISIONS</h3>
                        <p className="text-large">If you are a U.S. federal government end user, this Service is a “Commercial Item” as that term is defined at 48 C.F.R. §2.101, consisting of “Commercial Computer Software” and “Commercial Computer Software Documentation”, as those terms are used in 48 C.F.R. §12.212 or 48 C.F.R. §227.7202. Consistent with 48 C.F.R. §12.212 or 48 C.F.R. §227.7202-1 through 227.7202-4, as applicable, this Service is licensed to you with only those rights as provided under the terms and conditions of these Terms.</p>

                        <h3 className="text-dark offset">LIMITATION OF LIABILITY</h3>
                        <p className="text-large">YOU ASSUME ALL RESPONSIBILITY AND RISK FOR USE OF THIS WEB SITE AND THE MATERIALS INCLUDING WITHOUT LIMITATION ANY OF THE INFORMATION CONTAINED THEREIN.</p>
                        <p className="text-large">IN NO EVENT SHALL CAPZOOL OR ANY OF ITS DIRECTORS, OFFICERS, EMPLOYEES, SHAREHOLDERS, PARTNERS, OR AGENTS BE LIABLE FOR ANY INCIDENTAL, INDIRECT, PUNITIVE, EXEMPLARY, OR CONSEQUENTIAL DAMAGES WHATSOEVER (INCLUDING DAMAGES FOR LOSS OF PROFITS, INTERRUPTION, LOSS OF BUSINESS INFORMATION, OR ANY OTHER PECUNIARY LOSS) IN CONNECTION WITH ANY CLAIM, LOSS, DAMAGE, ACTION, SUIT OR OTHER PROCEEDING ARISING UNDER OR OUT OF THESE TERMS OF USE, INCLUDING WITHOUT LIMITATION YOUR USE OF, RELIANCE UPON, ACCESS TO, OR EXPLOITATION OF THIS WEB SITE, THE MATERIALS OR ANY PART THEREOF, OR ANY RIGHTS GRANTED TO YOU HEREUNDER, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, WHETHER THE ACTION IS BASED ON CONTRACT, TORT (INCLUDING NEGLIGENCE), INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS OR OTHERWISE.</p>
                        <p className="text-large">SOME JURISDICTIONS DO NOT ALLOW FOR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.</p>

                        <h3 className="text-dark offset">GOVERNING LAW AND JURISDICTION</h3>
                        <p className="text-large">These Terms of Use are governed by and shall be construed in accordance with the laws of the State of New York. You hereby consent to the personal jurisdiction of the state and federal courts located within such state. All actions, controversies and disputes arising from or relating to these Terms of Use shall be heard and decided exclusively before the courts located within the State, County and City of New York and not elsewhere. You agree that service of process in any actions, controversies and disputes arising from or relating to these Terms of Use may be effected by mailing a copy thereof by registered or certified mail (or any substantially similar form of mail), postage prepaid, to the other party however, nothing herein shall affect the right to effect service of process in any other manner permitted by law. These Terms of Use shall be construed neither against nor in favor of any party, but rather in accordance with the fair meaning of the language hereof. The invalidity or unenforceability of any part of these Terms of Use shall not affect the validity or enforceability of the balance hereof.</p>

                        <h3 class="text-dark offset">CONTACT</h3>
                        <p class="text-large">If you have concerns relating to this web site or these Terms of Use, please contact Capzool at support@capzool.com or via phone at 1-800-320-1480.</p>
                    </div>
                </div>
            </div>
        </div>
    </Modal></>
}

export default TermsOfService;
