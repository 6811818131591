import React,{useState} from "react";
import {formatCreditCardNumber, formatCVC, formatExpirationDate} from "./utils";
import Card from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import "../../assets/style/cardForm.scss"
import API from "../../utils/API";
import {useNavigate} from "react-router-dom";

const NewPaymentMethod = ({onFinish}) => {
    const [number, setNumber] = useState("")
    const [name, setName] = useState("")
    const [expiry, setExpiry] = useState("")
    const [cvc, setCvc] = useState("")
    const [issuer, setIssuer] = useState("")
    const [focused, setFocused] = useState(null)

    const [submitting, setSubmitting] = useState(false)

    const [error, setError] = useState(null)

    let navigate = useNavigate()

    const addCard = () => {
        if (!submitting && number.length>0 && name.length>0 && expiry.length>0 && cvc.length>0) {
            setSubmitting(true)
            API.post('/billing/addpaymentmethod', {
                cardNumber: number,
                expiry: expiry,
                cvc: cvc,
                cardName: name
            })
                .then((response) => {
                    if (onFinish) {
                        onFinish()
                        setNumber("")
                        setName("")
                        setExpiry("")
                        setCvc("")
                        setIssuer("")
                    }else {
                        navigate('/billing/payment-method')
                    }
                    setSubmitting(false)
                }).catch((err) => {
                setError(err.response.data.data.message)
                setSubmitting(false)
            })
        }
    }

    const setDataValue = (name,value) => {
        switch (name) {
            case 'number':
                setNumber(value)
                return
            case 'name':
                setName(value)
                return
            case 'expiry':
                setExpiry(value)
                return
            case 'cvc':
                setCvc(value)
                return
            case 'issuer':
                setIssuer(value)
                return
            case 'focused':
                setFocused(value)
                return
        }
    }

    const handleCallback = ({ issuer }, isValid) => {
        if (isValid) {
            setDataValue('issuer',issuer)
        }
    }

    const handleInputFocus = ({ target }) => {
        setDataValue('focused',target.name)
    }

    const handleInputChange = ({ target }) => {
        if (target.name === "number") {
            target.value = formatCreditCardNumber(target.value);
        } else if (target.name === "expiry") {
            target.value = formatExpirationDate(target.value);
        } else if (target.name === "cvc") {
            target.value = formatCVC(target.value);
        }

        setDataValue(target.name,target.value)
    }

    return (
        <div key="Payment">
            <div className="App-payment">
                <div className="row">
                    <div className="col-lg-7 col-md-12">
                        <div className="mt-3 cardForm">
                            <div className="title">Payment method</div>
                            <div className="subTitle">Credit card information</div>
                            <div className="form-group mb-2 mt-4">
                                <label>Card number</label>
                                <input
                                    type="tel"
                                    name="number"
                                    className="form-control"
                                    pattern="[\d| ]{16,22}"
                                    required
                                    onChange={handleInputChange}
                                    onFocus={handleInputFocus}
                                />
                            </div>
                            <div className="form-group mb-2">
                                <label>Name on card</label>
                                <input
                                    type="text"
                                    name="name"
                                    className="form-control"
                                    required
                                    onChange={handleInputChange}
                                    onFocus={handleInputFocus}
                                />
                            </div>
                            <div className="row mb-2">
                                <div className="col-6">
                                    <label>Expiry date</label>
                                    <input
                                        type="tel"
                                        name="expiry"
                                        className="form-control"
                                        pattern="\d\d/\d\d"
                                        required
                                        onChange={handleInputChange}
                                        onFocus={handleInputFocus}
                                    />
                                </div>
                                <div className="col-6">
                                    <label>CVV</label>
                                    <input
                                        type="tel"
                                        name="cvc"
                                        className="form-control"
                                        pattern="\d{3,4}"
                                        required
                                        onChange={handleInputChange}
                                        onFocus={handleInputFocus}
                                    />
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <input type="checkbox" className="checkboxTerms"/>
                                <label>I agree on terms & conditions</label>
                            </div>
                            <input type="hidden" name="issuer" value={issuer} />
                            {error && <div className="form-group mb-2 text-center"><span className="text-danger">{error}</span></div>}
                            <div className="form-actions mt-3 text-center">
                                <span className="addButton p-3" onClick={()=>addCard()}>
                                    {submitting?
                                    <>Submitting...</>
                                    :
                                    <>Save Payment Method</>
                                    }
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5 col-sm-12 pt-4 order-sm-first order-last">
                        <div className="row justify-content-center">
                            <div className="col-auto">
                            <Card
                                number={number}
                                name={name}
                                expiry={expiry}
                                cvc={cvc}
                                focused={focused}
                                callback={handleCallback}
                            />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewPaymentMethod
