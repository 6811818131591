import React,{useState} from "react";
import {useSelector} from "react-redux";
import { FaTh,FaGithubAlt } from "react-icons/fa";
import {HiOutlineUser} from "react-icons/hi";
import {BsCreditCard2Back} from "react-icons/bs";
import {MdOutlineListAlt} from "react-icons/md";
import {TbReportMoney} from "react-icons/tb";
import {BiMessageRoundedDots} from "react-icons/bi";
import {MdOutlinePhoneInTalk} from "react-icons/md";
import {
    useNavigate
} from "react-router-dom";

const SidePanel = ({setDrawer}) => {

    const user = useSelector(
        state => state.user.user
    )

    let navigate = useNavigate()

    const openPage = (url) => {
        navigate(url);
        setDrawer(false)
    }

    return (
        <div className="sidePanel m-0 p-0 overflow-hidden">
            <div className="row justify-content-center pt-3 m-0">
                <div className="col-auto">
                    <div className="row">
                        <div className="col-auto pt-3">
                            <span className="username">{user.name}</span>
                        </div>
                        <div className="col-auto">
                            {user.icon?
                                <img src={user.icon} className="avatar"/>
                            :
                                <div className="avatar text-center">{user.name[0]}</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <hr className="divider"/>
            <div className="row">
                <div className="col-8 text-start offset-2">
                    <div className="title mt-2">main</div>
                    <div className="row mt-3 p-3 link" onClick={()=>openPage('/')}>
                        <div className="col-auto iconBox">
                            <FaTh className="icon"/>
                        </div>
                        <div className="col-auto pt-1">
                            <span className="iconTitle">Dashboard</span>
                        </div>
                    </div>
                    <div className="row mt-0 p-3 link" onClick={()=>openPage('/profile')}>
                        <div className="col-auto iconBox">
                            <HiOutlineUser className="icon"/>
                        </div>
                        <div className="col pt-1">
                            <span className="iconTitle">Profile</span>
                        </div>
                    </div>
                    <div className="title mt-4">billing settings</div>
                    <div className="row mt-3 p-3 link" onClick={()=>openPage('/billing/payment-method')}>
                        <div className="col-auto iconBox">
                            <BsCreditCard2Back className="icon"/>
                        </div>
                        <div className="col-auto pt-1">
                            <span className="iconTitle">Payment methods</span>
                        </div>
                    </div>
                    <div className="row mt-0 p-3 link" onClick={()=>openPage('/billing/subscriptions')}>
                        <div className="col-auto iconBox">
                            <MdOutlineListAlt className="icon"/>
                        </div>
                        <div className="col pt-1">
                            <span className="iconTitle">Subscriptions</span>
                        </div>
                    </div>
                    <div className="row mt-0 p-3 link" onClick={()=>openPage('/billing/transactions')}>
                        <div className="col-auto iconBox">
                            <TbReportMoney className="icon"/>
                        </div>
                        <div className="col pt-1">
                            <span className="iconTitle">Transactions</span>
                        </div>
                    </div>
                    <div className="title mt-4">other</div>
                    <div className="row mt-3 p-3 link" onClick={()=>openPage('/message')}>
                        <div className="col-auto iconBox">
                            <BiMessageRoundedDots className="icon"/>
                        </div>
                        <div className="col-auto pt-1">
                            <span className="iconTitle">Messages</span>
                        </div>
                    </div>
                    <div className="row mt-0 p-3 link" onClick={()=>openPage('/support')}>
                        <div className="col-auto iconBox">
                            <MdOutlinePhoneInTalk className="icon"/>
                        </div>
                        <div className="col pt-1">
                            <span className="iconTitle">Support</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SidePanel
