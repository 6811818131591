import * as types from "../constants";
import API from "../../utils/API";

const initialState = {
    app: null,
    page: null,
    editSectionId: null,
    editBlockId: null,
    sections: [],
    editType: null,
    templateReload: null,
    selectedBlockId: null
};

export default function reducer(state = initialState, actions) {
    switch (actions.type) {
        case types.RESET_PAGE:
            return {
                ...state,
                ...initialState
            }
        case types.EDIT_PAGE_CONTENT: {
            if (actions.payload?.sections) {
                API.post(`/pagedata/${state?.page}/${state?.app}`,actions.payload);
            }
            return {
                ...state,
                ...actions.payload
            }
        }
        default:
            return {
                ...state,
                ...actions.payload
            }

    }
}
