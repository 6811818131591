import React, {useEffect, useState} from "react";
import {MdAnimation} from "react-icons/md";
import Animation from "../../editor/Animation";
import {bgImage} from "../../helpers/bgImage"
import {useCustomAnimation} from "../../helpers/animation"
import 'animate.css';
import SliderInput from "../../editor/subelements/SliderInput";
import CategoryTypeSelect from "../../editor/subelements/ecommerce/CategoryTypeSelect";
import Image from "../../editor/Image";
import {BsImage} from "react-icons/bs";
import _ from "lodash";
import Layer from "../../editor/Layer";
import {layer} from "../../helpers/layer";
import API from "../../../../utils/API";
import {useSelector} from "react-redux";

const Ecomcategories = (props) => {

    const [categories,setCategories] = useState(['Category 1','Category 2','Category 3','Category 4','Category 5','Category 6','Category 7','Category 8'])

    const pageeditor = useSelector(state => {
        return state.pageEditor
    })

    useEffect(()=>{
        if (props.data?.category) {
            API.post(`/ecommerce/${props.data?.category}/${pageeditor.app}`).then((response)=>{
                setCategories(response.data.data);
            })
        }else {
            setCategories([])
        }
    },[props.data?.category])

    const animation = useCustomAnimation(props.data.animation)

    if (props.edit) {
        return <div>

            <div className="subElement">
                <CategoryTypeSelect name={"category"} value={props.data?.category??[]} setValue={props.setValue} featureName={"Type"}/>
            </div>
            <div className="subElement p-2">
                <SliderInput name={"categories"} type={"categories"} value={props.data?.categories??1} setValue={props.setValue} featureName={"Categories per row"} min={1} max={4}/>
            </div>

            <div className="editItem">
                <Layer name={'categoryStyle'} setValue={props.setValue} value={props.data.categoryStyle} featureName="Category style"/>
            </div>
            <div className="editItem">
                <Image name={'image'} setValue={props.setValue} value={props.data.image} featureName="Background image" imageName={<BsImage style={{fontSize:"18px"}}/>}/>
            </div>
            <div className="editItem">
                <Animation name={'animation'} setValue={props.setValue} value={props.data.animation} featureName="Animation" imageName={<MdAnimation style={{fontSize:"18px"}}/>}/>
            </div>
        </div>
    }else {
        return <div style={_.merge({}, bgImage(props?.data?.image)??{})} className={animation}>
            <div className="row m-0 mt-2">
                {
                    categories.map((category,key)=>{
                        return <div key={key} className={`text-center m-0 col-${12/(1*props.data?.categories??1)}`}><div style={_.merge({}, layer(props.data?.categoryStyle))}>{category.title}</div></div>
                    })
                }
            </div>
        </div>
    }
}

export default Ecomcategories
