import {spacing} from "./spacing"

export const buttonStyle = (buttonObject) => {
    return {
        backgroundColor: buttonObject?.bgColor?(buttonObject.bgColor):'',
        border:(buttonObject?.borderWidth)?`solid ${buttonObject?.borderWidth}px ${buttonObject?.borderColor}`:'0',
        borderRadius: spacing(buttonObject?.borderRadius??{}),
        padding: spacing(buttonObject?.padding??{}),
        margin: spacing(buttonObject?.margin??{}),
        display: 'inline-block',
    }
}
