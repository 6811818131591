import React,{useState,useEffect} from "react";
import Animation from "../../editor/Animation";
import {MdAnimation} from "react-icons/md";
import {useCustomAnimation} from "../../helpers/animation";
import 'react-accessible-accordion/dist/fancy-example.css';
import API from "../../../../utils/API";
import {useSelector} from "react-redux";
import Layer from "../../editor/Layer";
import _ from "lodash";
import {layer} from "../../helpers/layer";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import parse from "html-react-parser";
import moment from "moment";

const Blog = (props) => {
    const [posts, setPosts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [page, setPage] = useState(null);

    const pageeditor = useSelector(state => {
        return state.pageEditor
    });

    const animation = useCustomAnimation(props.data.animation)

    useEffect(()=>{
        API.post(`/blogpost/posts/${props.appId??pageeditor.app}`,{page:1,perPage:20}).then((result)=>{
            setPosts(result.data.data.data)
        });
        API.post(`/blogpost/categories/${props.appId??pageeditor.app}`).then((result)=>{
            setCategories(result.data.data)
        })
    },[])

    if (props.edit) {
        return <div>
            <div className="editItem">
                <Layer name={'blockStyle'} setValue={props.setValue} value={props.data.blockStyle} featureName="List of posts style"/>
            </div>
            <div className="editItem">
                <Layer name={'blockPostStyle'} setValue={props.setValue} value={props.data.blockPostStyle} featureName="Post style"/>
            </div>
            <div className="editItem">
                <Animation name={'animation'} setValue={props.setValue} value={props.data.animation} featureName="Animation" imageName={<MdAnimation style={{fontSize:"18px"}}/>}/>
            </div>
        </div>
    }else {
        return <div style={_.merge({}, layer(page?((props?.data?.blockPostStyle)??{}):(props?.data?.blockStyle)??{}))} className={animation}>
            {page ?
                <div className="card m-2"><div className="card-body">
                    <div onClick={()=>setPage(null)} className="fs-6 fw-bold text-decoration-underline">Back to the list of posts</div>
                    <h3>{page.title}</h3>
                    <small>{moment(page.dateadd).format('LL')}</small>
                    <div>
                        {page?.category?.map((categoryItem,categoryItemKey)=>{
                            return <div className="badge bg-secondary p-1 m-1">{categoryItem.title}</div>
                        })}
                    </div>
                    {page.image?.length > 1 &&
                        <div>
                            <Carousel>
                                {page.image.map((imageUrl,index)=>{
                                    return (
                                        <div key={index}>
                                            <img src={imageUrl} alt="" className="rounded-3"/>
                                        </div>
                                    )
                                })}
                            </Carousel>
                        </div>
                    }
                    {page.image?.length === 1 &&
                        <div>
                            <img src={page.image[0]} className="img-fluid rounded-3"/>
                        </div>
                    }
                    <div className="mt-2">{page.content && parse(page.content)}</div>
                </div></div>
                :
                <>
                    {posts?.map((post, index) => {
                        return <div className="card m-2"><div className="card-body"><div key={index} className="row" onClick={()=>setPage(post)}>
                            {post.image?.length > 0 &&
                                <div className="col-md-4">
                                    <img src={post.image[0]} alt={post.title} className="img-fluid rounded-3"/>
                                </div>
                            }
                            <div className="col">
                                <h3>{post.title}</h3>
                                <small>{moment(post.dateadd).format('LL')}</small>
                                <div>
                                {post?.category?.map((categoryItem,categoryItemKey)=>{
                                    return <div className="badge bg-secondary p-1 m-1">{categoryItem.title}</div>
                                })}
                                </div>
                            </div>
                        </div>
                        </div></div>
                    })}
               </>
            }
        </div>
    }
}

export default Blog
