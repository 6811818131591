import React,{useState,useEffect} from "react";
import "../assets/style/mainpage.scss"
import "../assets/style/shop.scss"
import API from "../utils/API";
import {useNavigate} from "react-router-dom";
import {AiFillHeart, AiOutlinePlus} from "react-icons/ai";
import heroImage from "../assets/image/marketplace.png";
import ShopItem from "./ShopItem";

const Shop = () => {
    const [templates,setTemplates] = useState(null)
    const [categories,setCategories] = useState(null)
    let navigate = useNavigate()
    const [keyword,setKeyword] = useState('');


    const [category, setCategory] = useState(null);

    const [mostDownload,setMostDownload] = useState(null)

    const [selectedTemplateId, setSelectedTemplateId] = useState(null);

    const search = () => {
        loadTemplates();
    }

    useEffect(()=>{
        loadTemplates()
        API.get('/marketplace/categories').then((response) => {
            setCategories(response.data.data)
        })

        API.get('/marketplace/most-downloaded').then((response) => {
            setMostDownload(response.data.data)
        })
    },[])

    const loadTemplates = () => {
        API.post('/marketplace',{keyword:keyword,category:category}).then((response) => {
            setSelectedTemplateId(null);
            setTemplates(response.data.data);
        })
    }

    const like = (appId) => {
        API.post(`/marketplace/like/${appId}`).then((response) => {
            let newTemplates = [...templates];
            for(let i=0;i<newTemplates.length;i++){
                if(newTemplates[i].app.id === appId){
                    newTemplates[i].likes = response.data.data.quantity;
                }
            }
            setTemplates(newTemplates);
        })
    }

    const openTemplate = (id) => {
        setSelectedTemplateId(id);
    }

    return (
        <div className="mainPage shop">
            <div className="hero">
                <div className="row mb-2">
                    <div className="col">
                        <span className="title">Marketplace</span>
                    </div>
                    <div className="col-auto">
                        <button style={{borderRadius:10}} className="btn btn-outline-primary primaryButton" onClick={()=>{
                            navigate(`/shop/templates`);
                        }}><AiOutlinePlus size={20}/> Sell a Template</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-5">
                        <input type="text" className="form-control" placeholder="Keyword" value={keyword} onChange={(e)=>setKeyword(e.target.value)}/>
                    </div>
                    <div className="col-5">
                        <select className="form-control" placeholder="Select category" onChange={(e)=>setCategory(e.target.value)}>
                            <option value="">Select category</option>
                            {categories &&
                                categories.map((category) => {
                                    return (
                                        <option key={category.id} value={category.id}>{category.title}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="col-2">
                        <button className="btn primaryButton w-100" onClick={()=>search()}>Search</button>
                    </div>
                </div>
            </div>
            <>
                {selectedTemplateId ?
                    <ShopItem templateId={selectedTemplateId}/>
                    :
                    <>
                        <div className="mt-2 mb-2 row">
                            <div className="col-6 pe-4">
                                <div className="row heroBlock">
                                    <div className="col-6 text-center pt-5">
                                        <div className="title text-center">
                                            Sell & Buy Templates
                                        </div>
                                        <div className="subTitle text-center">
                                            Create and sell your own collectible and become a APPWORLD Designer
                                        </div>
                                        <div>
                                            <button className="btn button ps-3 pe-3">Explore</button>
                                        </div>
                                    </div>
                                    <div className="col-6 text-center">
                                        <img src={heroImage} className="img-fluid"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 mostDownload p-3">
                                <div className="title">Most Downloaded</div>
                                {(mostDownload && mostDownload.length>0) &&
                                    <div className="row mt-2">
                                        {mostDownload.map((template,index) => {
                                            return (
                                                <div className="col-6" key={template.id}>
                                                    <div className="row">
                                                        <div className="col-5">
                                                            <div className="position-relative">
                                                                <img src={template.image} className="img-fluid image" style={{borderRadius:10}}/>
                                                                <div className="badgeImage text-center">{index+1}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-7">
                                                            <div className="itemTitle">{template.name}</div>
                                                            <div className="itemSubtitle mt-2">{template.userName}</div>
                                                        </div>
                                                    </div>
                                                </div>)
                                        })}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="title mt-2 mb-2 ps-2">
                            Templates List
                        </div>
                        <div className="m-2">
                            <div className="row">
                                {(templates && templates.length > 0) &&
                                    templates.map((template) => {
                                        return (
                                            <div className="col-3" key={template.app.id}>
                                                <div className="template">
                                                    {template.template.image &&
                                                        <div className="containerImage" onClick={()=>openTemplate(template.template.id)}>
                                                            <img src={template.template.image} className="img-fluid image"/>
                                                        </div>
                                                    }
                                                    <div className="row mt-2 mb-2">
                                                        <div className="col subTitle">
                                                            {template.role === 'admin' ?
                                                                <>Admin</>
                                                                :
                                                                <>{template.name}</>
                                                            }
                                                        </div>
                                                        <div className="col-auto subTitle cursorPointer">
                                                            {template.likes} <AiFillHeart color="red" onClick={()=>like(template.app.id)}/>
                                                        </div>
                                                    </div>
                                                    <div className="title mt-2 mb-2 cursorPointer" onClick={()=>openTemplate(template.template.id)}>
                                                        {template.template.title}
                                                    </div>
                                                    <div className="row mt-2 mb-2">
                                                        <div className="col">
                                                            <div className="templateStatus">Current</div>
                                                        </div>
                                                        <div className="col-auto">
                                                            {template.template.price &&
                                                                <div className="price">${template.template.price}</div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </>
                }
            </>
        </div>
    )
}

export default Shop
