import React,{useState,useEffect} from "react";
import OnOffFeature from "../../editor/OnOffFeature";
import InlineEditableText from "../../InlineEditableText";
import Image from "../../editor/Image";
import {BsImage,BsTextareaResize} from "react-icons/bs";
import {MdAnimation} from "react-icons/md";
import Animation from "../../editor/Animation";
import {bgImage} from "../../helpers/bgImage"
import {buttonStyle} from "../../helpers/buttonStyle"
import {useCustomAnimation} from "../../helpers/animation"
import 'animate.css';
import {useSelector} from "react-redux";
import FormActionButton from "../../editor/FormActionButton";
import BGColor from "../../editor/BGColor";
import {IoIosColorPalette} from "react-icons/io";

const Form = (props) => {
    const animation = useCustomAnimation(props.data.animation)
    const [formSubmitted, setFormSubmitted] = useState(false);

    const pageeditor = useSelector(state => {
        return state.pageEditor
    });

    const submitForm = () => {
        setFormSubmitted(true);
    }

    if (props.edit) {
        return <div>
            <div className="editItem">
                <OnOffFeature name={'textEnabled'} setValue={props.setValue} value={props.data.textEnabled} featureName="Title" imageName="h1"/>
            </div>
            <div className="editItem">
                <OnOffFeature name={'subtitleEnabled'} setValue={props.setValue} value={props.data.subtitleEnabled} featureName="Subtitle" imageName="h2"/>
            </div>
            <div className="editItem">
                <OnOffFeature name={'paragraphEnabled'} setValue={props.setValue} value={props.data.paragraphEnabled} featureName="Paragraph" imageName="p"/>
            </div>
            <div className="editItem">
                <OnOffFeature name={'nameInput'} setValue={props.setValue} value={props.data.nameInput} featureName="Name input" imageName=<BsTextareaResize/>/>
            </div>
            <div className="editItem">
                <OnOffFeature name={'emailInput'} setValue={props.setValue} value={props.data.emailInput} featureName="Email input" imageName=<BsTextareaResize/>/>
            </div>
            <div className="editItem">
                <OnOffFeature name={'phoneInput'} setValue={props.setValue} value={props.data.phoneInput} featureName="Phone input" imageName=<BsTextareaResize/>/>
            </div>
            <div className="editItem">
                <OnOffFeature name={'textInput'} setValue={props.setValue} value={props.data.textInput} featureName="Text input" imageName=<BsTextareaResize/>/>
            </div>
            <div className="editItem">
                <FormActionButton name={'button'} setValue={props.setValue} value={props.data.button} featureName="Form action button" imageName="b"/>
            </div>
            <div className="editItem">
                <Image name={'image'} setValue={props.setValue} value={props.data.image} featureName="Image" imageName={<BsImage style={{fontSize:"18px"}}/>}/>
            </div>
            <div className="editItem">
                <BGColor name={'bgColor'} setValue={props.setValue} value={props.data.bgColor} featureName="Background" imageName={<IoIosColorPalette style={{fontSize:"18px"}}/>}/>
            </div>
            <div className="editItem">
                <Animation name={'animation'} setValue={props.setValue} value={props.data.animation} featureName="Animation" imageName={<MdAnimation style={{fontSize:"18px"}}/>}/>
            </div>
        </div>
    }else {
        return <div style={bgImage(props?.data?.image?.enabled==='on'?props?.data?.image:(props?.data?.bgColor?.enabled==='on'?props?.data?.bgColor:{}))} className={animation}>
            <div className="heroCenter">
                {formSubmitted ?
                    <h4>{props.data?.button?.afterSubmitMessage}</h4>
                    :
                <>
                    {props.data.textEnabled === "on" &&
                        <InlineEditableText isResult={true} setValue={props.setValue} name="text">{props.data.text}</InlineEditableText>
                    }
                    {props.data.subtitleEnabled === "on" &&
                        <InlineEditableText isResult={true} setValue={props.setValue} name="subTitle">{props.data.subTitle}</InlineEditableText>
                    }
                    {props.data.paragraphEnabled === "on" &&
                        <InlineEditableText isResult={true} setValue={props.setValue} name="paragraph">{props.data.paragraph}</InlineEditableText>
                    }
                    {props.data.nameInput === "on" &&
                        <div className="m-2">
                            <label><InlineEditableText defaultContent={'Name'} isResult={true} setValue={props.setValue} name="textName">{props.data.textName}</InlineEditableText></label>
                            <input type="text" className="form-control"/>
                        </div>
                    }
                    {props.data.emailInput === "on" &&
                        <div className="m-2">
                            <label><InlineEditableText defaultContent={'Email'} isResult={true} setValue={props.setValue} name="textEmail">{props.data.textEmail}</InlineEditableText></label>
                            <input type="text" className="form-control"/>
                        </div>
                    }
                    {props.data.phoneInput === "on" &&
                        <div className="m-2">
                            <label><InlineEditableText defaultContent={'Phone'} isResult={true} setValue={props.setValue} name="textPhone">{props.data.textPhone}</InlineEditableText></label>
                            <input type="text" className="form-control"/>
                        </div>
                    }
                    {props.data.textInput === "on" &&
                        <div className="m-2">
                            <label><InlineEditableText isResult={true} setValue={props.setValue} name="textName">{props.data.textName}</InlineEditableText></label>
                            <textarea className="form-control"></textarea>
                        </div>
                    }
                    {props?.data?.button?.enabled === "on" &&
                        <div className="row justify-content-center">
                            <div className="col-auto"><div onClick={(e)=>submitForm()} style={buttonStyle(props?.data?.button)}><InlineEditableText isResult={true} setValue={props.setValue} name="buttonText">{props.data.buttonText}</InlineEditableText></div></div>
                        </div>
                    }
                </>
                }
            </div>
        </div>
    }
}

export default Form
