import React,{useState,useEffect} from "react";
import {default as ImageListEditor} from "../../editor/subelements/ImageList";
import SliderInput from "../../editor/subelements/SliderInput";
import Animation from "../../editor/Animation";
import {MdAnimation} from "react-icons/md";
import {useCustomAnimation} from "../../helpers/animation";
import ColorPicker from "../../editor/subelements/ColorPicker";
import Spacing from "../../editor/subelements/Spacing";
import {bgImage} from "../../helpers/bgImage";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const Photogallery = (props) => {

    const [images,setImages] = useState([])

    const animation = useCustomAnimation(props.data.animation)

    useEffect(()=>{
        if (props.data.image && props.data.image.length > 0) {
            let list = [], i, n = props.data.image.length
            for (i = 0; i < n; i++) {
                list.push(props.data.image[i]);
            }
            setImages(list)
        }
    },[props.data.image])

    if (props.edit) {
        return <div>
            <div className="editItem p-2">
                <ImageListEditor name={"image"} value={props.data.image} setValue={props.setValue} featureName={""}/>
                <div className="subElement">
                    <ColorPicker name={"bgColor"} value={props.data?.bgColor} setValue={props.setValue} featureName={"Background color"}/>
                </div>
                <div className="subElement">
                    <Spacing name={"margin"} value={props.data?.margin} setValue={props.setValue} featureName={"Margin"}/>
                </div>
                <div className="subElement">
                    <Spacing name={"padding"} value={props.data?.padding} setValue={props.setValue} featureName={"Padding"}/>
                </div>
                <div className="subElement">
                    <Spacing name={"borderRadius"} value={props.data?.borderRadius} setValue={props.setValue} featureName={"Border radius"}/>
                </div>
                <div className="subElement">
                    <ColorPicker name={"borderColor"} value={props.data?.borderColor} setValue={props.setValue} featureName={"Border color"}/>
                </div>
                <div className="subElement">
                    <SliderInput name={"borderWidth"} value={props.data?.borderWidth} setValue={props.setValue} featureName={"Border width"} min={0} max={20}/>
                </div>
            </div>
            <div className="editItem">
                <Animation name={'animation'} setValue={props.setValue} value={props.data.animation} featureName="Animation" imageName={<MdAnimation style={{fontSize:"18px"}}/>}/>
            </div>
        </div>
    }else {
        return <div style={bgImage(props?.data)??{}} className={animation}>{images.length > 0 &&
            <Carousel>
                {images.map((image,index)=>{
                    return (
                        <div key={index}>
                            <img src={image} alt=""/>
                        </div>
                    )
                })}
            </Carousel>
        }</div>
    }
}

export default Photogallery
