import React,{useState,useEffect} from "react";
import API from "../../../utils/API";
import {AiFillDelete} from "react-icons/ai";
import {confirmAlert} from 'react-confirm-alert';
import TagsItem from "./TagsItem";

const Tags = ({appId}) => {

    const [list,setList] = useState([])
    const [editCategory, setEditCategory] = useState(false)
    const [editCategoryId, setEditCategoryId] = useState(null)

    const newCategory = () => {
        setEditCategoryId(null)
        setEditCategory(true)
    }

    const closeEditCategory = () => {
        setEditCategoryId(null)
        setEditCategory(false)
        loadList()
    }

    const deleteItem = (id) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure you want to delete this tag?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        API.delete(`/ecommerce/tag/${appId}/${id}`).then((result)=>{
                            loadList()
                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    }

    const startEditCategory = (id) => {
        setEditCategoryId(id)
        setEditCategory(true)
    }

    const loadList = () => {
        API.post(`/ecommerce/tags/${appId}`).then((result)=>{
            setList(result.data.data)
        })
    }

    useEffect(()=>{
        loadList()
    },[])

    return (<>
        <div className="m-2">
            <div className="header">
                <div className="row justify-content-between">
                    <div className="col-auto pt-2">
                        {editCategory ?
                            <>
                                {editCategoryId ?
                                    <>Edit tag</>
                                    :
                                    <>New tag</>
                                }
                            </>
                            :
                            <>Product tags</>
                        }
                    </div>
                    <div className="col-auto">
                        {!editCategory &&
                            <div className="primaryButton" onClick={()=>newCategory()}>+ New Tag</div>
                        }
                    </div>
                </div>
            </div>
        </div>
        {editCategory ?
            <TagsItem id={editCategoryId} app={appId} onClose={closeEditCategory}/>
            :
            <>
                {
                    list?.map((item,key)=>{
                        return <div key={key} className="categoryType">
                            <div className="row">
                                <div className="col" onClick={()=>startEditCategory(item.id)}>
                                    <div className="tagItem">{item.title}</div>
                                </div>
                                <div className="col-auto" onClick={()=>deleteItem(item.id)}>
                                    <AiFillDelete/>
                                </div>
                            </div>
                        </div>
                    })
                }
            </>
        }
    </>)
}

export default Tags