import React,{useState,useEffect} from "react";
import { FaTh,FaGithubAlt } from "react-icons/fa";
import { HiOutlineChartPie } from "react-icons/hi";
import {AiOutlineShop} from "react-icons/ai";
import {BiEnvelope} from "react-icons/bi";
import {RiSettings2Line} from "react-icons/ri";
import {FiLogOut} from "react-icons/fi";
import {logout} from "../redux/actions/userActions";
import {useDispatch} from "react-redux";
import {
    Link
} from "react-router-dom";

const Sidebar = () => {

    const [currentPage,setCurrentPage] = useState('dashboard')

    useEffect(()=>{
        if (window.location.href.indexOf('/statistic') !== -1) {
            setCurrentPage('statistic')
        }
        if (window.location.href.indexOf('/shop') !== -1) {
            setCurrentPage('shop')
        }
        if (window.location.href.indexOf('/message') !== -1) {
            setCurrentPage('messages')
        }
        if (window.location.href.indexOf('/billing') !== -1 || window.location.href.indexOf('/profile') !== -1 || window.location.href.indexOf('/password') !== -1) {
            setCurrentPage('settings')
        }
    },[window.location.href])

    const dispatch = useDispatch();

    return (
        <nav id="sidebarMenu" className="col-auto sidebar p-0 m-0">
            <div className="position-sticky h-100 position-relative" style={{width:"88px"}}>
                <Link to={'/'}><div className="text-center mt-4">
                    <FaGithubAlt className="logo" color={"black"}/>
                </div></Link>
                <div className="icons">
                    <Link to={'/'}>
                        <div onClick={()=>setCurrentPage('dashboard')} className={`iconBox text-center ${(currentPage==='dashboard')?`activeIconBox`:``}`}>
                            <FaTh className="icon"/>
                        </div>
                    </Link>
                    <Link to={'/statistic'}>
                        <div onClick={()=>setCurrentPage('statistic')} className={`iconBox text-center ${(currentPage==='statistic')?`activeIconBox`:``}`}>
                            <HiOutlineChartPie className="icon"/>
                        </div>
                    </Link>
                    <Link to={'/shop'}>
                        <div onClick={()=>setCurrentPage('shop')} className={`iconBox text-center ${(currentPage==='shop')?`activeIconBox`:``}`}>
                            <AiOutlineShop className="icon"/>
                        </div>
                    </Link>
                    <Link to={'/message'}>
                        <div onClick={()=>setCurrentPage('messages')} className={`iconBox text-center ${(currentPage==='messages')?`activeIconBox`:``}`}>
                            <BiEnvelope className="icon"/>
                        </div>
                    </Link>
                    <Link to={'/billing/payment-method'}>
                        <div onClick={()=>setCurrentPage('settings')} className={`iconBox text-center ${(currentPage==='settings')?`activeIconBox`:``}`}>
                            <RiSettings2Line className="icon"/>
                        </div>
                    </Link>
                </div>
                <div className="position-absolute bottom-0 w-100" style={{marginBottom:"30px"}}>
                    <div className="iconBox text-center" onClick={()=>dispatch(logout())}>
                        <FiLogOut className="icon"/>
                    </div>
                </div>
            </div>

        </nav>
    )
}

export default Sidebar
