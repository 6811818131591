import React from "react";
import "../../assets/style/appDashboard.scss"
import {AiFillAndroid,AiFillApple} from "react-icons/ai";
import {
    useNavigate
} from "react-router-dom";

const StatisticApp = ({app}) => {

    let icon = {}
    if (app.icon) {
        icon = {
            backgroundImage: `url("${app.icon}")`,
            backgroundSize:"contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat"
        }
    }

    let navigate = useNavigate()

    return (
        <div className='appDashboard'>
            <div onClick={()=>navigate('/statistic/app/'+app.id)} className="addLink text-center pt-4" style={icon}>

            </div>
            <div className="innerBox">
                <div className="title text-center">{app.title}</div>
                <div onClick={()=>navigate('/statistic/app/'+app.id)} className="button text-center"><span className="text">View statistic</span></div>
            </div>
        </div>
    )
}

export default StatisticApp
