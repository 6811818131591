import React from "react";
import "../assets/style/dashboard.scss"
import {Helmet} from "react-helmet";
import Header from "../components/Header";
import AppBar from "../components/AppBar";
import "../assets/style/appPage.scss";
import {useIsTablet} from "../hooks/useIsTablet";

const App = ({ children }) => {
    const isTablet = useIsTablet()

    return (
        <div className="container-fluid appBuilder">
            <Helmet>
                <link rel="stylesheet" href={`/css/main.css`} />
            </Helmet>
            <div className="row">
                {!isTablet &&
                    <AppBar/>
                }
                <main className={`col ${!isTablet && 'p-4'} pt-3`} style={{marginLeft:!isTablet?"88px":""}}>
                    <div className={`pt-3 ${!isTablet && 'ps-5 pe-5'}`}>
                        <Header component={children}/>
                        <div className="pt-5 appPage">
                            {children}
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default App
