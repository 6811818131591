import React from "react";
import SliderInput from "../../editor/subelements/SliderInput";
import Animation from "../../editor/Animation";
import {MdAnimation} from "react-icons/md";
import {useCustomAnimation} from "../../helpers/animation";
import ColorPicker from "../../editor/subelements/ColorPicker";
import Spacing from "../../editor/subelements/Spacing";
import {bgImage} from "../../helpers/bgImage";
import Elements from "../../editor/Elements";
import TeamShowcaseItem from "../../editor/TeamShowcaseItem";

const TeamShowcase = (props) => {

    const animation = useCustomAnimation(props.data.animation)

    if (props.edit) {
        return <div>
            <div className="editItem p-2">
                <div className="subElement subElementNoBorderTop">
                    <Elements name={"team"} setValue={props.setValue} value={props.data?.team} featureName={"Team"} itemComponent={<TeamShowcaseItem/>}/>
                </div>
                <div className="subElement">
                    <ColorPicker name={"bgColor"} value={props.data?.bgColor} setValue={props.setValue} featureName={"Background color"}/>
                </div>
                <div className="subElement">
                    <Spacing name={"margin"} value={props.data?.margin} setValue={props.setValue} featureName={"Margin"}/>
                </div>
                <div className="subElement">
                    <Spacing name={"padding"} value={props.data?.padding} setValue={props.setValue} featureName={"Padding"}/>
                </div>
                <div className="subElement">
                    <Spacing name={"borderRadius"} value={props.data?.borderRadius} setValue={props.setValue} featureName={"Border radius"}/>
                </div>
                <div className="subElement">
                    <ColorPicker name={"borderColor"} value={props.data?.borderColor} setValue={props.setValue} featureName={"Border color"}/>
                </div>
                <div className="subElement">
                    <SliderInput name={"borderWidth"} value={props.data?.borderWidth} setValue={props.setValue} featureName={"Border width"} min={0} max={20}/>
                </div>
            </div>
            <div className="editItem">
                <Animation name={'animation'} setValue={props.setValue} value={props.data.animation} featureName="Animation" imageName={<MdAnimation style={{fontSize:"18px"}}/>}/>
            </div>
        </div>
    }else {
        return <div style={bgImage(props?.data)??{}} className={animation}>
            {(props.data?.team?.list && props.data?.team?.list?.length > 0) &&
                <ul className="list-group">
                    {props.data?.team?.list.map((teamItem,teamItemKey)=>{
                        return (<li className="list-group-item" key={teamItemKey}>
                            <div className="row">
                                <div className="col text-center">
                                    <div>
                                        {teamItem.image &&
                                            <img className="img-fluid img-thumbnail rounded-circle" src={teamItem.image}/>
                                        }
                                    </div>
                                    <h4>{teamItem.title}</h4>
                                    <p>{teamItem.description}</p>
                                </div>
                            </div>
                        </li>)
                    })}
                </ul>
            }
        </div>
    }
}

export default TeamShowcase
