import React,{useState} from "react";
import bg from "../assets/image/signin.png"
import google from "../assets/image/google.png"
import {useSelector,useDispatch} from "react-redux";
import API from "../utils/API";
import {login} from "../redux/actions/userActions";
import {
    useNavigate
} from "react-router-dom";

const ResetPassword = () => {
    const [email,setEmail] = useState(null)
    const [error,setError] = useState(null)
    const [loading,setLoading] = useState(false)
    const [sent,setSent] = useState(false)

    let navigate = useNavigate()

    const sign = useSelector(state => {
        return state.user.sign
    });

    const dispatch = useDispatch();

    const submit = () => {
        if (!loading && email) {
            setLoading(true)
            API.post('/auth/restore', {email: email})
                .then(data => {
                    setLoading(false)
                    setSent(true)
                })
                .catch(error => {
                    setError('User not found')
                    setLoading(false)
                })
        }
    }

    return (
        <div className="auth main d-flex w-100 justify-content-center">
            <div className="row w-100 content justify-content-center">
                <div className="col-xl-8 col-lg-8 col-md-10 col-sm-11">
                    <div className="row m-0 p-0 centerBlock justify-content-center">
                        <div className="col-md col-sm-12 m-0 p-0" style={{minHeight:"480px",backgroundImage:`url(${bg})`,backgroundRepeat: "no-repeat",backgroundPosition : "center",backgroundSize: "cover"}}/>
                        <div className="col-md col-sm-12">
                            <div className="text-end p-2">
                                <span className="logoTitle">APP WORLD</span>
                            </div>
                            <div className="form">
                                <div>
                                    <div className="title mb-5">Reset password</div>
                                    {sent ?
                                        <>
                                        <div className="text-center">
                                            <span className="fs-5">Email with instructions how to restore access was successfully sent</span>
                                        </div>
                                        <div className="col-12 mb-3 mt-3 text-center">
                                            <span className="orSignUp cursorPointer" onClick={()=>navigate("/signin")}><span className="text-primary">Sign In</span></span>
                                        </div>
                                        </>
                                        :
                                    <>
                                    {error &&
                                        <div className="text-center text-danger">{error}</div>
                                    }
                                    <div className="row">
                                        <div className="col-12 mb-2">
                                            <input type="text" className="form-control mt-2" placeholder="Enter your email address" onChange={(e) => {setEmail(`${e.target.value}`);setError(null)}}/>
                                        </div>
                                        <div className="col-12 mb-2 mt-4">
                                            <button className="btn btn-primary w-100 button" onClick={submit}>
                                                {loading ?
                                                    <>Loading...</>
                                                    :
                                                    <>Restore password</>
                                                }
                                            </button>
                                        </div>
                                        <div className="col-12 mb-3 mt-3 text-center">
                                            <span className="orSignUp cursorPointer" onClick={()=>navigate("/signin")}><span className="text-primary">Sign In</span></span>
                                        </div>
                                    </div>
                                    </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword
