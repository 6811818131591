import React from "react";
import {TbDots} from "react-icons/tb";
import {useNavigate, useParams} from "react-router-dom";

const ShippingTaxes = () => {
    let { appid } = useParams()
    let navigate = useNavigate()

    return (
        <>
            <div className="row me-4">
                <div className="col-4 fs-5">
                    <span className="selectLabel">Show:</span>
                    <select className="inlineSelect">
                        <option>All Shipping</option>
                        <option>UPS</option>
                    </select>
                </div>
                <div className="col-4">
                    <input className="form-control inlineSearch" placeholder="Search Shipping"/>
                </div>
                <div className="col-4 text-end pt-2">
                    <span className="selectLabel">Sort by:</span>
                    <select className="inlineSelect">
                        <option>Default</option>
                        <option>Price</option>
                    </select>
                </div>
            </div>
            <div className="row justify-content-end me-4">
                <div className="col-auto">
                    <div className="addButton" onClick={(e)=>navigate('/ecommerce/'+appid+'/shippingitem')}>+ New Shipping</div>
                </div>
            </div>
        </>
    )
}

export default ShippingTaxes
