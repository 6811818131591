import React from 'react';
import Card from "react-credit-cards";

const CardItem = ({paymentMethod}) => {
    return (
        <Card
            preview={true}
            issuer={paymentMethod.cardtype}
            number={"************" + paymentMethod.cardnumber}
            expiry={paymentMethod.cardexpiration}
            name={paymentMethod.cardname}
        />
    )
}

export default CardItem;