import React from "react";
import {TbDots} from "react-icons/tb";
import {useNavigate, useParams} from "react-router-dom";

const Shipping = () => {
    let { appid } = useParams()
    let navigate = useNavigate()

    return (
        <>
            <div className="table-responsive mt-3 me-4">
                <table className="table table-borderless products">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Demographic</th>
                        <th>Shipping method</th>
                        <th>Price</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className="pt-4"><input type="text" className="form-control"/></td>
                        <td className="pt-4">
                            <select className="form-control">
                                <option>Select demographic...</option>
                            </select>
                        </td>
                        <td className="pt-4">
                            <select className="form-control">
                                <option>Select method...</option>
                            </select>
                        </td>
                        <td className="pt-4"><input type="text" className="form-control"/></td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className="row justify-content-end me-4">
                <div className="col-auto">
                    <div className="saveButton" onClick={(e)=>navigate('/ecommerce/'+appid+'/sale')}>Save</div>
                </div>
                <div className="col-auto">
                    <div className="cancelButton">Cancel</div>
                </div>
            </div>
        </>
    )
}

export default Shipping
