import React from "react";
import Main from "../layouts/Main";

const Setting = () => {
    return (
        <>
            <h2>Setting</h2>
        </>
    )
}

export default Setting
