import React from "react";
import OnOffFeature from "../../editor/OnOffFeature";
import {MdAnimation} from "react-icons/md";
import Animation from "../../editor/Animation";
import {useCustomAnimation} from "../../helpers/animation"
import 'animate.css';
import Layer from "../../editor/Layer";
import _ from "lodash";
import {layer} from "../../helpers/layer";

const Ecomcheckout = (props) => {
    const animation = useCustomAnimation(props.data.animation)

    if (props.edit) {
        return <div>
            <div className="editItem">
                <OnOffFeature name={'phoneRequired'} setValue={props.setValue} value={props.data.phoneRequired} featureName="Phone required" imageName="*"/>
            </div>
            <div className="editItem">
                <OnOffFeature name={'emailRequired'} setValue={props.setValue} value={props.data.phoneRequired} featureName="Email required" imageName="*"/>
            </div>
            <div className="editItem">
                <OnOffFeature name={'addressRequired'} setValue={props.setValue} value={props.data.addressRequired} featureName="Address required" imageName="*"/>
            </div>
            <div className="editItem">
                <Layer name={'divStyle'} setValue={props.setValue} value={props.data.divStyle} featureName="Block style"/>
            </div>
            <div className="editItem">
                <Animation name={'animation'} setValue={props.setValue} value={props.data.animation} featureName="Animation" imageName={<MdAnimation style={{fontSize:"18px"}}/>}/>
            </div>
        </div>
    }else {
        return <div style={_.merge({}, layer(props.data?.divStyle))} className={animation}>
            <h5>Personal information</h5>
            <div className="form-group">
                <lable>Your full name</lable>
                <input type="text" className="form-control" placeholder="Full name"/>
            </div>
            <div className="form-group">
                <lable>Your email</lable>
                <input type="text" className="form-control" placeholder="Email"/>
            </div>
            <div className="form-group">
                <lable>Your phone number</lable>
                <input type="text" className="form-control" placeholder="Phone"/>
            </div>
            <hr/>
            <h5>Address</h5>
            <div className="form-group">
                <lable>Country</lable>
                <input type="text" className="form-control" placeholder="Country"/>
            </div>
            <div className="form-group">
                <lable>City</lable>
                <input type="text" className="form-control" placeholder="City"/>
            </div>
            <div className="form-group">
                <lable>Address</lable>
                <input type="text" className="form-control" placeholder="Address"/>
            </div>
            <div className="form-group">
                <lable>Post code</lable>
                <input type="text" className="form-control" placeholder="Post code"/>
            </div>
        </div>
    }
}

export default Ecomcheckout
