import React from "react"
import Rating from "react-rating"
import {AiFillStar, AiOutlineStar} from "react-icons/ai"

const ReviewItemYelp = ({review}) => {

    return <>
        <Rating readonly={true} initialRating={review.rating} emptySymbol={<AiOutlineStar color={"red"}/>} fullSymbol={<AiFillStar color={"red"}/>}/><br/>
        <div className="row">
            <div className="col-8">
                {review.reviewer}<br/>
                {review.datetime.substring(0, 10)}<br/>
            </div>
            <div className="col-4">
                {review?.avatar &&
                    <img className="img-fluid rounded" src={review?.avatar}/>
                }
            </div>
        </div>
        {review.text}
    </>
}

export default ReviewItemYelp
