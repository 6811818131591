import React,{useState,useEffect} from "react";
import Main from "../layouts/Main";
import "../assets/style/mainpage.scss"
import MainPageDemo from "../assets/animation/mainpagedemo.json"
import Lottie from "lottie-react";
import NewApp from "../components/apps/NewApp";
import API from "../utils/API";
import App from "../components/apps/App";

const Home = () => {
    const [apps,setApps] = useState([])

    useEffect(()=>{
        API.get('/app/getall').then((response) => {
            setApps(response.data.data)
        })
    },[])

    return (
        <div className="mainPage">
            <div className="hero">
                <span className="title">Start creating a new project.</span>
                <span className="subTitle">You only need to click on the first Icon "+".<br/>And you will enter in this magic world.</span>
            </div>
            <div className="row">
                <div className="col-md-7 col-sm-12">
                    <div className="row mt-3 justify-content-md-center">
                        <div className="col-auto">
                            <NewApp/>
                        </div>
                        {apps.map((item,key)=>{
                           return (
                               <div className="col-auto" key={key}>
                                   <App app={item}/>
                               </div>
                           )
                        })}
                    </div>
                </div>
                <div className="col-5 d-sm-none d-md-block">
                    <Lottie animationData={MainPageDemo} loop={true} style={{position:"relative",top:"-13vw",marginBottom:"-13vw"}}/>
                </div>
            </div>
        </div>
    )
}

Home.layout = Main
Home.private = true

export default Home
