import React from "react";
import {useSelector} from "react-redux";

export const useClickDisable = () => {

    const pageeditor = useSelector(state => {
        return state.pageEditor
    });

    if (pageeditor?.app) {
        return `rightClickDisabled`
    }else {
        return "";
    }

}
