import React, {useEffect, useState} from "react";
import "../../assets/style/profile.scss";
import ImageUploader from "react-images-upload";
import API from "../../utils/API";
import {useDispatch} from "react-redux";
import {login} from "../../redux/actions/userActions";

const Profile = () => {
    const [profileData,setProfileData] = useState(null)
    const dispatch = useDispatch();


    const setValue = (name,value) => {
        setProfileData({ ...profileData, [name]: value })
    }

    const getUserData = () => {
        API.get('/me').then((response) => {
            setProfileData(response.data.data)
        });
    }

    useEffect(()=> {
        getUserData();
    },[])

    const onDrop = (picture,pictureURI) => {
        if (pictureURI.length > 0) {
            API.post('/image/', {image: pictureURI[0]}).then((response) => {
                setValue("icon", response.data.data.url)
            });
        }else {
            setValue("icon", null)
        }
    };

    const saveChanges = () => {
        API.post('/me/save', profileData).then((response) => {
            dispatch(login(response.data.data));
        });
    }

    return (
        <>
            {profileData &&
                <div className="row profilePage m-0 p-0 me-4">
                    <div className="col-3 editArea text-center pt-4">
                        <label>Avatar</label>
                        <ImageUploader
                            withIcon={true}
                            onChange={onDrop}
                            imgExtension={[".jpg",".jpeg", ".gif", ".png", ".gif", ".webp"]}
                            maxFileSize={5242880}
                            withPreview={true}
                            singleImage={true}
                            defaultImages={profileData.icon?[profileData.icon]:[]}
                        />
                    </div>
                    <div className="col-8 offset-1 editArea p-4">
                        <div className="row mt-5">
                            <div className="col-12">
                                <label>Name</label>
                                <input type="text" className="form-control" value={profileData.name} onChange={(e)=>setValue('name',e.target.value)}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 text-end p-4">
                        <span className="addButton p-3" onClick={saveChanges}>Save Changes</span>
                    </div>
                </div>
            }
        </>
    )
}

export default Profile
