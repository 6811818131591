import React,{useState,useEffect} from "react";
import {AiOutlinePlus} from "react-icons/ai";
import {useNavigate} from "react-router-dom";
import API from "../../utils/API";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu"
import {confirmAlert} from 'react-confirm-alert';
import {VscEdit} from "react-icons/vsc";
import {BsTrash} from "react-icons/bs";
import CardItem from "./CardItem";

const PaymentMethod = () => {
    let navigate = useNavigate()

    const [paymentMethods,setPaymentMethod] = useState([])
    const [loaded,setLoaded] = useState(false)

    const getPaymentMethods = () => {
        API.get('/billing/paymentmethods',)
            .then((response) => {
                setPaymentMethod(response.data.data)
                setLoaded(true)
            })
    }

    useEffect(()=>{
        getPaymentMethods()
    },[])

    const setAsDefaultPaymentMethod = (id) => {
        API.put('/billing/paymentmethods/'+id+'/default')
          .then((response) => {
                getPaymentMethods()
            })
    }

    const deletePaymentMethod = (id) => {
        confirmAlert({
            title: 'Are you sure?',
            message: 'Are you sure you want to delete this payment method?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        API.delete('/billing/paymentmethods/'+id)
                          .then((response) => {
                                getPaymentMethods()
                            })
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                    }
                }
            ]
        });
    }
    return (
        <div>
            <div className="row newPaymentMethodButton" onClick={(e)=>navigate('/billing/payment-method/new')}>
                <div className="col-auto p-1">
                    <div className="addLink text-center pt-1">
                        <AiOutlinePlus color={"#56B3F8"} size={"30px"}/>
                    </div>
                </div>
                <div className="col-auto addLinkTitle pt-3">
                    Add payment method
                </div>
            </div>
            {loaded &&
                <div>
                    <div className="row">
                        {paymentMethods &&
                            paymentMethods.map((paymentMethod, key) => {
                                return (
                                    <div className="col-auto text-center p-2" key={key}>
                                        {paymentMethod.isdefault === "1" ?
                                            <>
                                                <CardItem paymentMethod={paymentMethod}/>
                                                <span className="text-primary">Default payment method</span>
                                            </>
                                            :
                                            <>
                                                <ContextMenuTrigger id={key} attributes={{className:"h-100"}}>
                                                    <CardItem paymentMethod={paymentMethod}/>
                                                </ContextMenuTrigger>
                                                <ContextMenu
                                                    id={key}
                                                    hideOnLeave={true}
                                                >
                                                    <MenuItem onClick={()=>setAsDefaultPaymentMethod(paymentMethod.id)}>
                                                        <VscEdit/> Set as default payment method
                                                    </MenuItem>
                                                    <MenuItem divider />
                                                    <MenuItem onClick={()=>deletePaymentMethod(paymentMethod.id)}>
                                                        <BsTrash/> Delete payment method
                                                    </MenuItem>
                                                </ContextMenu>
                                            </>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            }
        </div>
    )
}

export default PaymentMethod
