import React, {useState,useRef,useEffect} from 'react'
import { ItemTypes } from './Constants'
import { useDrag } from 'react-dnd'
import {build} from "./blocks/BlockFactory";
import "../../assets/style/block.scss"
import {confirmAlert} from 'react-confirm-alert';
import {useSelector,useDispatch} from "react-redux";
import {set, setContent} from "../../redux/actions/pageeditorActions";
import uniqid from 'uniqid';
import _ from "lodash";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu"
import {VscEdit} from 'react-icons/vsc'
import {IoMdCopy} from 'react-icons/io'
import {BsTrash} from 'react-icons/bs'
import {TbTemplate} from 'react-icons/tb'
import {HiOutlinePencilAlt} from 'react-icons/hi'
import sleep from '@react-corekit/sleep';
import {useNavigate, useParams} from "react-router-dom";
import { Store } from 'react-notifications-component';
import Modal from "react-modal";
import API from "../../utils/API";
import {GrUpdate} from'react-icons/gr';
import {useIsTablet} from "../../hooks/useIsTablet";
import { useHoverIntent } from "react-use-hoverintent";

const Component = ({dragItem, name, image, source, templateId, isPersistent}) => {
    let navigate = useNavigate()
    const dispatch = useDispatch()

    const [isHovering, intentRef, setIsHovering] = useHoverIntent({
        timeout: 100
    });

    const elementRef = useRef(null)

    useEffect(()=>{
        if (isHovering) {
            dispatch(set({selectedBlockId:dragItem.id}))
        }
    },[isHovering]);

    const pageeditor = useSelector(state => {
        return state.pageEditor
    });

    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [minTop, setMinTop] = useState(0);
    const [maxBottom, setMaxBottom] = useState(0);

    const menuHeight = 100;

    useEffect(() => {
        function handleResize() {
            if (elementRef.current) {
                const x = elementRef.current.getBoundingClientRect().right;
                const y = elementRef.current.getBoundingClientRect().top;
                setPosition({x, y});
                setMinTop(document.getElementById("app-root")?.getBoundingClientRect()?.top);
                setMaxBottom(document.getElementById("app-root")?.getBoundingClientRect()?.bottom);
            }
        }

        handleResize(); // initial call to get position of the element on mount
        window.addEventListener("resize", handleResize);
        document.getElementById("app-root").addEventListener("scroll", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
            document.getElementById("app-root")?.removeEventListener("scroll", handleResize);
        };
    }, [elementRef,pageeditor]);

    let { appid } = useParams();
    const isTablet = useIsTablet()

    const [{isDragging,canDrag}, drag] = useDrag(() => ({
        type: ItemTypes.BLOCK,
        item: dragItem,
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
            canDrag: !!monitor.canDrag()
        }),
        canDrag: () => {
            return !!dragItem.component
        },
    }),[dragItem])

    const editBlock = () => {
        dispatch(set({editBlockId:dragItem.id,editType:'block'}))
    }

    const [templateModal, setTemplateModal] = useState(false)
    const [templateName, setTemplateName] = useState('')
    const [templateDataPersist, setTemplateDataPersist] = useState(false);

    const [templateEditModal, setTemplateEditModal] = useState(false)
    const [templateEditName, setTemplateEditName] = useState(name)

    const deleteBlock = () => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure you want to delete this block?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        let currentItems = pageeditor.sections, newItems = [], newSection, n = pageeditor.sections.length, i, j, k
                        for (i = 0; i < n; i++) {
                            k = currentItems[i].elements.length
                            newSection = []

                            for (j = 0; j < k; j++) {
                                if (dragItem.id !== currentItems[i].elements[j].id) {
                                    newSection.push(currentItems[i].elements[j])
                                }else {
                                    if (k>1) {
                                        newSection.push({id: currentItems[i].elements[j].id})
                                    }
                                }
                            }

                            if (newSection.length > 0) {
                                let section = {elements: newSection};
                                if (currentItems[i].layout) {
                                    section.layout = currentItems[i].layout
                                }
                                newItems.push(section)
                            }
                        }

                        dispatch(setContent({sections:newItems}))
                        dispatch(set({editBlockId:null,editType:null}));
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    }

    const deleteTemplate = () => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure you want to delete this template?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        API.delete(`/user/template/${templateId}`).then((response) => {
                            dispatch(set({templateReload:uniqid()}))
                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    }

    const cloneBlock = () => {
        let currentItems = pageeditor.sections, newSectionId, cloneSection, newItems = [], newSection, n = pageeditor.sections.length, i, j, k
        for (i = 0; i < n; i++) {
            k = currentItems[i].elements.length
            newSection = []
            cloneSection = false

            for (j = 0; j < k; j++) {
                newSection.push(currentItems[i].elements[j])
                if (dragItem.id === currentItems[i].elements[j].id) {
                    cloneSection = JSON.parse(JSON.stringify(currentItems[i].elements[j]))
                    cloneSection.id = uniqid()

                    newSectionId = i + 1
                }
            }

            newItems.push({elements:newSection})

            if (cloneSection) {
                newItems.push({elements:[cloneSection]})
            }
        }

        dispatch(set({sections:newItems,editSectionId:newSectionId}))
    }

    const renameTemplate = () => {
        if (templateEditName && templateEditName.length > 0) {
            API.post('/user/template/'+templateId,{title:templateEditName}).then((response) => {
                dispatch(set({templateReload:uniqid()}))
                setTemplateEditModal(false);
            });
        }
    }

    const saveTemplate = () => {
        if (templateName.length > 0) {

            let currentItems = pageeditor.sections, template, n = pageeditor.sections.length, i, j, k
            for (i = 0; i < n; i++) {
                k = currentItems[i].elements.length

                for (j = 0; j < k; j++) {
                    if (dragItem.id === currentItems[i].elements[j].id) {
                        template = JSON.parse(JSON.stringify(currentItems[i].elements[j]))
                        template.data.persistDataAcrossAllInstances = templateDataPersist;
                    }
                }
            }

            API.post('/user/block/'+appid,{...template,templateName}).then((response) => {
                setTemplateName('');
                setTemplateModal(false);
                setTemplateDataPersist(false)
                dispatch(set({templateReload:uniqid()}))

                if (templateDataPersist) {
                    setValue('persistentParentBlock', response.data.data.id);
                }

                Store.addNotification({
                    title: "Success!",
                    message: "Template created successfully",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            })
        }
    }

    const setValue = (name, value) => {

        if (dragItem.id) {
            let i, j, n = pageeditor.sections.length, k, newData = [], editBlock, newSection, dataBlock

            let isPersistentBlock = false;
            let persistentBlockId = null;
            let persistentBlockData = null;
            let persistentBlocks = [];

            for (i = 0; i < n; i++) {
                newSection = {elements : []}
                if (pageeditor.sections[i].layout) {
                    newSection.layout = pageeditor.sections[i].layout
                }

                k = pageeditor.sections[i].elements.length

                for (j = 0; j < k; j++) {
                    if (pageeditor.sections[i].elements[j]?.data?.persistDataAcrossAllInstances === true) {
                        persistentBlocks.push(pageeditor.sections[i].elements[j]?.data?.persistentTemplateId)
                    }

                    if (pageeditor.sections[i].elements[j].id === dragItem.id) {
                        editBlock = JSON.parse(JSON.stringify(pageeditor.sections[i].elements[j]))
                        dataBlock = editBlock.data??{}
                        if (name === 'persistentParentBlock') {
                            _.set(dataBlock, 'persistentTemplateId', value);
                            _.set(dataBlock, 'persistDataAcrossAllInstances', true);
                        }else {
                            _.set(dataBlock, name, value);
                        }

                        if (dataBlock.persistDataAcrossAllInstances === true) {
                            isPersistentBlock = true;
                            persistentBlockId = dataBlock.persistentTemplateId;
                            persistentBlockData = JSON.parse(JSON.stringify(dataBlock));
                        }

                        editBlock.data = dataBlock
                        newSection.elements.push(editBlock)
                    }else {
                        newSection.elements.push(pageeditor.sections[i].elements[j])
                    }
                }

                newData.push(newSection)
            }

            dispatch(setContent({sections:newData,isPersistentBlock,persistentBlockId,persistentBlockData:persistentBlockData}))
        }
    }

    const editSection = () => {
        let currentItems = pageeditor.sections, n = pageeditor.sections.length, i, j, k
        for (i = 0; i < n; i++) {
            k = currentItems[i].elements.length

            for (j = 0; j < k; j++) {
                if (dragItem.id === currentItems[i].elements[j].id) {
                    dispatch(set({editSectionId:i,editType:'section'}))
                    return;
                }
            }
        }
    }

    const handleContextMenu = (e, data) => {
        if (data.action === 'edit') {
            editBlock()
        }
        if (data.action === 'clone') {
            cloneBlock()
        }
        if (data.action === 'delete') {
            deleteBlock()
        }
        if (data.action === 'editsection') {
            editSection()
        }
        if (data.action === 'template') {
            setTemplateModal(true)
        }
        if (data.action === 'deleteTemplate') {
            deleteTemplate()
        }
        if (data.action === 'renameTemplate') {
            setTemplateEditModal(true)
        }
    }

    return (
        <div
            ref={drag}
            style={{
                opacity: isDragging ? 0.5 : 1,
                cursor: !!dragItem.component?'move':'auto'
            }}
            className="m-0 p-1 h-100"
        >
            {
                !!dragItem.component ?
                    <>
                        {source?
                            templateId ?
                                <>
                                    <ContextMenuTrigger id={templateId} attributes={{className:"h-100"}}>
                                    <div className="blockSource text-center pt-3">
                                        <img src={image} style={{width:"38px"}}/><br/>
                                        <span className="name">{name} {isPersistent}{isPersistent && <GrUpdate size={10}/>}</span>
                                    </div>
                                    </ContextMenuTrigger>
                                    <ContextMenu
                                        id={templateId}
                                        hideOnLeave={!isTablet}
                                    >
                                        <MenuItem data={{action: 'renameTemplate'}} onClick={handleContextMenu}>
                                            <HiOutlinePencilAlt/> Rename
                                        </MenuItem>
                                        <MenuItem divider />
                                        <MenuItem data={{action: 'deleteTemplate'}} onClick={handleContextMenu}>
                                            <BsTrash/> Delete template
                                        </MenuItem>
                                    </ContextMenu>
                                    <Modal
                                        isOpen={templateEditModal}
                                        className="modal-window newPageModal"
                                        overlayClassName="modal-overlay"
                                        onRequestClose={()=>setTemplateEditModal(false)}
                                    >
                                        <div>
                                            <div className="modalTitle row justify-content-center">
                                                <div className="col-auto icon">
                                                    <TbTemplate/>
                                                </div>
                                                <div className="col-auto text pt-2">
                                                    Rename template
                                                </div>
                                            </div>
                                            <div className="row justify-content-center mt-3 mb-3">
                                                <div className="col-10">
                                                    <input className="form-control" value={templateEditName} placeholder="Template name" onChange={(e) => {setTemplateEditName(e.target.value)}}/>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center mb-2">
                                                <div className="col-auto">
                                                    <div className="button pt-2" onClick={()=>renameTemplate()}>Save</div>
                                                </div>
                                            </div>
                                        </div>
                                    </Modal>
                                </>
                                :
                            <div className="blockSource text-center pt-3">
                                <img src={image} style={{width:"38px"}}/><br/>
                                <span className="name">{name}</span>
                            </div>
                        :
                            <>
                                <div id={dragItem.id} className="h-100" ref={intentRef}>
                                    <div ref={elementRef} className={`text-break componentBlock h-100 ${(dragItem.id === pageeditor.editBlockId && pageeditor.editType === 'block')?`inEditBlock`:(pageeditor.selectedBlockId === dragItem.id && position.x > 0 && position.y > minTop && position.y < maxBottom)?"inSelectedBlock":``}`}>
                                        {React.cloneElement(build(dragItem.component),{...dragItem, ...{setValue:setValue}})}
                                    </div>
                                    {(pageeditor.selectedBlockId === dragItem.id && position.x > 0 && position.y > minTop && position.y < maxBottom) &&
                                    <div className="cursorPointer" style={{position: "fixed",top:position.y,left:position.x,width:"40px",height:`${menuHeight}px`,paddingRight:"10px",paddingLeft:"10px",zIndex:1000}}>
                                        <div className="manageMenuButtons">
                                            <div className="row m-0 p-0">
                                                <div className="col-auto m-0 p-0">
                                                    <div className="buttonContainer text-center" onClick={()=>editBlock()}><VscEdit size={20}/></div>
                                                </div>
                                                <div className="col-auto m-0 p-0 mt-1">
                                                    <div className="buttonContainer text-center" onClick={()=>setTemplateModal(true)}><IoMdCopy size={20}/></div>
                                                </div>
                                                <div className="col-auto m-0 p-0 mt-1">
                                                    <div className="buttonContainer text-center" onClick={()=>deleteBlock()}><BsTrash size={20}/></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                </div>
                                <ContextMenu
                                    id={dragItem.id}
                                    onShow={()=>{
                                        document.querySelectorAll('.ql-tooltip').forEach(e => e.style.display = "none")
                                        sleep(500).then(() => {
                                            document.querySelectorAll('.ql-tooltip').forEach(e => e.className += ' ql-hidden')
                                            document.querySelectorAll('.ql-tooltip').forEach(e => e.style.display = "")
                                        });
                                    }}
                                    hideOnLeave={!isTablet}
                                >
                                    <MenuItem data={{action: 'edit'}} onClick={handleContextMenu}>
                                        <VscEdit/> Edit block
                                    </MenuItem>
                                    <MenuItem divider />
                                    <MenuItem data={{action: 'editsection'}} onClick={handleContextMenu}>
                                        <HiOutlinePencilAlt/> Edit section
                                    </MenuItem>
                                    <MenuItem divider />
                                    <MenuItem data={{action: 'template'}} onClick={handleContextMenu}>
                                        <TbTemplate/> Create template
                                    </MenuItem>
                                    <MenuItem divider />
                                    <MenuItem data={{action: 'clone'}} onClick={handleContextMenu}>
                                        <IoMdCopy/> Clone block
                                    </MenuItem>
                                    <MenuItem divider />
                                    <MenuItem data={{action: 'delete'}} onClick={handleContextMenu}>
                                        <BsTrash/> Delete block
                                    </MenuItem>
                                </ContextMenu>
                                <Modal
                                    isOpen={templateModal}
                                    className="modal-window newPageModal"
                                    overlayClassName="modal-overlay"
                                    onRequestClose={()=>setTemplateModal(false)}
                                >
                                    <div>
                                        <div className="modalTitle row justify-content-center">
                                            <div className="col-auto icon">
                                                <TbTemplate/>
                                            </div>
                                            <div className="col-auto text pt-2">
                                                Create template
                                            </div>
                                        </div>
                                        <div className="row justify-content-center mt-3 mb-3">
                                            <div className="col-10">
                                                <input className="form-control" value={templateName} placeholder="Template name" onChange={(e) => {setTemplateName(e.target.value)}}/>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center mt-3 mb-3">
                                            <div className="col-10">
                                                <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="persistChckbox"
                                                    checked={templateDataPersist}
                                                    onChange={(e) => {setTemplateDataPersist(e.target.checked)}}
                                                />
                                                <label className="form-check-label" htmlFor="persistChckbox" >
                                                    Persist data across all pages
                                                </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center mb-2">
                                            <div className="col-auto">
                                                <div className="button pt-2" onClick={()=>saveTemplate()}>Create</div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                            </>
                        }
                    </>
                :
                    <>
                    <ContextMenuTrigger id={dragItem.id+"section"}>
                        <div className="blankBlock text-center d-table w-100 h-100" onClick={()=>{editSection();navigate('/app/'+appid+'/blocks')}}>
                            <div className="d-table-cell align-middle w-100">
                                <div className="row justify-content-center">
                                    <div className="col-auto"><div className="addIcon">+</div></div>
                                </div>
                            </div>
                        </div>
                    </ContextMenuTrigger>
                        <ContextMenu
                            id={dragItem.id+"section"}
                            hideOnLeave={!isTablet}
                        >
                            <MenuItem data={{action: 'editsection'}} onClick={handleContextMenu}>
                                <HiOutlinePencilAlt/> Edit section
                            </MenuItem>
                        </ContextMenu>
                    </>
            }
        </div>
    )
}

export default Component
