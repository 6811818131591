import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
    {
        name: 'Sep',
        ios: 0,
        android: 0
    },
    {
        name: 'Oct',
        ios: 0,
        android: 0
    },
    {
        name: 'Nov',
        ios: 0,
        android: 0
    },
    {
        name: 'Dec',
        ios: 0,
        android: 0
    },
    {
        name: 'Jan',
        ios: 0,
        android: 0
    },
    {
        name: 'Feb',
        ios: 0,
        android: 0
    },
    {
        name: 'Mar',
        ios: 0,
        android: 0
    },
    {
        name: 'Apr',
        ios: 0,
        android: 0
    },
    {
        name: 'May',
        ios: 0,
        android: 0
    },
    {
        name: 'Jun',
        ios: 0,
        android: 0
    },
    {
        name: 'Jul',
        ios: 0,
        android: 0
    },
    {
        name: 'Aug',
        ios: 0,
        android: 0
    },
];

const Installations = () =>  {

    return <div style={{ width: '100%', height: 300 }}>
        <span style={{ fontSize: 14, color: '#000000'}}>Installations</span>
        <ResponsiveContainer>
            <LineChart
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="ios" stroke="#8884d8" activeDot={{ r: 8 }} />
                <Line type="monotone" dataKey="android" stroke="#82ca9d" />
            </LineChart>
        </ResponsiveContainer>
        </div>;
}

export default Installations;
