import React from "react";
import Animation from "../../editor/Animation";
import {MdAnimation} from "react-icons/md";
import {useCustomAnimation} from "../../helpers/animation";
import ColorPicker from "../../editor/subelements/ColorPicker";
import InlineEditableText from "../../InlineEditableText";
import OnOff from "../../editor/subelements/OnOff";
import {BsCardText, BsImage} from "react-icons/bs";
import Image from "../../editor/Image";
import SliderInput from "../../editor/subelements/SliderInput";
import {imageStyle} from "../../helpers/imageStyle";
import {useSelector} from "react-redux";

const Splashscreen = (props) => {

    const animation = useCustomAnimation(props.data.animation)

    const app = useSelector(state => {
        return state.app
    });

    if (props.edit) {
        return <div>
            <div className="editItem p-2">
                <div className="subElement subElementNoBorderTop">
                    <ColorPicker name={"bgColor"} value={props.data?.bgColor} setValue={props.setValue} featureName={"Background color"}/>
                </div>
                <div className="subElement">
                    <OnOff name={"showText"} value={props.data?.showText} featureName={"Show text"} setValue={props.setValue} imageName={<BsCardText/>}/>
                </div>
                <div className="subElement">
                    <SliderInput name={"iconSize"} value={props.data?.iconSize??20} setValue={props.setValue} featureName={"Icon size"} min={0} max={40}/>
                </div>
            </div>
            <div className="editItem">
                <Image name={'logo'} setValue={props.setValue} value={props.data.logo} featureName="Logo" defaultHeight={100} imageName={<BsImage style={{fontSize:"18px"}}/>}/>
            </div>
            <div className="editItem">
                <Animation name={'animation'} setValue={props.setValue} value={props.data.animation} featureName="Animation" imageName={<MdAnimation style={{fontSize:"18px"}}/>}/>
            </div>
        </div>
    }else {
        return <div style={{backgroundColor: props.data?.bgColor?(props.data?.bgColor):'',height:app.appMode?"100%":"630px",textAlign:"center",paddingTop:"70%",paddingBottom:"auto"}} className={animation}>
            {(props?.data?.logo?.enabled === "on" && props?.data?.logo?.image) &&
                <img src={props?.data?.logo.image} height={`${props?.data?.logo?.height??100}px`} style={imageStyle(props?.data?.logo)}/>
            }
            {props.data.showText === "on" &&
                <InlineEditableText isResult={true} setValue={props.setValue} name="text">{props.data.text}</InlineEditableText>
            }
        </div>
    }
}

export default Splashscreen
