import React,{useEffect,useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import "../../../assets/style/teamManagement.scss"
import API from "../../../utils/API";

const AppTeamCreate = () => {
    let { appid } = useParams()
    const [user, setUser] = useState({})
    let navigate = useNavigate()

    const setValue = (name,value) => {
        setUser({ ...user, [name]: value })
    }

    const createTeam = () => {
        API.put(`/team/${appid}`, user)
        .then(res => {
           navigate(`/app/${appid}/team`)
        });
    }

    return <div className="teamManagement row justify-content-center">
        <div className="col-6">
            <div>
                Name
                <input type="text" className="form-control mt-2" value={user.name} onChange={(e)=>setValue('name',e.target.value)}/>
            </div>
            <div className="mt-4">
                Email
                <input type="text" className="form-control mt-2" value={user.email} onChange={(e)=>setValue('email',e.target.value)}/>
            </div>
            <div className="mt-4">
                Role
                <select className="form-control mt-2" value={user.role} onChange={(e)=>setValue('role',e.target.value)}>
                    <option>Select...</option>
                    <option value="admin">Admin</option>
                    <option value="editor">Editor</option>
                </select>
            </div>
            <div className="mt-4">
                Status
                <select className="form-control mt-2" value={user.status} onChange={(e)=>setValue('status',e.target.value)}>
                    <option>Select...</option>
                    <option value="1">Active</option>
                    <option value="0">Not active</option>
                </select>
            </div>
            <div className="mt-4 text-center">
                <button className="btn buttonSave" onClick={(e)=>createTeam()}>Create</button>
            </div>
        </div>
    </div>
}

export default AppTeamCreate
