import React from "react";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowRoundBack } from "react-icons/io";
import { IoMdArrowRoundBack } from "react-icons/io";
import { IoArrowBackCircle } from "react-icons/io5";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { IoReturnUpBack } from "react-icons/io5";
import { TiArrowBack } from "react-icons/ti";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { IoReturnUpBackSharp } from "react-icons/io5";
import { IoCaretBackOutline } from "react-icons/io5";


export const BackButtonIcon = ({name,size,color}) => {
    return (<span style={{fontSize:`${size}px`}}>
        {name === 'IoIosArrowBack' &&
            <IoIosArrowBack color={color}/>
        }
        {name === 'IoIosArrowRoundBack' &&
            <IoIosArrowRoundBack color={color}/>
        }
        {name === 'IoMdArrowRoundBack' &&
            <IoMdArrowRoundBack color={color}/>
        }
        {name === 'IoArrowBackCircle' &&
            <IoArrowBackCircle color={color}/>
        }
        {name === 'IoArrowBackCircleOutline' &&
            <IoArrowBackCircleOutline color={color}/>
        }
        {name === 'IoReturnUpBack' &&
            <IoReturnUpBack color={color}/>
        }
        {name === 'TiArrowBack' &&
            <TiArrowBack color={color}/>
        }
        {name === 'MdOutlineKeyboardBackspace' &&
            <MdOutlineKeyboardBackspace color={color}/>
        }
        {name === 'IoReturnUpBackSharp' &&
            <IoReturnUpBackSharp color={color}/>
        }
        {name === 'IoCaretBackOutline' &&
            <IoCaretBackOutline color={color}/>
        }
    </span>)
}

export const backButtons = ['IoIosArrowBack','IoIosArrowRoundBack','IoMdArrowRoundBack','IoArrowBackCircle','IoArrowBackCircleOutline','IoReturnUpBack','TiArrowBack','MdOutlineKeyboardBackspace','IoReturnUpBackSharp','IoCaretBackOutline']
