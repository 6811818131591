import React from "react";
import "../assets/style/dashboard.scss"
import {Helmet} from "react-helmet";
import "../assets/style/appPage.scss";
import "../assets/style/iframe.scss";

const Admin = ({ children }) => {
    return (
        <div className="container-fluid">
            <Helmet>
                <link rel="stylesheet" href={`/css/main.css`} />
            </Helmet>
            <div className="appPage">
                {children}
            </div>
        </div>
    )
}

export default Admin
