import React, {useState,useEffect} from "react";
import { Formik,ErrorMessage, Form, Field } from 'formik';
import * as Yup from 'yup';
import API from "../../utils/API";

const NativeSignIn = ({appId, setAuthenticated, abortSignIn}) => {

    const [mode, setMode] = useState("signIn");

    const [signInError, setSignInError] = useState(null);
    const [restoreError, setRestoreError] = useState(null);
    const [setPasswordError, setSetPasswordError] = useState(null);
    const [restoreId, setRestoreId] = useState(null);

    const changeMode = (mode) => {
        setSignInError(null);
        setRestoreError(null);
        setSetPasswordError(null);
        if (mode !== 'setpassword') {
            setRestoreId(null);
        }
        setMode(mode);
    }

    const SignupSchema = Yup.object().shape({
        nickname: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Required'),
        password: Yup.string()
            .min(8, 'Too Short!')
            .max(32, 'Too Long!')
            .required('Required'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match'),
        email: Yup.string().email('Invalid email').required('Required').test('Unique Email','Email already in use',
            function(value){return new Promise((resolve, reject) => {
                API.post(`/api/auth/checkemail/${appId}`, {'email': value})
                    .then(res => resolve(true)).catch(e=>resolve(false))
            })}
        ),
    });

    const SigninSchema = Yup.object().shape({
        password: Yup.string()
            .min(8, 'Too Short!')
            .max(32, 'Too Long!')
            .required('Required'),
        email: Yup.string().email('Invalid email').required('Required'),
    });

    const RestorePasswordSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Required'),
    });

    const SetPasswordSchema = Yup.object().shape({
        password: Yup.string()
            .min(8, 'Too Short!')
            .max(32, 'Too Long!')
            .required('Required'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match'),
        code: Yup.string()
            .min(6, 'Too Short!')
            .max(6, 'Too Long!')
            .required('Required'),
    });

    return <div className="container" style={{margin: "100px auto"}}>
        {mode === 'signIn' &&
            <>
                <h2 className="text-center mb-4">Log In</h2>
                <Formik
                    initialValues={{ email: '', password: ''}}
                    validationSchema={SigninSchema}
                    onSubmit={(values,{ setSubmitting }) => {
                        API.post(`/api/auth/login/${appId}`, values)
                            .then(res => {
                                if (res.data.data.token) {
                                    localStorage.setItem('token', res.data.data.token);
                                    setAuthenticated(true);
                                }else if (res.data.data.error){
                                    setSignInError(res.data.data.error);
                                }
                                setSubmitting(false);
                            });
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                      }) => (
                        <Form>
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">Email address</label>
                                {signInError &&
                                    <div className="text-danger text-center">{signInError}</div>
                                }
                                <input
                                    type="email"
                                    autoComplete="one-time-code"
                                    className="form-control"
                                    placeholder="Enter your email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    name="email"
                                />
                                <ErrorMessage name="email" render={msg => <div className="text-danger">{msg}</div>}/>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="password" className="form-label">Password</label>
                                <input
                                    type="password"
                                    autoComplete="one-time-code"
                                    className="form-control"
                                    placeholder="Enter your password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password}
                                    name="password"
                                />
                                <ErrorMessage name="password" render={msg => <div className="text-danger">{msg}</div>} />
                            </div>

                            <button type="submit" className="btn btn-primary w-100" disabled={isSubmitting}>Log In</button>
                            <div className="mt-3 text-center"><span>or</span></div>
                            <button type="button" className="btn btn-success w-100 mt-3" disabled={isSubmitting} onClick={()=>changeMode('signUp')}>Create account</button>
                            <button type="button" className="btn btn-warning w-100 mt-3" disabled={isSubmitting} onClick={()=>changeMode('restoreaccess')}>Restore access</button>
                            <button type="button" className="btn btn-secondary w-100 mt-3" disabled={isSubmitting} onClick={()=>abortSignIn()}>Go back</button>
                        </Form>
                    )}
                </Formik>
            </>
        }
        {mode === 'signUp' &&
            <>
                <h2 className="text-center mb-4">Create account</h2>
                <Formik
                    initialValues={{ email: '', password: '', nickname: '',  confirmPassword: ''}}
                    validationSchema={SignupSchema}
                    onSubmit={values => {
                        API.post(`/api/auth/registration/${appId}`, values)
                            .then(res => {
                                localStorage.setItem('token',res.data.data.token);
                                setAuthenticated(true);
                            });
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                      }) => (
                <Form>
                    <div className="mb-3">
                        <label htmlFor="text" className="form-label">Name</label>
                        <Field
                            type="text"
                            autocomplete="one-time-code"
                            className="form-control"
                            placeholder="Enter your name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.nickname}
                            name="nickname"
                        />
                        <ErrorMessage name="nickname" render={msg => <div className="text-danger">{msg}</div>}/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">Email address</label>
                        <input
                            type="email"
                            autocomplete="one-time-code"
                            className="form-control"
                            placeholder="Enter your email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            name="email"
                        />
                        <ErrorMessage name="email" render={msg => <div className="text-danger">{msg}</div>}/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="password" className="form-label">Password</label>
                        <input
                            type="password"
                            autocomplete="one-time-code"
                            className="form-control"
                            placeholder="Enter your password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            name="password"
                        />
                        <ErrorMessage name="password" render={msg => <div className="text-danger">{msg}</div>} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
                        <input
                            type="password"
                            autocomplete="one-time-code"
                            className="form-control"
                            placeholder="Confirm your password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.confirmPassword}
                            name="confirmPassword"
                        />
                        <ErrorMessage name="confirmPassword" render={msg => <div className="text-danger">{msg}</div>}/>
                    </div>
                    <button type="submit" className="btn btn-success w-100" disabled={isSubmitting}>Create account</button>
                    <div className="mt-3 text-center"><span>or</span></div>
                    <button type="button" className="btn btn-primary w-100 mt-3" disabled={isSubmitting} onClick={()=>changeMode('signIn')}>Log In</button>
                    <button type="button" className="btn btn-secondary w-100 mt-3" disabled={isSubmitting} onClick={()=>abortSignIn()}>Go back</button>
                </Form>
                    )}
                </Formik>
            </>
        }

        {mode === 'restoreaccess' &&
            <>
                <h2 className="text-center mb-4">Restore access</h2>
                <Formik
                    initialValues={{ email: ''}}
                    validationSchema={RestorePasswordSchema}
                    onSubmit={(values,{ setSubmitting }) => {
                        API.post(`/api/auth/restorepasswordcode/${appId}`, values)
                            .then(res => {
                                if (res.data.data.restoreId) {
                                    setRestoreId(res.data.data.restoreId);
                                    changeMode('setpassword');
                                }else if (res.data.data.error){
                                    setRestoreError(res.data.data.error);
                                }
                                setSubmitting(false);
                            });
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                      }) => (
                        <Form>
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">Email address</label>
                                {restoreError &&
                                    <div className="text-danger text-center">{restoreError}</div>
                                }
                                <input
                                    type="email"
                                    autoComplete="one-time-code"
                                    className="form-control"
                                    placeholder="Enter your email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    name="email"
                                />
                                <ErrorMessage name="email" render={msg => <div className="text-danger">{msg}</div>}/>
                            </div>

                            <button type="submit" className="btn btn-primary w-100" disabled={isSubmitting}>Restore access</button>
                            <div className="mt-3 text-center"><span>or</span></div>
                            <button type="button" className="btn btn-success w-100 mt-3" disabled={isSubmitting} onClick={()=>changeMode('signIn')}>Sign In</button>
                            <button type="button" className="btn btn-secondary w-100 mt-3" disabled={isSubmitting} onClick={()=>abortSignIn()}>Go back</button>
                        </Form>
                    )}
                </Formik>
            </>
        }

        {mode === 'setpassword' &&
            <>
                <h2 className="text-center mb-4">Set new password</h2>
                <Formik
                    initialValues={{ password: '', code: ''}}
                    validationSchema={SetPasswordSchema}
                    onSubmit={(values,{ setSubmitting }) => {
                        API.post(`/api/auth/resetpassword/${restoreId}`, values)
                            .then(res => {
                                if (res.data.data.token) {
                                    localStorage.setItem('token', res.data.data.token);
                                    setAuthenticated(true);
                                }else if (res.data.data.error){
                                    setSetPasswordError(res.data.data.error);
                                }
                                setSubmitting(false);
                            });
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                      }) => (
                        <Form>
                            <div className="mb-3 text-center"><p>To initiate the process, a verification code has been sent to your registered email address. Once you locate the email, kindly retrieve the verification code and enter it below.</p></div>
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">Code </label>
                                {setPasswordError &&
                                    <div className="text-danger text-center">{setPasswordError}</div>
                                }
                                <input
                                    type="text"
                                    autoComplete="one-time-code"
                                    className="form-control"
                                    placeholder="Code"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.code}
                                    name="code"
                                />
                                <ErrorMessage name="email" render={msg => <div className="text-danger">{msg}</div>}/>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="password" className="form-label">Password</label>
                                <input
                                    type="password"
                                    autoComplete="one-time-code"
                                    className="form-control"
                                    placeholder="Enter your password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password}
                                    name="password"
                                />
                                <ErrorMessage name="password" render={msg => <div className="text-danger">{msg}</div>} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
                                <input
                                    type="password"
                                    autoComplete="one-time-code"
                                    className="form-control"
                                    placeholder="Confirm your password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.confirmPassword}
                                    name="confirmPassword"
                                />
                                <ErrorMessage name="confirmPassword" render={msg => <div className="text-danger">{msg}</div>}/>
                            </div>
                            <button type="submit" className="btn btn-primary w-100" disabled={isSubmitting}>Set new password</button>
                            <div className="mt-3 text-center"><span>or</span></div>
                            <button type="button" className="btn btn-success w-100 mt-3" disabled={isSubmitting} onClick={()=>changeMode('signIn')}>Sign In</button>
                            <button type="button" className="btn btn-secondary w-100 mt-3" disabled={isSubmitting} onClick={()=>abortSignIn()}>Go back</button>
                        </Form>
                    )}
                </Formik>
            </>
        }
    </div>
}

export default NativeSignIn;
