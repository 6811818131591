import React, {useState} from "react";
import "../../assets/style/profile.scss";
import API from "../../utils/API";
import { Store } from 'react-notifications-component';

const Password = () => {
    const [oldPassword, setOldPassword] = useState(null);
    const [password, setPassword] = useState(null);
    const [passwordConfirm,setPasswordConfirm] = useState(null)
    const [error,setError] = useState(null)

    const saveChanges = () => {
        if (!password || password.length < 8) {
            setError('Password length should be minimum 8 characters')
            return;
        }

        if (password !== passwordConfirm) {
            setError('Password and confirm password did not match')
            return;
        }


        API.post('/me/password', {oldPassword, password}).then((response) => {
            setError(null);
            setPasswordConfirm(null);
            setOldPassword(null);
            setOldPassword(null)
            Store.addNotification({
                title: "Success!",
                message: "Password changed successfully",
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
        }).catch(error => {
            setError('Invalid old password')
        })
    }

    return (
        <>
            <div className="row profilePage m-0 p-0 me-4">
                <div className="editArea">
                    {error &&
                        <div className="text-center mt-2 text-danger">{error}</div>
                    }
                    <div className="col-12 p-2">
                        <label>Current password</label>
                        <input type="password" className="form-control" onChange={(e)=>setOldPassword(e.target.value)}/>
                    </div>
                    <div className="col-12 p-2">
                        <label>New password</label>
                        <input type="password" className="form-control" onChange={(e)=>setPassword(e.target.value)}/>
                    </div>
                    <div className="col-12 p-2">
                        <label>Confirm password</label>
                        <input type="password" className="form-control" onChange={(e)=>setPasswordConfirm(e.target.value)}/>
                    </div>
                    <div className="col-12 text-end p-2 mt-3 pb-4">
                        <span className="addButton p-3" onClick={saveChanges}>Save Changes</span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Password
