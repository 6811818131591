import React,{useState} from "react"
import API from "../../../../utils/API";
import {Spinner} from "reactstrap"
import {BsTrash} from 'react-icons/bs'
import ReviewItemYelp from "./ReviewItemYelp";
import ReviewItemFacebook from "./ReviewItemFacebook";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

const Reviews = ({setValue, name, value, featureName, platform}) => {

    const [loading,setLoading] = useState(false)

    const loadReviews = () => {
        if (value?.url?.length > 10 && !loading) {
            setLoading(true)
            API.post('/reviews', {platform: platform, id: value?.url}).then((response) => {
                setValue(name+".list", response.data.data)
                setLoading(false)
            });
        }
    }

    const deleteReview = (id) => {
        if (value?.list && value?.list?.length) {
            let newreviews = [], n = value?.list?.length, i
            for (i=0; i < n; i++) {
                if (i !== id) {
                    newreviews.push(value?.list[i])
                }
            }
            setValue(name+".list",newreviews)
        }
    }

    const setAutoComplete = (autocomplete) => {
        setValue(name+".url",autocomplete.value.place_id)
    }

    return <>
        <div className="row">
            <div className="col pt-2">
                <span className="blockTitle">{featureName}</span>
            </div>
        </div>
        {platform === 'google' ?
            <div className="row">
                <div className="col-9">
                    <GooglePlacesAutocomplete
                        selectProps={{
                            onChange: setAutoComplete,
                        }}
                    />
                </div>
                <div className="col-3">
                    <div className="mainButton text-center" onClick={()=>loadReviews()}>{loading?<Spinner color="white" size="sm"/>:<>Load</>}</div>
                </div>
            </div>
        :
            <div className="row">
                <div className="col-9">
                    <input className="form-control" placeholder="Place url" value={value?.url} onChange={(e)=>setValue(name+".url",e.target.value)}/>
                </div>
                <div className="col-3">
                    <div className="mainButton text-center" onClick={()=>loadReviews()}>{loading?<Spinner color="white" size="sm"/>:<>Load</>}</div>
                </div>
            </div>
        }
        {(value?.list && value?.list?.length > 0) &&
            <ul className="list-group mt-2">
                {value?.list.map((review,reviewKey)=>{
                    return (<li className="list-group-item" key={reviewKey}>
                        <div className="row">
                            <div className="col">
                                {(platform === 'yelp' || platform === 'google' || platform === 'airbnb') &&
                                    <ReviewItemYelp review={review}/>
                                }
                                {platform === 'facebook' &&
                                    <ReviewItemFacebook review={review}/>
                                }
                            </div>
                            <div className="col-auto">
                                <span className="cursorPointer" onClick={()=>deleteReview(reviewKey)}><BsTrash/></span>
                            </div>
                        </div>
                    </li>)
                })}
            </ul>
        }
    </>
}

export default Reviews
