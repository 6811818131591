import React, {useEffect, useState} from "react";
import {BsShareFill} from "react-icons/bs";
import AppDevice from "../../components/AppDevice";
import "../../assets/style/sharing.scss"
import "../../assets/style/blocks.scss"
import svgImage from "../../assets/image/girl.svg";
import {
    FacebookShareButton,
    FacebookMessengerShareButton,
    FacebookMessengerIcon,
    LinkedinShareButton,
    TwitterShareButton,
    PinterestShareButton,
    VKShareButton,
    OKShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    RedditShareButton,
    EmailShareButton,
    TumblrShareButton,
    LivejournalShareButton,
    MailruShareButton,
    ViberShareButton,
    WorkplaceShareButton,
    LineShareButton,
    WeiboShareButton,
    PocketShareButton,
    InstapaperShareButton,
    HatenaShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    PinterestIcon,
    VKIcon,
    OKIcon,
    TelegramIcon,
    WhatsappIcon,
    RedditIcon,
    TumblrIcon,
    MailruIcon,
    EmailIcon,
    LivejournalIcon,
    ViberIcon,
    WorkplaceIcon,
    LineIcon,
    PocketIcon,
    InstapaperIcon,
    WeiboIcon,
    HatenaIcon
} from "react-share";
import API from "../../utils/API";
import {useParams} from "react-router-dom";
import {useIsTablet} from "../../hooks/useIsTablet";
import ElementManagement from "./blocks/ElementManagement";

const Sharing = () => {
    const [shareUrl,setShareUrl] = useState('https://apps.apple.com/us/app/hebrew-news-%D7%97%D7%93%D7%A9%D7%95%D7%AA-%D7%90%D7%A8%D7%94-%D7%91-%D7%91%D7%A2%D7%91%D7%A8%D7%99%D7%AA/id1464958836')
    const [title,setTitle] = useState(null)
    const [image,setImage] = useState(null)

    let { appid } = useParams();

    const isTablet = useIsTablet()

    useEffect(()=>{
        if (appid) {
            API.get('/app/get/'+appid).then((response) => {
                setTitle(response.data.data.title)
                setImage(response.data.data.icon)
            })
        }
    },[appid])

    return (
        <>
            <div className="row sharing position-relative justify-content-center">
                <div className="col-auto">
                    <div className="sharingContainer manageBlock">
                        <div className="titleBlock">
                            <div className="title">
                                <div className="row">
                                    <div className="col-auto iconBlock">
                                        <BsShareFill className="icon"/>
                                    </div>
                                    <div className="col name">
                                        sharing
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form">
                            <div className="formRow">
                                Name
                            </div>
                            <div className="formRow">
                                <input type="text" className="form-control" value={title} onChange={(e) => {setTitle(`${e.target.value}`);}}/>
                            </div>
                            <div className="formRow">
                                URL
                            </div>
                            <div className="formRow">
                                <input type="text" className="form-control" value={shareUrl} onChange={(e) => {setShareUrl(`${e.target.value}`);}}/>
                            </div>
                            <div className="formRow">
                                Share
                            </div>
                            <div className="formRow noBottomBorder">
                                <div className="row justify-content-start">
                                    <div className="col-auto p-3">
                                        <FacebookShareButton
                                            url={shareUrl}
                                            quote={title}
                                            className="Demo__some-network__share-button"
                                        >
                                            <FacebookIcon size={32} round />
                                        </FacebookShareButton>
                                    </div>

                                    <div className="col-auto p-3">
                                        <FacebookMessengerShareButton
                                            url={shareUrl}
                                            appId="521270401588372"
                                            className="Demo__some-network__share-button"
                                        >
                                            <FacebookMessengerIcon size={32} round />
                                        </FacebookMessengerShareButton>
                                    </div>

                                    <div className="col-auto p-3">
                                        <TwitterShareButton
                                            url={shareUrl}
                                            title={title}
                                            className="Demo__some-network__share-button"
                                        >
                                            <TwitterIcon size={32} round />
                                        </TwitterShareButton>
                                    </div>

                                    <div className="col-auto p-3">
                                        <TelegramShareButton
                                            url={shareUrl}
                                            title={title}
                                            className="Demo__some-network__share-button"
                                        >
                                            <TelegramIcon size={32} round />
                                        </TelegramShareButton>
                                    </div>

                                    <div className="col-auto p-3">
                                        <WhatsappShareButton
                                            url={shareUrl}
                                            title={title}
                                            separator=":: "
                                            className="Demo__some-network__share-button"
                                        >
                                            <WhatsappIcon size={32} round />
                                        </WhatsappShareButton>
                                    </div>

                                    <div className="col-auto p-3">
                                        <LinkedinShareButton url={shareUrl} className="Demo__some-network__share-button">
                                            <LinkedinIcon size={32} round />
                                        </LinkedinShareButton>
                                    </div>

                                    <div className="col-auto p-3">
                                        <PinterestShareButton
                                            url={shareUrl}
                                            media={image}
                                            className="Demo__some-network__share-button"
                                        >
                                            <PinterestIcon size={32} round />
                                        </PinterestShareButton>
                                    </div>

                                    <div className="col-auto p-3">
                                        <VKShareButton
                                            url={shareUrl}
                                            image={image}
                                            className="Demo__some-network__share-button"
                                        >
                                            <VKIcon size={32} round />
                                        </VKShareButton>
                                    </div>

                                    <div className="col-auto p-3">
                                        <OKShareButton
                                            url={shareUrl}
                                            image={image}
                                            className="Demo__some-network__share-button"
                                        >
                                            <OKIcon size={32} round />
                                        </OKShareButton>
                                    </div>

                                    <div className="col-auto p-3">
                                        <RedditShareButton
                                            url={shareUrl}
                                            title={title}
                                            windowWidth={660}
                                            windowHeight={460}
                                            className="Demo__some-network__share-button"
                                        >
                                            <RedditIcon size={32} round />
                                        </RedditShareButton>
                                    </div>

                                    <div className="col-auto p-3">
                                        <TumblrShareButton
                                            url={shareUrl}
                                            title={title}
                                            className="Demo__some-network__share-button"
                                        >
                                            <TumblrIcon size={32} round />
                                        </TumblrShareButton>
                                    </div>

                                    <div className="col-auto p-3">
                                        <LivejournalShareButton
                                            url={shareUrl}
                                            title={title}
                                            description={shareUrl}
                                            className="Demo__some-network__share-button"
                                        >
                                            <LivejournalIcon size={32} round />
                                        </LivejournalShareButton>
                                    </div>

                                    <div className="col-auto p-3">
                                        <MailruShareButton
                                            url={shareUrl}
                                            title={title}
                                            className="Demo__some-network__share-button"
                                        >
                                            <MailruIcon size={32} round />
                                        </MailruShareButton>
                                    </div>

                                    <div className="col-auto p-3">
                                        <EmailShareButton
                                            url={shareUrl}
                                            subject={title}
                                            body="body"
                                            className="Demo__some-network__share-button"
                                        >
                                            <EmailIcon size={32} round />
                                        </EmailShareButton>
                                    </div>
                                    <div className="col-auto p-3">
                                        <ViberShareButton
                                            url={shareUrl}
                                            title={title}
                                            className="Demo__some-network__share-button"
                                        >
                                            <ViberIcon size={32} round />
                                        </ViberShareButton>
                                    </div>

                                    <div className="col-auto p-3">
                                        <WorkplaceShareButton
                                            url={shareUrl}
                                            quote={title}
                                            className="Demo__some-network__share-button"
                                        >
                                            <WorkplaceIcon size={32} round />
                                        </WorkplaceShareButton>
                                    </div>

                                    <div className="col-auto p-3">
                                        <LineShareButton
                                            url={shareUrl}
                                            title={title}
                                            className="Demo__some-network__share-button"
                                        >
                                            <LineIcon size={32} round />
                                        </LineShareButton>
                                    </div>

                                    <div className="col-auto p-3">
                                        <WeiboShareButton
                                            url={shareUrl}
                                            title={title}
                                            image={image}
                                            className="Demo__some-network__share-button"
                                        >
                                            <WeiboIcon size={32} round />
                                        </WeiboShareButton>
                                    </div>

                                    <div className="col-auto p-3">
                                        <PocketShareButton
                                            url={shareUrl}
                                            title={title}
                                            className="Demo__some-network__share-button"
                                        >
                                            <PocketIcon size={32} round />
                                        </PocketShareButton>
                                    </div>

                                    <div className="col-auto p-3">
                                        <InstapaperShareButton
                                            url={shareUrl}
                                            title={title}
                                            className="Demo__some-network__share-button"
                                        >
                                            <InstapaperIcon size={32} round />
                                        </InstapaperShareButton>
                                    </div>

                                    <div className="col-auto p-3">
                                        <HatenaShareButton
                                            url={shareUrl}
                                            title={title}
                                            windowWidth={660}
                                            windowHeight={460}
                                            className="Demo__some-network__share-button"
                                        >
                                            <HatenaIcon size={32} round />
                                        </HatenaShareButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {!isTablet &&
                <div className="col blocks pt-sm-3">
                    <div className="row justify-content-center">
                        <div className="col-auto">
                            <div className="appEditContainer" id="app-root">
                                <AppDevice/>
                            </div>
                        </div>
                    </div>
                </div>
                }
                {!isTablet &&
                    <div className="col">
                        <div className="row justify-content-center" style={{marginTop:"200px"}}>
                            <div className="col-auto manageBlock text-center" style={{borderRadius:"10px"}}>
                                <img src={svgImage} style={{position:"relative",top:"-100px"}}/>
                                <div className="text-center mt-3" style={{position:"relative",top:"-100px"}}>
                                    <span style={{fontSize:"30px"}}>Set Up and Shared!</span>
                                    <div className="m-5 text-left" style={{fontSize:"20px",color:"#768194"}}>
                                        Our new service makes it easy for you to build a startup.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {!isTablet &&
                <div className="col-auto" style={{width:"88px"}}></div>
                }
            </div>
        </>
    )
}

export default Sharing
