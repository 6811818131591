import React, {useEffect, useState} from "react";
import bg from "../assets/image/signup.png";
import {formatCreditCardNumber, formatCVC, formatExpirationDate} from "./billing/utils";
import Card from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import "../assets/style/cardForm.scss"
import Switch from "react-switch";
import API from "../utils/API";
import {useNavigate} from "react-router-dom";

const Billing = () => {
    const [number, setNumber] = useState("")
    const [name, setName] = useState("")
    const [expiry, setExpiry] = useState("")
    const [cvc, setCvc] = useState("")
    const [issuer, setIssuer] = useState("")
    const [focused, setFocused] = useState(null)
    const [confirmTerms,setConfirmTerms] = useState(false)
    const [yearly,setYearly] = useState(false)

    const [submitting, setSubmitting] = useState(false)

    const [error, setError] = useState(null)

    const [monthlyPlan,setMonthlyPlan] = useState(false)
    const [yearlyPlan,setYearlyPlan] = useState(false)

    let navigate = useNavigate()

    useEffect(() => {
        API.get("/billing/plans").then(res => {
            setMonthlyPlan(res.data.data.monthly)
            setYearlyPlan(res.data.data.yearly)
        })
    },[])

    const addCard = () => {
        if (!confirmTerms) {
            setError('You must agree to the terms and conditions')
            return;
        }

        if (!submitting && number.length>0 && name.length>0 && expiry.length>0 && cvc.length>0) {
            setSubmitting(true)
            API.post('/billing/subscribe', {
                cardNumber: number,
                expiry: expiry,
                cvc: cvc,
                cardName: name,
                product: yearly?yearlyPlan.id:monthlyPlan.id,
            })
                .then((response) => {
                    navigate('/')
                    setSubmitting(false)
                }).catch((err) => {
                setError(err.response.data.data.message)
                setSubmitting(false)
            })
        }
    }

    const setDataValue = (name,value) => {
        switch (name) {
            case 'number':
                setNumber(value)
                return
            case 'name':
                setName(value)
                return
            case 'expiry':
                setExpiry(value)
                return
            case 'cvc':
                setCvc(value)
                return
            case 'issuer':
                setIssuer(value)
                return
            case 'focused':
                setFocused(value)
                return
        }
    }

    const handleCallback = ({ issuer }, isValid) => {
        if (isValid) {
            setDataValue('issuer',issuer)
        }
    }

    const handleInputFocus = ({ target }) => {
        setDataValue('focused',target.name)
    }

    const handleInputChange = ({ target }) => {
        if (target.name === "number") {
            target.value = formatCreditCardNumber(target.value);
        } else if (target.name === "expiry") {
            target.value = formatExpirationDate(target.value);
        } else if (target.name === "cvc") {
            target.value = formatCVC(target.value);
        }

        setDataValue(target.name,target.value)
    }

    return <div className="auth main d-flex w-100 justify-content-center">
        <div className="row w-100 content justify-content-center">
            <div className="col-xl-8 col-lg-10 col-md-12 col-sm-12">
                <div className="row m-0 p-0 centerBlock justify-content-center">
                    <div className="col-md col-sm-12 m-0 p-0" style={{minHeight:"200px",backgroundImage:`url(${bg})`,backgroundRepeat: "no-repeat",backgroundPosition : "center",backgroundSize: "cover"}}>
                    </div>
                    <div className="col-md col-sm-12">
                        <div className="text-end p-2">
                            <span className="logoTitle">APP WORLD</span>
                        </div>
                        <div className="form pt-1">
                            <div>
                                <span className="title">Subscription</span>
                                <div className="row justify-content-center">
                                    <div className="col-auto pt-1">
                                        Monthly
                                    </div>
                                    <div className="col-auto">
                                        <Switch onChange={(checked)=>{
                                            if (checked) {
                                                setYearly(true)
                                            }else {
                                                setYearly(false)
                                            }
                                        }} checked={yearly} uncheckedIcon={false} checkedIcon={false} onColor={"#5351FB"} offColor={"#8367F9"}/>
                                    </div>
                                    <div className="col-auto pt-1">
                                        Yearly
                                    </div>
                                    <div className="col-12 pt-1">
                                        <span style={{fontWeight:"bold"}}>Pay {yearly? <><span className="display-6">${yearlyPlan.price}</span> Yearly</> : <><span className="display-6">${monthlyPlan.price}</span> Monthly</>}</span>
                                    </div>
                                </div>
                                <p>Enter your payment details below</p>
                                <div className="mt-3 cardForm mb-2">
                                    <div className="row justify-content-center">
                                        <div className="col-auto">
                                            <Card
                                                number={number}
                                                name={name}
                                                expiry={expiry}
                                                cvc={cvc}
                                                focused={focused}
                                                callback={handleCallback}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group mb-2">
                                        <label>Card number</label>
                                        <input
                                            type="tel"
                                            name="number"
                                            className="form-control"
                                            pattern="[\d| ]{16,22}"
                                            required
                                            onChange={handleInputChange}
                                            onFocus={handleInputFocus}
                                        />
                                    </div>
                                    <div className="form-group mb-2">
                                        <label>Name on card</label>
                                        <input
                                            type="text"
                                            name="name"
                                            className="form-control"
                                            required
                                            onChange={handleInputChange}
                                            onFocus={handleInputFocus}
                                        />
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-6">
                                            <label>Expiry date</label>
                                            <input
                                                type="tel"
                                                name="expiry"
                                                className="form-control"
                                                pattern="\d\d/\d\d"
                                                required
                                                onChange={handleInputChange}
                                                onFocus={handleInputFocus}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <label>CVV</label>
                                            <input
                                                type="tel"
                                                name="cvc"
                                                className="form-control"
                                                pattern="\d{3,4}"
                                                required
                                                onChange={handleInputChange}
                                                onFocus={handleInputFocus}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group mb-2">
                                        <input type="checkbox" className="checkboxTerms" checked={confirmTerms} onChange={()=>setConfirmTerms(!confirmTerms)}/>
                                        <label>I agree on terms & conditions</label>
                                    </div>
                                    <input type="hidden" name="issuer" value={issuer} />
                                    {error && <div className="form-group mb-2 text-center"><span className="text-danger">{error}</span></div>}
                                    <div className="form-actions mt-3 text-center">
                                            <span className="addButton p-3" onClick={()=>addCard()}>
                                                {submitting?
                                                    <>Subscribing...</>
                                                    :
                                                    <>Subscribe</>
                                                }
                                            </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Billing;
