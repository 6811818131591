import React,{useState,useEffect} from 'react';
import {
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    FormGroup,
    Label,
    Button
} from "reactstrap"
import classnames from "classnames"
import {AvForm, AvInput, AvCheckboxGroup, AvCheckbox} from "availity-reactstrap-validation";
import API from "../../utils/API";
import {useParams} from "react-router-dom";
import AppPreview from "../../components/AppPreview";
import PagePreview from "../../components/PagePreview";
import Apple  from "../../assets/image/store/apple.png"
import Google  from "../../assets/image/store/google.png"

const SubmissionForm = () => {
    const [activeTab,setActiveTab] = useState("information")

    let { appid } = useParams()

    const [submitted,setSubmitted] = useState(false)

    const [initValues, setInitValues] = useState({})

    const [pages,setPages] = useState(null)

    const [loaded,setLoaded] = useState(false)

    const loadPages = () => {
        API.get('/page/getall/'+appid).then((response) => {
            setPages(response.data.data);
        });
    }

    const submit = (event, values) => {
        API.post('/app/submit/'+appid,values).then((response) => {
            setSubmitted(true)
        });
    }

    useEffect(()=>{
        if (appid) {
            loadPages()
            API.get('/app/submit/'+appid).then((response) => {
                if (response.data?.data?.id) {
                    if (response.data?.data?.status !== "4") {
                        setSubmitted(true)
                    }else {
                        setSubmitted(false)
                    }
                    setInitValues(response.data.data.data)
                }else if (response.data?.data?.app){
                    setInitValues({name:response.data?.data?.app.title,shortdescription:response.data?.data?.app.description})
                }
                setLoaded(true)
            });
        }
    },[appid])

    return (
        <div>
            <AvForm onValidSubmit={submit} model={initValues}>
            <div className="row">
                <div className="col">
                    <h3>App Submission</h3>
                </div>
                <div className="col-auto">
                    {!submitted &&
                        <div className="text-center mt-3">
                            <Button color="btn mainButton btn-lg ps-5 pe-5">Submit</Button>
                        </div>
                    }
                </div>
            </div>
            {loaded &&
                <>
                    {submitted &&
                        <div role="alert" className="fade alert alert-success alert-dismissible show">
                            <div className="alert-message">Application was submitted. We will send you email
                                notification once application is published on selected platforms
                            </div>
                        </div>
                    }
                        <Nav tabs>
                            <NavItem className="cursorPointer">
                                <NavLink
                                    className={classnames({active: activeTab === "information"})}
                                    onClick={() => {
                                        setActiveTab("information");
                                    }}
                                >
                                    Information
                                </NavLink>
                            </NavItem>
                            <NavItem className="cursorPointer">
                                <NavLink
                                    className={classnames({active: activeTab === "screenshots"})}
                                    onClick={() => {
                                        setActiveTab("screenshots");
                                    }}
                                >
                                    Screenshots
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab} style={{borderLeft:"solid 1px lightgray",borderRight:"solid 1px lightgray",borderBottom:"solid 1px lightgray"}}>
                            <TabPane tabId="information" className="border-left border-right p-3 border-1 border-dark">
                                <div className="row">
                                    <div className="col">
                                        <div>
                                            <div className="mb-5 text-center">
                                                <Label className="fw-bold h4">Select platform</Label>
                                                <AvCheckboxGroup inline name="platform" required>
                                                    <AvCheckbox className={"success"} style={{marginTop:"20px"}} label={<><img src={Apple} width={"50px"}/> App store</>} value="appstore"
                                                                disabled={submitted}/>
                                                    <AvCheckbox style={{marginTop:"20px"}} label={<div><img src={Google} width={"50px"}/> Google play</div>} value="googleplay"
                                                                disabled={submitted}/>
                                                </AvCheckboxGroup>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-auto">
                                                <div className="mb-5">
                                                    <Label className="fw-bold">Category</Label>
                                                    <AvCheckboxGroup name="category" required>
                                                        <AvCheckbox label="Art & Design" value="Art & Design"
                                                                    disabled={submitted}/>
                                                        <AvCheckbox label="Auto & Vehicles" value="Auto & Vehicles"
                                                                    disabled={submitted}/>
                                                        <AvCheckbox label="Beauty" value="Beauty" disabled={submitted}/>
                                                        <AvCheckbox label="Books & Reference" value="Books & Reference"
                                                                    disabled={submitted}/>
                                                        <AvCheckbox label="Business" value="Business" disabled={submitted}/>
                                                        <AvCheckbox label="Comics" value="Comics" disabled={submitted}/>
                                                        <AvCheckbox label="Communication" value="Communication"
                                                                    disabled={submitted}/>
                                                        <AvCheckbox label="Dating" value="Dating" disabled={submitted}/>
                                                        <AvCheckbox label="Education" value="Education" disabled={submitted}/>
                                                        <AvCheckbox label="Entertainment" value="Entertainment"
                                                                    disabled={submitted}/>
                                                        <AvCheckbox label="Events" value="Events" disabled={submitted}/>
                                                        <AvCheckbox label="Finance" value="Finance" disabled={submitted}/>
                                                        <AvCheckbox label="Food & Drink" value="Food & Drink"
                                                                    disabled={submitted}/>
                                                        <AvCheckbox label="Health & Fitness" value="Health & Fitness"
                                                                    disabled={submitted}/>
                                                        <AvCheckbox label="House & Home" value="House & Home"
                                                                    disabled={submitted}/>
                                                        <AvCheckbox label="Libraries & Demo" value="Libraries & Demo"
                                                                    disabled={submitted}/>
                                                        <AvCheckbox label="Lifestyle" value="Lifestyle" disabled={submitted}/>
                                                        <AvCheckbox label="Maps & Navigation" value="Maps & Navigation"
                                                                    disabled={submitted}/>
                                                        <AvCheckbox label="Medical" value="Medical" disabled={submitted}/>
                                                        <AvCheckbox label="Music & Audio" value="Music & Audio"
                                                                    disabled={submitted}/>
                                                        <AvCheckbox label="News & Magazines" value="News & Magazines"
                                                                    disabled={submitted}/>
                                                        <AvCheckbox label="Parenting" value="Parenting" disabled={submitted}/>
                                                        <AvCheckbox label="Personalization" value="Personalization"
                                                                    disabled={submitted}/>
                                                        <AvCheckbox label="Photography" value="Photography"
                                                                    disabled={submitted}/>
                                                        <AvCheckbox label="Productivity" value="Productivity"
                                                                    disabled={submitted}/>
                                                        <AvCheckbox label="Shopping" value="Shopping" disabled={submitted}/>
                                                        <AvCheckbox label="Social" value="Social" disabled={submitted}/>
                                                        <AvCheckbox label="Sports" value="Sports" disabled={submitted}/>
                                                        <AvCheckbox label="Tools" value="Tools" disabled={submitted}/>
                                                        <AvCheckbox label="Travel & Local" value="Travel & Local"
                                                                    disabled={submitted}/>
                                                        <AvCheckbox label="Video Players & Editors"
                                                                    value="Video Players & Editors" disabled={submitted}/>
                                                        <AvCheckbox label="Weather" value="Weather" disabled={submitted}/>
                                                    </AvCheckboxGroup>
                                                </div>
                                            </div>
                                            <div className="col ps-5">
                                                <div className="mb-5">
                                                    <FormGroup>
                                                        <Label className="fw-bold">Application name</Label>
                                                        <AvInput required type="text" name="name" disabled={submitted}/>
                                                    </FormGroup>
                                                    <FormGroup className="mt-3">
                                                        <Label className="fw-bold">Short description</Label>
                                                        <AvInput type="textarea" required name="shortdescription"
                                                                 disabled={submitted}/>
                                                    </FormGroup>
                                                    <FormGroup className="mt-3">
                                                        <Label className="fw-bold">Long description</Label>
                                                        <AvInput type="textarea" required name="longdescription"
                                                                 disabled={submitted}/>
                                                    </FormGroup>
                                                    <FormGroup className="mt-3">
                                                        <Label className="fw-bold">Website</Label>
                                                        <AvInput required type="url" name="website" disabled={submitted}/>
                                                    </FormGroup>
                                                    <FormGroup className="mt-3">
                                                        <Label className="fw-bold">Email</Label>
                                                        <AvInput required type="email" name="email" disabled={submitted}/>
                                                    </FormGroup>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane tabId="screenshots" className="border-left border-right p-3 text-center">
                                <span className="fw-bold h4">Select pages for screenshots</span>
                                <AvCheckboxGroup name="screenshot" required className="mt-3">
                                    <div className="row justify-content-center">
                                        {pages &&
                                            pages.map((page, index) => {
                                                return (
                                                    <div
                                                        className="col-lg-auto col-sm-auto border-left border-right">
                                                        <AvCheckbox label={page.title} value={page.id}
                                                                    disabled={submitted}/>
                                                        <PagePreview app={appid} page={page.id} preview={true}/>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </AvCheckboxGroup>
                            </TabPane>
                        </TabContent>
                </>
            }
            </AvForm>
        </div>
    )
}

export default SubmissionForm;
