import React,{useState,useEffect} from "react";
import "../assets/style/mainpage.scss"
import "../assets/style/shop.scss"
import ImageUploader from "react-images-upload";
import {useNavigate, useParams} from "react-router-dom";
import API from "../utils/API";
import TemplateCategory from "../components/fields/TemplateCategory";

const Template = () => {
    const [template,setTemplate] = useState(null);
    let { id } = useParams();
    let navigate = useNavigate()

    const setValue = (name,value) => {
        setTemplate({ ...template, [name]: value })
    }

    const onDrop = (picture,pictureURI) => {
        if (pictureURI.length > 0) {
            API.post('/image/', {image: pictureURI[0]}).then((response) => {
                setValue("image", response.data.data.url)
            });
        }else {
            setValue("image", null)
        }
    };

    useEffect(()=>{
        if (id) {
            API.get(`/templates/${id}`).then(res=>{
                setTemplate(res.data.data.template)
            });
        }
    },[id]);

    const handleSubmit = () => {
        API.put(`/templates/${id}`,template).then(res=>{
            navigate(`/shop/templates`)
        })
    }

    return <div className="mainPage shop">
        {template &&
        <>
        <div className="hero">
            <div className="row mb-2">
                <div className="col">
                    <span className="title">My Templates</span>
                </div>
            </div>
        </div>
        <div className="m-2">
            <div className="row profilePage m-0 p-0 me-4">
                <div className="col-3 editArea text-center pt-4">
                    <label>Image</label>
                    <ImageUploader
                        withIcon={true}
                        onChange={onDrop}
                        imgExtension={[".jpg",".jpeg", ".gif", ".png", ".gif", ".webp"]}
                        maxFileSize={5242880}
                        withPreview={true}
                        singleImage={true}
                        defaultImages={template.image?[template.image]:[]}
                    />
                </div>
                <div className="col-8 offset-1 editArea p-4">
                    <div className="row mt-5">
                        <div className="col-12">
                            <label>Name</label>
                            <input type="text" className="form-control" value={template.title} onChange={(e)=>setValue('title',e.target.value)}/>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-12">
                            <label>Category</label>
                            <TemplateCategory value={template.category} name="category" onChange={setValue}/>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-12">
                            <label>Status</label>
                            <select className="form-control" value={template.status} onChange={(e)=>setValue('status',e.target.value)}>
                                <option>Select...</option>
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                            </select>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-12">
                            <label>Description</label>
                            <textarea className="form-control" onChange={(e)=>setValue('description',e.target.value)} value={template.description}/>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-12">
                            <label>Price, $</label>
                            <input type="number" className="form-control" onChange={(e)=>setValue('price',e.target.value)} value={template.price}/>
                        </div>
                    </div>
                </div>
                <div className="col-12 text-end p-4">
                    <span className="addButton p-3" onClick={()=>handleSubmit()}>Save Changes</span>
                </div>
            </div>
        </div>
        </>
        }
    </div>
}

export default Template;
