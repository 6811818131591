import React from "react";
import ImageUploader from "react-images-upload";
import {useSelector} from "react-redux";
import API from "../../../../utils/API";

const ImageItem = ({setValue, name, value, featureName}) => {

    const pageeditor = useSelector(state => {
        return state.pageEditor
    });

    const onDrop = (picture,pictureURI) => {
        if (pictureURI.length > 0) {
            API.post('/image/' + pageeditor.app, {image: pictureURI[0]}).then((response) => {
                setValue(name, response.data.data.url)
            });
        }else {
            setValue(name, null)
        }
    };

    return <div>
        <div className="row">
            <div className="col">
                <span className="blockTitle">{featureName}</span>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <ImageUploader
                    withIcon={true}
                    onChange={onDrop}
                    imgExtension={[".jpg",".jpeg", ".gif", ".png", ".gif", ".webp"]}
                    maxFileSize={5242880}
                    withPreview={true}
                    singleImage={true}
                    defaultImages={value?[value]:[]}
                />
            </div>
        </div>
    </div>
}

export default ImageItem
