import React from "react";
import "../../assets/style/appDashboard.scss"
import {AiFillAndroid,AiFillApple} from "react-icons/ai";
import {
    useNavigate
} from "react-router-dom";
import {Delete} from "@mui/icons-material";

const NewApp = ({app}) => {

    let icon = {}
    if (app.icon) {
        icon = {
            backgroundImage: `url("${app.icon}")`,
            backgroundSize:"contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat"
        }
    }

    let navigate = useNavigate()

    return (
        <div className='appDashboard appItem'>
            <div onClick={()=>navigate('/app/'+app.id+'/dangerzone')} className="deleteButton" style={{position:"absolute",top:"-70px",right:"15px",zIndex:"1000",cursor:"pointer"}}><Delete style={{opacity:"0.6"}} className="text-danger"/></div>
            <div onClick={()=>navigate('/app/'+app.id+'/pages')} className="addLink text-center pt-4" style={icon}>

            </div>
            <div className="innerBox">
                <div className="title text-center">{app.title}</div>
                <div onClick={()=>navigate('/app/'+app.id+'/pages')} className="button text-center"><span className="text">Open App</span></div>
            </div>
            <div className="row">
                <div className="col-6">
                    <div className="row ms-2">
                        <div className="col-auto m-0 p-0">
                            <AiFillAndroid style={{fontSize:"30px"}} color={"#6c6c6c"}/>
                        </div>
                        <div className="col m-0 p-0 pt-2">
                            <div className="appStatusNotActive text-center">Not active</div>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="row me-2">
                        <div className="col m-0 p-0 pt-2">
                            <div className="appStatusNotActive text-center">Not active</div>
                        </div>
                        <div className="col-auto m-0 p-0">
                            <AiFillApple style={{fontSize:"30px"}} color={"#6c6c6c"}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewApp
