import React,{useState,useEffect} from "react";
import "../../assets/style/appSettings.scss"
import API from "../../utils/API";
import {useNavigate, useParams} from "react-router-dom";
import { Store } from 'react-notifications-component';

const DangerZone = () => {
    let { appid } = useParams()

    const [appData,setAppData] = useState(null)

    let navigate = useNavigate()

    const [appName,setAppName] = useState('')

    useEffect(()=> {
        if (appid) {
            API.get(`/app/get/${appid}`).then((response) => {
                setAppData(response.data.data)
            })
        }
    },[appid])

    const deleteApp = () => {
        API.delete(`/app/${appid}`).then((response) => {
            Store.addNotification({
                title: "Success!",
                message: "App was successfully deleted",
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
            navigate('/')
        })
    }

    return (
        <div className="appSettings">
            {appData &&
            <div className="row m-2 justify-content-center">
                <div className="col-auto text-center">
                    <h4>Delete application</h4>
                    <label>Enter application name "{appData.title}"</label>
                    <input type="text" className="form-control mt-2 mb-2" value={appName} onChange={(e)=>setAppName(e.target.value)}/>
                    <button className="button pt-3 pb-3 ps-5 pe-5" onClick={()=>deleteApp()} disabled={appName.toLowerCase() !== appData.title.toLowerCase()}>Delete</button>
                </div>
            </div>
            }
        </div>
    )
}

export default DangerZone
