import React, {useEffect, useState} from "react";
import {TbDots} from "react-icons/tb"
import {useNavigate, useParams} from "react-router-dom";
import API from "../../utils/API";
import Pagination from "../../components/Pagination";
import ListStat from "../../components/ListStat";

const Posts = () => {
    const [posts,setPosts] = useState([])
    let { appid } = useParams()
    let [keywords,setKeywords] = useState("")
    const [categories,setCategories] = useState(null)
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [data,setData] = useState(null)
    const [loading,setLoading] = useState(false)

    const [filtration,setFiltration] = useState({page:1,perPage:20})

    useEffect(()=>{
        setLoading(true);
        API.post(`/blogpost/posts/${appid}`,{...filtration,keyword:keywords,category:selectedCategory?[selectedCategory]:null}).then((result)=>{
            setPosts(result.data.data.data);
            setData(result.data.data);
            setLoading(false);
        });
    },[keywords,selectedCategory,filtration])

    useEffect(()=>{
        API.post(`/blogpost/categories/${appid}`).then((result)=>{
            setCategories(result.data.data)
        })
    },[])

    const changeData = (name,value) => {
        setFiltration({ ...filtration, [name]: value })
    }

    let navigate = useNavigate()

    return (
        <>
            <div className="row ms-2 me-2">
                <div className="col-6">
                    <div className="row">
                        <div className="col-auto pt-2">
                            <label>Show:</label>
                        </div>
                        <div className="col-auto">
                            <select className="form-control" onChange={(e)=>setSelectedCategory(e.target.value)}>
                                <option value={""}>All categories</option>
                                {categories &&
                                    categories.map((category,key)=>{
                                        return <option key={key} value={category.id}>{category.title}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <input className="form-control" onChange={(e)=>setKeywords(e.target.value)} style={{borderRadius:"20px"}} placeholder="Search by name, content"/>
                </div>
            </div>
            {(posts && posts.length > 0) &&
            <div className="table-responsive mt-3 me-3">
                <ListStat data={data}/>
                <table className="table table-borderless posts">
                    <thead>
                        <tr className="border-bottom">
                            <th style={{width:"100px"}}></th>
                            <th>Post name</th>
                            <th>Date</th>
                            <th>Category</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    {posts?.map((post,key)=>{
                        return <tr className="cursorPointer border-bottom" onClick={()=>navigate('/blogpost/'+appid+'/post/'+post.id)}>
                            <td>
                                {post.image &&
                                    <img src={post.image[0]} className="img-fluid" style={{borderRadius:"10px"}}/>
                                }
                            </td>
                            <td className="pt-4">{post.title}</td>
                            <td className="pt-4">{post.dateadd}</td>
                            <td className="pt-4">{
                                post?.category?.map((categoryItem,categoryItemKey)=>{
                                    return <div>{categoryItem.title}</div>
                                })
                            }</td>
                            <td></td>
                        </tr>
                    })}
                    </tbody>
                </table>
            </div>
            }
            {(data?.page > 0 && data?.pages > 0) &&
                <Pagination
                    onChange={changeData}
                    name={"page"}
                    page={data.page}
                    totalPages={data.pages}
                    loading={loading}/>
            }
        </>
    )
}

export default Posts
