import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Drawer from 'rc-drawer';
import "rc-drawer/assets/index.css"
import "../assets/style/drawer.scss"
import SidePanel from "./SidePanel";
import {
    useNavigate,
    useParams
} from "react-router-dom";
import AppHeader from "./AppHeader";
import {AiOutlineShop, AiOutlineBell, AiOutlineUser} from "react-icons/ai";
import {BiMessageRoundedDetail} from "react-icons/bi";
import {FiEye, FiPhone} from "react-icons/fi";
import API from "../utils/API";
import Modal from 'react-modal'
import {GoRocket} from "react-icons/go";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import {set} from "../redux/actions/pageeditorActions";

const Header = () => {
    const user = useSelector(
        state => state.user.user
    )
    let navigate = useNavigate()

    const [qrCode, setQRCode] = useState(null)

    const [drawer,setDrawer] = useState(false)

    const [previewOpen,setPreviewOpen] = useState(false)

    const [previewPhoneOpen, setPreviewPhoneOpen] = useState(false)

    const [app,setApp] = useState(null)

    const [submission,setSubmission] = useState(false)
    const [submissionInfoLoaded,setSubmissionInfoLoaded] = useState(false)

    let { appid } = useParams();

    const [messageStat,setMessageStat] = useState({exists:false,lastFromAdmin:false})

    const dispatch = useDispatch();

    useEffect(() => {
        if (user) {
            API.get('/app/submit/'+appid).then((response) => {
                if (response.data?.data?.id) {
                    setSubmission(true)
                }
                setSubmissionInfoLoaded(true)
            });
        }
    }, [appid])

    const [apps,setApps] = useState([])

    useEffect(()=>{
        if (user) {
            API.get('/app/getall').then((response) => {
                setApps(response.data.data)
            })
        }
    },[user])

    const MINUTE_MS = 10000;

    useEffect(() => {
        if (appid) {
            API.get('/app/get/'+appid).then((response) => {
                setApp(response.data.data)
            });
            API.get('/messagesstat/'+appid).then((response) => {
                setMessageStat(response.data.data)
            });
        }

        const interval = setInterval(() => {
            if (appid) {
                API.get('/app/get/'+appid).then((response) => {
                    setApp(response.data.data)
                });
                API.get('/messagesstat/'+appid).then((response) => {
                    setMessageStat(response.data.data)
                });
            }
        }, MINUTE_MS);

        return () => clearInterval(interval);
    }, [appid])

    useEffect(()=> {
        if (!qrCode && appid) {
            API.get('/app/qrcode/'+appid).then((response) => {
                setQRCode(response.data.data.qrCode);
            })
        }
    },[previewOpen,appid])

    const handleOnSelect = (item) => {
        navigate('/app/'+item.id+'/pages')
    }

    const formatResult = (item) => {
        return (
            <>
                <span style={{ display: 'block', textAlign: 'left', cursor: "pointer" }}>{item.title}</span>
            </>
        )
    }

    return (
        <div className="row justify-content-between header">
            <div className="col-auto">
                {app ?
                    <AppHeader app={app}/>
                    :
                    <span className="title">Welcome to AAPPWORLD</span>
                }
            </div>
            {apps.length > 0 &&
            <div className="col ps-5 d-md-none d-sm-none d-lg-block">
                <div className="input-group">
                    <div style={{ width: 400 }}>
                    <ReactSearchAutocomplete
                        items={apps}
                        fuseOptions={{ keys: ["title"] }}
                        resultStringKeyName="title"
                        onSelect={handleOnSelect}
                        formatResult={formatResult}
                        showItemsOnFocus={true}
                        styling={
                            {
                                zIndex: 100
                            }
                        }
                    />
                    </div>
                </div>
            </div>
            }
            <div className="col-auto">
                <div className="row">
                    {app &&
                        <>
                            <div className="col-auto pt-1 pr-0 mr-0 ml-0">
                                <div className="headerButton buttonBGGray text-center pt-1" onClick={()=> {setPreviewOpen(true);dispatch(set({selectedBlockId:null}));}}><FiEye/></div>
                            </div>
                            <Modal
                                isOpen={previewOpen}
                                className="modal-window previewModal"
                                overlayClassName="modal-overlay"
                                onRequestClose={()=>setPreviewOpen(false)}
                            >
                                <div>
                                    <div className="row justify-content-center">
                                        <div className="col p-0 m-0 border-1 shadow border-primary" style={{width:"380px",height:"665px"}}>
                                            <iframe src={`/nativeapp/${appid}`} width="393px" height="665px"/>
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                            <div className="col-auto pt-1 pr-0 mr-0 ml-0">
                                <div className="headerButton buttonBGGray text-center pt-1" onClick={()=>{setPreviewPhoneOpen(true);dispatch(set({selectedBlockId:null}));}}><FiPhone/></div>
                            </div>
                            <Modal
                                isOpen={previewPhoneOpen}
                                className="modal-window previewModal"
                                overlayClassName="modal-overlay"
                                onRequestClose={()=>setPreviewPhoneOpen(false)}
                            >
                                <div>
                                    <div className="row justify-content-center m-5">
                                        <div className="col-10 text-center">
                                            <h5>Scan this QR code with your phone in order to preview application</h5>
                                            {qrCode ?
                                                <img className="img-fluid" src={qrCode}/>
                                                :
                                                <span className="spinner-border text-primary" role="status"/>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                            {app.private &&
                                <div className="col-auto pt-1 pr-0 mr-0 ml-0">
                                    <div className="headerButton buttonBGYellow text-center pt-1" onClick={()=>navigate("/appusers/"+appid)}><AiOutlineUser/></div>
                                </div>
                            }
                            {app.ecommerce &&
                            <div className="col-auto pt-1 pr-0 mr-0 ml-0">
                                <div className="headerButton buttonBGYellow text-center pt-1" onClick={()=>navigate("/ecommerce/"+appid+"/products")}><AiOutlineShop/></div>
                            </div>
                            }
                            {app.blog &&
                            <div className="col-auto pt-1 pr-0 mr-0 ml-0">
                                <div className="headerButton buttonBGYellow text-center pt-1" onClick={()=>navigate("/blogpost/"+appid+"/posts")}><BiMessageRoundedDetail/></div>
                            </div>
                            }
                            {submissionInfoLoaded &&
                                <>
                                    {submission ?
                                        <div className="col-auto pt-1 mr-0 ml-0">
                                            <div className="headerButton buttonBGYellow text-center pt-1" onClick={()=>navigate(`/app/${appid}/submissionform`)}><GoRocket/></div>
                                        </div>
                                        :
                                        <div className="col-auto pt-1 mr-0">
                                            <div className="headerButton buttonBGGray text-center pt-1" onClick={()=>navigate("/app/"+appid+"/selectplan")}><GoRocket/></div>
                                        </div>
                                    }
                                </>
                            }
                            {messageStat.exists &&
                                <div className="col-auto pt-1 mr-0 ml-0">
                                    <div className="headerButton buttonBGGray text-center pt-1" onClick={()=>navigate("/app/"+appid+"/messages")}>
                                        <AiOutlineBell/>
                                        {messageStat.lastFromAdmin &&
                                            <div style={{width:"10px",height:"10px",borderRadius:"50%",backgroundColor:"#FDD78C",position: "relative",top:"-28px",left:"28px"}} className={"pulse"}/>
                                        }
                                    </div>
                                </div>
                            }
                        </>
                    }
                    <div className="col-auto pt-3">
                        <span className="username" onClick={()=>setDrawer(true)}></span>
                    </div>
                    <div className="col-auto">
                        {user.icon ?
                            <img className="avatar" src={user.icon} onClick={()=>setDrawer(true)}/>
                            :
                            <div className="avatar text-center" onClick={()=>setDrawer(true)}>{user.name[0]}</div>
                        }
                    </div>
                </div>
            </div>
            <Drawer
                placement={"right"}
                level={null}
                open={drawer}
                onHandleClick={() => setDrawer(!drawer)}
                onClose={() => setDrawer(false)}
                maskClosable={true}
                maskMotion={{
                    motionAppear: true,
                    motionName: 'mask-motion'
                }}
                motion={{
                    motionAppear: true,
                    motionName: 'panel-motion-right'
                }}
            >
                <SidePanel setDrawer={setDrawer}/>
            </Drawer>
        </div>
    )
}

export default Header
