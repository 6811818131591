import React,{useState,useEffect} from "react";
import "../../assets/style/appSettings.scss"
import ImageUploader from "react-images-upload";
import API from "../../utils/API";
import {useNavigate} from "react-router-dom";

const AppEditor = ({appid}) => {
    const [appData,setAppData] = useState({title:'',description:'',icon:null})

    let navigate = useNavigate()
    const [imageUploading,setImageUploading] = useState(false)

    const setValue = (name,value) => {
        setAppData({ ...appData, [name]: value })
    }

    const onDrop = (picture,pictureURI) => {
        if (pictureURI.length > 0) {
            setImageUploading(true)
            API.post('/image/', {image: pictureURI[0]}).then((response) => {
                setValue("icon", response.data.data.url)
                setImageUploading(false)
            });
        }else {
            setValue("icon", null)
        }
    };

    useEffect(()=> {
        if (appid) {
            API.get(`/app/get/${appid}`).then((response) => {
                setAppData(response.data.data)
            })
        }
    },[appid])

    const saveApp = () => {
        if (appData.title) {
            if (appid) {
                API.put(`/app/${appid}/`, appData).then((response) => {
                    navigate(`/app/${appid}/pages`)
                })
            } else {
                API.post('/app/', appData).then((response) => {
                    navigate(`/app/${response.data.data.id}/pages`)
                })
            }
        }
    }

    return (
        <div className="appSettings">
            <div className="row m-5">
                <div className="col-3">
                    <div className="row justify-content-center">
                        <div className="col-auto text-center">
                            <div className="text-center">App Logo</div>
                            <ImageUploader
                                withIcon={true}
                                onChange={onDrop}
                                imgExtension={[".jpg",".jpeg", ".gif", ".png", ".gif", ".webp"]}
                                maxFileSize={5242880}
                                withPreview={true}
                                singleImage={true}
                                defaultImages={appData.icon?[appData.icon]:[]}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-9">
                    <div>
                        Project name
                        <input type="text" className="form-control mt-2" value={appData.title} onChange={(e)=>setValue('title',e.target.value)}/>
                    </div>
                    <div className="mt-4">
                        Project description <span className="text-muted">(optional)</span>
                        <textarea rows={7} className="form-control mt-2" value={appData.description} onChange={(e)=>setValue('description',e.target.value)}/>
                    </div>
                </div>
            </div>
            <div className="row justify-content-end pb-3 m-5">
                <div className="col-auto">
                    <button className="btn buttonCancel" onClick={()=>navigate("/")}>Cancel</button>
                </div>
                <div className="col-auto">
                    <button className="btn buttonSave" onClick={()=>{if (!imageUploading) {saveApp()}}}>{imageUploading?"Loading...":<>{appid?'Save Changes':'Add app'}</>}</button>
                </div>
            </div>
        </div>
    )
}

export default AppEditor
