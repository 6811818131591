import React from "react"
import SliderInput from "../../editor/subelements/SliderInput"
import Animation from "../../editor/Animation"
import {MdAnimation} from "react-icons/md"
import {useCustomAnimation} from "../../helpers/animation"
import ColorPicker from "../../editor/subelements/ColorPicker"
import Spacing from "../../editor/subelements/Spacing"
import {bgImage} from "../../helpers/bgImage"
import Reviews from "../../editor/reviews/Reviews"
import OnOff from "../../editor/subelements/OnOff"
import {AiOutlineLink} from "react-icons/ai"
import ReviewItemYelpView from "./ReviewItemYelpView";

const AirbnbReviews = (props) => {

    const animation = useCustomAnimation(props.data.animation)

    if (props.edit) {
        return <div>
            <div className="editItem p-2">
                <div className="subElement subElementNoBorderTop">
                    <Reviews name={"reviews"} value={props.data?.reviews} setValue={props.setValue} featureName={"Reviews"} platform="airbnb"/>
                </div>
                <div className="subElement">
                    <OnOff name={"showLink"} value={props.data?.showLink} featureName={"Show original link"} setValue={props.setValue} imageName={<AiOutlineLink/>}/>
                </div>
                <div className="subElement">
                    <ColorPicker name={"textColor"} value={props.data?.textColor??"black"} setValue={props.setValue} featureName={"Text color"}/>
                </div>
                <div className="subElement">
                    <ColorPicker name={"ratingColor"} value={props.data?.ratingColor??"black"} setValue={props.setValue} featureName={"Rating color"}/>
                </div>
                <div className="subElement">
                    <ColorPicker name={"bgColor"} value={props.data?.bgColor} setValue={props.setValue} featureName={"Background color"}/>
                </div>
                <div className="subElement">
                    <Spacing name={"margin"} value={props.data?.margin} setValue={props.setValue} featureName={"Margin"}/>
                </div>
                <div className="subElement">
                    <Spacing name={"padding"} value={props.data?.padding} setValue={props.setValue} featureName={"Padding"}/>
                </div>
                <div className="subElement">
                    <Spacing name={"borderRadius"} value={props.data?.borderRadius} setValue={props.setValue} featureName={"Border radius"}/>
                </div>
                <div className="subElement">
                    <ColorPicker name={"borderColor"} value={props.data?.borderColor} setValue={props.setValue} featureName={"Border color"}/>
                </div>
                <div className="subElement">
                    <SliderInput name={"borderWidth"} value={props.data?.borderWidth} setValue={props.setValue} featureName={"Border width"} min={0} max={20}/>
                </div>
            </div>
            <div className="editItem">
                <Animation name={'animation'} setValue={props.setValue} value={props.data.animation} featureName="Animation" imageName={<MdAnimation style={{fontSize:"18px"}}/>}/>
            </div>
        </div>
    }else {
        return <div style={bgImage(props?.data)??{}} className={animation}>
            {(props.data?.reviews?.list && props.data?.reviews?.list?.length > 0) &&
                <ul className="list-group">
                    {props.data?.reviews?.list.map((review,reviewKey)=>{
                        return (<li className="list-group-item" key={reviewKey}>
                            <ReviewItemYelpView review={review} data={props.data}/>
                        </li>)
                    })}
                </ul>
            }
        </div>
    }
}

export default AirbnbReviews
