import React,{useEffect,useState} from "react";
import API from "../../../utils/API";

const BrandsItem = ({app,id,onClose}) => {

    const [category,setCategory] = useState({title:null,parent:null})

    const setValue = (name,value) => {
        setCategory((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const save = () => {
        API.post(`/ecommerce/brand/${id?'save':'add'}/${app}${id?('/'+id):''}`,category).then((result) => {
            onClose();
        });
    }

    useEffect(()=>{
        if (app && id) {
            API.get(`/ecommerce/brand/${app}/${id}`).then((result) => {
                setCategory(result.data.data);
            });
        }
    },[app,id])

    return <div className="m-2">
        <div className="row m-3">
            <div className="col-12">
                <label>Title</label>
                <input type="text" className="form-control" onChange={(e)=>setValue('title',e.target.value)} value={category.title}/>
            </div>
        </div>
        <div className="row justify-content-center">
            <div className="col-auto">
                <div className="primaryButton ps-3 pe-3" onClick={()=>save()}>Save</div>
            </div>
            <div className="col-auto">
                <div className="secondaryButton" onClick={()=>onClose()}>Cancel</div>
            </div>
        </div>
    </div>
}

export default BrandsItem