import React, {useEffect, useState} from "react";
import {TbDots} from "react-icons/tb";
import {useParams, useNavigate} from "react-router-dom";
import API from "../../utils/API";

const SalesCoupon = () => {
    let { appid } = useParams()
    let { productid } = useParams()
    let navigate = useNavigate()
    const [loaded,setLoaded] = useState(false)
    const [productData,setProductData] = useState({})

    const setValue = (name,value) => {
        setProductData({ ...productData, [name]: value })
    }

    useEffect(()=>{
        if (productid) {
            loadProduct()
        }else {
            setLoaded(true)
        }
    },[appid,productid])

    const save = () => {
        API.post(`/ecommerce/coupon/${productid?'save':'add'}/${appid}${productid?('/'+productid):''}`,productData).then((result) => {
            navigate('/ecommerce/'+appid+'/sales')
        });
    }

    const loadProduct = () => {
        API.get(`/ecommerce/coupon/${appid}/${productid}`).then((result) => {
            setProductData(result.data.data)
            setLoaded(true)
        })
    }

    return (
        <>
            <div className="table-responsive mt-3 me-4">
                <table className="table table-borderless products">
                    <thead>
                    <tr>
                        <th>Coupon code</th>
                        <th>Discount, %</th>
                        <th>Discount, $</th>
                        <th>Quantity</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className="pt-4"><input type="text" value={productData.couponcode} onChange={(e)=>setValue('couponcode',e.target.value)} className="form-control" placeholder="ex: Sale20"/></td>
                        <td className="pt-4"><input type="text" value={productData.discountpercent} onChange={(e)=>setValue('discountpercent',e.target.value)} className="form-control" placeholder="ex: 10"/></td>
                        <td className="pt-4"><input type="text" value={productData.discount} onChange={(e)=>setValue('discount',e.target.value)} className="form-control" placeholder="ex: 10.5"/></td>
                        <td className="pt-4"><input type="text" value={productData.quantity} onChange={(e)=>setValue('quantity',e.target.value)} className="form-control" placeholder="ex: 50"/></td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className="row justify-content-end me-4">
                <div className="col-auto">
                    <div className="saveButton" onClick={(e)=>save()}>Save</div>
                </div>
                <div className="col-auto">
                    <div className="cancelButton" onClick={(e)=>navigate('/ecommerce/'+appid+'/sale')}>Cancel</div>
                </div>
            </div>
        </>
    )
}

export default SalesCoupon
