import React from "react";
import OnOff from "./subelements/OnOff";
import ColorPicker from "./subelements/ColorPicker";
import Spacing from "./subelements/Spacing";
import SliderInput from "./subelements/SliderInput";
import ImageItem from "./subelements/ImageItem";

const Image = ({setValue, name, value, featureName, imageName, defaultHeight}) => {
    return <div className="mb-1">
        <div className="p-2">
            <OnOff name={name+".enabled"} value={value?.enabled} featureName={featureName} setValue={setValue} imageName={imageName}/>
        </div>
        {value?.enabled==="on" &&
            <>
                <div className="subElement">
                    <ImageItem name={name+".image"} value={value?.image} setValue={setValue} featureName={"Image"}/>
                </div>
                <div className="subElement">
                    <SliderInput name={name+".height"} value={value?.height??defaultHeight} setValue={setValue} featureName={"Image height"} min={0} max={200}/>
                </div>
                <div className="subElement">
                    <Spacing name={name+".margin"} value={value?.margin} setValue={setValue} featureName={"Margin"}/>
                </div>
                <div className="subElement">
                    <Spacing name={name+".padding"} value={value?.padding} setValue={setValue} featureName={"Padding"}/>
                </div>
                <div className="subElement">
                    <Spacing name={name+".borderRadius"} value={value?.borderRadius} setValue={setValue} featureName={"Border radius"}/>
                </div>
                <div className="subElement">
                    <ColorPicker name={name+".borderColor"} value={value?.borderColor} setValue={setValue} featureName={"Border color"}/>
                </div>
                <div className="subElement">
                    <SliderInput name={name+".borderWidth"} value={value?.borderWidth} setValue={setValue} featureName={"Border width"} min={0} max={20}/>
                </div>
            </>
        }
    </div>
}

export default Image
