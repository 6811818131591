import React, {useEffect, useState} from "react";
import {useParams, useNavigate} from "react-router-dom";
import API from "../../utils/API";

const SalesCoupons = () => {
    let { appid } = useParams()
    let navigate = useNavigate()
    const [coupons,setCoupons] = useState([])

    useEffect(()=>{
        API.post(`/ecommerce/coupons/${appid}`).then((result)=>{
            setCoupons(result.data.data);
        })
    },[]);

    return (
        <>
            <div className="row me-4">
                <div className="col-4 fs-5">
                    <span className="selectLabel">Show:</span>
                    <select className="inlineSelect">
                        <option>All coupons</option>
                        <option>Sales</option>
                    </select>
                </div>
                <div className="col-4">
                    <input className="form-control inlineSearch" placeholder="Search Coupon"/>
                </div>
                <div className="col-4 text-end pt-2">
                    <span className="selectLabel">Sort by:</span>
                    <select className="inlineSelect">
                        <option>Default</option>
                        <option>Price</option>
                    </select>
                </div>
            </div>
            <div className="row justify-content-end me-4">
                <div className="col-auto">
                    <div className="addButton" onClick={(e)=>navigate('/ecommerce/'+appid+'/sale')}>+ New Coupon</div>
                </div>
            </div>
            {(coupons && coupons.length > 0) &&
                <div className="table-responsive mt-3 me-4">
                    <table className="table table-borderless products">
                        <thead>
                        <tr>
                            <th>Code</th>
                            <th>Discount, %</th>
                            <th>Discount, $</th>
                            <th>Quantity</th>
                            <th>Date add</th>
                        </tr>
                        </thead>
                        <tbody>
                        {coupons?.map((coupon,key)=>{
                            return <tr className="cursorPointer" onClick={()=>navigate('/ecommerce/'+appid+'/sale/'+coupon.id)}>
                                <td className="pt-4">{coupon.couponcode}</td>
                                <td className="pt-4">{coupon.discountpercent?coupon.discountpercent+"%":""}</td>
                                <td className="pt-4">{"$"+coupon.discount?coupon.discount:""}</td>
                                <td className="pt-4">{coupon.quantity}</td>
                                <td className="pt-4">{coupon.dateadd}</td>
                            </tr>
                        })}
                        </tbody>
                    </table>
                </div>
            }
        </>
    )
}

export default SalesCoupons
