import React, {UseState, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import "../../assets/style/mainpage.scss"
import API from "../../utils/API";
import ListStat from "../../components/ListStat";
import Pagination from "../../components/Pagination";

const AppUsers = () => {

    let { appid } = useParams()

    const [users,setUsers] = useState([])
    const [data,setData] = useState(null)
    const [loading,setLoading] = useState(false)

    const [filtration,setFiltration] = useState({page:1,perPage:20})

    useEffect(()=>{
        setLoading(true);
        API.post(`/app/users/${appid}`,filtration).then((result)=>{
            setUsers(result.data.data.data);
            setData(result.data.data);
            setLoading(false);
        });
    },[filtration])

    const changeData = (name,value) => {
        setFiltration({ ...filtration, [name]: value })
    }

    return <div className="mainPage blogpost">
        <div className="hero">
            <span className="title">Registered users</span>
        </div>
        <div className="mt-3 p-2">
            {(users && users.length > 0) &&
                <div className="table-responsive mt-3 me-3">
                    <ListStat data={data}/>
                    <table className="table table-borderless posts">
                        <thead>
                        <tr className="border-bottom">
                            <th>Name</th>
                            <th>Status</th>
                            <th>Email</th>
                            <th>Registration data</th>
                        </tr>
                        </thead>
                        <tbody>
                        {users?.map((user,key)=>{
                            return <tr className="cursorPointer border-bottom">
                                <td className="pt-4">{user.nickname}</td>
                                <td className="pt-4"><span className="badge bg-success">Active</span></td>
                                <td className="pt-4">{user.email}</td>
                                <td className="pt-4">{user.dateregistred}</td>
                            </tr>
                        })}
                        </tbody>
                    </table>
                </div>
            }
            {(data?.page > 0 && data?.pages > 0) &&
                <Pagination
                    onChange={changeData}
                    name={"page"}
                    page={data.page}
                    totalPages={data.pages}
                    loading={loading}/>
            }
        </div>
    </div>
}

export default AppUsers;
