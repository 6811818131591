export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";

export const EDIT_PAGE = "EDIT_PAGE";
export const RESET_PAGE = "RESET_PAGE";
export const EDIT_PAGE_CONTENT = "EDIT_PAGE_CONTENT";

export const EDIT_TYPE_BLOCK = "BLOCK"
export const EDIT_TYPE_SECTION = "SECTION"

export const APP_MENU_VISIBLE = "APP_MENU_VISIBLE"
export const APP_MODE = "APP_MODE"
