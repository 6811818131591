import React from "react";
import GooglePlacesAutocomplete,{geocodeByAddress, getLatLng} from 'react-google-places-autocomplete';

const GooglePlaceSelect = ({setValue, name, value, featureName}) => {

    const setAutoComplete = (autocomplete) => {
        geocodeByAddress(autocomplete.label)
            .then(results => getLatLng(results[0]))
            .then(({ lat, lng }) => {
                setValue(name,{lat:lat,lng:lng})
            })
    }

    return <div className="slider">
        <div className="row">
            <div className="col">
                <span className="blockTitle">{featureName}</span>
            </div>
        </div>
        <div className="row ps-1">
            <div className="col">
                <GooglePlacesAutocomplete
                    selectProps={{
                        onChange: setAutoComplete,
                    }}
                />
            </div>
        </div>
    </div>
}

export default GooglePlaceSelect
