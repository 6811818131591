import React,{useEffect} from "react";
import {useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";

const PageSelectionRequirement = () => {
    let navigate = useNavigate()

    let { appid } = useParams();

    const pageeditor = useSelector(state => {
        return state.pageEditor
    });

    useEffect(()=>{
        if (pageeditor && !pageeditor.page && appid) {
            navigate("/app/"+appid+"/pages");
        }
    },[pageeditor,appid])

    return <></>
}

export default PageSelectionRequirement
