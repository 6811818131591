import { combineReducers } from "redux";
import user from "./userReducer";
import pageEditor from "./pageeditorReducer";
import app from "./appReducer";

export default combineReducers({
    user,
    pageEditor,
    app
});
