import React,{useState} from "react";
import Image from "../../editor/Image";
import {BsImage} from "react-icons/bs";
import {MdAnimation} from "react-icons/md";
import Animation from "../../editor/Animation";
import {imageStyle} from "../../helpers/imageStyle"
import {useCustomAnimation} from "../../helpers/animation"
import 'animate.css';
import Elements from "../../editor/Elements";
import MenuItem from "../../editor/MenuItem";
import MenuButtonPicker from "../../editor/subelements/MenuButtonPicker";
import {MenuIcon} from "../../helpers/MenuIcon";
import SliderInput from "../../editor/subelements/SliderInput";
import ColorPicker from "../../editor/subelements/ColorPicker";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import {setMenuStatus} from "../../../../redux/actions/appActions"
import useClickOutside from 'click-outside-hook';
import BackButtonPicker from "../../editor/subelements/BackButtonPicker";
import {BackButtonIcon} from "../../helpers/BackButtonIcon";

const Hero = (props) => {
    const dispatch = useDispatch()

    const animation = useCustomAnimation(props.data.animation)

    const app = useSelector(state => {
        return state.app
    });

    const ref = useClickOutside(() => {
        if (app.openedMenu === props.id) {
            dispatch(setMenuStatus(null))
        }
    });

    const toggleMenu = () => {
        if (app.openedMenu === props.id) {
            dispatch(setMenuStatus(null))
        }else {
            dispatch(setMenuStatus(props.id))
        }
    }

    if (props.edit) {
        return <div>
            <div className="editItem">
                <MenuButtonPicker name='icon' value={props.data.icon??'bars'} setValue={props.setValue} featureName={"Menu icon"}/>
                <div className="subElement">
                    <SliderInput name={"iconSize"} value={props.data?.iconSize??20} setValue={props.setValue} featureName={"Menu icon size"} min={0} max={40}/>
                </div>
                <div className="subElement">
                    <ColorPicker name={"iconColor"} value={props.data?.iconColor??'#000000'} setValue={props.setValue} featureName={"Menu icon color"}/>
                </div>
                <BackButtonPicker name='backicon' value={props.data.backicon??'IoIosArrowBack'} setValue={props.setValue} featureName={"Back icon"}/>
                <div className="subElement">
                    <SliderInput name={"backiconSize"} value={props.data?.backiconSize??20} setValue={props.setValue} featureName={"Back icon size"} min={0} max={40}/>
                </div>
                <div className="subElement">
                    <ColorPicker name={"backiconColor"} value={props.data?.backiconColor??'#000000'} setValue={props.setValue} featureName={"Back icon color"}/>
                </div>
                <div className="subElement">
                    <ColorPicker name={"menuBgColor"} value={props.data?.menuBgColor??'#ffffff'} setValue={props.setValue} featureName={"Menu background color"}/>
                </div>
                <div className="subElement">
                    <ColorPicker name={"menuElementsColor"} value={props.data?.menuElementsColor??'#000000'} setValue={props.setValue} featureName={"Menu elements color"}/>
                </div>
                <div className="subElement">
                    <Elements name={"menu"} setValue={props.setValue} value={props.data?.menu} featureName={"Menu links"} itemComponent={<MenuItem/>}/>
                </div>
            </div>
            <div className="editItem">
                <Image name={'logo'} setValue={props.setValue} value={props.data.logo} featureName="Logo" defaultHeight={100} imageName={<BsImage style={{fontSize:"18px"}}/>}/>
            </div>
            <div className="editItem">
                <Animation name={'animation'} setValue={props.setValue} value={props.data.animation} featureName="Animation" imageName={<MdAnimation style={{fontSize:"18px"}}/>}/>
            </div>
        </div>
    }else {
        return <div className={animation}>
            <div className="row">
                <div className="col-3">
                    <div className="d-table-cell align-middle position-relative">
                        <BackButtonIcon name={props.data.backicon??'IoIosArrowBack'} size={props.data?.backiconSize??20} color={props.data?.backiconColor??'#000000'}/>
                    </div>
                </div>
                <div className="col-6 text-center">
                    {(props?.data?.logo?.enabled === "on" && props?.data?.logo?.image) &&
                        <img src={props?.data?.logo.image} height={`${props?.data?.logo?.height??100}px`} style={imageStyle(props?.data?.logo)}/>
                    }
                </div>
                <div className="col-3 text-center d-table">
                    <div className="d-table-cell align-middle position-relative">
                        <div onClick={(e)=>{
                            toggleMenu()
                            e.stopPropagation();
                        }}>
                            <MenuIcon name={props.data.icon??'bars'} size={props.data?.iconSize??20} color={props.data?.iconColor??'#000000'}/>
                        </div>
                        {(props.id === app.openedMenu) &&
                            <div ref={ref} className="position-absolute" style={{opacity:1,zIndex:"10000",backgroundColor:props.data?.menuBgColor??'#ffffff',top:-20,right:0,width:"300px",minHeight:"100px",paddingBottom:"20px"}}>
                                <div className="text-end p-4 pb-2 cursor-pointer" onClick={(e)=>{toggleMenu();e.stopPropagation();}}>
                                    <FontAwesomeIcon icon={faTimes} style={{fontSize:props.data?.iconSize??20}} color={props.data?.iconColor??'#000000'}/>
                                </div>
                                {props?.data?.menu?.list?.map((item,index)=> {
                                    let href;
                                    if (item?.link?.type === "webaddress") {
                                        href = item?.link?.url;
                                    }else if (item?.link?.type === "email") {
                                        href = "emailto:"+item?.link?.email;
                                    }else if (item?.link?.type === "phone") {
                                        href = "tel:"+item?.link?.phone;
                                    }else if (item?.link?.type === "file") {
                                        href = item?.link?.file;
                                    }else {
                                        href = "internallink"+item?.link?.page;
                                    }
                                    return (
                                        <div key={index}>
                                            <div style={{
                                                paddingLeft: "30px",
                                                paddingRight: "30px",
                                                marginTop: "10px",
                                                marginBottom: "10px"
                                            }} className="text-start">
                                                <a href={href}
                                                   style={{color: props.data?.menuElementsColor??'#000000', fontSize: "20px"}}>{item.title}</a>
                                                {item.description &&
                                                    <div style={{color: props.data?.menuElementsColor??'#000000', fontSize: "14px"}}>{item.description}</div>
                                                }
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    }
}

export default Hero
