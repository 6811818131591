import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import API from "../../../utils/API";
import _ from "lodash";
import {build} from "../../../components/builder/blocks/BlockFactory";
import "../../../assets/style/blockEdit.scss";

const DefaultBlockSettings = () => {
    const [block,setBlock] = useState(null)

    let { slug } = useParams();

    const setValue = (name, value) => {
        let dataBlock,tempBlock = {data:{}}

        dataBlock = block.data??{}

        _.set(dataBlock, name, value);
        console.log(dataBlock);
        tempBlock.data = dataBlock
        console.log(tempBlock.data);

        API.put('/admin/block/defaultdata/'+slug,{...tempBlock.data});

        setBlock(tempBlock)
    }

    useEffect(()=>{
        if (slug) {
            API.get('/admin/block/defaultdata/'+slug).then((response) => {
                setBlock({data:_.isObject(response.data.data)?response.data.data:{}})
            })
        }
    },[slug])

    return <div className="row">
        <div className="col-6">
            <div className="blockEdit">
                {block &&
                    React.cloneElement(build(slug),{...block, ...{edit:true,setValue:setValue}})
                }
            </div>
        </div>
        <div className="col-6">
            {block &&
                React.cloneElement(build(slug),{...block, ...{setValue:setValue}})
            }
        </div>
    </div>
}

export default DefaultBlockSettings
