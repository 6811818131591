import React from "react"
import {useSelector} from "react-redux";
import SectionEditor from "./SectionEditor";
import BlockEditor from "./BlockEditor";

const ElementManagement = (props) => {
    const pageeditor = useSelector(state => {
        return state.pageEditor
    })

    return <>
        {(pageeditor.editSectionId !== null && pageeditor.editType === 'section') &&
            <div>
                <SectionEditor/>
            </div>
        }
        {(pageeditor.editBlockId !== null && pageeditor.editType === 'block') &&
            <div>
                <BlockEditor/>
            </div>
        }
    </>
}

export default ElementManagement
