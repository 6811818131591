import React from "react";
import {TbDots} from "react-icons/tb";

const Orders = () => {
    return (
        <>
            <div className="row me-4">
                <div className="col-4 fs-5">
                    <span className="selectLabel">Show:</span>
                    <select className="inlineSelect">
                        <option>All statuses</option>
                        <option>New</option>
                        <option>Paid</option>
                    </select>
                </div>
                <div className="col-4">
                    <input className="form-control inlineSearch" placeholder="Search by customer"/>
                </div>
                <div className="col-4 text-end pt-2">
                    <span className="selectLabel">Sort by:</span>
                    <select className="inlineSelect">
                        <option>Date</option>
                        <option>Status</option>
                    </select>
                </div>
            </div>
        </>
    )
}

export default Orders
