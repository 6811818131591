import React from "react";
import ColorPicker from "./subelements/ColorPicker";
import Spacing from "./subelements/Spacing";
import SliderInput from "./subelements/SliderInput";

const Layer = ({setValue, name, value, featureName}) => {
    return <div className="mb-1">
        <div className="row onOff">
            <div className="col p-3 text-center">
                <span className="blockTitle">{featureName}</span>
            </div>
        </div>
        <div className="subElement">
            <ColorPicker name={name+".fontColor"} value={value?.fontColor} setValue={setValue} featureName={"Font color"}/>
        </div>
        <div className="subElement">
            <SliderInput name={name+".fontSize"} value={value?.fontSize??14} setValue={setValue} featureName={"Font size"} min={4} max={50}/>
        </div>
        <div className="subElement">
            <ColorPicker name={name+".bgColor"} value={value?.bgColor} setValue={setValue} featureName={"Background color"}/>
        </div>
        <div className="subElement">
            <Spacing name={name+".margin"} value={value?.margin} setValue={setValue} featureName={"Margin"}/>
        </div>
        <div className="subElement">
            <Spacing name={name+".padding"} value={value?.padding} setValue={setValue} featureName={"Padding"}/>
        </div>
        <div className="subElement">
            <Spacing name={name+".borderRadius"} value={value?.borderRadius} setValue={setValue} featureName={"Border radius"}/>
        </div>
        <div className="subElement">
            <ColorPicker name={name+".borderColor"} value={value?.borderColor} setValue={setValue} featureName={"Border color"}/>
        </div>
        <div className="subElement">
            <SliderInput name={name+".borderWidth"} value={value?.borderWidth} setValue={setValue} featureName={"Border width"} min={0} max={20}/>
        </div>
    </div>
}

export default Layer
