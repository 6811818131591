import React from "react";
import OnOff from "./subelements/OnOff";

const OnOffFeature = ({setValue, name, value, featureName, imageName}) => {
    return <div className="p-2 mb-1">
        <OnOff name={name} value={value} featureName={featureName} setValue={setValue} imageName={imageName}/>
    </div>
}

export default OnOffFeature
