import React from "react"
import Rating from "react-rating"
import {AiFillStar, AiOutlineStar} from "react-icons/ai"

const ReviewItemFacebook = ({review}) => {

    return <>
        <div className="row">
            <div className="col">
                {review.reviewer}<br/>
                {review.datetime.substring(0, 10)}<br/>
            </div>
        </div>
        {review.text}
    </>
}

export default ReviewItemFacebook
