import * as types from "../constants";

const initialState = {
    user: localStorage.getItem('appbuilderuser')?JSON.parse(localStorage.getItem('appbuilderuser')):{name:null,email:null,token:null,role:null,icon:null},
    sign: !!localStorage.getItem('appbuilderuser')
};

export default function reducer(state = initialState, actions) {
    switch (actions.type) {
        case types.USER_LOGIN:
            localStorage.setItem('appbuilderjwt',actions.payload.token)
            localStorage.setItem('appbuilderuser',JSON.stringify(actions.payload))
            return {
                ...state,
                user: actions.payload,
                sign: true
            };
        case types.USER_LOGOUT:
            localStorage.removeItem('appbuilderjwt')
            localStorage.removeItem('appbuilderuser')
            return {
                ...state,
                user: null,
                sign: false
            };
        default:
            return state;
    }
}
