import React,{useState} from "react";
import { MultiSelect } from 'primereact/multiselect';

const CategorySelect = ({setValue, name, value, featureName}) => {
    const [selectedCities, setSelectedCities] = useState(null);
    const cities = [
        { name: 'Category 1', code: 'NY' },
        { name: 'Category 2', code: 'RM' },
        { name: 'Category 3', code: 'LDN' },
        { name: 'Category 4', code: 'IST' },
        { name: 'Category 5', code: 'PRS' }
    ];

    return <div className="slider">
        <div className="row">
            <div className="col">
                <span className="blockTitle">{featureName}</span>
            </div>
        </div>
        <div className="row ps-1">
            <div className="col">
                <MultiSelect
                    value={selectedCities}
                    onChange={(e) => setSelectedCities(e.value)}
                    options={cities}
                    optionLabel="name"
                    display="chip"
                    placeholder="Select Category"
                    style={{width:"300px"}}
                />
            </div>
        </div>
    </div>
}

export default CategorySelect
