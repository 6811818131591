import React from "react"
import TextInput from "./subelements/TextInput";
import TextAreaInput from "./subelements/TextAreaInput";

const FAQItem = ({item, itemKey, editItem}) => {

    const setValue = (name,value) => {
        editItem(itemKey,name,value)
    }

    return (<>
        <div className="subElement subElementNoBorderTop">
            <TextInput name={"question"} value={item?.question} featureName={"Question"} setValue={setValue}/>
        </div>
        <div className="subElement">
            <TextAreaInput name={"answer"} value={item?.answer} featureName={"Answer"} rows={5} setValue={setValue}/>
        </div>
    </>)
}

export default FAQItem
