import React from "react"
import ImageItem from "./subelements/ImageItem";
import TextInput from "./subelements/TextInput";
import TextAreaInput from "./subelements/TextAreaInput";

const LogoShowCaseItem = ({item, itemKey, editItem}) => {

    const setValue = (name,value) => {
        editItem(itemKey,name,value)
    }

    return (<>
        <div className="subElement subElementNoBorderTop">
            <ImageItem name={"avatar"} value={item?.avatar} setValue={setValue} featureName={"Avatar"}/>
        </div>
        <div className="subElement">
            <TextInput name={"name"} value={item?.name} featureName={"Name"} setValue={setValue}/>
        </div>
    </>)
}

export default LogoShowCaseItem
