import React,{useState,useEffect} from 'react';
import {FaPlus} from'react-icons/fa';
import API from "../../utils/API";
import {useNavigate, useParams} from "react-router-dom";

const SelectPlan = () => {
    let navigate = useNavigate()

    const [monthlyPlan,setMonthlyPlan] = useState(false)
    const [yearlyPlan,setYearlyPlan] = useState(false)

    const [checked, setChecked] = useState(false)

    const [planType,setPlanType] = useState('yearly')

    let { appid } = useParams()

    useEffect(()=> {
        if (!checked && appid) {
            API.get(`/billing/subscription/${appid}`).then(res => {
                if (res?.data?.data?.id) {
                    navigate(`/app/${appid}/submissionform`)
                }
                setChecked(true)
            });
        }
    },[checked,appid])

    useEffect(() => {
        API.get("/billing/plans").then(res => {
            setMonthlyPlan(res.data.data.monthly)
            setYearlyPlan(res.data.data.yearly)
        })
    },[])

    return <>{checked &&
        <>
        <h3>App Submission</h3><div className="row">
        <div className="mx-auto mt-3 col-xl-8 col-md-10 boxShadow"><h2 className="text-center">Subscribe</h2>
            <div className="justify-content-center mt-3 mb-2 row">
                <div className="col-auto">
                    <div role="group" className="btn-group">
                        <button type="button" className={`${planType==='monthly'?'mainButton':'mainButtonOutline'} btn`} onClick={()=>setPlanType('monthly')}>Monthly billing</button>
                        <button type="button" className={`${planType==='yearly'?'mainButton':'mainButtonOutline'} btn`} onClick={()=>setPlanType('yearly')}>Annual billing</button>
                    </div>
                </div>
            </div>
            {(monthlyPlan && yearlyPlan) &&
            <div className="tab-content">
                <div role="tabpanel" id="pricing-tabs-tabpane-monthly" aria-labelledby="pricing-tabs-tab-monthly"
                     className="fade tab-pane active show">
                    <div className="py-4 row justify-content-center">
                        <div className="mb-3 mb-md-0 col-sm-4">
                            <div className="text-center h-100 card">
                                <div className="d-flex flex-column card-body">
                                    <div className="mb-4"><h5>Plus</h5>
                                        {planType === 'monthly' ?
                                        <>
                                        <span className="display-4">${monthlyPlan.price}</span><span>/mo</span>
                                        </>
                                            :
                                        <>
                                            <span className="display-4">${yearlyPlan.price}</span><span>/year</span>
                                        </>
                                        }
                                        </div>
                                    {planType === 'monthly' ?
                                        <>
                                            {monthlyPlan.description?.length > 0 &&
                                                monthlyPlan.description.split("\n").map(function(item) {
                                                    return <>{item}<br/></>
                                                })
                                            }
                                        </>
                                        :
                                        <>
                                            {yearlyPlan.description?.length > 0 &&
                                                yearlyPlan.description.split("\n").map(function(item) {
                                                    return <>{item}<br/></>
                                                })
                                            }
                                        </>
                                    }
                                    <div className="mt-3">
                                        <button type="button" className="btn mainButton btn-lg" onClick={(e)=>navigate(`/app/${appid}/selectpaymentmethod/${planType}`)}>Select plan</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
    </div>
    </>
    }</>
}

export default SelectPlan;
