import React from "react"
import TextInput from "./subelements/TextInput";
import TextAreaInput from "./subelements/TextAreaInput";
import LinkPicker from "./subelements/LinkPicker";

const MenuItem = ({item, itemKey, editItem}) => {

    const setValue = (name,value) => {
        console.log(itemKey,name,value);
        editItem(itemKey,name,value)
    }

    return (<>
        <div className="subElement">
            <TextInput name={"title"} value={item?.title} featureName={"Title"} setValue={setValue}/>
        </div>
        <div className="subElement">
            <TextAreaInput name={"description"} value={item?.description} featureName={"Description"} rows={3} setValue={setValue}/>
        </div>
        <div className="subElement">
            <LinkPicker name={"link"} value={item?.link} setValue={setValue} featureName={"Link"}/>
        </div>
    </>)
}

export default MenuItem
