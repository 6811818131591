import React,{useState} from "react";
import { MultiSelect } from 'primereact/multiselect';

const ButtonActionType = ({setValue, name, value, featureName}) => {
    const types = [
        { name: 'User registration', code: 'registration' },
        { name: 'Message', code: 'message' }
    ];

    return <div className="slider">
        <div className="row">
            <div className="col">
                <span className="blockTitle">{featureName}</span>
            </div>
        </div>
        <div className="row ps-1">
            <div className="col">
                <MultiSelect
                    value={value}
                    onChange={(e) => setValue(name,e.value)}
                    options={types}
                    optionLabel="name"
                    display="chip"
                    placeholder={`Select ${featureName}`}
                    style={{width:"250px"}}
                />
            </div>
        </div>
    </div>
}

export default ButtonActionType
