import React,{useState,useEffect} from "react";
import "../assets/style/mainpage.scss"
import "../assets/style/shop.scss"
import API from "../utils/API";

const TemplateStatistics = () => {
    const [performances, setPerformances] = useState(null)

    useEffect(() => {
        API.post("/templates/marketplace/statistics").then(res=>{
            setPerformances(res.data.data)
        })
    },[])

    return <div className="mainPage shop">
        <div className="hero">
            <div className="row mb-2">
                <div className="col">
                    <span className="title">Store performance</span>
                </div>
            </div>
        </div>
        <div className="m-2">
            {performances &&
                performances.length ?
                <>
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Purchase date</th>
                            <th scope="col">Template</th>
                            <th scope="col">Price</th>
                        </tr>
                        </thead>
                        <tbody>
                        {performances.map((item,index)=>{
                            return <tr key={index}>
                                <th scope="row">{item.purchase.id}</th>
                                <td>{item.purchase.datepurchase}</td>
                                <td className="text-primary">{item.template.title}</td>
                                <td className="text-success strong">${item.purchase.price}</td>
                            </tr>
                        })}
                        </tbody>
                    </table>
                </>
                :
                <h5>Data not found</h5>
            }
        </div>
    </div>
}

export default TemplateStatistics;
