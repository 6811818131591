import React, {useEffect, useState} from "react";
import {Navigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import API from "../utils/API";
import {login} from "../redux/actions/userActions";

const OneClickLogIn = (props) => {

    const dispatch = useDispatch();
    let { token } = useParams();
    const [loading,setLoading] = useState(true)

    const sign = useSelector(state => {
        return state.user.sign
    });

    useEffect(()=>{
        API.get('/auth/oneclicklogin/'+token).then((response) => {
            dispatch(login(response.data.data));
            setLoading(false)
        }).catch(error => {
            setLoading(false)
        })
    },[])


    return <>{(!loading && (sign)) && <Navigate to="/"/>}</>
}

export default OneClickLogIn;
