import React,{useState,useEffect} from "react";
import Switch from "react-switch";
import {GiPlainCircle} from "react-icons/gi";
import {FaTimes} from "react-icons/fa";
import {MdOutlineEdit} from "react-icons/md";
import {VscCopy} from "react-icons/vsc";
import {HiOutlineTrash} from "react-icons/hi";
import {BiSave,BiMessageRoundedDetail} from "react-icons/bi";
import API from "../../utils/API";
import { confirmAlert } from 'react-confirm-alert';
import {ImHome3} from "react-icons/im";
import {AiOutlineShop} from "react-icons/ai";
import {BsFillLockFill} from "react-icons/bs"
import {useSelector,useDispatch} from "react-redux";
import {set} from "../../redux/actions/pageeditorActions";

const EditPage = ({appId,page,setEditPage,getPages}) => {

    const pageeditor = useSelector(state => {
        return state.pageEditor
    });

    const dispatch = useDispatch()

    const [pageActive,setPageActive] = useState(page.status==="1")
    const [pageName,setPageName] = useState(page.title)
    const [isHomePage,setIsHomePage] = useState(page.isfavorite==="1")
    const [isECommerce,setIsECommerce] = useState(page.isecommerce==="1")
    const [isBlog,setIsBlog] = useState(page.isblog==="1")
    const [isPrivate,setIsPrivate] = useState(page.isprivate==="1")

    const [pageEdit,setPageEdit] = useState(false)

    const savePage = () => {
        API.post('/page/'+appId+'/'+page.id,{title:pageName,status:pageActive?"1":"0",isECommerce,isBlog,isPrivate}).then((response) => {
            if (page.isfavorite!=="1" && isHomePage) {
                API.post('/page/homepage/'+page.id,{isFavorite:1}).then((response) => {
                    getPages()
                    setEditPage(null)
                })
            }else {
                getPages()
                setEditPage(null)
            }
        })
    }

    const removePage = (id) => {
        confirmAlert({
            title: 'Remove page',
            message: 'Are you sure you want to remove this page?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        if (pageeditor.app === appId && pageeditor.page === id) {
                            dispatch(set({app: null, page: null}))
                        }
                        API.delete('/page/'+id).then((response) => {
                            getPages(true)
                            setEditPage(null)
                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    }

    return <div className="editPage">
        <div className="list p-2">
            <div className="block m-2">
                <div className="row m-0">
                    <div className="col-auto">
                        <GiPlainCircle color={pageActive?"#B9D896":"#FFC542"}/>
                    </div>
                    <div className="col pageTitle">
                        {pageEdit ?
                            <input className="form-control" autoFocus style={{height:"25px"}} value={pageName} onChange={(e)=>setPageName(e.target.value)}/>
                            :
                            <>{pageName}</>
                        }
                    </div>
                    <div className="col-auto text-end">
                        <FaTimes className="cursorPointer" onClick={()=>setEditPage(null)}/>
                    </div>
                </div>
            </div>
            <div className="row m-0 mt-4 mb-2">
                <div className="col-3">
                    <div className="block blockShadow actionButton cursorPointer" onClick={()=>setPageEdit(true)}>
                        <MdOutlineEdit className="icon"/><br/>
                        <span className="text">Edit</span>
                    </div>
                </div>
                <div className="col-3">
                    <div className="block blockShadow actionButton cursorPointer">
                        <VscCopy className="icon"/><br/>
                        <span className="text">Duplicate</span>
                    </div>
                </div>
                <div className="col-3">
                    <div className="block blockShadow actionButton cursorPointer" onClick={()=>removePage(page.id)}>
                        <HiOutlineTrash className="icon"/><br/>
                        <span className="text">Remove</span>
                    </div>
                </div>
                <div className="col-3">
                    <div className="block blockShadow actionButton cursorPointer" onClick={()=>savePage()}>
                        <BiSave className="icon"/><br/>
                        <span className="text">Save</span>
                    </div>
                </div>
            </div>
            <div className="m-2 ps-2 blockTitle mt-4">PAGE OPTIONS</div>
            <div className="block m-2 options">
                <div className="row m-0 pageOption cursorPointer" onClick={()=>{
                    if (page.isfavorite!=="1") {
                        setIsHomePage(!isHomePage)
                    }
                }}>
                    <div className="col-2">
                        <div className={`typeBlock ${isHomePage?'activeType':''}`}><ImHome3 className="mt-1"/></div>
                    </div>
                    <div className="col-10 title pt-2">
                        Front page
                    </div>
                </div>
                <hr/>
                <div className="row m-0 pageOption cursorPointer" onClick={()=>{
                    setIsECommerce(!isECommerce)
                }}>
                    <div className="col-2">
                        <div className={`typeBlock ${isECommerce?'activeType':''}`}><AiOutlineShop className="mt-2"/></div>
                    </div>
                    <div className="col-10 title pt-2">
                        E-commerce
                    </div>
                </div>
                <hr/>
                <div className="row m-0 pageOption cursorPointer" onClick={()=>{
                    setIsBlog(!isBlog)
                }}>
                    <div className="col-2">
                        <div className={`typeBlock ${isBlog?'activeType':''}`}><BiMessageRoundedDetail className="mt-2"/></div>
                    </div>
                    <div className="col-10 title pt-2">
                        Blog
                    </div>
                </div>
                <hr/>
                <div className="row m-0 pageOption cursorPointer" onClick={()=>{
                    setIsPrivate(!isPrivate)
                }}>
                    <div className="col-2">
                        <div className={`typeBlock ${isPrivate?'activeType':''}`}><BsFillLockFill className="mt-2"/></div>
                    </div>
                    <div className="col-10 title pt-2">
                        Private
                    </div>
                </div>
            </div>
        </div>
        <div className="bottom">
            <div className="block m-2" style={{height:"50px"}}>
                <div className="row m-0">
                    <div className="col">
                        <span className="settingName">ACTIVE</span>
                    </div>
                    <div className="col-auto pt-1">
                        <Switch onChange={(checked)=>setPageActive(checked)} checked={pageActive} height={19} width={37} checkedIcon={false} uncheckedIcon={false} onColor={"#B0C797"} offColor={"#EEEFF3"}/>
                    </div>
                </div>
            </div>
        </div>
        </div>
}

export default EditPage
