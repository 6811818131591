import React,{useState} from "react";
import {MdAnimation} from "react-icons/md";
import Animation from "../../editor/Animation";
import {bgImage} from "../../helpers/bgImage"
import {useCustomAnimation} from "../../helpers/animation"
import 'animate.css';
import {FiEdit} from "react-icons/fi";
import _ from "lodash";
import Layer from "../../editor/Layer";
import Product from "../../editor/Product";

const Ecomcart = (props) => {

    const [products,setProducts] = useState([
        {
        "name":"IPad Pro",
        "SKU":"ASWRE123",
        "image":"https://gettow.s3.amazonaws.com/image/64180d714ce87.jfif",
        "price":1234.43,
        "quantity":4
        },
        {
            "name":"MacBook Pro",
            "SKU":"KFJGU931",
            "image":"https://gettow.s3.amazonaws.com/image/64180d72ef467.jfif",
            "price":2434.00,
            "quantity":1
        }
    ])

    let total = 0

    const animation = useCustomAnimation(props.data.animation)

    if (props.edit) {
        return <div>
            <div className="editItem">
                <Product name={'productStyle'} setValue={props.setValue} value={props.data.productStyle} featureName="Product style"/>
            </div>
            <div className="editItem">
                <Layer name={'blockStyle'} setValue={props.setValue} value={props.data.blockStyle} featureName="Block style"/>
            </div>
            <div className="editItem">
                <Animation name={'animation'} setValue={props.setValue} value={props.data.animation} featureName="Animation" imageName={<MdAnimation style={{fontSize:"18px"}}/>}/>
            </div>
        </div>
    }else {
        return <div style={_.merge({}, bgImage(props?.data?.image)??{})} className={animation}>
            <div className="m-0 mt-2">
                {products.map((product,key)=>{
                    total = total + product.price*product.quantity
                    return (<><div className="row">
                        <div className="col-3">
                            <img src={product.image} className="img-fluid" style={{borderRadius:"10px"}}/>
                        </div>
                        <div className="col-6">
                            <h5>{product.name}</h5>
                            <h6>${product.price}</h6>
                            <h6>{product.quantity} items</h6>
                            <p>{product.SKU}</p>
                        </div>
                        <div className="col-3 text-right">
                            <FiEdit/>
                        </div>
                    </div><hr/></>)
                })}
                <div className="text-center">
                    <h4>Total: ${total}</h4>
                    <a className="btn btn-success rounded-5">Proceed to checkout</a>
                </div>
            </div>
        </div>
    }
}

export default Ecomcart
