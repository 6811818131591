import React from "react";
import OnOffFeature from "../../editor/OnOffFeature";
import Button from "../../editor/Button";
import InlineEditableText from "../../InlineEditableText";
import Image from "../../editor/Image";
import {BsImage} from "react-icons/bs";
import {MdAnimation} from "react-icons/md";
import Animation from "../../editor/Animation";
import {bgImage} from "../../helpers/bgImage"
import {buttonStyle} from "../../helpers/buttonStyle"
import {useCustomAnimation} from "../../helpers/animation"
import 'animate.css';
import ColorPicker from "../../editor/subelements/ColorPicker";

const Ecomproduct = (props) => {
    const animation = useCustomAnimation(props.data.animation)

    if (props.edit) {
        return <div>
            <div className="editItem">
                <OnOffFeature name={'titleEnabled'} setValue={props.setValue} value={props.data.titleEnabled} featureName="Title" imageName="h1"/>
            </div>
            <div className="editItem">
                <OnOffFeature name={'descriptionEnabled'} setValue={props.setValue} value={props.data.descriptionEnabled} featureName="Description" imageName="p"/>
            </div>
            <div className="subElement">
                <ColorPicker name={"titleColor"} value={props.data?.titleColor??"black"} setValue={props.setValue} featureName={"Title color"}/>
            </div>
            <div className="subElement">
                <ColorPicker name={"descriptionColor"} value={props.data?.descriptionColor??"black"} setValue={props.setValue} featureName={"Description color"}/>
            </div>
            <div className="subElement">
                <ColorPicker name={"priceColor"} value={props.data?.priceColor??"black"} setValue={props.setValue} featureName={"Price color"}/>
            </div>
            <div className="editItem">
                <Animation name={'animation'} setValue={props.setValue} value={props.data.animation} featureName="Animation" imageName={<MdAnimation style={{fontSize:"18px"}}/>}/>
            </div>
        </div>
    }else {
        return <div style={bgImage(props?.data?.image)??{}} className={animation}>
            {props.data.titleEnabled === "on" &&
                <InlineEditableText isResult={true} setValue={props.setValue} name="title">{props.data.title}</InlineEditableText>
            }
            {props.data.descriptionEnabled === "on" &&
                <InlineEditableText isResult={true} setValue={props.setValue} name="description">{props.data.description}</InlineEditableText>
            }
            {props.data.paragraphEnabled === "on" &&
                <InlineEditableText isResult={true} setValue={props.setValue} name="paragraph">{props.data.paragraph}</InlineEditableText>
            }
            {props?.data?.button?.enabled === "on" &&
                <div className="text-center"><button style={buttonStyle(props?.data?.button)}><InlineEditableText isResult={true} setValue={props.setValue} name="buttonText">{props.data.buttonText}</InlineEditableText></button></div>
            }
        </div>
    }
}

export default Ecomproduct
