import Hero from "./general/Hero";
import Images from "./general/Images";
import Video from "./general/Video";
import InstagramFeed from "./social/InstagramFeed";
import TwitterFeed from "./social/TwitterFeed";
import FacebookPage from "./social/FacebookPage";
import FacebookGroup from "./social/FacebookGroup";
import YelpReviews from "./review/YelpReviews";
import FacebookReviews from "./review/FacebookReviews";
import GoogleReviews from "./review/GoogleReviews";
import AirbnbReviews from "./review/AirbnbReviews";
import GoogleMap from "./general/GoogleMap";
import Rssfeed from "./general/Rssfeed";
import Photogallery from "./general/Photogallery";
import Restaurantmenu from "./general/Restaurantmenu";
import LogoShowCase from "./general/LogoShowCase";
import FAQ from "./general/FAQ";
import TeamShowcase from "./general/TeamShowcase";
import SocialLinks from "./general/SocialLinks";
import Text from "./general/Text";
import Header from "./general/Header";
import Ecomproduct from "./ecommerce/Ecomproduct";
import Ecomcategory from "./ecommerce/Ecomcategory";
import Ecomcategories from "./ecommerce/Ecomcategories";
import Ecomcart from "./ecommerce/Ecomcart";
import Ecomsearch from "./ecommerce/Ecomsearch";
import Ecomcheckout from "./ecommerce/Ecomcheckout";
import Blog from "./general/Blog";
import PopUp from "./general/PopUp";
import SchedulerBlock from "./general/SchedulerBlock";
import Form from "./general/Form";
import Embed from "./general/Embed";
import Splashscreen from "./general/Splashscreen";

export const build = (slug) => {
    switch (slug?.toLowerCase()) {
        case 'hero':
            return <Hero/>
        case 'images':
            return <Images/>
        case 'video':
            return <Video/>
        case 'instagramfeed':
            return <InstagramFeed/>
        case 'twitterfeed':
            return <TwitterFeed/>
        case 'facebookpage':
            return <FacebookPage/>
        case 'facebookgroup':
            return <FacebookGroup/>
        case 'yelpreviews':
            return <YelpReviews/>
        case 'facebookreviews':
            return <FacebookReviews/>
        case 'googlereviews':
            return <GoogleReviews/>
        case 'airbnbreviews':
            return <AirbnbReviews/>
        case 'googlemap':
            return <GoogleMap/>
        case 'rssfeed':
            return <Rssfeed/>
        case 'photogallery':
            return <Photogallery/>
        case 'restaurantmenu':
            return <Restaurantmenu/>
        case 'logoshowcase':
            return <LogoShowCase/>
        case 'faq':
            return <FAQ/>
        case 'teamshowcase':
            return <TeamShowcase/>
        case 'social':
            return <SocialLinks/>
        case 'text':
            return <Text/>
        case 'header':
            return <Header/>
        case 'ecomproduct':
            return <Ecomproduct/>
        case 'ecomcategory':
            return <Ecomcategory/>
        case 'ecomcategories':
            return <Ecomcategories/>
        case 'ecomcart':
            return <Ecomcart/>
        case 'ecomsearch':
            return <Ecomsearch/>
        case 'ecomcheckout':
            return <Ecomcheckout/>
        case 'blog':
            return <Blog/>
        case 'popup':
            return <PopUp/>
        case 'scheduler':
            return <SchedulerBlock/>
        case 'form':
            return <Form/>
        case 'embed':
            return <Embed/>
        case 'splashscreen':
            return <Splashscreen/>
        case undefined:
            return <></>
        default:
            return <>blank</>
    }
}
