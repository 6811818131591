import React from "react"

const ReviewItemFacebookView = ({review,data}) => {

    return <>
        <div className="row">
            <div className="col">
                {review.reviewer}<br/>
                {review.datetime.substring(0, 10)}<br/>
            </div>
        </div>
        {review.text}
    </>
}

export default ReviewItemFacebookView
