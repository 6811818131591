import React from "react";
import Sidebar from "../components/Sidebar";
import "../assets/style/dashboard.scss"
import {Helmet} from "react-helmet";
import Header from "../components/Header";

const Main = ({ children }) => {
    return (
        <div className="container-fluid appBuilder">
            <Helmet>
                <link rel="stylesheet" href={`/css/main.css`} />
            </Helmet>
            <div className="row">
            <Sidebar/>
                <main className="col p-4" style={{marginLeft:"88px"}}>
                    <div className="pt-3 ps-5 pe-5">
                        <Header component={children}/>
                        <div className="pt-5">
                            {children}
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default Main
