import * as types from "../constants";

export function set(data) {
    return {
        type: types.EDIT_PAGE,
        payload: data
    };
}

export function setContent(data) {
    return {
        type: types.EDIT_PAGE_CONTENT,
        payload: data
    };
}

export function reset() {
    return {
        type: types.RESET_PAGE,
        payload: null
    };
}
