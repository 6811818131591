import React from "react";
import Slider from 'react-input-slider';

const SliderInput = ({setValue, name, value, featureName, min, max, type}) => {
    return <div className="slider">
        <div className="row">
            <div className="col">
                <span className="blockTitle">{featureName}</span>
            </div>
        </div>
        <div className="row ps-1">
            <div className="col">
                <Slider
                    styles={{
                        active: {
                            backgroundColor: '#69B2FF'
                        },
                        thumb: {
                            width: 20,
                            height: 20,
                            backgroundColor: '#69B2FF'
                        },
                        disabled: {
                            opacity: 0.5
                        }
                    }}
                    xmax={max} xmin={min} xstep={1} axis="x" x={value??0} onChange={({ x }) => {setValue(name,x)}} />
            </div>
            <div className="col-auto blockTitle">
                {value??0} {type??'px'}
            </div>
        </div>
    </div>
}

export default SliderInput
