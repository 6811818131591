import React, {useState} from "react";
import {TbDots} from "react-icons/tb";
import API from "../../utils/API";

const Transactions = () => {

    const [transactions, setTransactions] = useState(null)

    const loadTransactions = () => {
        API.get('/billing/transactions').then(res => {
            setTransactions(res.data.data)
        })
    }

    useState(()=>{
        loadTransactions()
    },[])

    return (
        <div className="mt-4 ms-3 me-5">
            {transactions && transactions.length > 0 &&
            <div className="table-responsive mt-3">
                <table className="list w-100">
                    <tr>
                        <th>ID</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Amount</th>
                        <th>Note</th>
                    </tr>
                    {transactions.map(transaction => (
                        <tr key={transaction.billingpayments.id}>
                            <td>{transaction.billingpayments.id}</td>
                            <td>{transaction.billingpayments.dateadd}</td>
                            <td><span className="statusActive">Paid</span></td>
                            <td>${1*transaction.billingpayments.amount/100}</td>
                            <td>{transaction.billingpayments.note}</td>
                        </tr>
                        ))}
                </table>
            </div>
            }
        </div>
    )
}

export default Transactions
