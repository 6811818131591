import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {useIsTablet} from "../../../hooks/useIsTablet";
import {set} from "../../../redux/actions/pageeditorActions";
import API from "../../../utils/API";
import useClickOutside from "click-outside-hook";
import {BsFiles, BsFillLockFill, BsThreeDotsVertical} from "react-icons/bs";
import EditPage from "../../../components/editpage/Editpage";
import {MdKeyboardArrowDown, MdKeyboardArrowUp} from "react-icons/md";
import {GiPlainCircle} from "react-icons/gi";
import {AiOutlineHome, AiOutlinePlusCircle, AiOutlineShop} from "react-icons/ai";
import {BiMessageRoundedDetail} from "react-icons/bi";
import Modal from "react-modal";
import {FaLock} from "react-icons/fa";

const PagesSelect = () => {
    const [pageList,setPageList] = useState(null)

    const dispatch = useDispatch()

    let navigate = useNavigate()

    const pageeditor = useSelector(state => {
        return state.pageEditor
    })

    const isTablet = useIsTablet()

    const [activePage,setActivePage] = useState(null)
    const [editorPage,setEditorPage] = useState(null)

    const [newPage, setNewPage] = useState(false)

    const [newPageName,setNewPageName] = useState(null)
    const [newPageDescription,setNewPageDescription] = useState(null)

    const [editPage,setEditPage] = useState(null)

    let { appid } = useParams()

    const setHomePage = (list) => {
        if (list && list.length > 0) {
            let i,n = list.length
            for (i=0; i<n; i++) {
                if (list[i].isfavorite === "1") {
                    dispatch(set({app:appid,page:list[i].id}))
                }
            }
        }
    }

    useEffect(()=>{
        if (pageeditor.app && pageeditor.page && pageeditor.app === appid && pageeditor.page !== editorPage) {
            setEditorPage(pageeditor.page)
        }else if (pageeditor.page !== editorPage) {
            setEditorPage(null)
            setHomePage(pageList)
        }
    },[pageeditor,appid])

    const selectPage = (page) => {
        setActivePage(page)
        API.get('/pagedata/'+page).then((response) => {
            dispatch(set({app:appid,page:page,sections:response.data.data.data,editType:null}))
        })
    }

    const getPages = (initLoad=false) => {
        API.get('/page/getall/'+appid).then((response) => {
            setPageList(response.data.data)
            if (initLoad && pageeditor.app !== appid) {
                if (response.data.data.length) {
                    selectPage(response.data.data[0].id)
                }
            }
        })
    }

    useEffect(()=>{
        if (appid) {
            getPages(true);
        }
    },[appid])

    const ref = useClickOutside(() => {
        setActivePage(null)
    })

    const move = (index, delta) => {
        let array = pageList
        let newIndex = index + delta;
        if (newIndex < 0 || newIndex === array.length) return;
        let indexes = [index, newIndex].sort((a, b) => a - b);
        array.splice(indexes[0], 2, array[indexes[1]], array[indexes[0]]);

        let positions = [],topPosition = array.length,i
        for (i = 0; i < array.length; i++) {
            positions.push({
                id: array[i].id,
                position: topPosition
            })
            topPosition--
        }

        API.put('/page/saveposition/'+appid,positions).then((response) => {
            getPages()
        })
    }

    const moveUp = (index) => {
        move(index, -1)
    }

    const moveDown = (index) => {
        move(index, 1)
    }

    const createNewPage = () => {
        if (newPageName && newPageName.length > 0) {
            API.post('/page/'+appid,{title:newPageName,description:newPageDescription}).then((response) => {
                getPages()
                setNewPageName(null)
                setNewPageDescription(null)
                setNewPage(false)
                selectPage(response.data.data.id)
                navigate('/app/'+appid+'/blocks');
            })
        }
    }

    return (
        <div className="pages manageBlock">
            <div className="titleBlock">
                <div className="title">
                    <div className="row">
                        <div className="col-3 iconBlock">
                            <BsFiles className="icon"/>
                        </div>
                        <div className="col-9 name">
                            pages
                        </div>
                    </div>
                </div>
            </div>

            {
                editPage ?
                    <EditPage page={editPage} setEditPage={setEditPage} getPages={getPages} appId={appid}/>
                    :
                    <>
                        <div className="list pt-2 pb-2">
                            <div ref={ref}>
                                {pageList &&
                                    pageList.map((pageItem,key)=>{
                                        return <div onClick={()=>{
                                            selectPage(pageItem.id)
                                        }} className={`row me-2 ms-2 pt-2  pb-2 pageBlock cursorPointer ${activePage===pageItem.id?`active`:`justify-content-center`}`} key={key}>
                                            {
                                                (activePage===pageItem.id && pageItem.issplashscreen !== "1") &&
                                                <div className="col-2">
                                                    <div className="text-center">
                                                        <MdKeyboardArrowUp
                                                            style={{fontSize:"24px"}}
                                                            onClick={()=>{
                                                                if (key > 1) {
                                                                    moveUp(key)
                                                                }
                                                            }}
                                                            color={key > 1?"#707070":"D4D3D2"}
                                                        />
                                                    </div>
                                                    <div className="text-center">
                                                        <MdKeyboardArrowDown
                                                            style={{fontSize:"24px"}}
                                                            onClick={()=>{
                                                                if (key < pageList.length - 1) {
                                                                    moveDown(key)
                                                                }
                                                            }}
                                                            color={key < pageList.length - 1?"#707070":"D4D3D2"}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            <div className={`col-1 ${activePage===pageItem.id?`pt-2`:``}`}>
                                                <GiPlainCircle color={pageItem.status==="1"?"#B9D896":"#FFC542"}/>
                                            </div>
                                            <div className={`col pageTitle ${activePage===pageItem.id?`pt-2`:``} ${(activePage===pageItem.id || editorPage===pageItem.id)?`pageTitleActive`:``}`}>
                                                {pageItem.title}
                                            </div>
                                            {pageItem.issplashscreen !== "1" &&
                                            <>
                                            <div className={`col text-end ${activePage===pageItem.id?`pt-2`:``}`}>
                                                {
                                                    pageItem.isfavorite === "1" &&
                                                    <AiOutlineHome style={{fontSize:"24px"}} color={"#B9D896"}/>
                                                }
                                                {
                                                    pageItem.isecommerce === "1" &&
                                                    <AiOutlineShop style={{fontSize:"24px"}} color={"#B9D896"}/>
                                                }
                                                {
                                                    pageItem.isblog === "1" &&
                                                    <BiMessageRoundedDetail style={{fontSize:"24px"}} color={"#B9D896"}/>
                                                }
                                                {
                                                    pageItem.isprivate === "1" &&
                                                    <BsFillLockFill style={{fontSize:"24px"}} color={"darkred"}/>
                                                }
                                            </div>
                                            {
                                                activePage===pageItem.id &&
                                                <div className="col-auto text-end pt-2">
                                                    <BsThreeDotsVertical style={{fontSize:"34px"}} onClick={()=>setEditPage(pageItem)}/>
                                                </div>
                                            }
                                            </>
                                            }
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                        <div className="bottom">
                            <div className="title">
                                <div className="row cursorPointer" onClick={()=>setNewPage(true)}>
                                    <div className="col-4 iconBlock">
                                        <AiOutlinePlusCircle className="icon"/>
                                    </div>
                                    <div className="col-8 name">
                                        Add New Page
                                    </div>
                                </div>
                                <Modal
                                    isOpen={newPage}
                                    className="modal-window newPageModal"
                                    overlayClassName="modal-overlay"
                                    onRequestClose={()=>setNewPage(false)}
                                >
                                    <div>
                                        <div className="modalTitle row justify-content-center">
                                            <div className="col-auto icon">
                                                <FaLock/>
                                            </div>
                                            <div className="col-auto text pt-2">
                                                Create new page
                                            </div>
                                        </div>
                                        <div className="row justify-content-center mt-5">
                                            <div className="col-10">
                                                <input className="form-control" placeholder="Page name" onChange={(e) => {setNewPageName(e.target.value)}}/>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center mt-2 mb-5">
                                            <div className="col-10">
                                                <input className="form-control" placeholder="Page description" onChange={(e) => {setNewPageDescription(e.target.value)}}/>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center mb-2">
                                            <div className="col-auto">
                                                <div className="button pt-2" onClick={()=>createNewPage()}>Create A New Page</div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                        </div>
                    </>
            }
        </div>
    )
}

export default PagesSelect;
