import React,{useState,useEffect} from "react";
import {useSelector} from "react-redux";

export const useCustomAnimation = (animationObject) => {
    const [animation, setAnimation] = useState(false);

    useEffect(() => {
        setAnimation(true)
        const timer = setTimeout(() => setAnimation(false), 2000);
    }, [animationObject]);

    const pageeditor = useSelector(state => {
        return state.pageEditor
    });

    if (animationObject?.enabled==="on") {
        return `position-relative ${!pageeditor.textInstanceId?"z-index-1":""} animate__animated ${(!pageeditor.textInstanceId && animation)?animationObject.type:""}`
    }else {
        return "";
    }

}
