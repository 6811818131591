import React from "react";
import SliderInput from "../../editor/subelements/SliderInput";
import Animation from "../../editor/Animation";
import {MdAnimation} from "react-icons/md";
import {useCustomAnimation} from "../../helpers/animation";
import ColorPicker from "../../editor/subelements/ColorPicker";
import Spacing from "../../editor/subelements/Spacing";
import {bgImage} from "../../helpers/bgImage";
import Elements from "../../editor/Elements";
import FAQItem from "../../editor/FAQItem";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';

const FAQ = (props) => {
    const animation = useCustomAnimation(props.data.animation)

    if (props.edit) {
        return <div>
            <div className="editItem p-2">
                <div className="subElement subElementNoBorderTop">
                    <Elements name={"questions"} setValue={props.setValue} value={props.data?.questions} featureName={"Questions"} itemComponent={<FAQItem/>}/>
                </div>
                <div className="subElement">
                    <ColorPicker name={"bgColor"} value={props.data?.bgColor} setValue={props.setValue} featureName={"Background color"}/>
                </div>
                <div className="subElement">
                    <Spacing name={"margin"} value={props.data?.margin} setValue={props.setValue} featureName={"Margin"}/>
                </div>
                <div className="subElement">
                    <Spacing name={"padding"} value={props.data?.padding} setValue={props.setValue} featureName={"Padding"}/>
                </div>
                <div className="subElement">
                    <Spacing name={"borderRadius"} value={props.data?.borderRadius} setValue={props.setValue} featureName={"Border radius"}/>
                </div>
                <div className="subElement">
                    <ColorPicker name={"borderColor"} value={props.data?.borderColor} setValue={props.setValue} featureName={"Border color"}/>
                </div>
                <div className="subElement">
                    <SliderInput name={"borderWidth"} value={props.data?.borderWidth} setValue={props.setValue} featureName={"Border width"} min={0} max={20}/>
                </div>
            </div>
            <div className="editItem">
                <Animation name={'animation'} setValue={props.setValue} value={props.data.animation} featureName="Animation" imageName={<MdAnimation style={{fontSize:"18px"}}/>}/>
            </div>
        </div>
    }else {
        return <div style={bgImage(props?.data)??{}} className={animation}>
            {(props.data?.questions?.list && props.data?.questions?.list?.length > 0) &&
                <Accordion>
                    {props.data?.questions?.list.map((questionsItem,questionsItemKey)=>{
                        return (<AccordionItem key={questionsItemKey}>
                            <AccordionItemHeading>
                                <AccordionItemButton>{questionsItem.question}</AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>{questionsItem.answer}</p>
                            </AccordionItemPanel>
                        </AccordionItem>)
                    })}
                </Accordion>
            }
        </div>
    }
}

export default FAQ
