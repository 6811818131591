import React,{useState,useEffect} from "react";
import "../assets/style/mainpage.scss"
import "../assets/style/notifications.scss"
import Modal from "react-modal";
import DateTimePicker from 'react-datetime-picker';
import API from "../utils/API";
import {useNavigate} from "react-router-dom";

const Message = () => {
    const [newMessage, setNewMessage] = useState(false)
    const [scheduleDate, setScheduleDate] = useState(null);
    const [title, setTitle] = useState(null)
    const [content, setContent] = useState(null)
    const [app, setApp] = useState(null)
    const [apps, setApps] = useState(null)
    const [pages, setPages] = useState(null)
    const [page, setPage] = useState(null)

    const [messages,setMessages] = useState(null)

    let navigate = useNavigate()

    useEffect(()=>{
        API.get('/app/getall').then((response) => {
            setApps(response.data.data)
        })
        loadMessages()
    },[])

    useEffect(()=>{
        if (app) {
            API.get(`/page/getall/${app}`).then((response) => {
                setPages(response.data.data)
                setPage(null)
            })
        }else {
            setPages(null)
            setPage(null)
        }
    },[app])

    const loadMessages = () => {
        API.get('/pushnotification/all').then((response) => {
            setMessages(response.data.data)
        })
    }

    const createMessage = () => {
        if (title && content && app) {
            API.post('/pushnotification',{app:app,title:title,content:content,schedule:scheduleDate,page}).then((response) => {
                setScheduleDate(null)
                setApp(null)
                setTitle(null)
                setContent(null)
                setNewMessage(false)
                setPage(null)
                loadMessages()
            })
        }
    }

    return (
        <div className="mainPage notifications">
            <div className="hero">
                <span className="title">Notifications</span>
                <div className="subTitle">Looking for some solutions, check our FAQ <span className="simpleButton ps-3 pt-1 pb-1 pe-3" onClick={(e)=>navigate('/support')}>Support</span></div>
            </div>
            {(messages && messages.length > 0) &&
            <div className="messagesList mt-4">
                <div className="header">
                    <span className="title">NOTIFICATIONS LIST</span>
                </div>
                <table className="table mt-3">
                    <tr className="border-bottom">
                        <td className="p-2"><strong className="fs-6">Date add</strong></td>
                        <td className="p-2"><strong className="fs-6">Date Schedule</strong></td>
                        <td className="p-2"><strong className="fs-6">App</strong></td>
                        <td className="p-2"><strong className="fs-6">Page</strong></td>
                        <td className="p-2"><strong className="fs-6">Status</strong></td>
                        <td className="p-2"><strong className="fs-6">Title</strong></td>
                        <td className="p-2"><strong className="fs-6">Content</strong></td>
                    </tr>
                    {messages.map((message,key)=>{
                        return <tr className="border-bottom">
                            <td className="fs-6 p-2">{message.pushnotification.dateadd}</td>
                            <td className="fs-6 p-2">{message.pushnotification.schedule}</td>
                            <td className="fs-6 p-2">{message.app.title}</td>
                            <td className="fs-6 p-2">{message?.page?.title}</td>
                            <td className="fs-6 p-2"><nobr><span className="statusScheduled">&#x2022; Scheduled</span></nobr></td>
                            <td className="fs-6 p-2 text-wrap">{message.pushnotification.title}</td>
                            <td className="fs-6 p-2 text-wrap">{message.pushnotification.content}</td>
                        </tr>
                    })}
                </table>
            </div>
            }
            <div className="row justify-content-end mt-5">
                <div className="col-auto">
                    <span className="button p-3" onClick={()=>setNewMessage(true)}>New Notification</span>
                    <Modal
                        isOpen={newMessage}
                        className="modal-window newMessageModal"
                        overlayClassName="modal-overlay"
                        onRequestClose={()=>setNewMessage(false)}
                    >
                        <div>
                            <h2>New message</h2>
                            <div className="row justify-content-center mt-3">
                                <div className="col-12">
                                    <label>App</label>
                                    {apps &&
                                    <select className="form-control" onChange={(e)=>setApp(e.target.value)}>
                                        <option>Select app...</option>
                                        {apps.map((appItem,key)=>{
                                            return <option key={key} value={appItem.id}>{appItem.title}</option>
                                        })}
                                    </select>
                                    }
                                </div>
                            </div>
                            {pages &&
                            <div className="row justify-content-center mt-2">
                                <div className="col-12">
                                    <label>Page (optional)</label>
                                        <select className="form-control" onChange={(e)=>setPage(e.target.value)}>
                                            <option>Select page...</option>
                                            {pages.map((pageItem,key)=>{
                                                if (pageItem.issplashscreen !== '1') {
                                                    return <option key={key}
                                                                   value={pageItem.id}>{pageItem.title}</option>
                                                }
                                            })}
                                        </select>
                                </div>
                            </div>
                            }
                            <div className="row justify-content-center mt-2">
                                <div className="col-12">
                                    <label>Title</label>
                                    <input className="form-control" onChange={(e)=>setTitle(e.target.value)}/>
                                </div>
                            </div>
                            <div className="row justify-content-center mt-2">
                                <div className="col-12">
                                    <label>Content</label>
                                    <textarea className="form-control" rows={4} onChange={(e)=>setContent(e.target.value)}/>
                                </div>
                            </div>
                            <div className="row justify-content-center mt-2 mb-5">
                                <div className="col-12">
                                    <label>Schedule (optional)</label>
                                    <DateTimePicker onChange={setScheduleDate} value={scheduleDate} className="scheduleSelect"/>
                                </div>
                            </div>
                            <div className="row justify-content-end mb-2">
                                <div className="col-auto">
                                    <div className="buttonSend" onClick={()=>createMessage()}>Send</div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
        </div>
    )
}

export default Message
