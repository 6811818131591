import React,{useEffect,useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import "../../../assets/style/teamManagement.scss"
import API from "../../../utils/API";

const AppTeam = () => {
    let { appid } = useParams()
    const [team, setTeam] = useState(null)
    let navigate = useNavigate()

    useEffect(() => {
        API.get(`/team/all/${appid}`).then((response) => {
            setTeam(response.data.data)
        })
    },[])

    return <div className="teamManagement">
        {(team && team.length > 0) &&
        <table className="table table-borderless products">
            <thead>
            <tr>
                <th>User</th>
                <th>Level</th>
                <th>Status</th>
                <th>Email</th>
            </tr>
            </thead>
            <tbody>
            {team.map((item, index) => {
                return (
                    <tr key={index}>
                        <td style={{fontSize: "17px",fontWeight: "bold"}}>{item.name}</td>
                        <td>{item.role}</td>
                        <td>{item.status === "1" && <span className="badge bg-success m-1">Active</span>}</td>
                        <td>{item.email}</td>
                    </tr>
                )
            })}
            </tbody>
        </table>
        }
        <div className="row justify-content-end pb-3 m-5">
            <div className="col-auto">
                <button className="btn buttonSave" onClick={(e)=>navigate(`/app/${appid}/team/create`)}>New User</button>
            </div>
        </div>
    </div>
}

export default AppTeam
