import React,{useState,useEffect} from "react";
import {useParams} from "react-router-dom";
import API from "../utils/API";
import {build} from "../components/builder/blocks/BlockFactory";
import {setAppMode} from "../redux/actions/appActions"
import {useDispatch} from "react-redux";
import _ from "lodash";
import NativeSignIn from "./nativeapp/NativeSignIn";
import registrationGradient from "../assets/image/registrationgradient.jpg";

const NativeApp = () => {
    let { id } = useParams();
    const [data,setData] = useState(null)
    const [currentPage,setCurrentPage] = useState(null)
    const [homePage,setHomePage] = useState(null)
    const [loadingScreen,setLoadingScreen] = useState(null)

    const [authenticated,setAuthenticated] = useState(false)

    const [signInProcess,setSignInProcess] = useState(false)

    const [prevPage, setPrevPage] = useState(null)

    useEffect(()=>{
        if (localStorage.getItem('token')) {
            setAuthenticated(true);
        }
    },[]);

    const dispatch = useDispatch()

    const moveToHomePage = (homePageId) => {
        setLoadingScreen(null);
        setCurrentPage(homePageId);
    }

    useEffect(()=>{
        async function loadData() {
            dispatch(setAppMode())

            if (id) {
                API.get('/api/data/'+id).then(async(response) => {
                    if (response.data.data && response.data.data.length > 0) {
                        let i,n = response.data.data.length, isHomePage = false, isSplashScreen = false, homePageId, splashScreenId;

                        for (i=0; i<n; i++) {
                            if (response.data.data[i].page.isfavorite === "1") {
                                setHomePage(response.data.data[i].page.id);
                                isHomePage = true;
                                homePageId = response.data.data[i].page.id;
                            }

                            if (response.data.data[i].page.issplashscreen === "1" && !_.isEmpty(response.data.data[i].data)) {
                                setLoadingScreen(response.data.data[i].data);
                                splashScreenId = response.data.data[i].page.id;
                                isSplashScreen = true;

                                setTimeout(()=>moveToHomePage(homePageId), 3000);
                            }
                        }

                        if (isSplashScreen) {
                            setCurrentPage(splashScreenId);
                        }else if (isHomePage) {
                            setCurrentPage(homePageId);
                        }

                        if (!isHomePage) {
                            setHomePage(response.data.data[0].page.id);
                            if (!isSplashScreen) {
                                setCurrentPage(response.data.data[0].page.id);
                            }
                        }
                    }

                    setData(response.data.data);
                })
            }
        }
        loadData();
    },[id])

    const abortSignIn = () => {
        setSignInProcess(false);
        setCurrentPage(prevPage);
    }

    const loadPage = (pageId) => {
        const page = data.find(pageItem => parseInt(pageItem.page.id) === parseInt(pageId));

        if (page && parseInt(page.page.isprivate) === 1 && !authenticated) {
            setSignInProcess(true);
        }

        setPrevPage(currentPage);
        setCurrentPage(pageId);
    }

    const handleClick = (e) => {
        if (e.appLink &&  e.appLink.includes('internallink')) {
            let pageId = e.appLink.split("internallink")[1];
            loadPage(pageId);
            e.preventDefault();
            e.stopPropagation();
            return false;
        }
        if (e.target.href) {
            if (e.target.href.includes('internallink')) {
                let pageId = e.target.href.split("internallink")[1];
                loadPage(pageId);
                e.preventDefault();
                e.stopPropagation();
                return false;
            }
        }
    }

    return (
        <>
            {(!authenticated && signInProcess) ?
                <div className="p-0 m-0" style={{
                    overflowX:"hidden",
                    backgroundImage: `url(${registrationGradient})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition : "center",
                    backgroundSize: "cover",
                    height: "100vh"
                }}>
                    <NativeSignIn appId={id} setAuthenticated={setAuthenticated} abortSignIn={abortSignIn}/>
                </div>
                :
            <div className="p-0 m-0" id="popup-root" style={{overflowX:"hidden"}} onClick={(e)=>handleClick(e)}>
                {(data !== null && currentPage) &&
                    <>
                        {loadingScreen ?
                            <div style={{height:"100vh",width:"100vw"}}>
                                {loadingScreen.map((section,key)=>{
                                    return <div className={`row p-0 h-100 m-0`} key={key}>
                                        {section.elements.map((element,elementKey)=>{
                                            return <div key={elementKey} className={`h-100 m-0 p-0 col-${12/(1*section.elements.length)}`}>
                                                <div className="h-100">
                                                    {React.cloneElement(build(element.component),{...element})}
                                                </div>
                                            </div>
                                        })
                                        }
                                    </div>
                                })}
                            </div>
                            :
                            <>
                            {data.map((dataElem,dataKeyElem)=>{
                                if (dataElem.page.id === currentPage && dataElem.data?.length > 0) {
                                    return <div key={dataKeyElem}>
                                        {dataElem.data.map((section,key)=>{
                                            return <div className={`row p-0 row-eq-height m-0`} key={key}>
                                                {section.elements.map((element,elementKey)=>{
                                                    return <div key={elementKey} className={`m-0 p-0 col-${12/(1*section.elements.length)}`}>
                                                        <div className="h-100">
                                                            {React.cloneElement(build(element.component),{...element,appId:id})}
                                                        </div>
                                                    </div>
                                                })
                                                }
                                            </div>
                                        })}
                                    </div>
                                }
                            })}
                            </>
                        }
                    </>
                }
            </div>
            }
        </>
    )
}

export default NativeApp
