import React from "react";
import SliderInput from "../../editor/subelements/SliderInput";
import Animation from "../../editor/Animation";
import {MdAnimation} from "react-icons/md";
import {useCustomAnimation} from "../../helpers/animation";
import ColorPicker from "../../editor/subelements/ColorPicker";
import Spacing from "../../editor/subelements/Spacing";
import {bgImage} from "../../helpers/bgImage";
import TextInput from "../../editor/subelements/TextInput";
import {MdFacebook,MdOutlineMail} from "react-icons/md"
import {ImTwitter} from "react-icons/im"
import {SiInstagram,SiPinterest,SiTiktok} from "react-icons/si"

const SocialLinks = (props) => {

    const animation = useCustomAnimation(props.data.animation)

    if (props.edit) {
        return <div>
            <div className="editItem p-2">
                <div className="subElement subElementNoBorderTop">
                    <TextInput name={"facebook"} value={props.data?.facebook} setValue={props.setValue} featureName={"Facebook url"}/>
                </div>
                <div className="subElement">
                    <ColorPicker name={"facebookColor"} value={props.data?.facebookColor??'#4267B2'} setValue={props.setValue} featureName={"Facebook color"}/>
                </div>
                <div className="subElement">
                    <TextInput name={"twitter"} value={props.data?.twitter} setValue={props.setValue} featureName={"Twitter url"}/>
                </div>
                <div className="subElement">
                    <ColorPicker name={"twitterColor"} value={props.data?.twitterColor??'#1DA1F2'} setValue={props.setValue} featureName={"Twitter color"}/>
                </div>
                <div className="subElement">
                    <TextInput name={"instagram"} value={props.data?.instagram} setValue={props.setValue} featureName={"Instagram url"}/>
                </div>
                <div className="subElement">
                    <ColorPicker name={"instagramColor"} value={props.data?.instagramColor??'#3f729b'} setValue={props.setValue} featureName={"Instagram color"}/>
                </div>
                <div className="subElement">
                    <TextInput name={"pinterest"} value={props.data?.pinterest} setValue={props.setValue} featureName={"Pinterest url"}/>
                </div>
                <div className="subElement">
                    <ColorPicker name={"pinterestColor"} value={props.data?.pinterestColor??'#E60023'} setValue={props.setValue} featureName={"Pinterest color"}/>
                </div>
                <div className="subElement">
                    <TextInput name={"tiktok"} value={props.data?.tiktok} setValue={props.setValue} featureName={"Tiktok url"}/>
                </div>
                <div className="subElement">
                    <ColorPicker name={"tiktokColor"} value={props.data?.tiktokColor??'#000000'} setValue={props.setValue} featureName={"Tiktok color"}/>
                </div>
                <div className="subElement">
                    <TextInput name={"email"} value={props.data?.email} setValue={props.setValue} featureName={"Email"}/>
                </div>
                <div className="subElement">
                    <ColorPicker name={"emailColor"} value={props.data?.emailColor??'#006ee6'} setValue={props.setValue} featureName={"Email color"}/>
                </div>
                <div className="subElement">
                    <SliderInput name={"iconSize"} value={props.data?.iconSize??40} setValue={props.setValue} featureName={"Icon size"} min={10} max={100}/>
                </div>
                <div className="subElement">
                    <ColorPicker name={"bgColor"} value={props.data?.bgColor} setValue={props.setValue} featureName={"Background color"}/>
                </div>
                <div className="subElement">
                    <Spacing name={"margin"} value={props.data?.margin} setValue={props.setValue} featureName={"Margin"}/>
                </div>
                <div className="subElement">
                    <Spacing name={"padding"} value={props.data?.padding} setValue={props.setValue} featureName={"Padding"}/>
                </div>
                <div className="subElement">
                    <Spacing name={"borderRadius"} value={props.data?.borderRadius} setValue={props.setValue} featureName={"Border radius"}/>
                </div>
                <div className="subElement">
                    <ColorPicker name={"borderColor"} value={props.data?.borderColor} setValue={props.setValue} featureName={"Border color"}/>
                </div>
                <div className="subElement">
                    <SliderInput name={"borderWidth"} value={props.data?.borderWidth} setValue={props.setValue} featureName={"Border width"} min={0} max={20}/>
                </div>
            </div>
            <div className="editItem">
                <Animation name={'animation'} setValue={props.setValue} value={props.data.animation} featureName="Animation" imageName={<MdAnimation style={{fontSize:"18px"}}/>}/>
            </div>
        </div>
    }else {
        return <div style={bgImage(props?.data)??{}} className={animation}>
            <div className="row justify-content-center">
                {props?.data?.facebook &&
                    <div className="col-auto">
                        <a href={props?.data?.facebook} target="_blank"><MdFacebook  color={props.data?.facebookColor??'#4267B2'} size={props.data?.iconSize??40} className="cursor-pointer"/></a>
                    </div>
                }
                {props?.data?.twitter &&
                    <div className="col-auto">
                        <a href={props?.data?.twitter} target="_blank"><ImTwitter color={props.data?.twitterColor??'#1DA1F2'} size={props.data?.iconSize??40} className="cursor-pointer"/></a>
                    </div>
                }
                {props?.data?.instagram &&
                    <div className="col-auto">
                        <a href={props?.data?.instagram} target="_blank"><SiInstagram color={props.data?.instagramColor??'#3f729b'} size={props.data?.iconSize??40} className="cursor-pointer"/></a>
                    </div>
                }
                {props?.data?.pinterest &&
                    <div className="col-auto">
                        <a href={props?.data?.pinterest} target="_blank"><SiPinterest color={props.data?.pinterestColor??'#E60023'} size={props.data?.iconSize??40} className="cursor-pointer"/></a>
                    </div>
                }
                {props?.data?.tiktok &&
                    <div className="col-auto">
                        <a href={props?.data?.tiktok} target="_blank"><SiTiktok color={props.data?.tiktokColor??'#000000'} size={props.data?.iconSize??40} className="cursor-pointer"/></a>
                    </div>
                }
                {props?.data?.email &&
                    <div className="col-auto">
                        <a href={props?.data?.email} target="_blank"><MdOutlineMail color={props.data?.emailColor??'#006ee6'} size={props.data?.iconSize??40} className="cursor-pointer"/></a>
                    </div>
                }
            </div>
        </div>
    }
}

export default SocialLinks
