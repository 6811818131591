import React, {useEffect, useState} from "react";
import "../assets/style/statistic.scss"
import API from "../utils/API";
import {useParams} from "react-router-dom";
import Installations from "./statistic/Installations";
import Views from "./statistic/Views";
import ViewsByPlatform from "./statistic/ViewsByPlatform";
import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

const StatisticPerApp = () => {
    const [app,setApp] = useState(null)
    const [dateStart,setDateStart] = useState(null)
    const [dateEnd,setDateEnd] = useState(null)
    const [focused,setFocused] = useState(false)

    let { appid } = useParams()

    useEffect(()=>{
        API.get('/app/get/'+appid).then((response) => {
            setApp(response.data.data)
        })
    },[appid])

    return (
        <div className="mainPage statistic">
            {app &&
            <div className="hero">
                <div className="row">
                    {app.icon &&
                    <div className="col-auto">
                        <img className="img-fluid" src={app.icon} style={{maxWidth:"100px"}}/>
                    </div>
                    }
                    <div className="col">
                        <span className="title">Statistics</span>
                        <span className="appName">{app.title}</span>
                    </div>
                    <div className="col-auto">
                        <div className="mb-2">Date range</div>
                        <DateRangePicker
                            startDate={dateStart} // momentPropTypes.momentObj or null,
                            startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                            endDate={dateEnd} // momentPropTypes.momentObj or null,
                            endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                            onDatesChange={({ startDate, endDate }) => {setDateStart(startDate);setDateEnd(endDate)}} // PropTypes.func.isRequired,
                            focusedInput={focused} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                            onFocusChange={focusedInput => setFocused( focusedInput )}
                            minDate={null}// PropTypes.func.isRequired,
                        />

                    </div>
                </div>
            </div>
            }
            <div className="card mt-4 shadow-sm border-0">
                <div className="card-body">
                    <Installations/>
                </div>
            </div>
            <div className="card mt-4 shadow-sm border-0">
                <div className="card-body">
                    <div className="row">
                        <div className="col-6">
                            <Views/>
                        </div>
                        <div className="col-6">
                            <ViewsByPlatform/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StatisticPerApp
