import React from "react";
import SliderInput from "../../editor/subelements/SliderInput";
import Animation from "../../editor/Animation";
import {MdAnimation} from "react-icons/md";
import {useCustomAnimation} from "../../helpers/animation";
import ColorPicker from "../../editor/subelements/ColorPicker";
import Spacing from "../../editor/subelements/Spacing";
import {bgImage} from "../../helpers/bgImage";
import TextInput from "../../editor/subelements/TextInput";
import { FacebookProvider, Page } from 'react-facebook';
import {useClickDisable} from "../../helpers/clickDisable";

const FacebookPage = (props) => {

    const animation = useCustomAnimation(props.data.animation)
    const clickDisable = useClickDisable()

    if (props.edit) {
        return <div>
            <div className="editItem p-2">
                <div className="subElement subElementNoBorderTop">
                    <TextInput name={"feed"} value={props.data?.feed} setValue={props.setValue} featureName={"Page url"}/>
                </div>
                <div className="subElement">
                    <ColorPicker name={"bgColor"} value={props.data?.bgColor} setValue={props.setValue} featureName={"Background color"}/>
                </div>
                <div className="subElement">
                    <Spacing name={"margin"} value={props.data?.margin} setValue={props.setValue} featureName={"Margin"}/>
                </div>
                <div className="subElement">
                    <Spacing name={"padding"} value={props.data?.padding} setValue={props.setValue} featureName={"Padding"}/>
                </div>
                <div className="subElement">
                    <Spacing name={"borderRadius"} value={props.data?.borderRadius} setValue={props.setValue} featureName={"Border radius"}/>
                </div>
                <div className="subElement">
                    <ColorPicker name={"borderColor"} value={props.data?.borderColor} setValue={props.setValue} featureName={"Border color"}/>
                </div>
                <div className="subElement">
                    <SliderInput name={"borderWidth"} value={props.data?.borderWidth} setValue={props.setValue} featureName={"Border width"} min={0} max={20}/>
                </div>
            </div>
            <div className="editItem">
                <Animation name={'animation'} setValue={props.setValue} value={props.data.animation} featureName="Animation" imageName={<MdAnimation style={{fontSize:"18px"}}/>}/>
            </div>
        </div>
    }else {
        return <div style={bgImage(props?.data)??{}} className={animation}>{props?.data?.feed?.length > 0 &&
            <div className="row justify-content-center m-0 p-0">
                <div className={`col-auto m-0 p-0 ${clickDisable}`}>
            <FacebookProvider appId="508296159963693">
                <Page href={props?.data?.feed} style={{width:"100%"}} tabs="timeline" hideCover={true} hideCTA={true} smallHeader={true}/>
            </FacebookProvider>
                </div>
            </div>
        }</div>
    }
}

export default FacebookPage
