import React, {useState} from 'react';
import Modal from "react-modal";

const PrivacyPolicy = () => {
    const [modalOpen, setModalOpen] = useState(false);

    return <><a href="#" onClick={()=>setModalOpen(true)}>privacy policy</a><Modal
        isOpen={modalOpen}
        className="modal-window overflow-auto h-75"
        overlayClassName="modal-overlay"
        onRequestClose={()=>setModalOpen(false)}
    >
        <div>
            <div className="modalTitle row justify-content-center">
                <div className="col-auto text pt-2">
                    Privacy policy
                </div>
            </div>
            <div className="row justify-content-center mt-2">
                <div className="col-10 text-start">
                    <div className="small-10 medium-8 medium-push-2 small-push-1 text-large headline">
                        <p className="text-large">Please read this policy and if you have any questions contact us.</p>

                        <p className="text-large">By using the site you consent to the collection, use and disclosure of your personal
                            information in accordance with this policy.</p>

                        <p className="text-large">Capzool is committed to protecting your privacy. This privacy policy tells you about our
                            online collection, use and disclosure of personal information. The terms of this policy
                            apply to all personal information collected by Capzool on the website capzool.com and
                            other country-specific Capzool websites which may be linked to capzool.com. This site
                            is operated by Capzool, inc. ("Capzool"), and may be accessed in the United States and
                            abroad. For purposes of this Privacy Policy, 'Capzool', 'we', 'us', and 'our' references
                            Capzool, inc.</p>

                        <h3 className="text-dark offset">Changes in Privacy Policy</h3>

                        <p className="text-large">All "Personal Information" ("Personal Information" is defined as any information that
                            identifies or can be used to identify, contact, or locate the person to whom such
                            information pertains) that we collect and maintain will be subject to this Privacy Policy,
                            as amended from time to time. Capzool reserves the right to amend the Privacy Policy
                            from time to time at its sole discretion and will provide notice on the home page of the
                            Site when we make material changes to this Privacy Policy prior to the change
                            becoming effective.</p>
                        <p className="text-large">If Capzool is acquired by or merged with another entity, Capzool may provide to such
                            entity customer information that is related to that part of our business that was sold to or
                            merged with the other entity without your consent, but Capzool will provide notice of
                            such asset sales, acquisitions, or mergers on this Site.</p>

                        <h3 className="text-dark offset">Privacy Officer</h3>

                        <p className="text-large">We have designated a privacy officer at Capzool to be accountable for our policies and practices with respect to the management of personal information. You may forward any questions, comments or complaints that you might have about this privacy policy to:</p>

                        <p className="text-large">Zack Karni<br/>
                            Capzool, inc. <br/>
                            18730 Oxnard Street, Suite 202,<br/>
                            Tarzana, CA 91356, United States<br/>
                            Or by email to: support@capzool.com</p>



                        <h3 className="text-dark offset">Information Collected</h3>

                        <p className="text-large">When You enter the site, We collect Non-Personal Information such as Your browser type and IP address. Likewise, in order to offer You meaningful products and services and for other reasons, We may collect Personally Identifiable Information about You from the following sources:</p>
                        <ul>
                            <li>Information You give us on applications or other forms on the Site and/or Software; or</li>
                            <li>Information You send us via any medium, including, but not limited to email, telephone, and social media interaction; or</li>
                            <li>If You use a mobile device platform to access the Services or communicate with Capzool, information transmitted from Your mobile device; or</li>
                            <li>Information purchased from third parties.</li>
                        </ul>



                        <h3 className="text-dark offset">Registration</h3>

                        <p className="text-large">In order to purchase a subscription, You must provide us the following information to create an account: name, email address, company name and address, phone number, and credit card information.</p>
                        <p className="text-large">Capzool may request other information from You during or after the registration process that Capzool uses to provide better and more customized services to Users. You acknowledge that this information is personal to You, and by creating an account on Capzool, You may allow others, including Capzool, to identify You and to allow Capzool to use Your information in accordance with the Terms.</p>



                        <h3 className="text-dark offset">Customer Service</h3>

                        <p className="text-large">We collect information when You interact with Capzool’s customer service in order to accurately categorize and respond to customer inquiries and investigate breaches of Our terms.</p>



                        <h3 className="text-dark offset">Cookie and Tracking Technologies</h3>

                        <p className="text-large">The Site may automatically collect information as you browse, such as Internet Service Provider, browser type and version, operating system and device type, average time spent on our site(s), pages viewed, information accessed, the Internet Protocol (IP) address used to connect your computer to the Internet and other relevant statistics. Such information is sometimes referred to as web analytics and/or clickstream data.
                            We may combine this automatically collected log information with other information we collect about you. We do this to improve services we offer you, to improve marketing, analytics, and provide site functionality.</p>
                        <p className="text-large">Technologies such as: cookies, beacons, tags and scripts are used by Capzool and our affiliates, analytics services and service to provide site features and functionality. These technologies are used in analyzing trends, administering the site, tracking users’ movements around the site and to gather demographic information about our user base as a whole. We may receive reports based on the use of these technologies by these companies on an individual as well as aggregated basis.</p>
                        <ul>
                            <li>A cookie is a small, unique text file that a website can send to your computer when you visit a site. We may use session cookies, which terminate when a user closes his or her browser, and/or persistent cookies, which remain on the user's computer until manually deleted. Most web browsers can either alert you to the use of cookies or refuse to accept cookies entirely. If you do not want us to deploy cookies to your browser, you can set your browser to reject cookies or to notify you when a website tries to put a cookie on your computer. Rejecting cookies may affect your ability to use the Site.</li>
                            <li>We use Local Storage Objects (LSOs) such as HTML5 or Flash to store content information and preferences. Third parties with whom we partner to provide certain features on our site or to display advertising based upon your Web browsing activity may use LSOs such as HTML 5 or Flash to collect and store information.</li>
                            <li>We partner with a third party to either display advertising on our Web site or to manage our advertising on other sites. Our third party partner may use technologies such as cookies to gather information about your activities on this site and other sites in order to provide you advertising based upon your browsing activities and interests.</li>
                        </ul>



                        <h3 className="text-dark offset">Use of Personally Identifiable Information</h3>

                        <p className="text-large">Capzool uses and/or intends to use Personal Information to:</p>
                        <ul>
                            <li>Operate and improve the Site;</li>
                            <li>Provide Users with the Service and other products and services that a User 	may request or that a User has expressed interest in;</li>
                            <li>Facilitate subscription processing, reviews and analysis;</li>
                            <li>Evaluate User interest and needs in order to improve the Service and make 		available other offers, products or services;</li>
                            <li>Evaluate the types of offers, products or services We make available to Users 		and potential Users;</li>
                            <li>Track use of the Services;</li>
                            <li>Provide customer support;</li>
                            <li>Communicate and provide additional information that may be of interest to 		Users through email or other means, such as special offers, announcements, 		and marketing materials;</li>
                            <li>Send You reminders, technical notices, updates, security alerts and support 		and administrative messages, service bulletins, or marketing;</li>
                            <li>Provide advertisements to You through email messages; and</li>
                            <li>Manage Our everyday business needs such as Site administration, forum 		management, fulfillment, analytics, fraud prevention, enforcement of our 		corporate reporting obligations, Terms of Use or to comply with the law.</li>
                        </ul>



                        <h3 className="text-dark offset">Links/Postings to Other Sites</h3>

                        <p className="text-large">The Site and/or Software may contain links to other websites. Capzool is not responsible for the actions, practices, or content of such websites linked to or from the Site and/or Software. You understand that such websites may have their own legal documents to which you must agree to prior to using and that we have no control over these legal documents. As always, You understand that it is Your responsibility to verify your legal use of a website as well as use of information from the website with the corresponding website owner.</p>



                        <h3 className="text-dark offset">Market Research</h3>

                        <p className="text-large">Capzool may conduct online research surveys in order to gather feedback about the Site and opinions on important issues, through email invitations. When participating in a survey, We may ask You to submit Personally Identifiable Information. This Personally Identifiable Information is used for research purposes, and is not used for sales solicitations. Personally Identifiable Information collected through market research will be used only by Capzool and will not be given or sold to a third party without Your consent or as otherwise permitted by this Privacy Policy.</p>



                        <h3 className="text-dark offset">Exemptions from Privacy Policy</h3>

                        <p className="text-large">Our Privacy Policy does not apply to any information You may send to Capzool by email or instant messaging programs (i.e. AOL, Yahoo, etc.), as email and instant messages are not recognized as secure communication forms. As such, We request that You do not send any information, which You consider private, to us by email or instant message. Furthermore, Our Privacy Policy does not apply to any information You post from Our Platform and site to other websites (i.e. Twitter, Facebook, etc.), due to the public nature of such postings.</p>



                        <h3 className="text-dark offset">Links to 3rd party sites</h3>

                        <p className="text-large">Our Site includes links to other Web sites whose privacy practices may differ from those of Capzool. If you submit personal information to any of those sites, your information is governed by their privacy policies. We encourage you to carefully read the privacy policy of any Web site you visit.</p>



                        <h3 className="text-dark offset">Children</h3>

                        <p className="text-large">We do not intend to solicit or collect personal information from anyone under the age of 18 without parental consent. If you are under 18, you shall not use or enter information on this site, including without limitation, any Interactive areas of the site without parental consent. Please contact Customer Services at support@capzool.com, to obtain the necessary parental consent forms.</p>



                        <h3 className="text-dark offset">Access, Correction and Deletion of Personally Identifiable Data</h3>

                        <p className="text-large">You may request access to a report of the Personally Identifiable Information we maintain concerning Your use of the Service and you may request that the Personally Identifiable Information that we have collected and maintain be corrected or deleted. In order to make this request, please contact us at support@capzool.com. Please be aware that if you request that we delete Your Personally Identifiable Information, You may no longer have access to the Service. If Your access to the Service is terminated based upon Your request that We delete Your Personally Identifiable Information, please be advised that Your resulting inability to use the Service shall not be a basis for a refund or to avoid charges for the Service that You previously agreed to.</p>



                        <h3 className="text-dark offset">Transfer of Ownership</h3>

                        <p className="text-large">If the entire or substantial ownership of the Site were to change, Your user information may be transferred to the new owner so the service can continue operations. In any such transfer of information, Your user information would remain subject to the promises of Our then current Privacy Policy.</p>



                        <h3 className="text-dark offset">California Privacy Rights</h3>

                        <p className="text-large">Pursuant to Section 1798.83 of the California Civil Code, residents of California can obtain certain information about the types of Personally Identifiable Information that companies with whom they have an established business relationship have shared with third parties for direct marketing purposes during the preceding calendar year. In particular, the law provides that companies must inform consumers about the categories of Personally Identifiable Information that have been shared with third parties, the names and addresses of those third parties, and examples of the types of services or products marketed by those third parties. To request a copy of the information disclosure provided by Capzool pursuant to Section 1798.83 of the California Civil Code, please contact us via email to support@capzool.com.</p>



                        <h3 className="text-dark offset">Capzool’s Access to Your Account</h3>

                        <p className="text-large">You agree that Capzool can access Your account information in order to respond to Your service requests and/or as necessary, in Our sole discretion, to provide You with the Services.</p>



                        <h3 className="text-dark offset">Consent of Your Personal Information</h3>

                        <p className="text-large">By submitting personal information to us, you acknowledge and agree that you have consented to the collection, use and disclosure practices set out in this Privacy Policy as they relate to your personal information.</p>



                        <h3 className="text-dark offset">Privacy Complaints</h3>

                        <p className="text-large">Capzool takes its users' privacy concerns seriously. If you believe that Capzool has not complied with this Privacy Policy with respect to your Personal Information, you may contact the Privacy Officer at Capzool, Inc., 18730 Oxnard Street, Suite 202, Tarzana, CA 91356, United States. In your letter please describe in as much detail as possible the ways in which you believe that the Capzool Privacy Policy has not been complied with. We will investigate your complaint promptly. If you do not receive acknowledgment of your complaint or your complaint is not satisfactorily addressed, you should then contact TRUSTe through the TRUSTe Watchdog Dispute Resolution Process. TRUSTe will serve as a liaison with this Site to resolve your concerns.
                            If you have any questions about this Privacy Policy, the practices of this site, or your dealings with this Web site, you can contact our Privacy Officer at support@capzool.com.</p>

                        <p className="text-large">We strive to protect the Personal Information submitted to us, both during transmission and once we receive it. No method of transmission over the Internet, or method of electronic storage, is 100% secure, however. While we use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</p>
                        <p className="text-large">If you have any questions about security on our website, you can send email to us at support@capzool.com. </p>

                    </div>
                </div>
            </div>
        </div>
    </Modal></>
}

export default PrivacyPolicy;
