import React, {useEffect, useState} from "react";
import {TbRectangleVertical} from "react-icons/tb";
import {useSelector,useDispatch} from "react-redux";
import uniqid from "uniqid";
import {setContent} from "../../../redux/actions/pageeditorActions";
import "../../../assets/style/sections.scss";
import {IoMdCopy} from 'react-icons/io'
import {BsTrash} from 'react-icons/bs'
import {FaArrowCircleUp,FaArrowCircleDown} from 'react-icons/fa'
import {confirmAlert} from 'react-confirm-alert';
import {useIsTablet} from "../../../hooks/useIsTablet";

const SectionEditor = (props) => {

    const dispatch = useDispatch()

    const pageeditor = useSelector(state => {
        return state.pageEditor
    });

    const isTablet = useIsTablet()

    const resizeSection = (sizeLayout) => {
        let i,newBlocks = [], size = sizeLayout.length;
        for (i = 0; i < pageeditor.sections.length; i++) {
            if (i === pageeditor.editSectionId) {
                let sectionBlocks = [],j,currentSection = pageeditor.sections[i]

                for (j = 0; j < size; j++) {
                    if (j < currentSection.elements.length) {
                        currentSection.elements[j].id=uniqid()
                        sectionBlocks.push(currentSection.elements[j])
                    }else {
                        sectionBlocks.push({id:uniqid()})
                    }
                }

                currentSection.layout = sizeLayout
                currentSection.elements = sectionBlocks

                newBlocks.push(currentSection)
            }else {
                newBlocks.push(pageeditor.sections[i])
            }
        }
        dispatch(setContent({sections:newBlocks}))
    }

    const moveSectionUp = () => {
        let i,newBlocks = []
        for (i = 0; i < pageeditor.sections.length; i++) {
            if (i < pageeditor.editSectionId - 1 || i > pageeditor.editSectionId) {
                newBlocks.push(pageeditor.sections[i])
            }else if (i === pageeditor.editSectionId - 1) {
                newBlocks.push(pageeditor.sections[pageeditor.editSectionId])
                newBlocks.push(pageeditor.sections[i])
            }
        }

        dispatch(setContent({sections:newBlocks,editSectionId:pageeditor.editSectionId - 1}))
    }

    const moveSectionDown = () => {
        let i,newBlocks = []
        for (i = 0; i < pageeditor.sections.length; i++) {
            if (i < pageeditor.editSectionId || i > pageeditor.editSectionId + 1) {
                newBlocks.push(pageeditor.sections[i])
            }else if (i === pageeditor.editSectionId) {
                newBlocks.push(pageeditor.sections[pageeditor.editSectionId+1])
                newBlocks.push(pageeditor.sections[i])
            }
        }

        dispatch(setContent({sections:newBlocks,editSectionId:pageeditor.editSectionId + 1}))
    }

    const deleteSection = () => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure you want to delete this section?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        let i,newBlocks = []
                        for (i = 0; i < pageeditor.sections.length; i++) {
                            if (i !== pageeditor.editSectionId) {
                                newBlocks.push(pageeditor.sections[i])
                            }
                        }

                        dispatch(setContent({sections:newBlocks,editSectionId:null}))
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    }

    const cloneSection = () => {
        let i,newBlocks = [];
        for (i = 0; i < pageeditor.sections.length; i++) {
            newBlocks.push(pageeditor.sections[i]);

            if (i === pageeditor.editSectionId) {
                let j, newBlock, newElements = [], n = pageeditor.sections[i].elements.length;
                for (j = 0; j < n; j++) {
                    newBlock = JSON.parse(JSON.stringify(pageeditor.sections[i].elements[j]));
                    newBlock.id = uniqid();
                    newBlock.layout = pageeditor.sections[i].layout;
                    newElements.push(newBlock);
                }

                newBlocks.push({elements:newElements});
            }
        }

        dispatch(setContent({sections:newBlocks,editSectionId:pageeditor.editSectionId + 1}));
    }

    return <div className={`blocksContainer manageBlock sectionEditor ${!isTablet && 'ms-auto'} me-0`}>
        <div className="titleBlock">
            <div className="title sectionEditBg">
                <div className="row">
                    <div className="col-auto iconBlock">
                        <TbRectangleVertical className="icon sectionEditText float-start"/>
                        <TbRectangleVertical className="icon sectionEditText float-end"/>
                    </div>
                    <div className="col name sectionEditText">
                        edit section
                    </div>
                </div>
            </div>
        </div>
        <div className="list">
            <div className="sizeSelector">
                <div className="row m-0">
                    <div className="col-6 p-1">
                        <div onClick={()=>resizeSection([12])} className={`item ${JSON.stringify(pageeditor.sections[pageeditor.editSectionId].layout) === JSON.stringify([12]) ?'itemSelected':''}`}>
                            <div className="row m-0">
                                <div className="col-12 p-2">
                                    <div className="blockSize"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 p-1">
                        <div onClick={()=>resizeSection([6,6])} className={`item ${JSON.stringify(pageeditor.sections[pageeditor.editSectionId].layout) === JSON.stringify([6,6]) ?'itemSelected':''}`}>
                            <div className="row m-0">
                                <div className="col-6 p-2 pe-1">
                                    <div className="blockSize"></div>
                                </div>
                                <div className="col-6 p-2 ps-1">
                                    <div className="blockSize"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row m-0">
                    <div className="col-6 p-1">
                        <div onClick={()=>resizeSection([4,8])} className={`item ${JSON.stringify(pageeditor.sections[pageeditor.editSectionId].layout) === JSON.stringify([4,8]) ?'itemSelected':''}`}>
                            <div className="row m-0">
                                <div className="col-4 p-2 pe-1">
                                    <div className="blockSize"></div>
                                </div>
                                <div className="col-8 p-2 ps-1">
                                    <div className="blockSize"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 p-1">
                        <div onClick={()=>resizeSection([8,4])} className={`item ${JSON.stringify(pageeditor.sections[pageeditor.editSectionId].layout) === JSON.stringify([8,4]) ?'itemSelected':''}`}>
                            <div className="row m-0">
                                <div className="col-8 p-2 pe-1">
                                    <div className="blockSize"></div>
                                </div>
                                <div className="col-4 p-2 ps-1">
                                    <div className="blockSize"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row m-0">
                    <div className="col-6 p-1">
                        <div onClick={()=>resizeSection([4,4,4])} className={`item ${JSON.stringify(pageeditor.sections[pageeditor.editSectionId].layout) === JSON.stringify([4,4,4]) ?'itemSelected':''}`}>
                            <div className="row m-0">
                                <div className="col-4 p-2 pe-1">
                                    <div className="blockSize"></div>
                                </div>
                                <div className="col-4 p-2 pe-1 ps-1">
                                    <div className="blockSize"></div>
                                </div>
                                <div className="col-4 p-2 ps-1">
                                    <div className="blockSize"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 p-1">
                        <div onClick={()=>resizeSection([3,6,3])} className={`item ${JSON.stringify(pageeditor.sections[pageeditor.editSectionId].layout) === JSON.stringify([3,6,3]) ?'itemSelected':''}`}>
                            <div className="row m-0">
                                <div className="col-3 p-2 pe-1">
                                    <div className="blockSize"></div>
                                </div>
                                <div className="col-6 p-2 pe-1 ps-1">
                                    <div className="blockSize"></div>
                                </div>
                                <div className="col-3 p-2 ps-1">
                                    <div className="blockSize"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row m-0">
                    <div className="col-6 p-1">
                        <div onClick={()=>resizeSection([3,3,6])} className={`item ${JSON.stringify(pageeditor.sections[pageeditor.editSectionId].layout) === JSON.stringify([3,3,6]) ?'itemSelected':''}`}>
                            <div className="row m-0">
                                <div className="col-3 p-2 pe-1">
                                    <div className="blockSize"></div>
                                </div>
                                <div className="col-3 p-2 pe-1 ps-1">
                                    <div className="blockSize"></div>
                                </div>
                                <div className="col-6 p-2 ps-1">
                                    <div className="blockSize"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 p-1">
                        <div onClick={()=>resizeSection([6,3,3])} className={`item ${JSON.stringify(pageeditor.sections[pageeditor.editSectionId].layout) === JSON.stringify([6,3,3]) ?'itemSelected':''}`}>
                            <div className="row m-0">
                                <div className="col-6 p-2 pe-1">
                                    <div className="blockSize"></div>
                                </div>
                                <div className="col-3 p-2 pe-1 ps-1">
                                    <div className="blockSize"></div>
                                </div>
                                <div className="col-3 p-2 ps-1">
                                    <div className="blockSize"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row m-0 justify-content-center">
                    <div className="col-6 p-1">
                        <div onClick={()=>resizeSection([3,3,3,3])} className={`item ${JSON.stringify(pageeditor.sections[pageeditor.editSectionId].layout) === JSON.stringify([3,3,3,3]) ?'itemSelected':''}`}>
                            <div className="row m-0">
                                <div className="col-3 p-2 pe-1">
                                    <div className="blockSize"></div>
                                </div>
                                <div className="col-3 p-2 ps-1 pe-1">
                                    <div className="blockSize"></div>
                                </div>
                                <div className="col-3 p-2 ps-1 pe-1">
                                    <div className="blockSize"></div>
                                </div>
                                <div className="col-3 p-2 ps-1">
                                    <div className="blockSize"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-evenly m-0 mt-3 mb-3">
                <div className={`col-auto text-center iconButton ${pageeditor.editSectionId===0?`notActive`:``}`}>
                    <FaArrowCircleUp onClick={()=>{
                        if (pageeditor.editSectionId > 0) {
                            moveSectionUp()
                        }
                    }}/>
                </div>
                <div className="col-auto text-center iconButton">
                    <IoMdCopy onClick={()=>cloneSection()}/>
                </div>
                <div className="col-auto text-center iconButton">
                    <BsTrash onClick={()=>deleteSection()}/>
                </div>
                <div className={`col-auto text-center iconButton ${pageeditor.editSectionId===pageeditor.sections.length-1?`notActive`:``}`}>
                    <FaArrowCircleDown onClick={()=>{
                        if (pageeditor.editSectionId < pageeditor.sections.length-1) {
                            moveSectionDown()
                        }
                    }}/>
                </div>
            </div>
        </div>
    </div>
}

export default SectionEditor
