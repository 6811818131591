import React,{useState,useEffect} from "react";
import {TbDots} from "react-icons/tb";
import API from "../../utils/API";
import {useNavigate, useParams} from "react-router-dom";

const Products = () => {
    const [products,setProducts] = useState([])
    let { appid } = useParams()

    useEffect(()=>{
        API.post(`/ecommerce/products/${appid}`,{page:1,perPage:20}).then((result)=>{
            setProducts(result.data.data.data)
            console.log(result.data.data.data)
        });
    },[])

    let navigate = useNavigate()

    return (
        <>
            <div className="row me-4">
                <div className="col-4 fs-5">
                    <span className="selectLabel">Show:</span>
                    <select className="inlineSelect">
                        <option>All products</option>
                        <option>Category 1</option>
                    </select>
                </div>
                <div className="col-4">
                    <input className="form-control inlineSearch" placeholder="Search by Name, Brand, Varian etc…"/>
                </div>
                <div className="col-4 text-end pt-2">
                    <span className="selectLabel">Sort by:</span>
                    <select className="inlineSelect">
                        <option>Default</option>
                        <option>Price</option>
                    </select>
                </div>
            </div>
            {(products && products.length > 0) &&
            <div className="table-responsive mt-3 me-4">
                <table className="table table-borderless products">
                    <thead>
                    <tr>
                        <th style={{width:"100px"}}></th>
                        <th>Product name</th>
                        <th>Brand</th>
                        <th>SKU</th>
                        <th>Category</th>
                        <th>Tag</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {products?.map((product,key)=>{
                        return <tr className="cursorPointer" onClick={()=>navigate('/ecommerce/'+appid+'/product/'+product.id)}>
                            <td>
                                {product.image &&
                                    <img src={product.image[0]} className="img-fluid" style={{borderRadius:"10px"}}/>
                                }
                            </td>
                            <td className="pt-4">{product.title}</td>
                            <td className="pt-4">{
                                product?.brand?.map((brandItem,brandKey)=>{
                                    return <div>{brandItem.title}</div>
                                })
                            }</td>
                            <td className="pt-4">{product.sku}</td>
                            <td className="pt-4">{
                                product?.category?.map((brandItem,brandKey)=>{
                                    return <div>{brandItem.title}</div>
                                })
                            }</td>
                            <td className="pt-4">{
                                product?.tag?.map((brandItem,brandKey)=>{
                                    return <span className="badge bg-primary m-1">{brandItem.title}</span>
                                })
                            }</td>
                            <td></td>
                        </tr>
                    })}
                    </tbody>
                </table>
            </div>
            }
        </>
    )
}

export default Products
