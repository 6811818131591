import React,{useState,useEffect} from "react";
import {
    Link
} from "react-router-dom";
import {useIsTablet} from "../hooks/useIsTablet";
import {GiHamburgerMenu} from "react-icons/gi"
import Drawer from 'rc-drawer';
import "rc-drawer/assets/index.css"
import "../assets/style/drawer.scss"
import AppBarTablet from "./AppBarTablet";

const AppHeader = ({app}) => {
    const isTablet = useIsTablet()

    const [drawer,setDrawer] = useState(false)

    return (
     <>{app &&
         <>
            <span className="appTitle"><Link to={'/'}>AAPPWORLD</Link>
                {isTablet ? <br/> : '|'}
                {isTablet ?
                    <><GiHamburgerMenu size={26} onClick={()=>setDrawer(true)} color={"#5351FB"}/> </>
                    :
                    <></>
                }
                {app.title}
            </span>
             <Drawer
                 placement={"left"}
                 level={null}
                 open={drawer}
                 onHandleClick={() => setDrawer(!drawer)}
                 onClose={() => setDrawer(false)}
                 maskClosable={true}
                 width={90}
                 maskMotion={{
                     motionAppear: true,
                     motionName: 'mask-motion'
                 }}
                 motion={{
                     motionAppear: true,
                     motionName: 'panel-motion-left'
                 }}
             >
                 <AppBarTablet setDrawer={setDrawer}/>
             </Drawer>
         </>
     }</>
    )
}

export default AppHeader
