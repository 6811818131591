import React,{useState,useEffect} from "react";
import "../assets/style/appDevice.scss"
import {build} from "./builder/blocks/BlockFactory";
import API from "../utils/API";
import "../assets/style/appPreview.scss"

const PagePreview = ({app, page}) => {

    const [pageData,setPageData] = useState(false)

    useEffect(()=>{
        if (page && app) {
            API.get('/pagedata/'+page).then((response) => {
                setPageData(response.data.data.data);
            })
        }
    },[])

    return <div className="appPreviewBlock"><div className="appDevice">
        {pageData &&
            <>
                {pageData.map((section,key)=>{
                    return <div className={`row p-0 row-eq-height sectionRow m-0`} key={key}>
                        {section.elements.map((element,elementKey)=>{
                            return <div key={elementKey} className={`m-0 p-0 col-${12/(1*section.elements.length)}`}>
                                <div className="h-100">
                                    {React.cloneElement(build(element.component),{...element})}
                                </div>
                            </div>
                        })
                        }
                    </div>
                })}
            </>
        }
    </div></div>
}

export default PagePreview
