import React from "react";
import AppEditor from "./AppEditor";

const NewApp = () => {
    return (
        <AppEditor/>
    )
}

export default NewApp
