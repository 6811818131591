import React,{useEffect,useState} from "react";
import {AiOutlineSearch,AiOutlineMobile,AiTwotonePieChart} from "react-icons/ai";
import {BsFillCreditCardFill} from "react-icons/bs";
import {RiDashboardFill} from "react-icons/ri"
import "../assets/style/support.scss";
import Accordion from 'react-bootstrap/Accordion';
import API from "../utils/API";
const nl2br = require('react-nl2br');

const Support = () => {
    const [category,setCategory] = useState(false);
    const [keyword,setKeyword] = useState('');
    const [support,setSupport] = useState(null);

    useEffect(() => {
        if (category) {
            API.post("/faq",{category:category}).then(res => {
                setSupport(res.data.data);
            })
        }else {
            API.post("/faq").then(res => {
                setSupport(res.data.data);
            })
        }
    },[category]);

    const handleSearch = () => {
        if (keyword && keyword.length > 2) {
            API.post("/faq", {keyword: keyword}).then(res => {
                setSupport(res.data.data);
            })
        }
    }

    const searchByKeyword = (key) => {
        setKeyword(key);
        handleSearch();
    }

    return (
        <>
            <div className="mainPage support">
                <div className="hero">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <span className="title">Support</span>
                            <div className="subTitle">How can we help?</div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <div className="input-group">
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" placeholder="Ask a question" value={keyword} onChange={(e)=>setKeyword(e.target.value)}/>
                                        <div className="input-group-append">
                                            <button className="btn btn-primary" type="button" onClick={()=>handleSearch()}><AiOutlineSearch style={{fontSize:"20px"}}/></button>
                                        </div>
                                </div>
                            </div>
                            <div className="subTitle">Popular help topics: <span style={{textDecoration:"underline"}}><span onClick={()=>searchByKeyword('account')}>account</span>, <span onClick={()=>searchByKeyword('pricing')}>pricing</span>, <span onClick={()=>searchByKeyword('payment')}>payment</span></span></div>
                        </div>
                    </div>
                </div>
                <div className="row mt-5 mb-5 justify-content-center">
                    <div className="col-auto m-2 text-center typeLink cursorPointer" onClick={()=>setCategory('payment')}>
                        <div className="text-center"><BsFillCreditCardFill className="icon"/></div>
                        <span className="linkTitle">Payment</span>
                    </div>
                    <div className="col-auto m-2 text-center typeLink cursorPointer" onClick={()=>setCategory('app')}>
                        <div className="text-center"><AiOutlineMobile className="icon"/></div>
                        <span className="linkTitle">APP Features</span>
                    </div>
                    <div className="col-auto m-2 text-center typeLink cursorPointer" onClick={()=>setCategory('dashboard')}>
                        <div className="text-center"><RiDashboardFill className="icon"/></div>
                        <span className="linkTitle">Dashboard</span>
                    </div>
                    <div className="col-auto m-2 text-center typeLink cursorPointer" onClick={()=>setCategory('statistic')}>
                        <div className="text-center"><AiTwotonePieChart className="icon"/></div>
                        <span className="linkTitle">Statistics</span>
                    </div>
                </div>

                <div className="row justify-content-center mt-5">
                    <div className="col-lg-8 col-md-12">
                        <span className="supportTitle">Most important Questions</span>
                        {(support && support.length > 0) &&
                        <Accordion defaultActiveKey="0" className="mt-3">
                            {support.map((supportItem,supportKey) => {
                               return <Accordion.Item eventKey={supportKey}>
                                   <Accordion.Header>{supportItem.title}</Accordion.Header>
                                   <Accordion.Body>{nl2br(supportItem.content)}</Accordion.Body>
                               </Accordion.Item>
                            })}
                        </Accordion>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Support
