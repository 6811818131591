import React,{useState} from "react";
import bg from "../assets/image/signin.png"
import google from "../assets/image/google.png"
import Auth from "../layouts/Auth";
import {useSelector,useDispatch} from "react-redux";
import API from "../utils/API";
import {login} from "../redux/actions/userActions";
import {
    useNavigate
} from "react-router-dom";
import { googleLogout, useGoogleLogin } from '@react-oauth/google';

const Signin = () => {
    const [email,setEmail] = useState(null)
    const [password,setPassword] = useState(null)
    const [error,setError] = useState(null)
    const [loading,setLoading] = useState(false)

    let navigate = useNavigate()

    const sign = useSelector(state => {
        return state.user.sign
    });

    const googleLogin = useGoogleLogin({
        onSuccess: (codeResponse) => {
            setLoading(true)
            API.post('/auth/google/signin', {token: codeResponse.access_token})
                .then(data => {
                    dispatch(login(data.data.data));
                    setLoading(false)
                    navigate("/");
                })
                .catch(error => {
                    console.log('Login Failed:', error);
                    setError('User not found')
                    setLoading(false)
                })
        },
        onError: (error) => console.log('Login Failed:', error),
        ux_mode: 'popup'
    });

    const dispatch = useDispatch();

    const submit = () => {
        if (!loading) {
            setLoading(true)
            API.post('/signin', {email: email, password: password})
                .then(data => {
                    dispatch(login(data.data.data));
                    setLoading(false)
                    navigate("/");
                })
                .catch(error => {
                    setError('Invalid username/password')
                    setLoading(false)
                })
        }
    }

    return (
        <div className="auth main d-flex w-100 justify-content-center">
            <div className="row w-100 content justify-content-center">
                <div className="col-xl-8 col-lg-10 col-md-12 col-sm-12">
                    <div className="row m-0 p-0 centerBlock justify-content-center">
                        <div className="col-md col-sm-12 m-0 p-0" style={{minHeight:"200px",backgroundImage:`url(${bg})`,backgroundRepeat: "no-repeat",backgroundPosition : "center",backgroundSize: "cover"}}/>
                        <div className="col-md col-sm-12">
                            <div className="text-end p-2">
                                <span className="logoTitle">APP WORLD</span>
                            </div>
                            <div className="form">
                                <div>
                                    <span className="title">Welcome Back!</span>
                                    {error &&
                                        <div className="text-center text-danger">{error}</div>
                                    }
                                    <div className="row">
                                        <div className="col-12 mb-2">
                                            <label htmlFor="input1" className="label">
                                                            <span className="h6 small bg-white text-muted px-1">Username</span>
                                            </label>
                                            <input type="text" className="form-control mt-2" id="input1" onChange={(e) => {setEmail(`${e.target.value}`);setError(null)}}/>
                                        </div>
                                        <div className="col-12 mb-2">
                                            <label htmlFor="input2" className="label">
                                                <span className="h6 small bg-white text-muted px-1">Password</span>
                                            </label>
                                            <input type="password" className="form-control mt-2" id="input2" onChange={(e) => {setPassword(`${e.target.value}`);setError(null)}}/>
                                        </div>
                                        <div className="col-12 mb-2 text-end forgotPassword cursorPointer" onClick={()=>navigate("/restore")}>
                                            Forgot password
                                        </div>
                                        <div className="col-12 mb-2 mt-2">
                                            <button className="btn btn-primary w-100 button" onClick={submit}>
                                                {loading ?
                                                    <>Loading...</>
                                                    :
                                                    <>Sign in</>
                                                }
                                            </button>
                                        </div>
                                        <div className="col-12 mb-3 mt-3">
                                            <div className="strike">
                                                <span className="orSignIn">Or sign in with</span>
                                            </div>
                                        </div>
                                        <div className="col-12 mb-2 mt-2 text-center">
                                            <div onClick={() => googleLogin()} className="cursorPointer"><img src={google} style={{width:"20px"}}/> <span className="googleSignIn">Sign in with Google</span></div>
                                        </div>
                                        <div className="col-12 mb-3 mt-3 text-center">
                                            <span className="orSignUp cursorPointer" onClick={()=>navigate("/signup")}>Don't have an account? <span className="text-primary">Sign Up</span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

Signin.layout = Auth
Signin.private = false

export default Signin
