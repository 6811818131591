import React from "react"
import ImageItem from "./subelements/ImageItem";
import TextInput from "./subelements/TextInput";
import TextAreaInput from "./subelements/TextAreaInput";

const TeamShowcaseItem = ({item, itemKey, editItem}) => {

    const setValue = (name,value) => {
        editItem(itemKey,name,value)
    }

    return (<>
        <div className="subElement subElementNoBorderTop">
            <ImageItem name={"image"} value={item?.image} setValue={setValue} featureName={"Image"}/>
        </div>
        <div className="subElement">
            <TextInput name={"title"} value={item?.title} featureName={"Title"} setValue={setValue}/>
        </div>
        <div className="subElement">
            <TextAreaInput name={"description"} value={item?.description} featureName={"Description"} rows={3} setValue={setValue}/>
        </div>
    </>)
}

export default TeamShowcaseItem
