import React from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
    {
        name: 'Mon',
        views: 0
    },
    {
        name: 'Tue',
        views: 0
    },
    {
        name: 'Wed',
        views: 0
    },
    {
        name: 'Thu',
        views: 0
    },
    {
        name: 'Fri',
        views: 0
    },
    {
        name: 'Sat',
        views: 0
    },
    {
        name: 'Sun',
        views: 0
    }
];

const Views = () =>  {

    return <div style={{ width: '100%', height: 400 }}>
        <span style={{ fontSize: 14, color: '#000000'}}>Views</span>
        <ResponsiveContainer>
            <BarChart
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="views" fill="#8884d8" />
            </BarChart>
        </ResponsiveContainer>
    </div>;
}

export default Views;
