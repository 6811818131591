import React from "react";
import "../../assets/style/mainpage.scss"
import "../../assets/style/billing.scss"
import {
    useNavigate, useLocation
} from "react-router-dom";

const BillingLayout = ({ children }) => {
    let navigate = useNavigate()
    const location = useLocation();

    return (
        <div className="mainPage billing">
            <div className="hero">
                <span className="title">Account settings</span>
            </div>
            <div className="tabNavigation mt-3">
                <div className="header m-3">
                    <div className="row">
                        <div className="col-auto">
                            <span onClick={(e)=>navigate('/billing/payment-method')} className={`${location.pathname.indexOf('/billing/payment-method') !== -1?'buttonActive':'buttonNotActive'} m-1`}>Payment methods</span>
                        </div>
                        <div className="col-auto">
                            <span onClick={(e)=>navigate('/billing/subscriptions')} className={`${location.pathname.indexOf('/billing/subscriptions') !== -1?'buttonActive':'buttonNotActive'} m-1`}>Subscriptions</span>
                        </div>
                        <div className="col-auto">
                            <span onClick={(e)=>navigate('/billing/transactions')} className={`${location.pathname.indexOf('/billing/transactions') !== -1?'buttonActive':'buttonNotActive'} m-1`}>Transactions</span>
                        </div>
                        <div className="col-auto">
                            <span onClick={(e)=>navigate('/profile')} className={`${location.pathname.indexOf('/profile') !== -1?'buttonActive':'buttonNotActive'} m-1`}>Profile</span>
                        </div>
                        <div className="col-auto">
                            <span onClick={(e)=>navigate('/password')} className={`${location.pathname.indexOf('/password') !== -1?'buttonActive':'buttonNotActive'} m-1`}>Change password</span>
                        </div>
                    </div>
                </div>
                <div className="ms-4 mt-1 mb-3">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default BillingLayout
