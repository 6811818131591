import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux";
import SectionItem from "../../../components/builder/SectionItem";
import uniqid from "uniqid";
import _ from "lodash";
import {set, setContent} from "../../../redux/actions/pageeditorActions";
import API from "../../../utils/API";

const PageView = ({appid}) => {
    const [editorPage,setEditorPage] = useState(null)

    const dispatch = useDispatch()

    const pageeditor = useSelector(state => {
        return state.pageEditor
    })

    const move = (component,x,y) => {
        let currentItems = pageeditor.sections, newItems = [], newSection, n = pageeditor.sections.length, i, j, k, newId = uniqid()
        for (i = 0; i < n; i++) {
            k = currentItems[i].elements.length

            newSection = {elements:[]}

            for (j = 0; j < k; j++) {
                if (y === i && x === j) {
                    let newitem = _.clone(component)
                    newitem.id = newId
                    newSection.elements.push(newitem)
                }else {
                    if (component.id !== currentItems[i].elements[j].id) {
                        newSection.elements.push(currentItems[i].elements[j])
                    }else {
                        currentItems[i].elements[j] = {id:uniqid()}
                        newSection.elements.push(currentItems[i].elements[j])
                    }
                }
            }

            newItems.push(newSection)
        }

        dispatch(setContent({sections:newItems,editBlockId:newId,editType:'block'}))
    }

    useEffect(()=>{
        if (pageeditor.app && pageeditor.page && pageeditor.app === appid && pageeditor.page !== editorPage) {
            setEditorPage(pageeditor.page)
        }else if (pageeditor.page !== editorPage) {
            setEditorPage(null)
        }
    },[pageeditor,appid])

    const selectPage = (page) => {
        setEditorPage(page)
        API.get('/pagedata/'+page).then((response) => {
            dispatch(set({app:appid,page:page,sections:response.data.data.data,editType:null}))
        })
    }

    const handleClick = (e) => {
        if (e.target.href) {
            if (e.target.href.includes('internallink')) {
                let pageId = e.target.href.split("internallink")[1];
                selectPage(pageId);
                e.preventDefault();
                e.stopPropagation();
                return false;
            }else {
                window.open(e.target.href, '_blank').focus();
                e.preventDefault();
                e.stopPropagation();
                return false;
            }
        }
    }

    return <div onClick={(e)=>handleClick(e)}>
        {(editorPage && pageeditor.sections) &&
            pageeditor.sections.map((section,key)=>{
                return <div className={`row p-0 row-eq-height sectionRow m-0 ${(pageeditor.editSectionId===key && pageeditor.editType === 'section')?'sectionRowSelected':''}`} key={key}>
                    {section.elements.map((element,elementKey)=>{
                        return <div key={elementKey} className={`m-0 p-0 col-${section.layout?section.layout[elementKey]:12/(1*section.elements.length)}`}>
                            <SectionItem y={key} x={elementKey} component={element} move={move}></SectionItem>
                        </div>
                    })
                    }
                </div>
            })
        }
    </div>
}

export default PageView
