import React,{useState,useEffect} from "react";
import {useParams} from "react-router-dom";
import API from "../../utils/API";
import {AiOutlineClockCircle} from "react-icons/ai";

const Messages = () => {
    let { appid } = useParams()

    const [message, setMessage] = useState(null);
    const [comments,setComments] = useState(null);

    const loadMessages = () => {
        API.get('/messages/'+appid).then((response) => {
            setComments(response.data.data);
        });
    }

    const addComment = () => {
        API.post('/messages/'+appid,{
            message:message
        }).then((response) => {
            setMessage('');
            loadMessages();
        });
    }

    useEffect(() => {
        loadMessages();
        const interval = setInterval(() => {
            loadMessages();
        }, 6000);

        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [appid])

    return <div className="mainPage billing">
        <div className="hero">
            <span className="title">MESSAGES</span>
        </div>
        <div className="mt-5">
            <div className="row">
                <div className="col">
                    <input type="text" className="form-control" style={{borderRadius:"10px"}} value={message} onChange={(e) => setMessage(e.target.value)}/>
                </div>
                <div className="col-auto">
                    <button className="btn btn-primary" style={{borderRadius:"10px"}} onClick={()=>addComment()}>Add</button>
                </div>
            </div>
            {comments &&
                comments.map((comment) => {
                    return <div key={comment.id} className={`row mt-2 ${comment.fromadmin?'justify-content-end':''}`}>
                        <div className={`col-4 ${comment.fromadmin?'bg-secondary':'bg-primary'} text-white p-3`} style={{borderRadius:"10px"}}>
                            <div>
                            {comment.fromadmin ?
                                <strong>Admin</strong>
                                :
                                <strong>You</strong>
                            }
                            </div>
                            <div><AiOutlineClockCircle/>{comment.date}</div>
                            {comment.content}
                        </div>
                    </div>
                })
            }
        </div>
    </div>
}

export default Messages
