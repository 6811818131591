import React from "react";
import "../assets/style/dashboard.scss"
import {Helmet} from "react-helmet";
import "../assets/style/appPage.scss";
import AppBarMarketplaceManagement from "../components/AppBarMarketplaceManagement";
import "../assets/style/iframe.scss";

const MarketplaceManagement = ({ children }) => {
    return (
        <div className="container-fluid">
            <Helmet>
                <link rel="stylesheet" href={`/css/main.css`} />
            </Helmet>
            <div className="row">
                <AppBarMarketplaceManagement/>
                <main className="col p-4 pt-3" style={{marginLeft:"88px"}}>
                    <div className="pt-3 ps-5 pe-5">
                        <div className="pt-5 appPage">
                            {children}
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default MarketplaceManagement
