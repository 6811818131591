import React from "react";
import "../../assets/style/mainpage.scss"
import "../../assets/style/billing.scss"
import {
    useNavigate, useLocation, useParams
} from "react-router-dom";

const BillingLayout = ({ children }) => {
    let navigate = useNavigate()
    const location = useLocation();

    let { appid } = useParams()

    return (
        <div className="mainPage billing">
            <div className="hero">
                <span className="title">ADJUSTMENTS</span>
            </div>
            <div className="tabNavigation mt-3">
                <div className="header m-3">
                    <div className="row">
                        <div className="col-auto">
                            <span onClick={(e)=>navigate(`/app/${appid}/settings`)} className={`${location.pathname.indexOf(`/app/${appid}/settings`) !== -1?'buttonActive':'buttonNotActive'} m-1`}>App profile</span>
                        </div>
                        <div className="col-auto">
                            <span onClick={(e)=>navigate(`/app/${appid}/team`)} className={`${location.pathname.indexOf(`/app/${appid}/team`) !== -1?'buttonActive':'buttonNotActive'} m-1`}>Team</span>
                        </div>
                        <div className="col-auto">
                            <span onClick={(e)=>navigate(`/app/${appid}/dangerzone`)} className={`${location.pathname.indexOf(`/app/${appid}/dangerzone`) !== -1?'buttonActive':'buttonNotActive text-danger'} m-1`}>Danger zone</span>
                        </div>
                    </div>
                </div>
                <div className="ms-4 mt-1 mb-3">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default BillingLayout
