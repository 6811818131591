import React, {useEffect, useState} from "react";
import API from "../../utils/API";
import {useDispatch, useSelector} from "react-redux";
import {FiLayers} from "react-icons/fi";
import "../../assets/style/navigator.scss"
import {GiPlainCircle} from "react-icons/gi";
import {AiFillCaretDown,AiFillCaretRight} from "react-icons/ai";
import {set} from "../../redux/actions/pageeditorActions";
import SectionItem from "../../components/builder/SectionItem";

const Navigator = () => {
    const [blocks,setBlocks] = useState(null)
    const [page,setPage] = useState(null)
    const dispatch = useDispatch()

    const pageeditor = useSelector(state => {
        return state.pageEditor
    });

    useEffect(()=>{
        if (pageeditor.page) {
            API.get("/page/"+pageeditor.page).then((response)=>{
                setPage(response.data.data.page);
            })
        }
    },[pageeditor])

    useEffect(()=>{
        API.get("/user/block/all/1").then((response)=>{
            setBlocks(response.data.data);
        })
    },[])

    return <div className="navigator manageBlock">
        {page &&
            <>
                <div className="titleBlock">
                    <div className="title">
                        <div className="row">
                            <div className="col-3 iconBlock">
                                <FiLayers className="icon"/>
                            </div>
                            <div className="col-9 name">
                                navigator
                            </div>
                        </div>
                    </div>
                </div>
                <div className="list">
                    <div className="title">{page.title}</div>
                    {(pageeditor.sections && pageeditor.sections.length > 0) &&
                        <div className="sections">
                            {pageeditor.sections.map((section,key)=>{
                                return <><div className={`section ${(key !== 0)?`borderTop`:``}`} key={key}>
                                    <div className="row cursorPointer" onClick={()=>dispatch(set({editSectionId:key,editType:"section",selectedBlockId:null}))}>
                                        <div className="col-2 text-center">
                                            <GiPlainCircle color="#EEEFF3"/>
                                        </div>
                                        <div className="col-8 ps-4 sectionTitle">
                                            Section
                                        </div>
                                        <div className="col-2 text-center">
                                            {(pageeditor.editSectionId === key) ?
                                                <AiFillCaretDown color="#868686" size="20"/>
                                                :
                                                <AiFillCaretRight color="#868686" size="20"/>
                                            }
                                        </div>
                                    </div>
                                </div>
                                    {(pageeditor.editSectionId === key) &&
                                        <>
                                            <div className="section borderTop" onClick={()=>dispatch(set({editSectionId:key,editType:'section',selectedBlockId:null}))}>
                                                <div className="row cursorPointer ps-4">
                                                    <div className="col-2 text-center">
                                                        <div className={`row ${(pageeditor.editSectionId === key && pageeditor.editType === 'section')?'sectionSizeSelected':'sectionSize'}`}>
                                                            {section.elements.map((element,elementKey)=>{
                                                                    return <div key={elementKey} className={`item m-0 p-0 col-${12/(1*section.elements.length)}`}/>
                                                                })}
                                                        </div>
                                                    </div>
                                                    <div className="col-8 ps-4 sectionTitle pt-1">
                                                        Column
                                                    </div>
                                                    <div className="col-2 text-center">
                                                        <AiFillCaretDown color="#868686" size="20"/>
                                                    </div>
                                                </div>
                                            </div>
                                            {section.elements.map((element,elementKey)=>{
                                                if (blocks && blocks[element.component]) {
                                                    return <div className="section borderTop" key={elementKey} onClick={()=>dispatch(set({editBlockId:element.id,editType:'block',selectedBlockId:null}))}>
                                                        <div className="row cursorPointer ps-5">
                                                            <div className="col-2 text-center">
                                                                {(blocks && blocks[element.component] && blocks[element.component].image) &&
                                                                    <img src={blocks[element.component].image}
                                                                         style={{width: "28px", height: "27px"}} className={(pageeditor.editBlockId === element.id && pageeditor.editType === 'block')?'':'imageNotSelected'}/>
                                                                }
                                                            </div>
                                                            <div className="col-10 ps-4 sectionTitle">
                                                                {(blocks && blocks[element.component] && blocks[element.component].title) &&
                                                                    <span style={{fontWeight:(pageeditor.editBlockId===element.id && pageeditor.editType==="block")?"bold":null}}>{blocks[element.component].title}</span>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                }else {
                                                    return <></>
                                                }
                                            })}
                                        </>
                                    }
                                </>}
                            )}
                        </div>
                    }
                </div>
            </>
        }
    </div>
}

export default Navigator
