import {spacing} from "./spacing"

export const bgImage = (imageObject,hideBg) => {
    return {
        backgroundRepeat: "no-repeat",
        backgroundPosition : "center",
        backgroundSize: "cover",
        backgroundImage: (imageObject?.image && !hideBg)?`url(${imageObject.image})`:'',
        backgroundColor: imageObject?.bgColor?(imageObject.bgColor):'',
        border:(imageObject?.borderWidth)?`solid ${imageObject?.borderWidth}px ${imageObject?.borderColor}`:'0',
        borderRadius: spacing(imageObject?.borderRadius??{}),
        padding: spacing(imageObject?.padding??{}),
        margin: spacing(imageObject?.margin??{}),
        height: "100%",
        minHeight: "100px"
    }
}
