import React from "react";
import {useParams} from "react-router-dom";
import AppEditor from "./AppEditor";

const Settings = () => {
    let { appid } = useParams()

    return <AppEditor appid={appid}/>
}

export default Settings
