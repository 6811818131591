import React, {useState,useEffect} from "react";
import {useSelector} from "react-redux";
import API from "../../../../utils/API";
import {Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";

const LinkPicker = ({setValue, name, value, featureName}) => {

    const pageeditor = useSelector(state => {
        return state.pageEditor
    });

    const [activeTab,setActiveTab] = useState(value?.type??'page')

    const [pages,setPages] = useState(null)

    useEffect(()=>{
        if (pageeditor?.app) {
            API.get('/page/getall/'+pageeditor.app).then((response) => {
                setPages(response.data.data);
            });
        }
    },[pageeditor])

    return <div>
        <div className="row">
            <div className="col">
                <span className="blockTitle">{featureName}</span>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === "page" },'cursorPointer')}
                            style={{fontSize:"11px",color:"#3444CE",padding:"3px 3px"}}
                            onClick={() => {
                                setActiveTab("page");
                                setValue(name+".type","page");
                            }}
                        >
                            <strong>Page</strong>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === "webaddress" },'cursorPointer')}
                            style={{fontSize:"11px",color:"#3444CE",padding:"3px 3px"}}
                            onClick={() => {
                                setActiveTab("webaddress");
                                setValue(name+".type","webaddress");
                            }}
                        >
                            <strong>Web address</strong>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === "email" },'cursorPointer')}
                            style={{fontSize:"11px",color:"#3444CE",padding:"3px 3px"}}
                            onClick={() => {
                                setActiveTab("email");
                                setValue(name+".type","email");
                            }}
                        >
                            <strong>Email</strong>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === "phone" },'cursorPointer')}
                            style={{fontSize:"11px",color:"#3444CE",padding:"3px 3px"}}
                            onClick={() => {
                                setActiveTab("phone");
                                setValue(name+".type","phone");
                            }}
                        >
                            <strong>Phone</strong>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === "file" },'cursorPointer')}
                            style={{fontSize:"11px",color:"#3444CE",padding:"3px 3px"}}
                            onClick={() => {
                                setActiveTab("file");
                                setValue(name+".type","file");
                            }}
                        >
                            <strong>File</strong>
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab} className="mt-2">
                    <TabPane tabId="page">
                        <select className="form-control" value={value?.page}  onChange={(e)=>{setValue(name+".page",e.target.value)}}>
                            <option value={null}>Select app page</option>
                            {pages &&
                                pages.map((page,index)=>{
                                    return (
                                        <option key={index} value={page.id}>{page.title}</option>
                                    )
                                })
                            }
                        </select>
                    </TabPane>
                    <TabPane tabId="webaddress">
                        <input type="text" value={value?.url} onChange={(e)=>setValue(name+".url",e.target.value)} className="form-control" placeholder="Web address"/>
                    </TabPane>
                    <TabPane tabId="email">
                        <input type="text" value={value?.email} onChange={(e)=>setValue(name+".email",e.target.value)} className="form-control" placeholder="Email"/>
                    </TabPane>
                    <TabPane tabId="phone">
                        <input type="text" value={value?.phone} onChange={(e)=>setValue(name+".phone",e.target.value)} className="form-control" placeholder="Phone"/>
                    </TabPane>
                    <TabPane tabId="file">
                        <input type="file" className="form-control" placeholder="File" onChange={(event) => {
                            let files = event.target.files;
                            let reader = new FileReader();
                            reader.readAsDataURL(files[0]);
                            reader.onload = (e) => {
                                API.post('/page/file/upload',{base64:e.target.result,name:files[0].name}).then((response) => {
                                    setValue(name+".file",response.data.data)
                                });
                            }
                        }}/>
                        {value?.file &&
                            <div><a href={value.file} className="text-primary" target="_blank">View file</a></div>
                        }
                    </TabPane>
                </TabContent>
            </div>
        </div>
    </div>
}

export default LinkPicker
