import React,{useEffect,useState} from "react";
import {
    Routes,
    Route,
    Navigate
} from "react-router-dom";
import Signin from "../pages/Signin";
import Home from "../pages/Home";
import {useDispatch, useSelector} from "react-redux";
import Statistic from "../pages/Statistic";
import Shop from "../pages/Shop";
import Message from "../pages/Message";
import Setting from "../pages/Setting";
import Profile from "../pages/setting/Profile";
import PaymentMethod from "../pages/billing/PaymentMethod";
import Subscriptions from "../pages/billing/Subscriptions";
import Transactions from "../pages/billing/Transactions";
import Support from "../pages/Support";
import Auth from "../layouts/Auth";
import Main from "../layouts/Main";
import App from "../layouts/App";
import Blocks from "../pages/app/Blocks";
import Navigation from "../pages/app/Navigation";
import Pages from "../pages/app/Pages";
import Sharing from "../pages/app/Sharing";
import DefaultBlockSettings from "../pages/app/blocks/DefaultBlockSettings";
import Admin from "../layouts/Admin";
import MarketplaceManagement from "../layouts/MarketplaceManagement";
import API from "../utils/API";
import {login, logout} from "../redux/actions/userActions";
import NativeApp from "../pages/NativeApp";
import NewApp from "../pages/app/NewApp";
import Settings from "../pages/app/Settings";
import BillingLayout from "../layouts/sublayouts/BillingLayout";
import NewPaymentMethod from "../pages/billing/NewPaymentMethod";
import StatisticPerApp from "../pages/StatisticPerApp";
import BlogpostLayout from "../layouts/sublayouts/BlogpostLayout";
import Posts from "../pages/blogpost/Posts";
import Post from "../pages/blogpost/Post";
import Categories from "../pages/blogpost/Categories";
import Category from "../pages/blogpost/Category";
import EcommerceLayout from "../layouts/sublayouts/EcommerceLayout";
import Products from "../pages/ecomerce/Products";
import SalesCoupons from "../pages/ecomerce/SalesCoupons";
import ProductCategories from "../pages/ecomerce/ProductCategories";
import ShippingTaxes from "../pages/ecomerce/ShippingTaxes";
import Product from "../pages/ecomerce/Product";
import Variant from "../pages/ecomerce/Variant";
import SalesCoupon from "../pages/ecomerce/SalesCoupon";
import Shipping from "../pages/ecomerce/Shipping";
import Orders from "../pages/ecomerce/Orders";
import Signup from "../pages/SignUp";
import ResetPassword from "../pages/ResetPassword";
import SetPassword from "../pages/SetPassword";
import Password from "../pages/setting/Password";
import SelectPlan from "../pages/submission/SelectPlan";
import SelectPaymentMethod from "../pages/submission/SelectPaymentMethod";
import SubmissionForm from "../pages/submission/SubmissionForm";
import OneClickLogIn from "../pages/OneClickLogIn";
import Templates from "../pages/Templates";
import Template from "../pages/Template";
import TemplateStatistics from "../pages/TemplateStatistics";
import ShopItem from "../pages/ShopItem";
import AppAdjustments from "../layouts/sublayouts/AppAdjustments";
import AppTeam from "../pages/app/team/AppTeam";
import AppTeamCreate from "../pages/app/team/AppTeamCreate";
import Messages from "../pages/app/Messages";
import Billing from "../pages/Billing";
import DangerZone from "../pages/app/DangerZone";
import AppUsers from "../pages/app/AppUsers";

export const AppRouter = () => {

    const dispatch = useDispatch();

    const user = useSelector(
        state => state.user
    )

    useEffect(()=>{
        const urlParams = new URLSearchParams(window.location.search)
        if (urlParams.has('managementToken')) {
            localStorage.setItem('appbuilderjwt',urlParams.get('managementToken'))
            API.get('/signinbytoken').then((response) => {
                response.data.data.type = 'marketplace'
                dispatch(login(response.data.data))
            }).catch(error => {
                dispatch(logout())
            })
        }else if (urlParams.has('blockToken')) {
            localStorage.setItem('appbuilderjwt',urlParams.get('blockToken'))
            API.get('/signinbytoken').then((response) => {
                response.data.data.type = 'defaultBlock'
                dispatch(login(response.data.data))
            }).catch(error => {
                dispatch(logout())
            })
        }else {
            API.get('/me').then((response) => {
                response.data.data.type = null
                dispatch(login(response.data.data))
            }).catch(error => {
                dispatch(logout())
            })
        }
    },[])

    return (
        <Routes>
            <Route path="/nativeapp/:id" element={<NativeApp/>} />
            <Route path="/oneclicklogin/:token" element={<OneClickLogIn/>} />
            {!user?.sign ?
                <>
                    <Route index element={<Auth><Signin/></Auth>} />
                    <Route path='*' element={<Auth><Signin/></Auth>}/>
                </>
                :
                <>
                    {user.user.type === 'marketplace'?
                        <>
                            <Route path="/app/:appid/blocks" element={<MarketplaceManagement><Blocks/></MarketplaceManagement>} />
                            <Route path="/app/:appid/navigation" element={<MarketplaceManagement><Navigation/></MarketplaceManagement>} />
                            <Route path="/app/:appid/pages" element={<MarketplaceManagement><Pages/></MarketplaceManagement>} />
                        </>
                        :
                        <>
                            {user.user.type === 'defaultBlock' ?
                                <>
                                    <Route path="/admin/defaultdata/:slug" element={<Admin><DefaultBlockSettings/></Admin>} />
                                </>
                                :
                                <>
                                    <Route path="/billing" element={<Auth><Billing/></Auth>} />
                                    <Route path="/" element={<Main><Home/></Main>} />
                                    <Route path="/statistic" element={<Main><Statistic/></Main>} />
                                    <Route path="/statistic/app/:appid" element={<Main><StatisticPerApp/></Main>} />
                                    <Route path="/shop" element={<Main><Shop/></Main>} />
                                    <Route path="/shop/templates" element={<Main><Templates/></Main>} />
                                    <Route path="/shop/template/:id" element={<Main><Template/></Main>} />
                                    <Route path="/shop/statistics" element={<Main><TemplateStatistics/></Main>} />
                                    <Route path="/message" element={<Main><Message/></Main>} />
                                    <Route path="/settings" element={<Main><Setting/></Main>} />
                                    <Route path="/billing/payment-method" element={<Main><BillingLayout><PaymentMethod/></BillingLayout></Main>} />
                                    <Route path="/billing/payment-method/new" element={<Main><BillingLayout><NewPaymentMethod/></BillingLayout></Main>} />
                                    <Route path="/billing/subscriptions" element={<Main><BillingLayout><Subscriptions/></BillingLayout></Main>} />
                                    <Route path="/billing/transactions" element={<Main><BillingLayout><Transactions/></BillingLayout></Main>} />
                                    <Route path="/profile" element={<Main><BillingLayout><Profile/></BillingLayout></Main>} />
                                    <Route path="/password" element={<Main><BillingLayout><Password/></BillingLayout></Main>} />
                                    <Route path="/support" element={<Main><Support/></Main>} />
                                    <Route path="/newapp" element={<Main><NewApp/></Main>} />
                                    <Route path="/app/:appid/submissionform" element={<App><SubmissionForm/></App>} />
                                    <Route path="/app/:appid/selectplan" element={<App><SelectPlan/></App>} />
                                    <Route path="/app/:appid/selectpaymentmethod/:planType" element={<App><SelectPaymentMethod/></App>} />
                                    <Route path="/app/:appid/blocks" element={<App><Blocks/></App>} />
                                    <Route path="/app/:appid/navigation" element={<App><Navigation/></App>} />
                                    <Route path="/app/:appid/pages" element={<App><Pages/></App>} />
                                    <Route path="/app/:appid/settings" element={<App><AppAdjustments><Settings/></AppAdjustments></App>} />
                                    <Route path="/app/:appid/team" element={<App><AppAdjustments><AppTeam/></AppAdjustments></App>} />
                                    <Route path="/app/:appid/dangerzone" element={<App><AppAdjustments><DangerZone/></AppAdjustments></App>} />
                                    <Route path="/app/:appid/team/create" element={<App><AppAdjustments><AppTeamCreate/></AppAdjustments></App>} />
                                    <Route path="/app/:appid/sharing" element={<App><Sharing/></App>} />
                                    <Route path="/app/:appid/messages" element={<App><Messages/></App>} />
                                    <Route path="/appusers/:appid" element={<App><AppUsers/></App>} />
                                    <Route path="/blogpost/:appid/posts" element={<App><BlogpostLayout><Posts/></BlogpostLayout></App>} />
                                    <Route path="/blogpost/:appid/post/:id" element={<App><Post/></App>} />
                                    <Route path="/blogpost/:appid/post" element={<App><Post/></App>} />
                                    <Route path="/blogpost/:appid/categories" element={<App><BlogpostLayout><Categories/></BlogpostLayout></App>} />
                                    <Route path="/blogpost/:appid/category/:id" element={<App><BlogpostLayout><Category/></BlogpostLayout></App>} />
                                    <Route path="/blogpost/:appid/category" element={<App><BlogpostLayout><Category/></BlogpostLayout></App>} />
                                    <Route path="/ecommerce/:appid/products" element={<Main><EcommerceLayout><Products/></EcommerceLayout></Main>}/>
                                    <Route path="/ecommerce/:appid/sales" element={<Main><EcommerceLayout><SalesCoupons/></EcommerceLayout></Main>}/>
                                    <Route path="/ecommerce/:appid/categories" element={<Main><EcommerceLayout><ProductCategories/></EcommerceLayout></Main>}/>
                                    <Route path="/ecommerce/:appid/shippingtaxes" element={<Main><EcommerceLayout><ShippingTaxes/></EcommerceLayout></Main>}/>
                                    <Route path="/ecommerce/:appid/product/:productid" element={<Main><Product/></Main>}/>
                                    <Route path="/ecommerce/:appid/product" element={<Main><Product/></Main>}/>
                                    <Route path="/ecommerce/:appid/product/:product/variant/:variant" element={<Main><Variant/></Main>}/>
                                    <Route path="/ecommerce/:appid/product/:product/variant" element={<Main><Variant/></Main>}/>
                                    <Route path="/ecommerce/:appid/sale" element={<Main><EcommerceLayout><SalesCoupon/></EcommerceLayout></Main>}/>
                                    <Route path="/ecommerce/:appid/sale/:productid" element={<Main><EcommerceLayout><SalesCoupon/></EcommerceLayout></Main>}/>
                                    <Route path="/ecommerce/:appid/shippingitem" element={<Main><EcommerceLayout><Shipping/></EcommerceLayout></Main>}/>
                                    <Route path="/ecommerce/:appid/orders" element={<Main><EcommerceLayout><Orders/></EcommerceLayout></Main>}/>
                                </>
                            }
                        </>
                    }
                </>
            }
            <Route path="/signin" element={<Auth><Signin/></Auth>} />
            <Route path="/signup" element={<Auth><Signup/></Auth>} />
            <Route path="/restore" element={<Auth><ResetPassword/></Auth>} />
            <Route path="/auth/restore/:id" element={<Auth><SetPassword/></Auth>} />
            <Route path="/admin/defaultdata/:slug/:token" element={<Admin><DefaultBlockSettings/></Admin>} />
        </Routes>
    )
}
