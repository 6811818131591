import React, {useEffect, useState} from "react";
import {FiLayers} from "react-icons/fi";
import SectionItem from "../../../components/builder/SectionItem";
import uniqid from "uniqid";
import _ from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import API from "../../../utils/API";
import {reset} from "../../../redux/actions/pageeditorActions";
import {IoMdArrowRoundBack} from "react-icons/io";

const BlocksSelect = () => {
    const dispatch = useDispatch()

    const [page,setPage] = useState()

    const [keyword, setKeyword] = useState('')

    const pageeditor = useSelector(state => {
        return state.pageEditor
    });

    const app = useSelector(state => {
        return state.app
    });

    const [blocks,setBlocks] = useState(null)
    const [blocksUser,setBlocksUser] = useState(null)

    const [myTemplate,setMyTemplate] = useState(false)

    let { appid } = useParams();

    useEffect(()=>{
        API.get('/user/block/all').then((response) => {
            setBlocks(response.data.data)
        })
        API.get(`/user/block/category/user/${appid}`).then((response) => {
            setBlocksUser(response.data.data)
        })
    },[pageeditor.templateReload])

    useEffect(()=>{
        if (appid && pageeditor.app && pageeditor.app !== appid) {
            dispatch(reset())
        }
    },[appid,pageeditor])

    useEffect(()=>{
        API.get(`/page/${pageeditor.page}`).then((response) => {
            setPage(response.data.data.page)
        })
    },[pageeditor.page])

    return <>
        {pageeditor.sections.length > 0 &&
            <div className="blocksContainer manageBlock">
                <div className="titleBlock">
                    <div className="title">
                        <div className="row">
                            <div className="col-3 iconBlock">
                                <FiLayers className="icon"/>
                            </div>
                            <div className="col-9 name">
                                components
                            </div>
                        </div>
                    </div>
                </div>
                <div className="list">
                    {!myTemplate &&
                    <div className="row m-0">
                        {page && page.issplashscreen !== '1' &&
                        <div className="col-12 mt-1 mb-1">
                            <input type="text" className="form-control" placeholder="Search components" value={keyword} onChange={(e) => setKeyword(e.target.value)}/>
                        </div>
                        }
                        {(blocks && page) &&
                            blocks.map((block,key)=>{
                                if ((!keyword.length || (block.title.toUpperCase().includes(keyword.toUpperCase()))) && (block.slug !== 'splashscreen' && page.issplashscreen !== '1') || (block.slug === 'splashscreen' && page.issplashscreen === '1')) {
                                    return <div className="col-6" key={key}>
                                        {block.slug !== 'myblocks' ?
                                            <SectionItem name={block.title} image={block.image} source={true}
                                                         component={{
                                                             id: uniqid(),
                                                             component: block.slug,
                                                             data: _.isObject(block.defaultdata) ? block.defaultdata : {}
                                                         }}>
                                            </SectionItem>
                                            :
                                            (blocksUser && blocksUser.length > 0) &&
                                            <div style={{
                                                width: "100%",
                                                height: "100%",
                                                cursor: "pointer",
                                                position: app.openedMenu ? "" : "relative"
                                            }} onClick={() => setMyTemplate(true)}>
                                                <div className="blockSource text-center pt-3">
                                                    <img src={block.image} style={{width: "38px"}}/><br/>
                                                    <span className="name">{block.title}</span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }else {
                                    return <></>
                                }
                            })
                        }
                    </div>
                    }
                    {(myTemplate && blocksUser && blocksUser.length > 0) &&
                        <div className="row m-0 mt-2">
                            <div className="col-12 text-center title" style={{fontSize:"16px"}}><div className="mr-3 d-inline cursorPointer"><IoMdArrowRoundBack onClick={()=>setMyTemplate(false)}/></div> My blocks</div>
                            {blocksUser.map((block,key)=>{
                                return <div className="col-6" key={key}>
                                    <SectionItem name={block.title} image={block.image} source={true} isPersistent={block?.defaultdata?.persistDataAcrossAllInstances} templateId={block.blockId} component={{
                                        id: uniqid(),
                                        component: block.slug,
                                        data: _.isObject(block.defaultdata)?block.defaultdata:{}
                                    }}></SectionItem>
                                </div>
                            })}
                        </div>
                    }
                </div>
            </div>
        }
        </>;
}

export default BlocksSelect;
