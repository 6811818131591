import React from "react";
import {default as ImageListEditor} from "../../editor/subelements/ImageList";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import SliderInput from "../../editor/subelements/SliderInput";
import Animation from "../../editor/Animation";
import {MdAnimation} from "react-icons/md";
import {useCustomAnimation} from "../../helpers/animation";
import ColorPicker from "../../editor/subelements/ColorPicker";
import Spacing from "../../editor/subelements/Spacing";
import {bgImage} from "../../helpers/bgImage";

const Images = (props) => {
    const animation = useCustomAnimation(props.data.animation)

    if (props.edit) {
        return <div>
            <div className="editItem p-2">
                <ImageListEditor name={"image"} value={props.data.image} setValue={props.setValue} featureName={""}/>
                <div className="subElement">
                    <SliderInput name={"imagesPerRow"} value={props.data.imagesPerRow??1} setValue={props.setValue} featureName={"Images per row"} min={1} max={4} type={''}/>
                </div>
                <div className="subElement">
                    <ColorPicker name={"bgColor"} value={props.data?.bgColor} setValue={props.setValue} featureName={"Background color"}/>
                </div>
                <div className="subElement">
                    <Spacing name={"margin"} value={props.data?.margin} setValue={props.setValue} featureName={"Margin"}/>
                </div>
                <div className="subElement">
                    <Spacing name={"padding"} value={props.data?.padding} setValue={props.setValue} featureName={"Padding"}/>
                </div>
                <div className="subElement">
                    <Spacing name={"borderRadius"} value={props.data?.borderRadius} setValue={props.setValue} featureName={"Border radius"}/>
                </div>
                <div className="subElement">
                    <ColorPicker name={"borderColor"} value={props.data?.borderColor} setValue={props.setValue} featureName={"Border color"}/>
                </div>
                <div className="subElement">
                    <SliderInput name={"borderWidth"} value={props.data?.borderWidth} setValue={props.setValue} featureName={"Border width"} min={0} max={20}/>
                </div>
            </div>
            <div className="editItem">
                <Animation name={'animation'} setValue={props.setValue} value={props.data.animation} featureName="Animation" imageName={<MdAnimation style={{fontSize:"18px"}}/>}/>
            </div>
        </div>
    }else {
        return <div style={bgImage(props?.data,true)??{}} className={animation}>{props?.data?.image?.length > 0 &&
        props?.data?.image?.length === 1 ?
            <img className="img-fluid" src={props?.data?.image[0]}/>
            :
            <ImageList variant="woven"
                       cols={(props.data.imagesPerRow && props.data.imagesPerRow <= props?.data?.image?.length) ? props.data.imagesPerRow : 1}>
                {props?.data?.image?.map((item) => (
                    <ImageListItem key={item.img}>
                        <img
                            src={`${item}`}
                            srcSet={`${item}`}
                            loading="lazy"
                        />
                    </ImageListItem>
                ))}
            </ImageList>
        }</div>
    }
}

export default Images
