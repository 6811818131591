import React, {useState} from "react";
import {TbDots} from "react-icons/tb";
import { Dropdown } from 'rsuite';
import API from "../../utils/API";
import { confirmAlert } from 'react-confirm-alert';

const Subscriptions = () => {

    const [subscriptions, setSubscriptions] = useState(null)

    const loadSubscriptions = () => {
        API.get('/billing/subscriptions').then(res => {
            setSubscriptions(res.data.data)
        })
    }

    const cancelSubscription = (id) => {
        confirmAlert({
            title: 'Cancel subscription',
            message: 'Are you sure you want to cancel this subscription?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        API.delete(`/billing/subscription/${id}`).then((result) => {
                            loadSubscriptions();
                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    }

    useState(()=>{
       loadSubscriptions()
    },[])

    return (
        <div className="mt-4 ms-3 me-5">
            {(subscriptions && subscriptions.length > 0) &&
            <div className="mt-3">
                <table className="list w-100">
                    <tr>
                        <th>App</th>
                        <th>Product</th>
                        <th>Status</th>
                        <th>Price</th>
                        <th></th>
                    </tr>
                    {subscriptions.map((subscription,subscriptionKey) => {
                        return <tr>
                            <td style={{maxWidth:"100px"}}>
                                {subscription.app.icon &&
                                    <img className="appIcon" src={subscription.app.icon}/>
                                }
                                 <span className="appName">{subscription.app.title}</span>
                            </td>
                            <td>{subscription.product.title}</td>
                            <td><span className="statusActive">{subscription.billingsubscription.status}</span></td>
                            <td>${subscription.product.price}</td>
                            <td>
                                <Dropdown renderToggle={TbDots} placement={'leftStart'}>
                                    <Dropdown.Item onClick={()=>cancelSubscription(subscription.billingsubscription.id)}>Cancel</Dropdown.Item>
                                </Dropdown>
                            </td>
                        </tr>
                    })}
                </table>
            </div>
            }
        </div>
    )
}

export default Subscriptions
