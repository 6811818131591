import React,{useState} from "react";
import {useParams} from "react-router-dom";
import Categories from "./categories/Categories";
import Brands from "./categories/Brands";
import Tags from "./categories/Tags";

const ProductCategories = () => {

    const [type,setType] = useState('Category');

    let { appid } = useParams()

    return (
        <>
        <div className="row category ms-2 me-4 justify-content-between p-0">
            <div className="col area me-4 p-0">
                <div className="m-2">
                    <div className="header">
                        Types
                    </div>
                </div>
                <div className={`type ${type==='Category'?'activeType':''}`} onClick={()=>setType('Category')}>
                    <span className={`${type==='Category'?'active':''}`}>Category</span>
                </div>
                <div className={`type ${type==='Tags'?'activeType':''}`} onClick={()=>setType('Tags')}>
                    <span className={`${type==='Tags'?'active':''}`}>Tags</span>
                </div>
                <div className={`type ${type==='Brand'?'activeType':''}`} onClick={()=>setType('Brand')}>
                    <span className={`${type==='Brand'?'active':''}`}>Brand</span>
                </div>
            </div>
            <div className="col-8 area p-0">
                {type === 'Category' &&
                    <Categories appId={appid}/>
                }
                {type === 'Brand' &&
                    <Brands appId={appid}/>
                }
                {type === 'Tags' &&
                    <Tags appId={appid}/>
                }
            </div>
        </div>
        </>
    )
}

export default ProductCategories
