import React from 'react';
import "./assets/style/global.scss";
import { Provider } from "react-redux"
import store from "./redux/store/index";
import {AppRouter} from "./routers";
import {
    BrowserRouter as Router
} from "react-router-dom";
import { LoadScript } from '@react-google-maps/api';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import 'react-notifications-component/dist/theme.css'
import { ReactNotifications } from 'react-notifications-component'
import 'rsuite/dist/rsuite.min.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ErrorBoundary } from "react-error-boundary";
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

LogRocket.init('fdlzlc/appbuilder');
setupLogRocketReact(LogRocket);

function App() {
  return (
      <ErrorBoundary
          fallback={<div>Reloading</div>}
          onError={(error, errorInfo) => {
              window.location.reload();
          }}
      >
      <GoogleOAuthProvider clientId="320296623065-3p9871798a5jtc4qgm9c7vhpe7muo0h3.apps.googleusercontent.com">
      <LoadScript googleMapsApiKey="AIzaSyAz9ek3YxuibHs9V8osx0f7PotG7FYczio" libraries={["places"]}>
          <ReactNotifications />
          <Provider store={store}>
          <Router>
            <AppRouter/>
          </Router>
      </Provider>
      </LoadScript>
      </GoogleOAuthProvider>
      </ErrorBoundary>
  )
}

export default App;
