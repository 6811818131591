import React,{useState,useEffect} from "react";
import {BsFiles,BsFillGridFill,BsShareFill} from "react-icons/bs";
import { FaGithubAlt } from "react-icons/fa";
import {FiLayers} from "react-icons/fi";
import {HiOutlineDeviceMobile} from "react-icons/hi";
import {FiLogOut} from "react-icons/fi";
import {logout} from "../redux/actions/userActions";
import {useDispatch} from "react-redux";
import {
    Link,
    useParams
} from "react-router-dom";
import "../assets/style/appsidebar.scss";
import API from "../utils/API";

const AppBar = () => {

    const [currentPage,setCurrentPage] = useState('pages')
    const [app, setApp] = useState(null)

    useEffect(()=>{
        if (window.location.href.indexOf('/pages') !== -1) {
            setCurrentPage('pages')
        }
        if (window.location.href.indexOf('/navigation') !== -1) {
            setCurrentPage('navigation')
        }
        if (window.location.href.indexOf('/sharing') !== -1) {
            setCurrentPage('sharing')
        }
        if (window.location.href.indexOf('/settings') !== -1) {
            setCurrentPage('settings')
        }
    },[window.location.href])

    const dispatch = useDispatch();

    let { appid } = useParams();

    useEffect(() => {
        API.get(`/app/get/${appid}`).then((response) => {
            setApp(response.data.data)
        })
    },[appid]);

    return (
        <nav id="sidebarMenu" className="col-auto appsidebar p-0 m-0">
            <div className="position-sticky h-100 position-relative" style={{width:"88px"}}>
                <Link to={'/'}><div className="text-center mt-4">
                    <FaGithubAlt className="logo" color={"black"}/>
                </div></Link>
                <div className="icons">
                    <Link to={'/app/'+appid+'/pages'}>
                        <div onClick={()=>setCurrentPage('pages')} className={`iconBox text-center ${(currentPage==='pages')?`activeIconBox`:``}`}>
                            <BsFiles className="icon"/>
                        </div>
                    </Link>
                    <Link to={'/app/'+appid+'/navigation'}>
                        <div onClick={()=>setCurrentPage('navigation')} className={`iconBox text-center ${(currentPage==='navigation')?`activeIconBox`:``}`}>
                            <FiLayers className="icon"/>
                        </div>
                    </Link>
                    <Link to={'/app/'+appid+'/blocks'}>
                        <div onClick={()=>setCurrentPage('blocks')} className={`iconBox text-center ${(currentPage==='blocks')?`activeIconBox`:``}`}>
                            <BsFillGridFill className="icon"/>
                        </div>
                    </Link>
                    {(app && app.published) &&
                    <Link to={'/app/'+appid+'/sharing'}>
                        <div onClick={()=>setCurrentPage('sharing')} className={`iconBox text-center ${(currentPage==='sharing')?`activeIconBox`:``}`}>
                            <BsShareFill className="icon"/>
                        </div>
                    </Link>
                    }
                    <Link to={'/app/'+appid+'/settings'}>
                        <div onClick={()=>setCurrentPage('settings')} className={`iconBox text-center ${(currentPage==='settings')?`activeIconBox`:``}`}>
                            <HiOutlineDeviceMobile className="icon"/>
                        </div>
                    </Link>
                </div>
                <div className="position-absolute bottom-0 w-100" style={{marginBottom:"30px"}}>
                    <div className="iconBox text-center" onClick={()=>dispatch(logout())}>
                        <FiLogOut className="icon"/>
                    </div>
                </div>
            </div>

        </nav>
    )
}

export default AppBar
