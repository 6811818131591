import React from "react";
import "../assets/style/auth.scss"
import { Helmet } from 'react-helmet'

const Auth = ({ children }) => {

    return (
        <main className="content appBuilder">
            <Helmet>
                <link rel="stylesheet" href={`/css/auth.css`} />
            </Helmet>
            {children}
        </main>
    )
}

export default Auth
