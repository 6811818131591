import React from "react";
import SliderInput from "../../editor/subelements/SliderInput";
import Animation from "../../editor/Animation";
import {MdAnimation} from "react-icons/md";
import {useCustomAnimation} from "../../helpers/animation";
import ColorPicker from "../../editor/subelements/ColorPicker";
import Spacing from "../../editor/subelements/Spacing";
import {bgImage} from "../../helpers/bgImage";
import TextInput from "../../editor/subelements/TextInput";
import { Timeline } from 'react-twitter-widgets'
import {useClickDisable} from "../../helpers/clickDisable";

const TwitterFeed = (props) => {

    const animation = useCustomAnimation(props.data.animation)
    const clickDisable = useClickDisable()

    if (props.edit) {
        return <div>
            <div className="editItem p-2">
                <div className="subElement subElementNoBorderTop">
                    <TextInput name={"feed"} value={props.data?.feed} setValue={props.setValue} featureName={"Account name"}/>
                </div>
                <div className="subElement">
                    <ColorPicker name={"bgColor"} value={props.data?.bgColor} setValue={props.setValue} featureName={"Background color"}/>
                </div>
                <div className="subElement">
                    <Spacing name={"margin"} value={props.data?.margin} setValue={props.setValue} featureName={"Margin"}/>
                </div>
                <div className="subElement">
                    <Spacing name={"padding"} value={props.data?.padding} setValue={props.setValue} featureName={"Padding"}/>
                </div>
                <div className="subElement">
                    <Spacing name={"borderRadius"} value={props.data?.borderRadius} setValue={props.setValue} featureName={"Border radius"}/>
                </div>
                <div className="subElement">
                    <ColorPicker name={"borderColor"} value={props.data?.borderColor} setValue={props.setValue} featureName={"Border color"}/>
                </div>
                <div className="subElement">
                    <SliderInput name={"borderWidth"} value={props.data?.borderWidth} setValue={props.setValue} featureName={"Border width"} min={0} max={20}/>
                </div>
                <div className="subElement">
                    <SliderInput name={"feedHeight"} value={props.data?.feedHeight??400} setValue={props.setValue} featureName={"Feed height"} min={10} max={1000}/>
                </div>
            </div>
            <div className="editItem">
                <Animation name={'animation'} setValue={props.setValue} value={props.data.animation} featureName="Animation" imageName={<MdAnimation style={{fontSize:"18px"}}/>}/>
            </div>
        </div>
    }else {
        return <div style={bgImage(props?.data)??{}} className={animation}>{props?.data?.feed?.length > 0 &&
            <div className={clickDisable}>
                <Timeline
                    dataSource={{
                        sourceType: 'profile',
                        screenName: props?.data?.feed
                    }}
                    options={{
                        height: props.data?.feedHeight??400
                    }}
                />
            </div>
        }</div>
    }
}

export default TwitterFeed
