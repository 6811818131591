import React from "react"
import {BsTrash} from 'react-icons/bs'
import _ from "lodash"

const Elements = ({setValue, name, value, featureName, itemComponent}) => {

    const deleteItem = (id) => {
        if (value?.list && value?.list?.length) {
            let newList = [], n = value?.list?.length, i
            for (i=0; i < n; i++) {
                if (i !== id) {
                    newList.push(value?.list[i])
                }
            }
            setValue(name+".list",newList)
        }
    }

    const addItem = () => {
        let currentItems = value?.list??[]
        currentItems.push({})
        setValue(name+".list",currentItems)
    }

    const editItem = (id,valName,newValue) => {
        if (value?.list && value?.list?.length) {
            let newList = [], n = value?.list?.length, i
            for (i=0; i < n; i++) {
                let currentItem = value?.list[i]
                if (i !== id) {
                    newList.push(currentItem)
                }else {
                    _.set(currentItem, valName, newValue)
                    newList.push(currentItem)
                }
            }
            setValue(name+".list",newList)
        }
    }

    return <div className="">
        <div className="row">
            <div className="col pt-2">
                <span className="blockTitle">{featureName}</span>
            </div>
        </div>
        <div className="row justify-content-center">
            <div className="col-auto pt-2">
               <span className="cursorPointer" onClick={()=>addItem()}><div className="addElementIcon">+</div></span>
            </div>
        </div>
        {(value?.list && value?.list?.length > 0) &&
            <ul className="list-group mt-2">
                {value?.list.map((item,itemKey)=>{
                    return (<li className="list-group-item" key={itemKey}>
                        <div className="row justify-content-end">
                            <div className="col-auto">
                                <span className="cursorPointer" onClick={()=>deleteItem(itemKey)}><BsTrash/></span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                {React.cloneElement(itemComponent,{item:item,itemKey:itemKey,editItem:editItem})}
                            </div>
                        </div>
                    </li>)
                })}
            </ul>
        }
    </div>
}

export default Elements
