import React,{useState,useEffect} from "react";
import {BsFiles,BsFillGridFill} from "react-icons/bs";
import {FiLayers} from "react-icons/fi";
import {
    Link,
    useParams
} from "react-router-dom";
import "../assets/style/appsidebar.scss";

const AppBarMarketplaceManagement = () => {

    const [currentPage,setCurrentPage] = useState('pages')

    useEffect(()=>{
        if (window.location.href.indexOf('/pages') !== -1) {
            setCurrentPage('pages')
        }
        if (window.location.href.indexOf('/navigation') !== -1) {
            setCurrentPage('navigation')
        }
        if (window.location.href.indexOf('/blocks') !== -1) {
            setCurrentPage('blocks')
        }
    },[window.location.href])

    let { appid } = useParams();

    return (
        <nav id="sidebarMenu" className="col-auto appsidebar p-0 m-0">
            <div className="position-sticky h-100 position-relative" style={{width:"88px"}}>
                <div className="icons">
                    <Link to={'/app/'+appid+'/pages'}>
                        <div onClick={()=>setCurrentPage('pages')} className={`iconBox text-center ${(currentPage==='pages')?`activeIconBox`:``}`}>
                            <BsFiles className="icon"/>
                        </div>
                    </Link>
                    <Link to={'/app/'+appid+'/navigation'}>
                        <div onClick={()=>setCurrentPage('navigation')} className={`iconBox text-center ${(currentPage==='navigation')?`activeIconBox`:``}`}>
                            <FiLayers className="icon"/>
                        </div>
                    </Link>
                    <Link to={'/app/'+appid+'/blocks'}>
                        <div onClick={()=>setCurrentPage('blocks')} className={`iconBox text-center ${(currentPage==='blocks')?`activeIconBox`:``}`}>
                            <BsFillGridFill className="icon"/>
                        </div>
                    </Link>
                </div>
            </div>

        </nav>
    )
}

export default AppBarMarketplaceManagement
