import React from "react";
import "../assets/style/appDevice.scss"
import {useSelector} from "react-redux";
import {build} from "./builder/blocks/BlockFactory";

const AppDevice = ({app, page}) => {

    const pageeditor = useSelector(state => {
        return state.pageEditor
    });

    return <div className="appDevice">
        {pageeditor.sections &&
            pageeditor.sections.map((section,key)=>{
                return <div className={`row p-0 row-eq-height sectionRow m-0`} key={key}>
                    {section.elements.map((element,elementKey)=>{
                        return <div key={elementKey} className={`m-0 p-0 col-${12/(1*section.elements.length)}`}>
                            <div className="h-100">
                                {React.cloneElement(build(element.component),{...element})}
                            </div>
                        </div>
                    })
                    }
                </div>
            })
        }
    </div>
}

export default AppDevice
