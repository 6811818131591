import React, {useEffect, useState} from "react";
import "../assets/style/statistic.scss"
import API from "../utils/API";
import StatisticApp from "../components/apps/StatisticApp";

const Statistic = () => {
    const [apps,setApps] = useState([])

    useEffect(()=>{
        API.get('/app/getall').then((response) => {
            setApps(response.data.data)
        })
    },[])

    return (
        <div className="mainPage statistic">
            <div className="hero">
                <span className="title">Statistic</span>
            </div>
            <div className="row mt-3 justify-content-md-center">
                {apps.map((item,key)=>{
                    return (
                        <div className="col-auto" key={key}>
                            <StatisticApp app={item}/>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Statistic
