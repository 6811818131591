import React from "react";
import OnOffFeature from "../../editor/OnOffFeature";
import InlineEditableText from "../../InlineEditableText";
import {MdSearch,MdAnimation} from "react-icons/md";
import Animation from "../../editor/Animation";
import {bgImage} from "../../helpers/bgImage"
import {useCustomAnimation} from "../../helpers/animation"
import 'animate.css';
import ColorPicker from "../../editor/subelements/ColorPicker";
import SliderInput from "../../editor/subelements/SliderInput";
import CategorySelect from "../../editor/subelements/ecommerce/CategorySelect";

const Ecomsearch = (props) => {
    const animation = useCustomAnimation(props.data.animation)

    if (props.edit) {
        return <div>
            <div className="editItem">
                <OnOffFeature name={'titleEnabled'} setValue={props.setValue} value={props.data.titleEnabled} featureName="Title" imageName="h1"/>
            </div>
            <div className="editItem">
                <OnOffFeature name={'descriptionEnabled'} setValue={props.setValue} value={props.data.descriptionEnabled} featureName="Description" imageName="p"/>
            </div>
            <div className="subElement">
                <CategorySelect name={"category"} value={props.data?.category??[]} setValue={props.setValue} featureName={"Category"}/>
            </div>
            <div className="subElement">
                <SliderInput name={"products"} type={"products"} value={props.data?.products??1} setValue={props.setValue} featureName={"Products per row"} min={1} max={3}/>
            </div>
            <div className="subElement">
                <ColorPicker name={"titleColor"} value={props.data?.titleColor??"black"} setValue={props.setValue} featureName={"Product name color"}/>
            </div>
            <div className="editItem">
                <div className="p-2">
                    <OnOffFeature name={'productDescriptionEnabled'} setValue={props.setValue} value={props.data.productDescriptionEnabled} featureName="Product description enabled" imageName="P"/>
                </div>
                {props.data.productDescriptionEnabled === "on" &&
                    <div className="subElement">
                        <ColorPicker name={"descriptionColor"} value={props.data?.descriptionColor??"black"} setValue={props.setValue} featureName={"Product description color"}/>
                    </div>
                }
            </div>
            <div className="subElement">
                <ColorPicker name={"priceColor"} value={props.data?.priceColor??"black"} setValue={props.setValue} featureName={"Product price color"}/>
            </div>
            <div className="editItem">
                <Animation name={'animation'} setValue={props.setValue} value={props.data.animation} featureName="Animation" imageName={<MdAnimation style={{fontSize:"18px"}}/>}/>
            </div>
        </div>
    }else {
        return <div style={bgImage(props?.data?.image)??{}} className={animation}>
            {props.data.titleEnabled === "on" &&
                <InlineEditableText isResult={true} setValue={props.setValue} name="title">{props.data.title}</InlineEditableText>
            }
            {props.data.descriptionEnabled === "on" &&
                <InlineEditableText isResult={true} setValue={props.setValue} name="description">{props.data.description}</InlineEditableText>
            }
            <div className="row m-0 mt-2">
                <div className="col m-1">
                    <div className="wrapperInputIcon">
                        <div className="icon"><MdSearch/></div>
                        <input type="text" className="form-control input" style={{borderRadius:"10px"}} placeholder="Search products"/>
                    </div>
                </div>
            </div>
            <div className="row m-0 mt-2">
                <div className="col m-1 text-center" style={{border:"solid 1px lightgray",borderRadius:"20px"}}>
                    <h3>IPad</h3>
                    <img src="https://gettow.s3.amazonaws.com/image/6412b30750260.jfif" className="img-fluid"/>
                    <div><span className="text-primary fs-4">$1200</span></div>
                    <p>test test test</p>
                </div>
                <div className="col m-1 text-center" style={{border:"solid 1px lightgray",borderRadius:"20px"}}>
                    <h3>IPad</h3>
                    <img src="https://gettow.s3.amazonaws.com/image/6412b30750260.jfif" className="img-fluid"/>
                    <div><span className="text-primary fs-4">$1200</span></div>
                    <p>test test test</p>
                </div>
            </div>
        </div>
    }
}

export default Ecomsearch
