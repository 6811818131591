import React from "react";
import ImageUploader from "react-images-upload";
import {useSelector} from "react-redux";
import API from "../../../../utils/API";

const ImageList = ({setValue, name, value, featureName}) => {

    const pageeditor = useSelector(state => {
        return state.pageEditor
    });

    const onDrop = (picture,pictureURI) => {
        API.post('/imagemulti/' + pageeditor.app, {pictureURI}).then((response) => {
            setValue(name, response.data.data)
        });
    };

    return <div>
        <div className="row">
            <div className="col">
                <span className="blockTitle">{featureName}</span>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <ImageUploader
                    withIcon={true}
                    onChange={onDrop}
                    imgExtension={[".jpg",".jpeg", ".gif", ".png", ".gif"]}
                    maxFileSize={5242880}
                    withPreview={true}
                    multiple={false}
                    defaultImages={value?value:[]}
                />
            </div>
        </div>
    </div>
}

export default ImageList
