import React,{useState,useEffect} from "react";
import OnOffFeature from "../../editor/OnOffFeature";
import Button from "../../editor/Button";
import InlineEditableText from "../../InlineEditableText";
import Image from "../../editor/Image";
import {BsImage} from "react-icons/bs";
import {MdAnimation} from "react-icons/md";
import Animation from "../../editor/Animation";
import {bgImage} from "../../helpers/bgImage"
import {buttonStyle} from "../../helpers/buttonStyle"
import {useCustomAnimation} from "../../helpers/animation"
import 'animate.css';
import {useSelector} from "react-redux";
import { IoIosColorPalette } from "react-icons/io";
import BGColor from "../../editor/BGColor";


const Hero = (props) => {
    const animation = useCustomAnimation(props.data.animation)
    const [buttonHref, setButtonHref] = useState('')

    const pageeditor = useSelector(state => {
        return state.pageEditor
    });

    useEffect(() => {
        let hrefTemp = '';
        if (props?.data?.button?.link) {
            if (props?.data?.button?.link?.type === "webaddress") {
                hrefTemp = props?.data?.button?.link?.url;
            } else if (props?.data?.button?.link?.type === "email") {
                hrefTemp = "emailto:" + props?.data?.button?.link?.email;
            } else if (props?.data?.button?.link?.type === "phone") {
                hrefTemp = "tel:" + props?.data?.button?.link?.phone;
            } else if (props?.data?.button?.link?.type === "file") {
                hrefTemp = props?.data?.button?.link?.file;
            } else {
                hrefTemp = "internallink" + props?.data?.button?.link?.page;
            }
        }
        setButtonHref(hrefTemp);
    }, [props?.data?.button?.link])

    if (props.edit) {
        return <div>
            <div className="editItem">
                <OnOffFeature name={'textEnabled'} setValue={props.setValue} value={props.data.textEnabled} featureName="Title" imageName="h1"/>
            </div>
            <div className="editItem">
                <OnOffFeature name={'subtitleEnabled'} setValue={props.setValue} value={props.data.subtitleEnabled} featureName="Subtitle" imageName="h2"/>
            </div>
            <div className="editItem">
                <OnOffFeature name={'paragraphEnabled'} setValue={props.setValue} value={props.data.paragraphEnabled} featureName="Paragraph" imageName="p"/>
            </div>
            <div className="editItem">
                <Button name={'button'} setValue={props.setValue} value={props.data.button} featureName="Button" imageName="b"/>
            </div>
            <div className="editItem">
                <Image name={'image'} setValue={props.setValue} value={props.data.image} featureName="Image" imageName={<BsImage style={{fontSize:"18px"}}/>}/>
            </div>
            <div className="editItem">
                <BGColor name={'bgColor'} setValue={props.setValue} value={props.data.bgColor} featureName="Background" imageName={<IoIosColorPalette style={{fontSize:"18px"}}/>}/>
            </div>
            <div className="editItem">
                <Animation name={'animation'} setValue={props.setValue} value={props.data.animation} featureName="Animation" imageName={<MdAnimation style={{fontSize:"18px"}}/>}/>
            </div>
        </div>
    }else {
        return <div style={bgImage(props?.data?.image?.enabled==='on'?props?.data?.image:(props?.data?.bgColor?.enabled==='on'?props?.data?.bgColor:{}))} className={animation}>
            <div className="heroCenter">
                {props.data.textEnabled === "on" &&
                    <InlineEditableText isResult={true} setValue={props.setValue} name="text">{props.data.text}</InlineEditableText>
                }
                {props.data.subtitleEnabled === "on" &&
                    <InlineEditableText isResult={true} setValue={props.setValue} name="subTitle">{props.data.subTitle}</InlineEditableText>
                }
                {props.data.paragraphEnabled === "on" &&
                    <InlineEditableText isResult={true} setValue={props.setValue} name="paragraph">{props.data.paragraph}</InlineEditableText>
                }
                {props?.data?.button?.enabled === "on" &&
                    <div className="row justify-content-center">
                        <div className="col-auto"><div onClick={(e)=>e.appLink = !pageeditor.app?buttonHref:false} style={buttonStyle(props?.data?.button)}><InlineEditableText isResult={true} setValue={props.setValue} name="buttonText">{props.data.buttonText}</InlineEditableText></div></div>
                    </div>
                }
            </div>
        </div>
    }
}

export default Hero
