import React from "react";
import {useParams} from "react-router-dom";
import "../../assets/style/pages.scss"
import PageView from "./blocks/PageView";
import ElementManagement from "./blocks/ElementManagement";
import {HTML5Backend} from "react-dnd-html5-backend";
import { DndProvider } from 'react-dnd'
import "../../assets/style/blocks.scss"
import {useIsTablet} from "../../hooks/useIsTablet";
import PagesSelect from "./blocks/PagesSelect";
import {useIsEditor} from "../../hooks/useIsEditor";
import {set} from "../../redux/actions/pageeditorActions";
import {useDispatch} from "react-redux";
import {BsFiles} from "react-icons/bs";

const Pages = () => {
    const isTablet = useIsTablet()
    const isEditor = useIsEditor();

    let { appid } = useParams()

    const dispatch = useDispatch()

    const setPagesView = () => {
        dispatch(set({editBlockId:null,editType:null}))
    }

    return (
        <>
            <div className="row blocks">
                <div className={`col ${isTablet && 'm-0 p-0'}`}>
                    {isTablet ?
                        <div className="row justify-content-center">
                            <div className="col-auto">
                            {!isEditor ?
                                <PagesSelect/>
                                :
                                <>
                                    <div className="pages manageBlock mb-2" style={{height:"unset"}} onClick={()=>setPagesView()}>
                                        <div className="titleBlock">
                                            <div className="title">
                                                <div className="row">
                                                    <div className="col-3 iconBlock">
                                                        <BsFiles className="icon"/>
                                                    </div>
                                                    <div className="col-9 name">
                                                        pages
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                            <ElementManagement/>
                            </div>
                        </div>
                        :
                        <PagesSelect/>
                    }
                </div>
                <div className={`col ${isTablet && 'm-0 p-0'}`}>
                    <div className="row justify-content-center">
                        <div className="col-auto blocks">
                            <div className="appEditContainer" id="app-root">
                                <DndProvider backend={HTML5Backend}>
                                    <PageView appid={appid}/>
                                </DndProvider>
                            </div>
                        </div>
                    </div>
                </div>
                {!isTablet &&
                <div className="col">
                    <ElementManagement/>
                </div>
                }
                {!isTablet &&
                    <div className="col-auto" style={{width:"88px"}}></div>
                }
        </div>
        </>
    )
}

export default Pages
