import React,{useState,useEffect} from "react"
import SliderInput from "../../editor/subelements/SliderInput"
import Animation from "../../editor/Animation"
import {MdAnimation} from "react-icons/md"
import {useCustomAnimation} from "../../helpers/animation"
import ColorPicker from "../../editor/subelements/ColorPicker"
import Spacing from "../../editor/subelements/Spacing"
import {bgImage} from "../../helpers/bgImage"
import OnOff from "../../editor/subelements/OnOff"
import {AiOutlineLink} from "react-icons/ai"
import TextInput from "../../editor/subelements/TextInput";
import API from "../../../../utils/API";

const AirbnbReviews = (props) => {

    const [list,setList] = useState(null)

    const animation = useCustomAnimation(props.data.animation)

    const urlRegex = /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/;

    useEffect(()=>{
        if ((props.data?.rssurl && props.data?.rssurl?.length > 0)) {

            if (urlRegex.test(props.data?.rssurl)) {
                API.post('/rssfeed', {url: props.data?.rssurl}).then((response) => {
                    setList(response?.data?.data?.channel?.item)
                });
            }
        }
    },[props.data?.rssurl])

    if (props.edit) {
        return <div>
            <div className="editItem p-2">
                <div className="subElement subElementNoBorderTop">
                    <TextInput name={"rssurl"} value={props.data?.rssurl} featureName={"RSS Feed url"} setValue={props.setValue}/>
                </div>
                <div className="subElement">
                    <SliderInput name={"articles"} value={props.data.articles??10} setValue={props.setValue} featureName={"Articles"} min={1} max={30} type={''}/>
                </div>
                <div className="subElement">
                    <OnOff name={"showLink"} value={props.data?.showLink} featureName={"Show original link"} setValue={props.setValue} imageName={<AiOutlineLink/>}/>
                </div>
                <div className="subElement">
                    <ColorPicker name={"linkColor"} value={props.data?.linkColor??"black"} setValue={props.setValue} featureName={"Link color"}/>
                </div>
                <div className="subElement">
                    <ColorPicker name={"titleColor"} value={props.data?.titleColor??"black"} setValue={props.setValue} featureName={"Title color"}/>
                </div>
                <div className="subElement">
                    <ColorPicker name={"descriptionColor"} value={props.data?.descriptionColor??"black"} setValue={props.setValue} featureName={"Description color"}/>
                </div>
                <div className="subElement">
                    <ColorPicker name={"dateColor"} value={props.data?.dateColor??"black"} setValue={props.setValue} featureName={"Date color"}/>
                </div>
                <div className="subElement">
                    <ColorPicker name={"bgColor"} value={props.data?.bgColor} setValue={props.setValue} featureName={"Background color"}/>
                </div>
                <div className="subElement">
                    <Spacing name={"margin"} value={props.data?.margin} setValue={props.setValue} featureName={"Margin"}/>
                </div>
                <div className="subElement">
                    <Spacing name={"padding"} value={props.data?.padding} setValue={props.setValue} featureName={"Padding"}/>
                </div>
                <div className="subElement">
                    <Spacing name={"borderRadius"} value={props.data?.borderRadius} setValue={props.setValue} featureName={"Border radius"}/>
                </div>
                <div className="subElement">
                    <ColorPicker name={"borderColor"} value={props.data?.borderColor} setValue={props.setValue} featureName={"Border color"}/>
                </div>
                <div className="subElement">
                    <SliderInput name={"borderWidth"} value={props.data?.borderWidth} setValue={props.setValue} featureName={"Border width"} min={0} max={20}/>
                </div>
            </div>
            <div className="editItem">
                <Animation name={'animation'} setValue={props.setValue} value={props.data.animation} featureName="Animation" imageName={<MdAnimation style={{fontSize:"18px"}}/>}/>
            </div>
        </div>
    }else {
        return <div style={bgImage(props?.data)??{}} className={animation}>
            {list &&
                    list.map((item,key)=>{
                        if (key < props.data.articles??10) {
                            return (
                                <div key={key} className="mb-2">
                                    <h4 style={{color: props.data?.titleColor}}>{item.title}</h4>
                                    <p style={{color: props.data?.dateColor}}>{item.pubDate}</p>
                                    <div className="rssDescription" style={{color: props.data?.descriptionColor}}
                                         dangerouslySetInnerHTML={{__html: item.description}}></div>
                                    {props.data?.showLink === "on" &&
                                        <>
                                            {item.link ?
                                                <a style={{color: props.data?.linkColor}} href={item.link}
                                                   target="_blank" rel="noopener noreferrer">{item.link}</a>
                                                :
                                                urlRegex.test(item.guid) ?
                                                    <a style={{color: props.data?.linkColor}} href={item.guid}
                                                       target="_blank" rel="noopener noreferrer">{item.guid}</a>
                                                    :
                                                    <></>
                                            }
                                        </>
                                    }
                                    <hr/>
                                </div>
                            )
                        }else {
                            return <></>
                        }
                    })
            }
        </div>
    }
}

export default AirbnbReviews
