import React from 'react'
import { ItemTypes } from './Constants'
import { useDrop } from 'react-dnd'
import Component from "./Component";
import { Overlay, OverlayType } from './Overlay.js'
import {useDispatch, useSelector} from "react-redux";
import uniqid from "uniqid";
import _ from "lodash";
import {setContent} from "../../redux/actions/pageeditorActions";

function SectionItem({ x, y, component, name, image, source, templateId, isPersistent }) {
    const app = useSelector(state => {
        return state.app
    });

    const dispatch = useDispatch()

    const pageeditor = useSelector(state => {
        return state.pageEditor
    })

    const move = (component,x,y) => {
        let currentItems = pageeditor.sections, newItems = [], newSection, n = pageeditor.sections.length, i, j, k, newId = uniqid()
        for (i = 0; i < n; i++) {
            k = currentItems[i].elements.length

            newSection = {elements:[]}
            if (currentItems[i].layout) {
                newSection.layout = currentItems[i].layout
            }

            for (j = 0; j < k; j++) {
                if (y === i && x === j) {
                    let newitem = _.clone(component)
                    newitem.id = newId
                    newSection.elements.push(newitem)
                }else {
                    if (component.id !== currentItems[i].elements[j].id) {
                        newSection.elements.push(currentItems[i].elements[j])
                    }else {
                        currentItems[i].elements[j] = {id:uniqid()}
                        newSection.elements.push(currentItems[i].elements[j])
                    }
                }
            }

            newItems.push(newSection)
        }

        dispatch(setContent({sections:newItems,editBlockId:newId,editType:'block'}))
    }

    const [{ isOver,canDrop }, drop] = useDrop(() => ({
        accept: ItemTypes.BLOCK,
        drop: (item) => {
            move(item,x,y)
        },
        canDrop: (item) => {
            if (item.id === component.id) {
                return true
            }
            return !component.component
        },
        collect: monitor => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop(),
        }),
    }), [x, y, move])

    return (
        <div ref={drop} style={{width:"100%",height:"100%",position:app.openedMenu?"":"relative"}}>
            <Component dragItem={component} name={name} image={image} source={source} templateId={templateId} isPersistent={isPersistent}/>
            {isOver && !canDrop && <Overlay type={OverlayType.IllegalMoveHover} />}
            {isOver && canDrop && <Overlay type={OverlayType.LegalMoveHover} />}
        </div>
    )
}

export default SectionItem
