import React, {useState} from "react";

const TextInput = ({setValue, name, value, featureName}) => {

    const [currentValue, setCurrentValue] = useState(value)

    const handleChange = (e) => {
        setCurrentValue(e.target.value)
        setValue(name,e.target.value)
    }

    return <div className="slider">
        <div className="row">
            <div className="col">
                <span className="blockTitle">{featureName}</span>
            </div>
        </div>
        <div className="row ps-1">
            <div className="col">
                <input className="form-control" value={currentValue} onChange={(e)=>handleChange(e)}/>
            </div>
        </div>
    </div>
}

export default TextInput
